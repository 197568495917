'use strict';

var formPrepare = require('./formPrepare'),
    progress = require('../../progress'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    storelocator = require('../storelocator'),
    _debounce = require('../../lib/lodash.debounce'),
    storesDialog = require('./storesDialog'),
    googleplaces = require('../../googleplaces'),
    usaPostalMap = require('../../helpers/usaStatePostalMapping'),
    shippingCreateAccount = require('./shippingCreateAccount');

var shippingMethods, $form, $loader, $ajaxLoading, updatingShippingMethodList,
    ajaxRequestCount = 0;

/**
 * @function
 * @description function to show the page ajax loader and disable the submit button (does change the validation process submit button disable status)
 */
function showLoader() {
    ajaxRequestCount++;
    if (ajaxRequestCount === 1) {
        $loader.parent().addClass('gl_loading');
        $loader.removeClass('hidden');
        $ajaxLoading.val('').trigger('change'); // empty value = form invalid (see formPrepare.js)
    }
}

/**
 * @function
 * @description function to hide the page ajax loader and enable the submit button (does change the validation process submit button disable status)
 */
function hideLoader() {
    ajaxRequestCount--;
    if (ajaxRequestCount <= 0) {
        $loader.addClass('hidden');
        $loader.parent().removeClass('gl_loading');
        $ajaxLoading.val('done').trigger('change');
        ajaxRequestCount = 0; //security
    }
}

/**
 * @function
 * @description Initializes gift message box, if shipment is gift
 */
function giftMessageBox() {
    // show gift message box, if shipment is gift
    $('.gift-message-text').toggleClass('hidden', $('input[name$="_shippingAddress_isGift"]:checked').val() !== 'true');
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
    var $summary = $('#secondary.summary');

    // load the updated summary area
    showLoader();
    $.ajax({
        url: window.Urls.summaryRefreshURL,
        data: {checkoutstep: 3}
    }).done(function (data) {
        $summary.html(data);

        // hide edit shipping method link
        //$summary.fadeIn('fast');
        $summary.find('.checkout-mini-cart .minishipment .header a').hide();
        $summary.find('.order-totals-table .order-shipping .label a').hide();

        window.vbqUtils.priceDisplay.refresh();
    }).fail(function () {
        window.alert(Resources.CHECKOUT_AJAX_ERROR);
    })
    .always(function () {
        window.vbqUtils.cache.$window.trigger('stickyCheckoutRightCol');
        hideLoader();
    });
}

/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(url, extraParams) {
    // ERB-213 - Address forms - USA/Canada states logic (+ Australia and Mexico)
    // Changes to properly get all the address fields

    var $formInputs = $form.find(':input'),
        phoneNumberIntl = $formInputs.filter('[name$="_phone_intltel"]').length ? window.intlTelInputGlobals.getInstance($formInputs.filter('[name$="_phone_intltel"]')[0]).getNumber() : '',
        phoneNumber = $formInputs.filter('[name$="_phone"]').val(),
        params = {
            vlb_pickUpInStoreStoreId: $formInputs.filter('#js-storeId').val() || '',
            firstName: $formInputs.filter('[name$="_firstName"]').val(),
            lastName: $formInputs.filter('[name$="_lastName"]').val(),
            address1: $formInputs.filter('[name$="_address1"]').val(),
            address2: $formInputs.filter('[name$="_address2"]').val(),
            countryCode: $formInputs.filter('[id$="_country"]').val(),
            stateCode: $formInputs.filter('[id$="_state"]').val(),
            postalCode: $formInputs.filter('[name$="_postal"]').val(),
            city: $formInputs.filter('[name$="_city"]').val(),
            phone: phoneNumberIntl !== null && phoneNumberIntl != '' ? phoneNumberIntl : phoneNumber,
            format: 'ajax'
        };

    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
function selectShippingMethod(shippingMethodID) {
    // nothing entered
    if (!shippingMethodID) {
        return;
    }

    // attempt to set shipping method
    showLoader();
    $.getJSON(
        getShippingMethodURL(window.Urls.selectShippingMethodsList, {shippingMethodID: shippingMethodID})
    ).done(function (data) {
        updateSummary();
        if (!data || (!data.shippingMethodID && !data.error)) {
            window.alert('Couldn\'t select shipping method.');
            hideLoader();
            return false;
        }
        else if (data.error && data.message) {
            // ERB-236 - Avalara tax computation
            if (data.type != 'invalidBasket') {
                var $submitButton = $('#js-submitButtonWrapper');
                $submitButton.hide();
                $('#taxError').remove();
                $('<div id="taxError" class="error-form">' + data.message + '</div>').insertAfter($submitButton);
            }
            else {
                window.location = window.Urls.cartShow;
            }
        }

        // display promotion in UI and update the summary section,
        // if some promotions were applied
        $('.shippingpromotions').empty();

        // SG-TO-DO the for loop below isn't doing anything?
        // if (data.shippingPriceAdjustments && data.shippingPriceAdjustments.length > 0) {
        //     var len = data.shippingPriceAdjustments.length;
        //     for (var i=0; i < len; i++) {
        //         var spa = data.shippingPriceAdjustments[i];
        //     }
        // }

        // SR-46 SFCC - checkout pages
        if (window.vbqUtils.isSR) {
            var $shippingMethodList = $('.js-shipping-method-list');
            $shippingMethodList.find('.shipping-method-wrapper').removeClass('active');
            $shippingMethodList.find('.shippingmethod-details-wrapper.ui-state-active').closest('.shipping-method-wrapper').addClass('active');
        }

        hideLoader();
    }).fail(function () {
        window.alert(Resources.CHECKOUT_AJAX_ERROR);
        hideLoader();

        // updateSummary() doesn't trigger but summary should remain sticky
        window.vbqUtils.cache.$window.trigger('stickyCheckoutRightCol');
    });
}

/**
 * ERB-52 - International Shipping
 * Ask for customer confirmation when filled address does not match the cart mode (domestic/international)
 *
 * @param {String} message
 * @param {Array} buttons
 */
function askForConfirmation(message, buttons) {
    buttons.push({
        id: 'js-askForConfirmationClose',
        text: window.Resources.CLOSE,
        click: function () {
            $askForConfirmation.dialog('close');
        }
    });

    // inform the customer and ask confirmation
    var $askForConfirmation = $('<div>').attr('id', 'js-askForConfirmation').append(message).dialog({
        autoOpen: false,
        draggable: false,
        modal: true,
        resizable: false,
        width: '800',
        closeOnEscape: true, // EADA-34 - All modal can be closing on escape key: .dialog() case
        closeText: window.Resources.CLOSE,
        classes: {
            'ui-dialog': 'askForConfirmationDialog' //used to force styles
        },
        buttons: buttons,
        open: function (e) {
            // EADA-40 - The modal container is not labeled as such: .dialog() case
            window.vbqUtils.jqDialogOpenAdjust($(e.target).closest('.ui-dialog'));
        }
    });
    $askForConfirmation.siblings('div.ui-dialog-titlebar').remove();
    $askForConfirmation.parent().find('.ui-button').addClass('gl_button gl_btn-blue');

    $askForConfirmation.dialog('open');
}

/**
 * @function
 * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
 * based on the merchandise in the cart and the currently entered shipping address
 * (the address may be only partially entered).  If the list of applicable shipping methods
 * has changed because new address information has been entered, then issue another AJAX
 * request which updates the currently selected shipping method (if needed) and also updates
 * the UI.
 */
function updateShippingMethodList() {
    if (updatingShippingMethodList) {
        return;
    }

    var $submitButton = $('#js-submitButtonWrapper');

    // ERB-236 - Avalara tax computation
    $submitButton.show();
    $('#taxError').remove();

    var $shippingMethodList = $('.js-shipping-method-list');
    if (!$shippingMethodList || $shippingMethodList.length === 0) {
        updatingShippingMethodList = false;
        return;
    }

    //only send request if the postal code is empty or valid (format check on several countries)
    //also wait for state code for Canada and Australia (needed for validation) (+ Mexico)
    var $postalCode = $form.find('[id$="_postal"]'),
        $stateCode = $form.find('[id$="_states_state"]'),
        isCA = window.vbq && window.vbq.localizationData && window.vbq.localizationData.countryCode == 'CA',
        isAU = window.vbq && window.vbq.localizationData && window.vbq.localizationData.countryCode == 'AU',
        isMX = window.vbq && window.vbq.localizationData && window.vbq.localizationData.countryCode == 'MX';

    if (($postalCode.val() === '' || $postalCode.valid())
        && (!isCA || !isAU || !isMX || $stateCode.val() !== '')
    ) {
        updatingShippingMethodList = true;
        showLoader();
        $.getJSON(
            getShippingMethodURL(window.Urls.shippingMethodsJSON)
        ).done(function (data) {
            updatingShippingMethodList = false;
            if (!data) {
                window.alert('Couldn\'t get list of applicable shipping methods.');
                hideLoader();
                return false;
            }

            // ERB-52 International Shipping
            // address are validated when enough fields are filled
            if (data.error === true) {
                //manage error type
                var validator = $form.validate(),
                    options = {};
                switch (data.type) {
                    case 'noCart':
                        window.location = window.Urls.cartShow;
                        break;
                    case 'invalidJurisdiction': // Avatax
                    case 'invalidTax': // Avatax
                    case 'invalidBasket': // Avatax
                    case 'invalidDutiesAndTaxes': // Zonos
                        $submitButton.hide();
                        alert(data.message);
                        break;
                    case 'multishippingNotSupportedForInternational':
                        $submitButton.hide();
                        alert(data.message);
                        break;
                    case 'invalidAddress':
                        options[$form.find('input[name$="_address1"]').attr('name')] = data.message;
                        validator.showErrors(options);
                        break;
                    case 'invalidState':
                        options[$form.find('input[name$="_stateCode"]').attr('name')] = data.message;
                        validator.showErrors(options);
                        break;
                    case 'invalidZipCodeFormat':
                        options[$form.find('input[name$="_postal"]').attr('name')] = data.message;
                        validator.showErrors(options);
                        break;
                    case 'invalidCountry':
                        options[$form.find('input[name$="_country"]').attr('name')] = data.message;
                        validator.showErrors(options);
                        break;
                    case 'invalidModeUS':
                        $submitButton.hide();
                        // inform the customer and ask confirmation
                        askForConfirmation(
                            data.message,
                            [
                                {
                                    text: window.Resources.I_AGREE,
                                    click: function () {
                                        progress.show($('.askForConfirmationDialog').find('.ui-dialog-buttonset'));
                                        window.location = window.Urls.EU_updateSessionURL + '?country=' + $form.find('select[id$="_state"]').val() + '&currency=USD&forceRHTPricebook=true';
                                    }
                                }
                            ]
                        );
                        break;
                    case 'invalidModeFromDomesticToInternational':
                        $submitButton.hide();
                        // inform the customer and ask confirmation
                        askForConfirmation(
                            data.message,
                            [
                                {
                                    text: window.Resources.I_AGREE,
                                    click: function () {
                                        showLoader();
                                        progress.show($('.askForConfirmationDialog').find('.ui-dialog-buttonset'));
                                        $.getJSON(
                                            window.Urls.contextChooserForceCartMode + '?mode=RHT&country=' + data.countryCode
                                        ).done(function (data) {
                                            // force the new country value before submitting
                                            $form.find('input[name$="_country"]').val(data.countryCode);
                                            // submit the form with saveAddressInCart action, bypassing the validation
                                            $('<button name="dwfrm_singleshipping_shippingAddress_saveAddressInCart" type="submit" formnovalidate>').appendTo('.address').click();
                                            //hideLoader(); do not hide the loader as the form submit is ongoing
                                        }).fail(function () {
                                            window.alert(Resources.CHECKOUT_AJAX_ERROR);
                                            hideLoader();
                                        });
                                    }
                                }
                            ]
                        );
                        break;
                    case 'invalidModeFromInternationalToDomestic':
                    case 'invalidModeFromDomesticToDomestic':
                        $submitButton.hide();
                        // inform the customer and ask confirmation
                        askForConfirmation(
                            data.message,
                            [
                                {
                                    text: window.Resources.I_AGREE,
                                    click: function () {
                                        progress.show($('.askForConfirmationDialog').find('.ui-dialog-buttonset'));
                                        showLoader();
                                        $.getJSON(
                                            window.Urls.contextChooserForceCartMode + '?mode=RET&country=' + data.countryCode
                                        ).done(function (data) {
                                            // force the new country value before submitting
                                            $form.find('input[name$="_country"]').val(data.countryCode);
                                            // submit the form with saveAddressInCart action, bypassing the validation
                                            $('<button name="dwfrm_singleshipping_shippingAddress_saveAddressInCart" type="submit" formnovalidate>').appendTo('.address').click();
                                          //hideLoader(); do not hide the loader as the form submit is ongoing
                                        }).fail(function () {
                                            window.alert(Resources.CHECKOUT_AJAX_ERROR);
                                            hideLoader();
                                        });
                                    }
                                }
                            ]
                        );
                        break;
                    default:
                        $submitButton.hide();
                        alert('Unmapped error ' + data.type);
                }

                hideLoader();
                return false;
            }

            // Always updated the UI (price display bug when omniDefaultShipment is not the defaultShipment)
            shippingMethods = data;

            // load the shipping method form
            showLoader();
            $.ajax({
                url: getShippingMethodURL(window.Urls.shippingMethodsList)
            }).done(function (data) {
                if (!data) {
                    window.location = window.Urls.cartShow;
                }

                $shippingMethodList.html(data);
                $shippingMethodList.fadeIn('fast');

                // rebind the radio buttons onclick function to a handler.
                $shippingMethodList.find('[name$="_shippingMethodID"]').click(function () {
                    selectShippingMethod($(this).val());
                });

                // if nothing is selected in the shipping methods select the first one
                if ($shippingMethodList.find('.input-radio:checked').length === 0) {
                    $shippingMethodList.find('.input-radio:first').prop('checked', true);
                }

                selectShippingMethod($shippingMethodList.find('.input-radio').filter(':checked').val());

                tooltip.init();
                hideLoader();

                formPrepare.init({
                    continueSelector: '[name$="shippingAddress_save"]',
                    formSelector: '[id$="singleshipping_shippingAddress"]'
                });

                formPrepare.validateForm(); // init the form validation again so the new fields are taken into account
                $form.validate(); // revalidate the form
                // Initialize the jQuery UI elements
                window.vbqUtils.initializeJQueryUiPlugins();
            }).fail(function () {
                window.alert(Resources.CHECKOUT_AJAX_ERROR);
                hideLoader();
            });

            hideLoader();

        }).fail(function () {
            window.alert(Resources.CHECKOUT_AJAX_ERROR);
            updatingShippingMethodList = false;
            hideLoader();
        });
    } else {
        updatingShippingMethodList = false;
    }
}

/**
 * @function
 * clean all address form fields except country (forced)
 */
function resetAddress() {
    $form.find(':input').not('[name$="_country"]').not('[name$="_country_fake"]') // nerve change the country
        .filter('#js-storeId').val('').end()
        .filter('[type="text"], [type="tel"]').val('').trigger('change').end()
        .filter('[type="checkbox"]').prop('checked', false).trigger('change').checkboxradio().checkboxradio('refresh').end()
        .filter('select').filter('[id$="_state"]').val('').trigger('change');

    $form.find('.gl_form-group').removeClass('gl_has-error gl_has-success');
}

/**
 * @function
 * manage the pick up in store feature
 */
function pickUpInStoreInit() {
    // ERB-22 Omnichannel pick up in-store
    // ERB-214 Storelocator - Google Map and stores list for pick up in-store checkout
    var $singleShippingElements = $form.find('.js-singleShippingElements'),
        $pickUpInStoreElements = $form.find('.js-pickUpInStoreElements'),
        $pickUpInStoreDialogWrapper = $('#js-pickUpInStoreDialogWrapper'),
        $pickUpInStoreOpenDialog = $('#js-pickUpInStoreOpenDialog'),
        $shippingTypeButtons = $('.js-shippingTypeButton'),
        $shipToStoreAddress = $('#js-shipToStoreAddress'),
        $shipToSingleAddress = $('#js-shipToSingleAddress'),
        $storeAddressCard = $('#js-storeAddressCard'),
        showStoreSection = function () {
            $singleShippingElements.addClass('hidden');
            resetAddress();
            $pickUpInStoreElements.removeClass('hidden');

            //ERV-560 - Checkout: modification of shipping options - filling form with store data to pass further validation
            var storeID = $storeAddressCard.attr('data-storeIdForLoad'),
                store = window.vbq.stores[storeID];
            fillFormWithStoreData(store);

            $shippingTypeButtons.removeClass('active');
            $shipToStoreAddress.siblings('.js-shippingTypeButton').addClass('active');
        };

    $shippingTypeButtons.on('keydown', function (e) {
        // mouse click or enter/space key
        if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
            // prevent the default action to stop scrolling when space is pressed
            e.preventDefault();

            $(e.target).find('.js-shippingTypeChoice').trigger('click');
        }
    });

    $shipToStoreAddress.on('click', function () {
        showStoreSection();
    });

    $shipToSingleAddress.on('click', function () {
        if ($pickUpInStoreElements.length) {
            resetAddress();
            $storeAddressCard.addClass('hidden').find('.store-address').empty();
            $singleShippingElements.removeClass('hidden');
            $pickUpInStoreElements.addClass('hidden');
        }

        $pickUpInStoreOpenDialog.html($pickUpInStoreOpenDialog.attr('data-label')).removeClass('gl_btn-white gl_btn-small').addClass('gl_btn-blue');

        $shippingTypeButtons.removeClass('active');
        $shipToSingleAddress.siblings('.js-shippingTypeButton').addClass('active');
    });

    $pickUpInStoreOpenDialog.on('click', function (e) {
        e.preventDefault();

        var $pickUpInStoreCloseDialog = $pickUpInStoreDialogWrapper.find('.js-store-locator-close');

        $pickUpInStoreDialogWrapper.removeClass('hidden').dialog({
            draggable: false,
            modal: true,
            resizable: false,
            closeText: window.Resources.CLOSE,
            closeOnEscape: true, // EADA-34 - All modal can be closing on escape key: .dialog() case
            title: window.Resources.SELECT_STORE,
            classes: {
                'ui-dialog': 'pickUpInStoreDialog shippingPickUpInStoreDialog' //used to force styles
            },
            position: {
                my: 'center top',
                at: 'center top'
            },
            open: function (e) {
                // EADA-40 - The modal container is not labeled as such: .dialog() case
                window.vbqUtils.jqDialogOpenAdjust($(e.target).closest('.ui-dialog'));

                // deactivate body scroll on mobile/tablet when dialog full width/height
                window.vbqUtils.cache.$body.addClass('storelocator-dialog-active');

                storelocator.init();
                // ECM-297 - Checkout - Pick up in store - Select a store
                // Trigger the callback after the dialog opens, so that the map displays
                window.vbqUtils.cache.$document.trigger('gMapCallback');

                $pickUpInStoreCloseDialog.on('click', function () {
                    $pickUpInStoreDialogWrapper.dialog('close');
                });

                window.vbqUtils.cache.$document.on('click', '.ui-widget-overlay', function () {
                    $pickUpInStoreCloseDialog.trigger('click');
                });
            },
            focus: function () {
                // EADA-74 - Storelocator - focus on modal opening
                $pickUpInStoreCloseDialog.focus();
            },
            close: function () {
                // activate body scroll
                window.vbqUtils.cache.$body.removeClass('storelocator-dialog-active');

                $pickUpInStoreCloseDialog.off('click');

                // EADA-74 - Storelocator - focus on open dialog button on modal close
                $pickUpInStoreOpenDialog.focus();
            }
        });
    });

    window.vbqUtils.cache.$window.resize(_debounce(function () {
        if ($pickUpInStoreDialogWrapper.is(':visible')) {
            if (window.vbqUtils.breakpoint.is('fromDesktop')) {
                $pickUpInStoreDialogWrapper.dialog('option', 'position', {
                    my: 'center top+100',
                    at: 'center top',
                    of: window,
                    collision: 'none'
                });
            }
        }
    }, 400));

    // init can be done here as the dialog is already in the page source code
    storesDialog.init();

    $('.js-storesWrapper').on('click', '.js-chooseStore', function () {
        var storeID = $(this).attr('data-store'),
            store = window.vbq.stores[storeID];
        //1 fill card with translated Address
        $storeAddressCard.removeClass('hidden')
            .find('.store-name').html(store.name).end()
            .find('.store-address').html(store.translatedAddress).end()
            .find('.store-phone').html(store.phone).attr('href', 'tel:' + store.phone).end()
            .find('.store-opening-hours-title').removeClass('hidden').end()
            .find('.store-detail-time-info').html(store.translatedStoreHoursDatetime);

        //ERV-560 - Checkout: modification of shipping options - use function for filling form data
        fillFormWithStoreData(store);

        //ERV-437 - [US Site] Order Total false - Order Summary - enable change binding
        $form.find(':input')
            .filter('[name$="_address1"], [name$="_address2"], [name$="_state"], [name$="_city"], [name$="_postal"]')
            .trigger('change');

        //ERV-560 - Checkout: modification of shipping options - replace initial store id with selected one
        $storeAddressCard.attr('data-storeIdForLoad', store.id);

        //3 update dialog button label and classes
        $pickUpInStoreOpenDialog.html($pickUpInStoreOpenDialog.attr('data-anotherLabel')).removeClass('gl_btn-blue').addClass('gl_btn-white gl_btn-small');

        $pickUpInStoreDialogWrapper.dialog('close');
        if (window.vbqUtils.breakpoint.is('mobileAndTablet')) {
            window.vbqUtils.cache.$body.scrollTop(0);
        }
    });

    // on load, if store ID is known then fill the store detail card
    if ($shipToStoreAddress.prop('checked')) {
        showStoreSection();
    }
}

/**
 * ERV-437 - [US Site] Order Total false - Order Summary - create separate function for disabling change event binding
 * @function
 * disables binding of form inputs change
 */
function disableFormInputsChange($form) {
    $form.off('change',
        'input[name$="_addressFields_address1"], input[name$="_addressFields_address2"], select[name$="_addressFields_states_state"], input[name$="_addressFields_states_state"], input[name$="_addressFields_city"], input[name$="_addressFields_postal"]'
    );
}

/**
 * ERV-437 - [US Site] Order Total false - Order Summary - create separate function for enabling change event binding
 * @function
 * enables binding of form inputs change
 */
function enableFormInputsChange($form) {
    $form.on('change',
        'input[name$="_addressFields_address1"], input[name$="_addressFields_address2"], select[name$="_addressFields_states_state"], input[name$="_addressFields_states_state"], input[name$="_addressFields_city"], input[name$="_addressFields_postal"]',
        updateShippingMethodList
    );
}

/**
 * ERV-560 - Checkout: modification of shipping options
 * @function
 * fills form fields with store data
 */
function fillFormWithStoreData(store) {
    //ERV-437 - [US Site] Order Total false - Order Summary - disable change binding
    disableFormInputsChange($form);

    //2 fill address form fields with store address
    //clean all address fields except country (forced)
    resetAddress();

    store = store || {};

    var tmp = (store && store.name) ? store.name.split(' ') : [],
        firstname = '',
        lastname = '';
    for (var i = 0; i < tmp.length; i++) {
        if (i === 0) {
            firstname = tmp[i];
        }
        else {
            lastname = lastname + ' ' + tmp[i];
        }
    }

    //security
    if (lastname === '') {
        lastname = 'Vilebrequin';
    }

    //ERV-437 - [US Site] Order Total false - Order Summary - removed triggering
    var $formInputs = $form.find(':input');
    $formInputs.filter('#js-storeId').val(store.id);
    $formInputs.filter('[name$="_firstName"]').val(firstname);
    $formInputs.filter('[name$="_lastName"]').val(lastname);
    $formInputs.filter('[name$="_address1"]').val(store.address1);
    $formInputs.filter('[name$="_address2"]').val(store.address2);
    //$formInputs.filter('[id$="_country"]').val(store.countryCode); //forced
    $formInputs.filter('[id$="_state"]').val(store.stateCode);
    $formInputs.filter('[name$="_postal"]').val(store.postalCode);
    $formInputs.filter('[name$="_city"]').val(store.city);
    $formInputs.filter('[name$="_phone"]').val(store.phone);
    $formInputs.filter('[name$="_phone_intltel"]').val(store.phone);

    //ERV-437 - [US Site] Order Total false - Order Summary - enable change binding
    enableFormInputsChange($form);
}

exports.init = function () {
    googleplaces.init({
        country: window.vbq.localizationData.countryCode
    });

    formPrepare.init({
        continueSelector: '[name$="shippingAddress_save"]',
        formSelector: '[id$="singleshipping_shippingAddress"]'
    });

    $form = $('form[id$="_shippingAddress"]');
    $loader = $('#js-ajaxLoader');
    $ajaxLoading = $('#js-ajaxLoading');

    // ECM24-57 - Create an account checkout // Shipping Info
    shippingCreateAccount.init($form);

    // ECM23-119 - USA ZIP Codes & Postal Codes List By States
    usaPostalMap.init($form);

    window.vbqUtils.phoneCodes.init($form.find('.country_field'), $form.find('.phone_field'));
    $('input[name$="_shippingAddress_isGift"]').on('click', giftMessageBox);

    //ERV-437 - [US Site] Order Total false - Order Summary - use function
    enableFormInputsChange($form);

    // ERB-213 - Address forms - USA/Canada states logic (+ Australia and Mexico)
    // Update the shipping form
    $form.on('change', 'select[name$="_addressFields_country"]', function () {
        var $this = $(this);
        showLoader();
        $.ajax({
            url: util.appendParamsToUrl(Urls.updateShippingAddressForm, {'countryCode': $this.val(), 'form': $form.serialize()})
        }).done(function (response) {
            $form.find('.address-dynamic-form').html(response);

            hideLoader();
            formPrepare.init({
                continueSelector: '[name$="shippingAddress_save"]',
                formSelector:'[id$="singleshipping_shippingAddress"]'
            });

            // Initialize the jQuery UI elements
            window.vbqUtils.initializeJQueryUiPlugins();
            window.vbqUtils.initFloatingLabels($form);
            window.vbqUtils.phoneCodes.init($form.find('.country_field'), $form.find('.phone_field'));

            // update cached form elements
            googleplaces.updateCache();

            // ECM23-119 - USA ZIP Codes & Postal Codes List By States
            // update cached form elements and events
            usaPostalMap.update();
        }).fail(function () {
            window.alert(window.Resources.CHECKOUT_AJAX_ERROR);
            hideLoader();
        });
    });

    giftMessageBox();
    updateShippingMethodList();
    pickUpInStoreInit();

    // ERB-51 - SEO Tracking - Google Tag Manager - push GA event
    $('button.shipping-continue-button').filter('[name$="shippingAddress_save"]').on('click', function () {
        // ECM24-8 - Datalayer update (GA4 GTM)
        var shippingMethod = $('.input-radio').filter('[name$="_shippingMethodID"]').filter(':checked').val();
        window.vbqUtils.gtmUtils.pushCheckoutInfoEvent(1, {shippingMethods: [shippingMethod]});

        // show loader to inform the customer
        if ($form.valid()) {
            $loader.parent().addClass('gl_loading');
            $loader.removeClass('hidden');
        }
    });

    // VOP-4 - Add the possibility to deliver an UFD Cannes order to Ondine Plage
    var $ondineAddressToggle = $('#js-ondineAddressToggle');
    if ($ondineAddressToggle.length) {
        var $formInputs = $form.find(':input'),
            $label = $('#' + $formInputs.filter('[name$="_address1"]').attr('id')).siblings('label'),
            $postalCodeInput = $formInputs.filter('[name$="_postal"]');
        $ondineAddressToggle.on('click', function () {
            if ($ondineAddressToggle.prop('checked')) {
                // fill the shipping address with the Vilebrequin Ondine Plage one
                $formInputs.filter('[name$="_firstName"]').val("L'ONDINE").trigger('change');
                $formInputs.filter('[name$="_lastName"]').val('VILEBREQUIN PLAGE').trigger('change');
                $formInputs.filter('[name$="_address1"]').val('').trigger('change');
                $formInputs.filter('[name$="_address2"]').val('64, Bd de la Croisette').trigger('change');
                $formInputs.filter('[id$="_state"]').val('').trigger('change');
                $formInputs.filter('[name$="_city"]').val('CANNES').trigger('change');

                // set the label
                $label.attr('original-label', $label.html()).html(window.Resources.ONDINE_ALTERNATE_LABEL + '*');
                // hide the unwanted captions
                $('#dwfrm_singleshipping_shippingAddress_addressFields_postal-caption').addClass('hidden');
                $('#dwfrm_singleshipping_shippingAddress_addressFields_address1-caption').addClass('hidden');

                // hide the "Use as billing" checkbox and uncheck it, idem for "Add To AddressBook"
                $('#js-ondineUseAsBilling').addClass('hidden');
                $('#js-useAsBillingAddress').prop('checked', false).trigger('change');
                $('#js-ondineAddToAddressBook').addClass('hidden');
                $('#js-addToAddressBook').prop('checked', false).trigger('change');

                $('.js-ondineElements').addClass('hidden');

                // VOP-6 - ondine delivery default miss info
                // moved postal field update last, because after it is changed, rest of the js code is not executed.
                $postalCodeInput.attr('original-val', $postalCodeInput.val() || '').val('06400').trigger('change');
            }
            else {
                // reset the shipping address form
                $formInputs.filter('[name$="_firstName"]').val('').trigger('change');
                $formInputs.filter('[name$="_lastName"]').val('').trigger('change');
                $formInputs.filter('[name$="_address1"]').val('').trigger('change');
                $formInputs.filter('[name$="_address2"]').val('').trigger('change');
                $formInputs.filter('[id$="_state"]').val('').trigger('change');
                $formInputs.filter('[name$="_city"]').val('').trigger('change');

                // reset the label
                $label.text($label.attr('original-label')).removeAttr('original-label');
                // show the hidden captions
                $('#dwfrm_singleshipping_shippingAddress_addressFields_postal-caption').removeClass('hidden');
                $('#dwfrm_singleshipping_shippingAddress_addressFields_address1-caption').removeClass('hidden');

                // show the "Use as billing" checkbox, idem for "Add To AddressBook"
                $('#js-ondineUseAsBilling').removeClass('hidden');
                $('#js-ondineAddToAddressBook').removeClass('hidden');

                $('.js-ondineElements').removeClass('hidden');

                // VOP-6 - ondine delivery default miss info
                // moved postal field update last, because after it is changed, rest of the js code is not executed.
                $postalCodeInput.val($postalCodeInput.attr('original-val') || '').trigger('change');
            }

            $form.valid();
        });
    }
};

exports.updateShippingMethodList = updateShippingMethodList;
