'use strict';


// ECM24-48 - Checkout Success page optimization for VBQ
var createaccount = window.vbq.siteId.indexOf('SR') != -1 ? require('../account') : require('./createaccount');

exports.init = function init () {
    // ECM24-48 - Checkout Success page optimization
    createaccount.init();
};
