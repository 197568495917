'use strict';

var cache = {};

function initCache() {
    cache.$document = $(document);
    cache.$specialDay = cache.$document.find('.js-special_day');
    cache.$giftDateWrapper = cache.$document.find('.js-gift_date');
    cache.$giftDateText = cache.$giftDateWrapper.find('.js-gift_selected-date');
    cache.$giftDateValue = cache.$giftDateWrapper.find('.js-gift_selected-date-value');
    cache.$giftdate = cache.$document.find('#dwfrm_giftcert_purchase_giftdate');

    // get calendar locale
    cache.calendarLocale = cache.$giftdate && cache.$giftdate.length ?
        cache.$giftdate.attr('data-calendar-locale') :
        'default';

    // update calendar locale; if it's 'en' then use 'default'
    cache.calendarLocale = cache.calendarLocale !== 'en' ? cache.calendarLocale : 'default';
}

/**
 * on page load check if "giftdate" field has any value and show the calendar selection if it has
 */
function updateSpecialDayState() {
    if (cache.$giftdate.val()) {
        cache.$specialDay.trigger('click');
    }
}

/**
 * remove
 */
function clear() {
    // hide calendar selection
    if (cache.$specialDay.is(':checked')) {
        cache.$specialDay.trigger('click');
    }

    // reset any selected date
    cache.calendar.clear();
}

/**
 * Get min date for the calendar
 * It should be current date + 1 day
 * @returns {Date} date object
 */
function getMinDate() {
    var date = new Date();
    date.setDate(date.getDate() + 1);

    return date;
}

/**
 * Get max date for the calendar
 * It should be current date + 12 months
 * @returns {Date} date object
 */
function getMaxDate() {
    var date = new Date();
    date.setMonth(date.getMonth() + 12);

    return date;
}

/**
 * Set as default date any date set on the giftdate field
 * @returns {Date|null} date or null if no value is set on giftdate field
 */
function getDefaultDate() {
    return cache.$giftdate.val() || null;
}

/**
 * Show or hide gift date field
 * @param {Object} e - event object
 */
function specialDayAction(e) {
    cache.$giftDateWrapper.toggleClass('hidden', !e.currentTarget.checked);
}

function initEvents() {
    cache.$specialDay.on('click', specialDayAction);

    cache.calendar = window.flatpickr('#dwfrm_giftcert_purchase_giftdate', {
        inline: true,
        minDate: getMinDate(),
        maxDate: getMaxDate(),
        dateFormat: 'Y.m.d', // used to send date to server
        altFormat: 'd F Y', // used for storefront
        altInput: true,
        altInputClass: 'giftcert-alt-input',
        locale: cache.calendarLocale,
        defaultDate: getDefaultDate(),
        onReady: function(selectedDates, dateStr, instance) {
            // fix calendar keyboard accessibility
            instance.prevMonthNav.setAttribute('tabindex', '0');
            instance.prevMonthNav.setAttribute('role', 'button');
            instance.nextMonthNav.setAttribute('tabindex', '0');
            instance.nextMonthNav.setAttribute('role', 'button');
            instance.monthsDropdownContainer.setAttribute('tabindex', '0');
            instance.currentYearElement.setAttribute('tabindex', '0');
            instance.calendarContainer.setAttribute('aria-describedby', 'giftcert-calendar-desc');
        },
        onChange: function(dateobj, datestr, instance) {
            if (cache.calendar.altInput.value) {
                cache.$giftDateText.removeClass('hidden');
                cache.$giftDateValue.html(cache.calendar.altInput.value);
            }
            else {
                cache.$giftDateText.addClass('hidden');
            }
        },
        onKeyDown: function(dateobj, datestr, instance, e) {
            // 9  - tab
            // 13 - enter
            // 32 - space
            // 40 - arrow down

            // keyboard accessibility on prev month nav
            if ((e.keyCode == 13 || e.keyCode == 32) && e.target == instance.prevMonthNav) {
                e.preventDefault();
                instance.changeMonth(-1);
            }

            // keyboard accessibility on next month nav
            if ((e.keyCode == 13 || e.keyCode == 32) && e.target == instance.nextMonthNav) {
                e.preventDefault();
                instance.changeMonth(1);
            }

            // keyboard accessibility on calendar days
            if (e.keyCode == 40 && (e.target == instance.prevMonthNav || e.target == instance.nextMonthNav)) {
                instance.daysContainer.querySelector('.flatpickr-day:not(.prevMonthDay):not(.flatpickr-disabled)').focus();
            }

            // keyboard accessibility on calendar panel
            if (e.keyCode == 9 && e.shiftKey && e.target.classList.value.indexOf('flatpickr-day') != -1) {
                instance.nextMonthNav.focus();
            }
        }
    });
}

module.exports = {
    init: function () {
        initCache();
        initEvents();
        updateSpecialDayState();
    },
    clear: clear
};
