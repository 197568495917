'use strict';

var dialog = require('./dialog'),
    validator = require('./validator'),
    tooltip = require('./tooltip'),
    progress = require('./progress');

var login = {
    /**
     * @private
     * @function
     * @description init events for the loginPage
     */
    init: function () {
        var preventDoubleSubmit = false;

        //o-auth binding for which icon is clicked
        $('.oAuthIcon').on('click', function () {
            $('#OAuthProvider').val(this.id);
        });

        //toggle the value of the rememberme checkbox
        $('#dwfrm_login_rememberme').on('change', function () {
            if ($('#dwfrm_login_rememberme').attr('checked')) {
                $('#rememberme').val('true');
            } else {
                $('#rememberme').val('false');
            }
        });

        $('#password-reset').on('click', function (e) {
            e.preventDefault();

            dialog.open({
                url: $(e.target).data('href'),
                options: {
                    title: window.Resources.REQUEST_RESET_PASS,
                    classes: {
                        'ui-dialog': 'passwordResetDialog' // used to find close button
                    },
                    open: function () {
                        var $requestPasswordForm = $('form[name$="_requestpassword"]'),
                            $submit = $requestPasswordForm.find('button[name$="_requestpassword_send"]');

                        window.vbqUtils.initFloatingLabels($requestPasswordForm);

                        $requestPasswordForm.validate(); // required before adding rule
                        $requestPasswordForm.find('input').filter('[type="email"]').rules('add', {email: true});
                        window.vbqUtils.validationLogic.formValidation($requestPasswordForm);

                        $submit.off('click').on('click', function (e) {
                            if (!$requestPasswordForm.valid()) {
                                return;
                            }
                            e.preventDefault();
                            var data = $requestPasswordForm.serialize();
                            // add form action to data
                            data += '&' + $submit.attr('name') + '=';
                            // make sure the server knows this is an ajax request
                            if (data.indexOf('ajax') === -1) {
                                data += '&format=ajax';
                            }
                            $.ajax({
                                type: 'POST',
                                url: $requestPasswordForm.attr('action'),
                                data: data,
                                success: function (response) {
                                    if (typeof response === 'object'
                                        && !response.success
                                        && response.error === 'CSRF Token Mismatch'
                                    ) {
                                        window.location.href = Urls.csrffailed;
                                    }
                                    else if (typeof response === 'string') {
                                        dialog.$container.html(response);
                                    }
                                },
                                failure: function () {
                                    dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
                                },
                                complete: function (xhr, status) {
                                    // EADA-59 - Set focus after ajax update
                                    dialog.$close.focus();
                                }
                            });
                        });
                    }
                }
            });
        });

        // ERV-43 My Account - change password issue
        // add validation rules for password fields
        // ERV-125 registration page - no red text or borders on fields with errors
        // add validation for email field
        $('form').each(function () {
            var $form = $(this);
            $form.validate(); //required before adding rule
            $form.not('.js-noPasswordConstraint') // add the regexp only on forms for creation or modification of the account password, not login like one
                .find('input')
                .filter('[type="password"]')
                .not('[name^="dwfrm_profile_login_currentpassword_"], .customPasswordValidation')
                .each(function () {
                    // special characters accepted by Salesforce for customer password: #!&$%*+/?=^`~}|{
                    // login form: dwfrm_login_password_
                    // change information form: dwfrm_profile_login_password_
                    // change password form: dwfrm_profile_login_newpassword_ + dwfrm_profile_login_newpasswordconfirm_
                    // registration form: dwfrm_profile_login_password_ + dwfrm_profile_login_passwordconfirm_
                    $(this).rules('add', {minlength: 8, maxlength: 255, pattern: new RegExp(/^(?=.*[0-9])(?=.*[#\!&\$%\*\+\/\?\=\^`~\}\|\{])[a-zA-Z0-9#\!&\$%\*\+\/\?\=\^`~\}\|\{]{8,255}$/)});
                });

            $form.find('input')
                .filter('input[type="email"]').each(function () {
                    $(this).rules('add', {email: true});
                });

            window.vbqUtils.validationLogic.formValidation($form);
        });

        // password constraint details tooltip
        tooltip.init();

        //ERB-51 - SEO Tracking - Google Tag Manager - push GTM data on submit
        $('form.login-link-form').on('submit', function () {
            if (preventDoubleSubmit) {
                return false;
            }

            if ($(this).valid()) {
                preventDoubleSubmit = true;
                $('#js-guestCheckoutButton').attr('disabled', 'disabled');

                // ECM24-8 - Datalayer update (GA4 GTM)
                window.vbqUtils.gtmUtils.pushLoginEvent();
            }
        });

        $('.checkout-guest-form').on('submit', function () {
            if (preventDoubleSubmit) {
                return false;
            }

            if ($(this).valid()) {
                preventDoubleSubmit = true;
                $('#js-loginButton').attr('disabled', 'disabled');

                // ECM24-8 - Datalayer update (GA4 GTM)
                window.vbqUtils.gtmUtils.pushGuestCheckoutEvent();
            }
        });

        $('#js-guestCheckoutButton, #js-loginButton').on('click', function () {
            var $this = $(this);
            if ($this.closest('form').valid()) {
                $('#js-guestCheckoutButton, #js-loginButton').addClass('disabled');
                progress.show($this);
            }
        });
    }
};

module.exports = login;
