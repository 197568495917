'use strict';

var util = require('./util');

var currentQuery = null,
    lastQuery = null,
    runningQuery = null,
    delay = 30,
    call,
    $resultsContainer,
    $ariaSuggestionCount;

var searchsuggest = {
    /**
     * @function
     * @description Configures parameters and required object instances
     */
     // ERF-75 Search Results integration init: function () {
    init: function (config) {
        //ERF-75 used config object instead on multiple parameters
        var $searchForm = config.$container.find('form[name="simpleSearch"]'),
            $searchField = $searchForm.find('input[name="q"]'),
            $searchFieldWrapper = $searchForm.find('.js-searchField');

        // disable browser auto complete
        $searchField.attr('autocomplete', 'off');

        // on focus listener (clear default value)
        $searchField.focus(function () {
            if (!$resultsContainer) {
                // create results container if needed
                //ERV-19 Search rendering glitch when clicking search bar - added .hidden class to not show empty list on first render
                $resultsContainer = $('<div/>').attr('id', 'search-suggestions').attr('class', 'hidden').attr('role', 'region').attr('aria-label',  window.Resources.SEARCH_SUGGESTION).insertAfter($searchFieldWrapper);
                $ariaSuggestionCount = $('<div/>').addClass('offscreen js-ariaSuggestionCount').attr('aria-live', 'assertive').insertAfter($resultsContainer);
            }
            //ERF-75 used config object instead on multiple parameters
            if ($searchField.val() === config.defaultValue) {
                $searchField.val('');
            }
        });

        $(document).on('click', function (e) {
            //ERF-75 Search suggestions integration, changed logic for closing search suggestions
            if (!$(e.target).closest(".search-suggestion-wrapper").length) {
                setTimeout(this.clearResults, 200);
            }
        }.bind(this));

        config.cache.$body.on("click", ".search-suggestions-close", function () {
            setTimeout(this.clearResults, 200);
        }.bind(this));

        // on key up listener
        $searchField.keyup(function (e) {
            // no query currently running, init an update
            if (!runningQuery) {
                // get keyCode (window.event is for IE)
                var keyCode = e.keyCode || window.event.keyCode;

                // check for an ENTER or ESC
                if (keyCode === 13 || keyCode === 27) {
                    this.clearResults();
                    return;
                }

                currentQuery = $searchField.val().trim();

                runningQuery = currentQuery;
                setTimeout(this.suggest.bind(this), delay);
            }
        }.bind(this));
    },

    /**
     * @function
     * @description trigger suggest action
     */
    suggest: function () {
        var that = this;
        // check whether query to execute (runningQuery) is still up to date and had not changed in the meanwhile
        // (we had a little delay)
        if (runningQuery !== currentQuery) {
            // update running query to the most recent search phrase
            runningQuery = currentQuery;
        }

        // if it's empty clear the results box and return
        if (runningQuery.length === 0) {
            that.clearResults();
            runningQuery = null;
            return;
        }

        // if the current search phrase is the same as for the last suggestion call, just return
        if (lastQuery === runningQuery) {
            runningQuery = null;
            return;
        }

        if (call) { // abort previous request
            call.abort();
            runningQuery = null;
        }

        // build the request url
        var reqUrl = util.appendParamToURL(window.Urls.searchsuggest, 'q', runningQuery);

        // execute server call
        var call = $.ajax({
            url: reqUrl,
            success: function (data) {
                var suggestionHTML = data,
                    ansLength = suggestionHTML.trim().length;

                // if there are results populate the results div
                if (ansLength === 0) {
                    that.clearResults();
                }
                else {
                    // update the results div
                    //ERV-19 Search rendering glitch when clicking search bar - removed .hidden class from the element when there is results
                    if ($resultsContainer.hasClass('hidden')) {
                        $resultsContainer.removeClass('hidden');
                    }

                    var $code = $('<div/>').append(suggestionHTML),
                        count = $code.find('.suggestion-link').length;

                    $ariaSuggestionCount.html(count + ' suggestion' + (count > 1 ? 's' : '') + ' available');
                    $resultsContainer.html(suggestionHTML).fadeIn(200);

                    //ERB-51 - SEO Tracking - Google Tag Manager - update product impressions
                    window.vbqUtils.gtmUtils.updateProductImpressions();
                }

                // record the query that has been executed
                lastQuery = runningQuery;
                // reset currently running query
                runningQuery = null;

                // check for another required update (if current search phrase is different from just executed call)
                if (currentQuery !== lastQuery) {
                    // ... and execute immediately if search has changed while this server call was in transit
                    runningQuery = currentQuery;
                    setTimeout(that.suggest.bind(that), delay);
                }

                call = null;
            },
            error: function () {
                // reset currently running query
                runningQuery = null;
                call = null;
                $resultsContainer = null;
            }
        });
    },
    /**
     * @function
     * @description
     */
    clearResults: function () {
        if (!$resultsContainer) { return; }
        $ariaSuggestionCount.html("no suggestion available");
        $resultsContainer.fadeOut(200, function () {$resultsContainer.empty();});
    },
    destroy: function (config) {
        config.$container.find('input[name="q"]').off();
        $('#search-suggestions').remove();
        $(document).off('click');
        $('.js-ariaSuggestionCount').remove();
        config.cache.$body.off("click", ".search-suggestions-close");
        if (call) {
            call.abort();
        }
        currentQuery = null;
        lastQuery = null;
        runningQuery = null;
    }
};

module.exports = searchsuggest;
