'use strict';

module.exports = {
    'Ags.': 'AGU',
    'B.C.': 'BCN',
    'Sur': 'BCS',
    'Camp.': 'CAM',
    'Chis.': 'CHP',
    'Chih.': 'CHH',
    'Coah.': 'COA',
    'Col.': 'COL',
    'CDMX': 'CMX',
    'Dgo.': 'DUR',
    'Gto.': 'GUA',
    'Gro.': 'GRO',
    'Hgo.': 'HID',
    'Jal.': 'JAL',
    'Méx.': 'MEX',
    'Mich.': 'MIC',
    'Mor.': 'MOR',
    'Nay.': 'NAY',
    'N.L.': 'NLE',
    'Oax.': 'OAX',
    'Pue.': 'PUE',
    'Qro.': 'QUE',
    'Q.R.': 'ROO',
    'S.L.P.': 'SLP',
    'Sin.': 'SIN',
    'Son.': 'SON',
    'Tab.': 'TAB',
    'Tamps.': 'TAM',
    'Tlax.': 'TLA',
    'Ver.': 'VER',
    'Yuc.': 'YUC',
    'Zac.': 'ZAC'
};
