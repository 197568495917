'use strict';

var _debounce = require('./lib/lodash.debounce'),
    validationLogic = require('./validationLogic'),
    progress = require('./progress');

/**
 * Initialize newsletter form events
 *
 * @param {jQuery object} $form - form for newsletter subscription
 */
function initFormEvents($form) {
    if ($form.length) {
        var $formfieldset = $form.find('.gl_form-group'),
            $email = $form.find('.js-newsletter-email'),
            $formOk = $form.find('.js-newsletter-ok'),
            $optinconsent = $form.find('.js-newsletter-optin'),
            $successWrapper = $form.find('.js-subscription-success'),
            validator = $form.validate(),
            options = {};

        $email.rules('add', {
            required: true,
            email: true
        });

        $email.on('keyup input blur', _debounce(function () {
            if ($(this).val() !== '') {
                validator.element(this);
            }

            if ($successWrapper.is(':visible')) {
                $successWrapper.hide().html('');
            }

            if ($formfieldset.hasClass('gl_has-success')) {
                $formfieldset.removeClass('gl_has-success');
            }
        }, 200));

        // put back the form in pristine state (input with no value, no error)
        validator.resetForm();
        $formfieldset.removeClass('gl_has-error');

        //run submit if ok button was clicked
        $formOk.on('click keydown', function (e) {
            // mouse click or enter/space key
            if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
                // prevent the default action to stop scrolling when space is pressed
                e.preventDefault();

                $form.submit();
            }
        });

        //handle submit form logic for footer newsletter form
        $form.on('submit', function (e) {
            e.preventDefault();

            if (!$form.valid()) {
                return false;
            }

            var email = $email.val(),
                source= $email.data('source'),
                optinconsent = $optinconsent.prop('checked'),
                formOrigin = $form.data('origin') || '';

            //send email to server
            $.ajax({
                method: 'POST',
                url: window.Urls.subscribeToNewsletter,
                data: {
                    email: email,
                    source: source,
                    optinconsent: optinconsent,
                    formOrigin: formOrigin
                },
                cache: false,
                beforeSend: function () {
                    options = {};
                    $formfieldset.removeClass('gl_has-success gl_has-error').addClass('gl_loading');
                }
            }).done(function (data) {
                // GDPR-33 - GDPR - Revision of consents
                if (!data.error && data.type == 'success') {
                    $formfieldset.removeClass('gl_loading').addClass('gl_has-success');

                    if ($successWrapper.length) {
                        $successWrapper.html(window.Resources.THANK_YOU_FOR_SUBSCRIPTION).show();
                    }

                    // remove email from input field
                    $email.val('');
                    // uncheck the consent
                    $optinconsent.prop('checked', false);
                    $optinconsent.trigger('change');
                }
                // GDPR-26 - GDPR - Footer Modal - New consent modal
                else if (!data.error && data.type == 'consents') {
                    var dialog = require('./dialog');
                    dialog.open({
                        url: window.Urls.getConsentForm,
                        customFocusOnCloseElement: $formOk,
                        options: {
                            width: 'auto',
                            title: window.Resources.CONSENT_THANKYOU,
                            dialogClass: 'consent-dialog',
                            open: function () {
                                window.vbqUtils.initializeJQueryUiPlugins();

                                var $consentForm = $('#js-newsletterSubscriptionConsents'),
                                    $newsletterSubscriptionConsentsButton = $('#js-newsletterSubscriptionConsentsButton');

                                validationLogic.formValidation($consentForm);

                                $consentForm.on('submit', function (e) {
                                    e.preventDefault();
                                    if (!$consentForm.valid()) {
                                        return false;
                                    }

                                    $newsletterSubscriptionConsentsButton.attr('disabled', 'disabled');
                                    progress.show($newsletterSubscriptionConsentsButton);

                                    $.ajax({
                                        method: 'POST',
                                        url: window.Urls.consentForNewsletterSubscription,
                                        data: {
                                            email: email,
                                            source: source,
                                            optinconsent: $consentForm.find('#dwfrm_newsletterconsent_optinconsent').prop('checked'),
                                            formOrigin: formOrigin
                                        },
                                        cache: false,
                                        beforeSend: function () {
                                            options = {};
                                            $formfieldset.removeClass('gl_has-success gl_has-error').addClass('gl_loading');
                                        }
                                    }).done(function (data) {
                                        $formfieldset.removeClass('gl_loading');

                                        if (!data.alert && data.type == 'success') {
                                            $formfieldset.addClass('gl_has-success');

                                            if ($successWrapper.length) {
                                                $successWrapper.html(window.Resources.THANK_YOU_FOR_SUBSCRIPTION).show();
                                            }
                                        }
                                        else {
                                            options[$email.attr('name')] = window.Resources.VALIDATE_EMAIL_GLOBAL;
                                            validator.showErrors(options);
                                        }

                                        dialog.close();
                                    }).fail(function () {
                                        $formfieldset.removeClass('gl_loading');
                                        options[$email.attr('name')] = window.Resources.SERVER_ERROR;
                                        validator.showErrors(options);
                                        dialog.close();
                                    });
                                });
                            }.bind(this)
                        },
                        closeCallback: function () {
                            // reset the form
                            $formfieldset.removeClass('gl_has-error gl_loading');
                            $email.val('');

                            // uncheck the consent
                            $optinconsent.prop('checked', false);
                            $optinconsent.trigger('change');
                        }
                    });
                }
                else {
                    $formfieldset.removeClass('gl_loading');
                    switch (data.type) {
                        case 'format':
                            options[$email.attr('name')] = window.Resources.VALIDATE_EMAIL;
                            break;
                        case 'already':
                            options[$email.attr('name')] = window.Resources.VALIDATE_EMAIL_ALREADY;
                            break;
                        default:
                            options[$email.attr('name')] = window.Resources.VALIDATE_EMAIL_GLOBAL;
                            break;
                    }
                    validator.showErrors(options);
                }
            }).fail(function () {
                $formfieldset.removeClass('gl_loading');
                options[$email.attr('name')] = window.Resources.SERVER_ERROR;
                validator.showErrors(options);
            });
        });
    }
}

/**
 * ERB-173 - Newsletter subscription
 * ERV-73 - missing email input validation
 */
function initializeEvents() {
    // standard form validation (formPrepare.js, validationLogic.js, ...) can not be used here
    // so the form status and error message are managed directly through jQuery validator

    // initialize all newsletter forms, from content, footer...
    $('.js-newsletter').each(function () {
        initFormEvents($(this));
    });
}

exports.init = function init () {
    initializeEvents();
};
