'use strict';

var loadUtils = true,
    updateCountryNames = true;

/**
 * manage phone numbers international codes
 */
function init($country, $phone) {
    if (loadUtils) {
        window.intlTelInputGlobals.loadUtils('https://' + window.location.host + window.Urls.intlTelInputUtils);
        loadUtils = false;
    }

    var iti, countryCode, phone;

    // already initialized
    if ($phone.parent().hasClass('iti')) {
        // force the country refresh
        iti = window.intlTelInputGlobals.getInstance($phone[0]);
        countryCode = $country.val();
        phone = $.trim($phone.val());
        if (countryCode !== null && countryCode !== '' && phone === '') {
            iti.setCountry(countryCode.toLowerCase());
        }
        return;
    }

    if (updateCountryNames) {
        var countryData = window.intlTelInputGlobals.getCountryData();
        $.each(countryData, function(i, country) {
            var translatedName = $.trim($country.find('option[value="' + country.iso2.toUpperCase() + '"]').text());
            if (translatedName !== null && translatedName !== '') {
                var tmp = country.name.split('(');
                country.name = translatedName + (tmp.length == 2 ? ' (' + tmp[1].toUpperCase() : '');
            }
            else {
                country.name = country.name.toUpperCase();
            }
        });

        updateCountryNames = false;
    }

    var initialCountry = $country.val(),
        customerCountry,
        preferredCountries = [];
    initialCountry = initialCountry ? initialCountry.toLowerCase() : '';

    // Set the session country code by default when no value exists
    if (initialCountry !== ''
        && window.vbq
        && window.vbq.localizationData
        && window.vbq.localizationData.countryCode
        && window.vbq.localizationData.countryCode !== ''
        && window.vbq.localizationData.countryCode !== 'not_supported'
    ) {
        initialCountry = window.vbq.localizationData.countryCode.toLowerCase();
    }

    if (window.vbq
        && window.vbq.localizationData
        && window.vbq.localizationData.customerCountryCode
        && window.vbq.localizationData.customerCountryCode !== ''
    ) {
        customerCountry = window.vbq.localizationData.customerCountryCode.toLowerCase();
        preferredCountries.push(customerCountry);
    }

    if (initialCountry && initialCountry != customerCountry) {
        preferredCountries.push(initialCountry);
    }

    // for Canary Islands, Ceuta and Melilla, use the Spain data for the phone
    if (initialCountry == 'ic' || initialCountry == 'ea') {
        initialCountry = 'es';
    }

    countryCode = $country.length ? $country.val() : null;
    if (countryCode !== null && countryCode !== '') {
        countryCode = countryCode.toLowerCase();
        phone = $.trim($phone.val());
        if (initialCountry != countryCode && phone === '') {
            initialCountry = countryCode;
        }
    }

    if ($phone.length > 0) {
        // https://github.com/jackocnr/intl-tel-input/tree/v20.0.0?tab=readme-ov-file#initialisation-options
        iti = window.intlTelInput($phone[0], {
            countrySearch: false,
            autoPlaceholder: 'aggressive',
            initialCountry: initialCountry,
            nationalMode: true,
            preferredCountries: preferredCountries,
            hiddenInput: function(telInputName) {
                return {
                    phone: telInputName
                };
            }
        });
        iti.promise.then(function () {
            if ($phone.parent().hasClass('iti')) {
                $phone.attr('name', function (i, attr) { return attr + '_intltel'; });
            }

            $country.on('change', function () {
                countryCode = $country.val();
                phone = $.trim($phone.val());
                if (countryCode !== '' && phone === '') {
                    iti.setCountry(countryCode.toLowerCase());
                }
            });

            $phone.rules('add', {phoneintl: []});
        });
    }
}

exports.init = init;
