'use strict';

var dialog = require('./dialog'),
    product = require('./pages/product'),
    util = require('./util');


var makeUrl = function (url, source, productListID, custom) {
    if (source) {
        url = util.appendParamToURL(url, 'source', source);
    }
    if (productListID && productListID != 'undefined') {
        url = util.appendParamToURL(url, 'productlistid', productListID);
    }
    // ERB-210 - Product detail page - implement iframe logic
    // Use a custom URL parameter to be able to add a custom decorator to the iframes
    if (custom) {
        url = util.appendParamToURL(url, 'custom', true);
    }
    return url;
};

var removeParam = function (url) {
    if (url.indexOf('?') !== -1) {
        return url.substring(0, url.indexOf('?'));
    } else {
        return url;
    }
};

var quickview = {
    init: function (options) {
        if (!this.exists()) {
            var dialogId = (options && options.id) ? options.id + 'Dialog' : 'QuickViewDialog';
            this.$container = $('<div/>').attr('id', dialogId).appendTo(document.body);
        }
        this.productLinks = $('#search-result-items .thumb-link').map(function (index, thumbLink) {
            return $(thumbLink).attr('data-href');
        });
    },

    setup: function (qvUrl) {
        var $btnNext = $('.quickview-next'),
            $btnPrev = $('.quickview-prev');

        this.productLinkIndex = -1;
        for (var i = 0; i < this.productLinks.length; i++) {
            if (removeParam(this.productLinks[i]) === removeParam(qvUrl)) {
                this.productLinkIndex = i;
                break;
            }
        }

        // hide the buttons on the compare page or when there are no other products
        if (this.productLinks.length <= 1 || $('.compareremovecell').length > 0) {
            $btnNext.hide();
            $btnPrev.hide();
            return;
        }

        if (this.productLinkIndex === this.productLinks.length - 1) {
            $btnNext.attr('disabled', 'disabled');
        }
        if (this.productLinkIndex === 0) {
            $btnPrev.attr('disabled', 'disabled');
        }

        $btnNext.on('click', function (e) {
            e.preventDefault();
            this.navigateQuickview(1);
        }.bind(this));
        $btnPrev.on('click', function (e) {
            e.preventDefault();
            this.navigateQuickview(-1);
        }.bind(this));
    },

    /**
     * @param {Number} step - How many products away from current product to navigate to. Negative number means navigate backward
     */
    navigateQuickview: function (step) {
        // default step to 0
        this.productLinkIndex += (step ? step : 0);
        var url = makeUrl(this.productLinks[this.productLinkIndex], 'quickview');
        dialog.replace({
            url: url,
            callback: this.setup.bind(this, url)
        });
    },

    /**
     * @description show quick view dialog
     * @param {Object} options
     * @param {String} options.url - url of the product details
     * @param {String} options.source - source of the dialog to be appended to URL
     * @param {String} options.productlistid - to be appended to URL
     * @param {Function} options.callback - callback once the dialog is opened
     */
    show: function (options) {
        var url,
            qvDialog;
        if (!this.exists()) {
            this.init(options);
        }
        // ERB-210 - Product detail page - implement iframe logic
        // Changes to display an iframe for product quickviews on the PDP
        if ($('#wrapper').hasClass('pt_product-details')) {
            url = makeUrl(options.url, options.source, options.productlistid, true);
            qvDialog = dialog.open({
                url: url,
                customCloseSelector: options.customCloseSelector || '',
                options: {
                    width: 'auto',
                    closeOnEscape: true,
                    closeText: window.Resources.CLOSE,
                    dialogClass : options.id ? options.id + '-dialog' : 'quickview-dialog',
                    title: window.Resources.QUICK_VIEW_POPUP,
                    open: function () {
                        if (typeof options.callback === 'function') { options.callback(); }
                    }.bind(this)
                }
            });
        }
        else {
            url = makeUrl(options.url, options.source, options.productlistid);
            qvDialog = dialog.open({
                target: this.$container,
                url: url,
                customCloseSelector: '.js-qv-close-btn',
                options: {
                    width: 'auto',
                    closeOnEscape: true,
                    closeText: window.Resources.CLOSE,
                    dialogClass : options.id ? options.id + '-dialog' : 'quickview-dialog',
                    title: window.Resources.QUICK_VIEW_POPUP,
                    position: {
                        collision: 'none' // important to fix bug with top dialog cutting on iPhone 6
                    },
                    open: function () {
                        this.setup(url);
                        product.initQuickview();
                        if (typeof options.callback === 'function') { options.callback(); }
                    }.bind(this)
                }
            });
        }

        window.vbqUtils.cache.$document.on('viewportchange', function (e, viewportName, viewportValue) {
            if (viewportValue <= 2) {
                qvDialog.close();
            }
        });

        window.vbqUtils.cache.$document.on('click', '.ui-widget-overlay', function () {
            $('.ui-dialog-titlebar-close').trigger('click');
        });
    },
    exists: function () {
        return this.$container && (this.$container.length > 0);
    }
};

module.exports = quickview;
