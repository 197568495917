'use strict';

var bynderScriptUrl = 'https://d8ejoa1fys2rk.cloudfront.net/modules/bynder-embed/4.0.0/js/bynder-embed.min.js';
var cache = {};
cache.document = document;

/**
 * Create bynder video placehoder
 * @param {Object} dataAttributes - object with element attributes to use for video placeholder
 * @return {DOM Element} videoplaceholder
 */
function createBynderVideoPlacehoder(dataAttributes) {
    var mediaId = dataAttributes.mediaId || '',
        autoplay = dataAttributes.autoplay || false,
        videoplaceholder = cache.document.createElement('span');
    videoplaceholder.classList.add('js-bynderVideo');
    videoplaceholder.setAttribute('data-bynder-widget', 'video-item');
    videoplaceholder.setAttribute('data-media-id', mediaId);
    videoplaceholder.setAttribute('data-autoplay', autoplay);

    return videoplaceholder;
}

/**
 * Create bynder script
 */
function createBynderScript() {
    var bynderScript = cache.document.createElement('script');
    bynderScript.setAttribute('src', bynderScriptUrl);
    bynderScript.setAttribute('id', 'bynder-widgets-js');
    bynderScript.setAttribute('data-account-url', 'media.vilebrequin.com');
    bynderScript.setAttribute('data-language', 'en');
    bynderScript.setAttribute('defer', true);

    return bynderScript;
}

/**
 * Load again the bynder videos
 * @param {String} videotype - video type to use
 */
function reloadDynamicBynderVideos(videotype) {
    for (var i = 0; i < cache.dynamicBynderVideos.length; i++) {
        var videoWrapper = cache.dynamicBynderVideos[i],
            dataAttributes = {
                mediaId: videotype == 'mobile' ? videoWrapper.dataset.mediaIdMobile : videoWrapper.dataset.mediaIdTabletDesktop,
                autoplay: videoWrapper.dataset.autoplay
            },
            // create video placeholder element for bynder
            videoPlaceholder = createBynderVideoPlacehoder(dataAttributes),
            // create the script element to load the bynder script
            bynderScript = createBynderScript();

        // append script to the video placeholder
        videoPlaceholder.appendChild(bynderScript);

        // attacth to the video wrapper element, this will remove previous video
        videoWrapper.replaceChildren(videoPlaceholder);
    }
}

/**
 * Cache needed elements
 */
function initCache() {
    cache.dynamicBynderVideos = cache.document.querySelectorAll('.js-dynamicBynderVideo');
}

/**
 * Init events
 */
function initEvents() {
    // do not set up the events if there are no dynamic bynder videos
    if (!cache.dynamicBynderVideos || cache.dynamicBynderVideos.length === 0) {
        return;
    }

    window.vbqUtils.breakpoint.set([
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('portrait'),
            enter: function () {
                // create bynder video definition for portrait view
                reloadDynamicBynderVideos('mobile');
            }
        }, {
            media: window.vbqUtils.breakpoint.getMediaQuery('landscape'),
            enter: function () {
                // create bynder video definition for landscape view
                reloadDynamicBynderVideos();
            }
        }
    ]);
}

module.exports = {
    init: function () {
        initCache();
        initEvents();
    }
};
