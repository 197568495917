'use strict';

function init() {
    // reCaptcha init
    window.vbqUtils.recaptchaHelper.init();
}

module.exports = {
    init: init
};
