'use strict';
var dialog = require('../../dialog'),
    util = require('../../util');

function imagesShowMore () {
    $('.js-productImagesShowMore').on('click tap', function (e) {
        e.preventDefault();
        e.stopPropagation();

        $(this).parent().focus()
            .closest('.product-images-mosaic').addClass('product-images-deployed');
    });
}

/**
 * @description Replaces the images in the image container, for eg. when a different color was clicked.
 */
function replaceImages ($pdpMain) {
    var $newImages = $pdpMain.find('.js-updateImages'),
        $imageContainer = $pdpMain.find('.product-image-container');
    if ($newImages.length === 0) { return; }

    $imageContainer.html($newImages.html());

    $newImages.remove();
    imagesShowMore();
}

module.exports = function () {};
module.exports.replaceImages = replaceImages;
module.exports.imagesShowMore = imagesShowMore;
