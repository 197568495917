'use strict';

var login = require('../login'),
    cache = {};

function initCache() {
    cache.$document = $(document);
    cache.$openMobileLogin = cache.$document.find('#js-open-mobile-login');
    cache.$repairServiceBanner = cache.$document.find('#js-repair-service-banner');
    cache.$repairServiceCustomerSection = cache.$document.find('#js-repair-service-customer');
}

function activateMobileLogin() {
    cache.$repairServiceBanner.addClass('gl_desktop-only');
    cache.$repairServiceCustomerSection.removeClass('gl_desktop-only');
}

function initEvents() {
    cache.$openMobileLogin.on('click', activateMobileLogin);
}

exports.init = function () {
    initCache();
    initEvents();
    login.init();
};
