'use strict';

// ERB-52 International Shipping - removed phone, postal code and cc owner validation

// global form validator settings
//ERF-30 Added highlight and unhighlight listeners
var settings = {
    errorClass: 'gl_error-message',
    errorElement: 'span',
    //onkeyup: false,
    ignore : [],
    highlight: function (element, errorClass, validClass) {
        var $element = $(element),
            ariaDescribedbyElement = $element.attr('aria-describedby') || '',
            $formGroup = $element.parents('.gl_form-group'),
            $elementCaption = $formGroup.find('.js-form-caption') || $(),
            elementCaptionId = $elementCaption.attr('id') || '',
            $successContainer = $formGroup.find('.gl_input-success-container');

        if (!$element.attr('formnovalidate')) {
            if ($element.val() != $element.attr('placeholder')) {
                $formGroup.find('.backend-error-message').remove();
                $formGroup.addClass('gl_has-error');

                // EADA23-20 - Remove form-caption ID from input aria-describedby if input group has .gl_has-error
                if ($elementCaption.length) {
                    $element.attr('aria-describedby', $.trim(ariaDescribedbyElement.replace(elementCaptionId, '')));
                }
            }

            $formGroup.removeClass('gl_has-success');
            $successContainer.attr('aria-hidden', 'true');
        }
    },
    unhighlight: function (element, errorClass, validClass) {
        var $element = $(element),
            ariaDescribedbyElement = $element.attr('aria-describedby') || '',
            $formGroup = $element.parents('.gl_form-group'),
            $elementCaption = $formGroup.find('.js-form-caption') || $(),
            elementCaptionId = $elementCaption.attr('id') || '',
            $successContainer = $formGroup.find('.gl_input-success-container');

        if (!$element.attr('formnovalidate')) {
            $formGroup.removeClass('gl_has-error');
            $formGroup.find('.backend-error-message').remove();

            // EADA23-20 - Add form-caption ID to input aria-describedby
            if ($elementCaption.length && ariaDescribedbyElement.indexOf(elementCaptionId) == -1) {
                $element.attr('aria-describedby', ariaDescribedbyElement + ' ' + elementCaptionId);
            }

            if (!$formGroup.hasClass('gl_no-success-allowed')) {
                if ($element.val() != $element.attr('placeholder')) {
                    $formGroup.addClass('gl_has-success');
                    $successContainer.attr('aria-hidden', 'false');
                }
            }
        }
    },
    onfocusout: function (element) {
        if (!this.checkable(element)) {
            this.element(element);
        }
    },
    errorPlacement: function (error, element) {
        var $fieldset = $(element).parents(".gl_form-group");
        $fieldset.find(".gl_error-message").remove();
        $fieldset.append(error);
        // ENF-56 - Landing Page Careers - Make checkboxes with errors have a red outline
        if ($(element).attr('type') == 'checkbox') {
            $(element).checkboxradio('option', 'classes.ui-checkboxradio-icon', 'checkbox-error');
        }
    },
    onkeyup: function (element, event) {
        // run validation on each keyup event
        if (element.classList.contains('customPasswordValidation')) {
            this.element(element);
        }
        else {
            // run default validator functionality on keyup event
            // Avoid revalidate the field when pressing one of the following keys
            // Shift       => 16
            // Ctrl        => 17
            // Alt         => 18
            // Caps lock   => 20
            // End         => 35
            // Home        => 36
            // Left arrow  => 37
            // Up arrow    => 38
            // Right arrow => 39
            // Down arrow  => 40
            // Insert      => 45
            // Num lock    => 144
            // AltGr key   => 225
            var excludedKeys = [
                16, 17, 18, 20, 35, 36, 37,
                38, 39, 40, 45, 144, 225
            ];
            if (event.which === 9 && this.elementValue(element) === '' || $.inArray(event.keyCode, excludedKeys) !== -1) {
                return;
            } else if (element.name in this.submitted || element.name in this.invalid) {
                this.element(element);
            }
        }
    }
};

/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod('gift-cert-amount', function (value, el) {
    var isOptional = this.optional(el);
    var isValid = (!isNaN(value)) && (parseFloat(value) >= 5) && (parseFloat(value) <= 5000);
    return isOptional || isValid;
}, Resources.GIFT_CERT_AMOUNT_INVALID);

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod('positivenumber', function (value) {
    if ($.trim(value).length === 0) { return true; }
    return (!isNaN(value) && Number(value) >= 0);
}, ''); // '' should be replaced with error message if needed

/**
 * Add XSS check validation
 * Text fields must have 'xss' css class to be validated
 */
$.validator.addMethod('xss', function (value) {
    var regexp = new RegExp(Resources.FORM_XSS_PATTERN, 'gi');
    return !regexp.test(value);
}, Resources.FORM_XSS_ERROR_MESSAGE);

// ECM-880 - Adyen 3DSv2 - add validators
/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function (value) {
    var isValid = /^(?!(([0-9 -]){13,19})).*$/.test($.trim(value));
    return isValid;
};

/**
 * Add CCOwner validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod('owner', validateOwner, Resources.INVALID_OWNER);
// ECM-880 - Adyen 3DSv2 - end validators

$.extend($.validator.messages, {
    required: Resources.VALIDATE_REQUIRED,
    remote: Resources.VALIDATE_REMOTE,
    email: Resources.VALIDATE_EMAIL,
    url: Resources.VALIDATE_URL,
    date: Resources.VALIDATE_DATE,
    dateISO: Resources.VALIDATE_DATEISO,
    number: Resources.VALIDATE_NUMBER,
    digits: Resources.VALIDATE_DIGITS,
    creditcard: Resources.VALIDATE_CREDITCARD,
    equalTo: Resources.VALIDATE_EQUALTO,
    maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
    minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
    rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
    range: $.validator.format(Resources.VALIDATE_RANGE),
    max: $.validator.format(Resources.VALIDATE_MAX),
    min: $.validator.format(Resources.VALIDATE_MIN)
});

var validator = {
    settings: settings,
    init: function () {
        $.validator.setDefaults(this.settings); // security

        var self = this;
        $('form:not(.suppress)').each(function () {
            $(this).validate(self.settings);
        });
    },
    initForm: function (f) {
        $(f).validate(this.settings);
    }
};

module.exports = validator;
