'use strict';

var _debounce = require('../lib/lodash.debounce');

/**
 * EW-124 CRO - Style Guide
 */
var init = function () {
    var $fitsTabButton = $('#js-fitsTabButton'),
        $activitiesTabButton = $('#js-activitiesTabButton'),
        $fitsTab = $('#js-fitsTab'),
        $activitiesTab = $('#js-activitiesTab'),
        $anchorTab = $('.js-anchorTab'),
        $styleguideTitle = $('.styleguide-slider-title'),
        $womenSliders = $('.styleguide-women').find('.js-slider'),
        $allAdditionalContentWrapper = $('.js-additionalContentWrapper');

    if (window.vbqUtils.breakpoint.is('mobileAndTablet')) {
        $fitsTab.find('.js-slider').slick({
            slidesToShow: 1,
            dots: true,
            arrows: false,
            centerMode: true,
            infinite: true,
            centerPadding: '0',
            variableWidth: true
        });
    }

    $fitsTabButton.click(function () {
        $activitiesTabButton.removeClass('active');
        $activitiesTab.removeClass('active');
        if ($activitiesTab.find('.js-slider').hasClass('slick-initialized')) {
            $activitiesTab.find('.js-slider').slick('unslick');
        }

        $fitsTabButton.addClass('active');
        $fitsTab.addClass('active');
        if (window.vbqUtils.breakpoint.is('mobileAndTablet')) {
            $fitsTab.find('.js-slider').slick({
                slidesToShow: 1,
                dots: true,
                arrows: false,
                centerMode: true,
                infinite: true,
                centerPadding: '0',
                variableWidth: true
            });
        }
    });

    $activitiesTabButton.click(function () {
        $fitsTabButton.removeClass('active');
        $fitsTab.removeClass('active');
        if ($fitsTab.find('.js-slider').hasClass('slick-initialized')) {
            $fitsTab.find('.js-slider').slick('unslick');
        }

        $activitiesTabButton.addClass('active');
        $activitiesTab.addClass('active');
        if (window.vbqUtils.breakpoint.is('mobileAndTablet')) {
            $activitiesTab.find('.js-slider').slick({
                slidesToShow: 1,
                dots: true,
                arrows: false,
                centerMode: true,
                infinite: true,
                centerPadding: '0',
                variableWidth: true
            });
        }
    });

    $('.styleguide-tabs').find('.lazyload.forced').each(function (index, el) {
        window.lazySizes.loader.unveil(el);
    });

    // women styleguide: highlight active anchor/title
    $anchorTab.click(function () {
        var $activeAnchor = $(this);

        // unset highlight
        $anchorTab.removeClass('active');
        $styleguideTitle.removeClass('active');

        // highlight active anchor/title
        $activeAnchor.addClass('active');
        $styleguideTitle.filter($activeAnchor.attr('href')).addClass('active');
    });

    var $seeMoreToggles = $('.js-seeMoreToggle');
    if ($seeMoreToggles.length) {
        $seeMoreToggles.on('click', function () {
            var $this = $(this),
                $carousel = $this.closest('.js-slider'),
                $carouselSeeMore = $carousel.find('.js-seeMoreToggle'),
                $additionalContentWrapper = $carousel.next('.js-additionalContentWrapper'),
                isActive = $this.attr('aria-checked') == 'false';

            // reset other links of this carousel
            $carouselSeeMore.attr('aria-checked', 'false').find('.js-seeMore').removeClass('hidden').end().find('.js-seeLess').addClass('hidden');

            if (isActive) {
                // update the clicked fake link
                $this.attr('aria-checked', 'true').find('.js-seeMore').addClass('hidden').end().find('.js-seeLess').removeClass('hidden');

                // clone the additional content
                var $clone = $this.closest('.styleguide-fit').find('.js-additionalContent').clone();
                $additionalContentWrapper.html($clone.html()).removeClass('hidden');

                // show img on desktop
                if (window.vbqUtils.breakpoint.is('fromDesktop')) {
                    $additionalContentWrapper.find('.styleguide-additional-content-img').removeClass('hidden');
                }
            }
            else {
                // update the clicked fake link
                $this.attr('aria-checked', 'false').find('.js-seeMore').removeClass('hidden').end().find('.js-seeLess').addClass('hidden');
                $additionalContentWrapper.addClass('hidden').html('');
            }
        });
    }

    window.vbqUtils.breakpoint.set([
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('mobileAndTablet'),
            enter: function () {
                if ($fitsTab.hasClass('active')) {
                    $fitsTab.find('.js-slider').slick('unslick').slick({
                        slidesToShow: 1,
                        dots: true,
                        arrows: false,
                        centerMode: true,
                        infinite: true,
                        centerPadding: '0',
                        variableWidth: true,
                        adaptiveHeight: true
                    });
                }
                else if ($activitiesTab.hasClass('active')) {
                    $activitiesTab.find('.js-slider').slick('unslick').slick({
                        slidesToShow: 1,
                        dots: true,
                        arrows: false,
                        centerMode: true,
                        infinite: true,
                        centerPadding: '0',
                        variableWidth: true,
                        adaptiveHeight: true
                    });
                }

                $womenSliders.slick('unslick').slick({
                    slidesToShow: 1,
                    dots: true,
                    arrows: false,
                    centerMode: true,
                    infinite: true,
                    centerPadding: '0',
                    variableWidth: true,
                    adaptiveHeight: true
                });

                $allAdditionalContentWrapper.find('.styleguide-additional-content-img').addClass('hidden');
            }
        },
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('fromDesktop'),
            enter: function () {
                if ($fitsTab.find('.js-slider').length) {
                    $fitsTab.find('.js-slider').slick('unslick');
                }
                if ($activitiesTab.find('.js-slider').length) {
                    $activitiesTab.find('.js-slider').slick('unslick');
                }

                // ECM-967 New style guide women
                $womenSliders.slick('unslick').slick({
                    slidesToShow: 5,
                    dots: false,
                    arrows: true,
                    infinite: true
                });

                $allAdditionalContentWrapper.find('.styleguide-additional-content-img').removeClass('hidden');
            }
        }
    ]);

    window.vbqUtils.cache.$window.resize(_debounce(function () {
        if (window.vbqUtils.breakpoint.is('mobileAndTablet')) {
            if ($fitsTab.hasClass('slick-initialized')) {
                $fitsTab.slick('setPosition');
            }
            if ($activitiesTab.hasClass('slick-initialized')) {
                $activitiesTab.slick('setPosition');
            }
            if ($womenSliders.hasClass('slick-initialized')) {
                $womenSliders.slick('setPosition');
            }
        }
    }, 250));
};

exports.init = init;
