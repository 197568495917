'use strict';

/**
 * ERF-88 Size charts page
 */
var init = function () {
    var $sizeChartTabTogglers = $('.js-sizeChartTabToggler'),
        $sizeChartPagesAnchorsPanel = $('.js-sizeChart-pageAnchors'),
        $sizeChartPagesAnchors = $('.js-pageAnchor'),
        $sizeChartTabs = $('.js-sizeChartTab'),
        $sizeChartPages = $('.js-sizeChartBlock'),
        $sizeChartCollectionSelect = $('#js-sizeChartCollectionSelect'),
        // EW-123 - Size chart reboot
        sizeChartCountries = ['FR', 'UK', 'IT', 'US'],
        currentCountry = window.vbq.localizationData ? window.vbq.localizationData.countryCode : '',
        sizeChartCountry = sizeChartCountries.indexOf(currentCountry) != -1 ? (currentCountry == 'GB' || currentCountry == 'JE' || currentCountry == 'GG' ? 'UK' : currentCountry) : 'EU',
        sizeChartUnit = sizeChartCountry == 'US' ? 'INCH' : 'CM',
        manageSizeCharts = function ($chart) {
            var $page = $chart && $chart.length > 0 ? $chart : $sizeChartPages.filter('.shown');
            $page.each(function () {
                var $currentPage = $(this),
                    currentSizeChartCountry = $currentPage.find('.js-sizeChartCountrySelect').val(),
                    currentSizeChartUnit = $currentPage.find('.js-sizeChatUnit').filter('.active').attr('value');

                $currentPage.find('.js-sizeChart').addClass('hidden');

                var $sizecharts = $currentPage.find('.js-sizeChart'),
                    $sizechart = $sizecharts.filter('.js-sizeChart-' + currentSizeChartCountry + '-' + currentSizeChartUnit);
                if ($sizechart.length) {
                    $sizechart.removeClass('hidden');
                }
                else {
                    $sizecharts.filter('.js-sizeChart-' + sizeChartCountry + '-' + sizeChartUnit).removeClass('hidden');
                }
            });
        };

    $sizeChartTabTogglers.on('click', function (e) {
        e.preventDefault();
        var $this = $(this);

        $sizeChartTabTogglers.removeClass('active').attr('aria-selected', 'false');
        $this.addClass('active').attr('aria-selected', 'true');

        $sizeChartTabs.removeClass('shown').removeAttr('tabindex')
            .filter('#'+ $this.attr('data-target')).addClass('shown').attr('tabindex', '0')
            // reset all anchors from Men tab
            .find('.js-sizeChart-pageAnchors').removeClass('hidden')
            .find('.js-pageAnchor').removeClass('active').attr('aria-selected', 'false')
                .attr('tabindex', '-1').first().attr('tabindex', '0');

        $sizeChartPages.addClass('shown').removeAttr('tabindex')
            .find('.js-sizeChartWrapper').addClass('hidden').filter('[id$="_' + $sizeChartCollectionSelect.val()  + '"]').removeClass('hidden');

        manageSizeCharts();
    });

    $sizeChartPagesAnchors.on('click', function (e) {
        e.preventDefault();
        var $this = $(this);

        $sizeChartPagesAnchors.removeClass('active').attr('aria-selected', 'false');
        $this.addClass('active').attr('aria-selected', 'true');

        $sizeChartPages.removeClass('shown').removeAttr('tabindex')
            .filter('#'+ $this.attr('data-target')).addClass('shown').attr('tabindex', '0')
            .find('.js-sizeChartWrapper').addClass('hidden').filter('[id$="_' + $sizeChartCollectionSelect.val()  + '"]').removeClass('hidden');

        manageSizeCharts();
    });

    // show all related content of all tabs
    $sizeChartCollectionSelect.on('change', function () {
        var val = $sizeChartCollectionSelect.val();
        if (val !== '') {
            $('#js-sizeChartsWrapper').removeClass('hidden');

            // reset tabs choice: show all related tab content on select change
            $sizeChartTabTogglers.removeClass('active').attr('aria-selected', 'false')
                .attr('tabindex', '-1').first().attr('tabindex', '0');
            $sizeChartTabs.addClass('shown').removeAttr('tabindex');

            // reset & hidden all anchors from Men tab
            $sizeChartPagesAnchorsPanel.addClass('hidden');
            $sizeChartPagesAnchors.removeClass('active').attr('aria-selected', 'false')
                .attr('tabindex', '-1').first().attr('tabindex', '0');

            // show all related content of all tabs
            $sizeChartPages.addClass('shown').removeAttr('tabindex')
                .find('.js-sizeChartWrapper').addClass('hidden').filter('[id$="_' + $sizeChartCollectionSelect.val()  + '"]').removeClass('hidden');

            manageSizeCharts();
        }
        else {
            $('#js-sizeChartsWrapper').addClass('hidden');
        }
    });

    $('.js-sizeChatUnit').on('click', function (e) {
        e.preventDefault();

        var $this = $(this),
            $chart = $this.closest('.js-sizeChartWrapper');

        $chart.find('.js-sizeChatUnit').removeClass('active');
        $(this).addClass('active');
        manageSizeCharts($chart);
    });

    $('.js-sizeChartCountrySelect').on('change', function () {
        manageSizeCharts($(this).closest('.js-sizeChartWrapper'));
    });
};

exports.init = init;
