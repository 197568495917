'use strict';
var wishlist = require('./pages/wishlist');

/*
function initQuickViewButtons() {
    //ERF-123 Product set detail page - product tile for product sets do not have quickview
    var $quickViewImage = $('.tiles-container').find('.product-image:not(.js-qvdisabled)');

    $quickViewImage.on('mouseenter', function () {
        // ERB-210 - Product detail page - implement iframe logic
        // Use a quickviewbutton class instead of an id, to disallow multiple dialogs
        var $this = $(this),
            $qvButton = $this.find('.js-quickviewbutton');
        if ($qvButton.length === 0) {
            // ERF-16 Home page - Quickview button icon not required
            $qvButton = $('<a class="js-quickviewbutton quickview">' + Resources.QUICK_VIEW + '</a>');
        }
        var $link = $this.find('.thumb-link');
        $qvButton.attr({
            'href': $link.attr('data-href'),
            'title': $link.attr('data-title')
        }).appendTo(this);
        //ERF-58 Product detail page - qvbutton was event stacking on tile hover
        $qvButton.on('click', function (e) {
            e.preventDefault();
            var $this = $(this);

            //ERB-51 - SEO Tracking - Google Tag Manager - push click
            window.vbqUtils.gtmUtils.productQuickView($this);

            quickview.show({
                url: $this.data('href'),
                source: 'quickview',
                customCloseSelector: '#js-close-quickview'
            });
        });
    });
    //Fix for IE issue with multiple quick view display on the page
    $quickViewImage.on('mouseleave', function () {
        $('.js-quickviewbutton').remove();
    });
}
*/

function gridViewToggle() {
    $('.toggle-grid').on('click', function () {
        $('.search-result-content').toggleClass('wide-tiles');
        $(this).toggleClass('wide');
    });
}

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents($tiles) {
    gridViewToggle();
    /*
        ERF-75 Search results removed tiles colors SG functionality
        $('.swatch-list').on('mouseleave', function () {
            // Restore current thumb image
            var $tile = $(this).closest('.product-tile'),
                $thumb = $tile.find('.product-image .thumb-link img').eq(0),
                data = $thumb.data('current');

            $thumb.attr({
                src: data.src,
                alt: data.alt,
                title: data.title
            });
        });
        $('.swatch-list .swatch').on('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass('selected')) { return; }

            var $tile = $(this).closest('.product-tile');
            $(this).closest('.swatch-list').find('.swatch.selected').removeClass('selected');
            $(this).addClass('selected');
            $tile.find('.thumb-link').attr('data-href', $(this).attr('href'));
            $tile.find('name-link').attr('href', $(this).attr('href'));

            var data = $(this).children('img').filter(':first').data('thumb');
            var $thumb = $tile.find('.product-image .thumb-link img').eq(0);
            var currentAttrs = {
                src: data.src,
                alt: data.alt,
                title: data.title
            };

            $thumb.attr(currentAttrs);
            $thumb.data('current', currentAttrs);
        }).on('mouseenter', function () {
            // get current thumb details
            var $tile = $(this).closest('.product-tile'),
                $thumb = $tile.find('.product-image .thumb-link img').eq(0),
                data = $(this).children('img').filter(':first').data('thumb'),
                current = $thumb.data('current');

            // If this is the first time, then record the current img
            if (!current) {
                $thumb.data('current', {
                    src: $thumb[0].src,
                    alt: $thumb[0].alt,
                    title: $thumb[0].title
                });
            }

            // Set the tile image to the values provided on the swatch data attributes
            $thumb.attr({
                src: data.src,
                alt: data.alt,
                title: data.title
            });
        });
    */

    // EADA-12 - Show hover image and View details fake button on the product tile accessibility
    $tiles.on('focus', '.js-product-tile-link', function (e) {
        $(this).find('.js-product-image').addClass('keyboard-hover');
    });

    $tiles.on('blur', '.js-product-tile-link', function () {
        $(this).find('.js-product-image').removeClass('keyboard-hover');
    });

    wishlist.updateWishlistIcons();

    if (window.vbqUtils.isSR) {
        $('.product-pricing').has('.gl_price-beforePromo').addClass('promotional-price');
    }
}

exports.init = function () {
    // .tiles-container should be product tiles set wrapper to activate keyboard-hover state
    var $tiles = $('.tiles-container .product-tile');
    if ($tiles.length === 0) {
        return;
    }

    $tiles.each(function (idx) {
        $(this).data('idx', idx);
    });

    initializeEvents($tiles);
};
