'use strict';

var formPrepare = require('./checkout/formPrepare'),
    util = require('../util'),
    _debounce = require('../lib/lodash.debounce');

var $form, $loader, $continueButton, $ajaxLoading, $phone,
    ajaxRequestCount = 0;

/**
 * @function
 * @description function to show the page ajax loader and disable the submit button (does change the validation process submit button disable status)
 */
function showLoader() {
    ajaxRequestCount++;
    if (ajaxRequestCount === 1) {
        window.vbqUtils.progressLoader(true, $loader);
        $ajaxLoading.val('').trigger('change'); // empty value = form invalid (see formPrepare.js)
    }
}

/**
 * @function
 * @description function to hide the page ajax loader and enable the submit button (does change the validation process submit button disable status)
 */
function hideLoader() {
    ajaxRequestCount--;
    if (ajaxRequestCount <= 0) {
        window.vbqUtils.progressLoader(false, $loader);
        $ajaxLoading.val('done').trigger('change');
        ajaxRequestCount = 0; //security
    }
}

/**
 * Init cached elements
 */
function initCache() {
    $form = $('form[id$="y2customer2"]');

    formPrepare.init({
        formSelector: 'form[id$="y2customer2"]',
        continueSelector: '#js-y2customerformProcessOnSubmit'
    });

    $continueButton = $('#js-y2customerformProcessOnSubmit');
    $loader = $('#js-y2customerformLoader');
    $ajaxLoading = $('#js-y2customerformLoader');

    $phone = $form.find('.phone_field');
    window.vbqUtils.phoneCodes.init($form.find('.country_field'), $phone);
}

/**
 * On form submit action
 * @param {jQuery Object} e - jquery event object
 */
function onFormSubmit(e) {
    // Prevent enter key submit and follow the normal submit button logic
    e.preventDefault();

    if ($continueButton.length > 0 && $continueButton.is(':not(:disabled)')) {
        $continueButton.trigger('click');
    }
}

/**
 * On country change action
 * @param {JQuery Object} e - jquery event object
 */
function onCountryChange(e) {
    var country = $(e.currentTarget).val(),
        phone = $phone.val();

    // force update since it is not a form submit
    if ($phone.parent().hasClass('iti') && (phone === '' || phone === null)) {
        phone = window.intlTelInputGlobals.getInstance($phone[0]).getNumber();
    }

    showLoader();
    $.ajax({
        url: util.appendParamsToUrl(Urls.updateCustomerForm, {'countryCode': country, 'form': $form.serialize()})
    }).done(function (response) {
        $form.find('.address-dynamic-form').html(response);

        formPrepare.init({
            formSelector: '[id$="y2customer2"]',
            continueSelector: '#js-y2customerformProcessOnSubmit'
        });

        // Initialize the jQuery UI elements
        window.vbqUtils.initializeJQueryUiPlugins();
        window.vbqUtils.initFloatingLabels($form);
        $phone = $form.find('.phone_field');
        window.vbqUtils.phoneCodes.init($form.find('.country_field'), $phone);
        if (phone !== null && phone !== '') {
            window.intlTelInputGlobals.getInstance($phone[0]).setNumber(phone);
        }

        var $state = $form.find('dwfrm_y2customer_states_state');
        if ($state.is('input')) {
            $state.rules('add', {
                maxlength: 4
            });
        }

        hideLoader();
    }).fail(function () {
        window.alert(Resources.CHECKOUT_AJAX_ERROR);
        hideLoader();
    });
}

/**
 * On click button action
 */
function onClickButton() {
    // force update since it is not a form submit
    if ($phone.parent().hasClass('iti')) {
        $phone.siblings('input').val(window.intlTelInputGlobals.getInstance($phone[0]).getNumber());
    }

    // security, if the form is not valid, do not submit it
    if (!$form.valid()) {
        $continueButton.prop('disabled', true);
        return;
    }

    $form.off('submit'); // permit normal form submit

    showLoader();

    // remove 'mouseover' event to not enable the button during the form submition
    $continueButton.parent().off('mouseover');
    $form.submit();
}

/**
 * Validates the phone number field
 * @param {Object} $phone - jQuery object of the phone number field
 */
function validatePhoneAction($phone) {
    var isValid = $phone.valid();

    if (isValid) {
        formPrepare.validateForm();
    }
    else {
        $continueButton.prop('disabled', true);
    }
}

/**
 * On Phone change action
 * @param {JQuery Object} e - jquery event object
 */
function onPhoneChange(e) {
    var $this = $(e.currentTarget);

    validatePhoneAction($this);
}

/**
 * Init Events
 */
function initEvents() {
    $form.on('submit', onFormSubmit);
    $form.on('change', '[name$="_country"]', onCountryChange);
    $form.on('keyup input', '.phone_field', _debounce(onPhoneChange, 200));
    $continueButton.click(onClickButton);
}

exports.init = function () {
    initCache();
    initEvents();
};
