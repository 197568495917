'use strict';

var dialog = require('../../dialog'),
    util = require('../../util'),
    progress = require('../../progress'),
    storelocator = require('../storelocator');

var storesDialog = {
    /**
     * @function
     * @description shared behavior for all stores selection dialogs
     */
    init: function () {
        $('.js-storeDetailsToggle, .js-mobile-storeDetailsToggle').on('click keydown', function(e) {
            // mouse click or enter/space key
            if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
                // prevent the default action to stop scrolling when space is pressed
                e.preventDefault();

                var $toggleControl = $(this),
                    $toggleParent = $toggleControl.parents('.js-store-content'),
                    $toggleContent = $toggleParent.find('.checkout-store-data'),
                    $siblingsToggleControl = $toggleParent.find('.js-storeDetailsToggleBtn');

                // sync mobile/desktop toggle controls
                $siblingsToggleControl.attr('aria-expanded', $toggleControl.attr('aria-expanded') === 'true' ? 'false' : 'true');

                // manage open/close button identificator
                $siblingsToggleControl.filter('.js-storeDetailsToggle').toggleClass('active');
                $siblingsToggleControl.filter('.js-mobile-storeDetailsToggle').toggleClass('ui-state-active');

                $toggleContent.toggleClass('hidden').attr('aria-hidden', $toggleContent.attr('aria-hidden') === 'true' ? 'false' : 'true');
            }
        });

        var $form = $('.js-checkUltraFastDelivery');
        if ($form.length) {
            $form.validate();
            window.vbqUtils.initializeJQueryUiPlugins();
            window.vbqUtils.initFloatingLabels($form);
        }
    },
    /**
     * OCC-3 - Omnichannel Click and Collect
     * @function
     * @description shared behavior between PDP page and PDP iframe (linked product(s))
     */
    PDPInit: function () {
        var $wrapper = $('.js-pdp-main');

        if ($wrapper.find('.js-sameDayDeliveryOptionOnly').length) {
            // mouseenter and mouseleave events do not bubble, they need to be attached to the button itself
            $('.js-sameDayDeliveryOptionOnly').off('mouseenter mouseleave') // security
                .on('mouseenter', function () {
                    $(this).toggleClass('gl_btn-blue gl_btn-white');
                    $('.add-to-cart-primary').toggleClass('gl_btn-blue gl_btn-white');
                })
                .on('mouseleave', function () {
                    $(this).toggleClass('gl_btn-blue gl_btn-white');
                    $('.add-to-cart-primary').toggleClass('gl_btn-blue gl_btn-white');
                });
        }

        if ($wrapper.find('.js-clickAndCollectOpenDialog').length) {
            $wrapper.off('click', '.js-clickAndCollectOpenDialog').on('click', '.js-clickAndCollectOpenDialog', function (e) {
                e.preventDefault();
                var pid = $('.js-pid').val(),
                    $this = $(this);
                progress.show($this);
                storesDialog.openDialog(pid, 'pdp', false, $this);
            });
        }

        if ($wrapper.find('.js-notSoldOnlineOpenDialog').length) {
            $wrapper.off('click', '.js-notSoldOnlineOpenDialog').on('click', '.js-notSoldOnlineOpenDialog', function (e) {
                e.preventDefault();
                var $this = $(this),
                    pid = $this.data('pid');
                progress.show($this);
                storesDialog.openDialog(pid, 'pdp', false, $this, 'nso');
            });
        }
    },
    /**
     * OCC-3 - Omnichannel Click and Collect
     * @function
     * @description shared behavior between PDP page and PDP iframe (linked product(s))
     */
    CartSummaryInit: function () {
        var $wrapper = $('#main');
        if ($wrapper.find('.js-clickAndCollectOpenDialog').length) {
            $wrapper.off('click', '.js-clickAndCollectOpenDialog').on('click', '.js-clickAndCollectOpenDialog', function (e) {
                e.preventDefault();
                var $button = $(this),
                    pid = $button.data('pid'),
                    lineItemUUID = $button.data('uuid');

                progress.show($button.parents('.js-cart-item-delivery-options'));
                storesDialog.openDialog(pid, 'cart', lineItemUUID, $button);
            });
        }
    },
    openDialog: function (pid, page, lineItemUUID, $target, storeType) {
        $.ajax({
            url: util.appendParamsToUrl(window.Urls.getStores, {
                pid: pid,
                type: storeType || 'cc',
                page: page,
                format: 'ajax'
            })
        }).done(function (data) {
            if (data && data.error) {
                window.alert(window.Resources.GLOBAL_AJAX_ERROR);
            }
            else {
                // reset variables for storelocator.js
                window.vbq.stores = null;
                window._gMapInitialized = false;

                dialog.open({
                    html: data,
                    bodyClass: 'storelocator-dialog-active',
                    customCloseSelector: '.js-store-locator-close',
                    options: {
                        autoOpen: false,
                        draggable: false,
                        modal: true,
                        resizable: false,
                        closeOnEscape: true,
                        classes: {
                            'ui-dialog': 'clickAndCollectDialog' //used to force styles
                        },
                        position: {
                            my: 'center top',
                            at: 'center top'
                        },
                        open: function () {
                            storelocator.init();
                            // init must be done here as the dialog content is loaded by ajax
                            storesDialog.init();

                            var $jsStoresWrapper = $('.js-storesWrapper');
                            $jsStoresWrapper
                                .attr('data-pid', pid).attr('data-uuid', lineItemUUID)
                                .off()
                                .on('click', '.js-chooseStore', function () {
                                    var $this = $(this),
                                        storeId = $this.attr('data-store');

                                    $this.attr('disabled', 'disabled');

                                    if (page == 'pdp') {
                                        $('.add-to-cart-primary').trigger('click', [storeId, false, '']);
                                    }
                                    else if (page == 'cart') {
                                        var pid = $jsStoresWrapper.attr('data-pid'),
                                            uuid = $jsStoresWrapper.attr('data-uuid');

                                        // update the cart line item and reload the page
                                        $.ajax({
                                            url: Urls.addProduct,
                                            type: 'POST',
                                            data: {pid: pid, uuid: uuid, storeid: storeId, storepickup: true, cc: true, ufd: false, postalCode: '', format: 'ajax'}
                                        }).always(function (data) {
                                            window.location.reload(true);
                                        });
                                    }
                                });

                            $('.js-checkUltraFastDelivery').off('submit').on('submit', function (e) {
                                e.preventDefault();
                                var $zipCodeInput = $('.js-ufdZipCode'),
                                    zipCode = $zipCodeInput.val(),
                                    zipCodeAriaDescribedBy = $zipCodeInput.attr('aria-describedby') || '',
                                    $checkUltraFastDeliveryResult = $('.js-checkUltraFastDeliveryResult').empty(),
                                    $okSlot = $('.js-checkUltraFastDeliveryResultOK').addClass('hidden'),
                                    $koSlot = $('.js-checkUltraFastDeliveryResultKO').addClass('hidden');

                                progress.show($(this));
                                $zipCodeInput.attr('aria-describedby', $.trim(zipCodeAriaDescribedBy.replace('js-ufdErrorResult', '')));

                                // update the cart line item and reload the page
                                $.ajax({
                                    url: window.Urls.checkUltraFastDeliveryForProduct,
                                    type: 'POST',
                                    data: {pid: pid, zipCode: zipCode, format: 'ajax'}
                                }).done(function (data) {
                                    // display result message
                                    if (data.eligible) {
                                        // success message + add-to-cart as ultraFastDelivery button
                                        $checkUltraFastDeliveryResult.html(data.code);
                                        $okSlot.removeClass('hidden');
                                    }
                                    else {
                                        // inegibility message
                                        zipCodeAriaDescribedBy = $zipCodeInput.attr('aria-describedby') || '';
                                        $checkUltraFastDeliveryResult.html(data.message);
                                        $zipCodeInput.attr('aria-describedby', zipCodeAriaDescribedBy + ' js-ufdErrorResult');
                                        $koSlot.removeClass('hidden');

                                        // EADA23-5 ADA Focus on the input field if error message is displayed
                                        $zipCodeInput.focus();
                                    }
                                }).fail(function() {
                                    window.alert(Resources.GLOBAL_AJAX_ERROR);
                                })
                                .always(function () {
                                    progress.hide();
                                });
                            });

                            $('.js-removeUltraFastDeliveryPostalCode').on('click', function(e) {
                                e.preventDefault();

                                progress.show($(this));
                                $.ajax({
                                    url: window.Urls.resetUltraFastDeliveryParameters,
                                    type: 'POST',
                                    data: {format: 'ajax'}
                                }).done(function (data) {
                                    if (data.success) {
                                        // close dialog
                                        dialog.close();
                                        // update the header and mobile menu UFD links
                                        $('.js-ultraFastDeliveryPostalCodeValue').empty();
                                        // reload add-to-cart form for current size
                                        $('#product-content').find('.gl_size-button').filter('.selected').first().click();
                                    }
                                    else {
                                        window.alert(Resources.GLOBAL_AJAX_ERROR);
                                        progress.hide();
                                    }
                                }).fail(function() {
                                    window.alert(Resources.GLOBAL_AJAX_ERROR);
                                    progress.hide();
                                });
                            });

                            $('.js-checkUltraFastDeliveryWrapper').on('click', '.js-addToCartAsUltraFastDelivery', function (e) {
                                e.preventDefault();
                                var $this = $(this),
                                    storeId = $this.attr('data-ufdStoreId'),
                                    postalCode = $this.attr('data-ufdPostalCode');

                                $this.attr('disabled', 'disabled');

                                if (page == 'pdp') {
                                    $('.add-to-cart-primary').trigger('click', [storeId, true, postalCode]);
                                }
                                else if (page == 'cart') {
                                    // TODO not covered in specs
                                }
                            });
                        }
                    }
                });
            }
        }).fail(function () {
            window.alert(Resources.GLOBAL_AJAX_ERROR);
        })
        .always(function () {
            progress.hide();
        });
    }
};

module.exports = storesDialog;
