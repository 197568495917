'use strict';

var cache = {};

/**
 * Cache elements
 */
function initCache() {
    cache.$document = $(document);
    cache.$fixedAmount = cache.$document.find('.js-fixed_amount');
    cache.$freeAmount = cache.$document.find('.js-free_amount');
    cache.$finalAmount = cache.$document.find('.js-final_amount');
    cache.$amountWrapper = cache.$document.find('.js-amount_wrapper');
    cache.$amountErrorWrapper = cache.$document.find('.js-amount_error_message');
    cache.$submitButton = cache.$document.find('#GiftCertificateForm').find('button').filter('[type="submit"]');
}

/**
 * Action to do on free amount value input / change
 * @param {jQuery Object} e - event object
 * @returns {Boolean} true if the current char is not a dot
 */
var keyPressAction = function (e) {
    // uncheck any selected amount, if any
    cache.$fixedAmount.removeClass('gl_has-selected').attr('aria-pressed', 'false');

    // Don't allow dot char
    var keycode = e.keyCode,
        notAllowedKeyCodes = [
            110, // dot . ( numpad )
            107, //  ( numpad )
            189, // dash - ( keyboard )
            109, // minus - ( keyboard numpad )
            190, // dot ( keyboard )
            188, // comma ,
            32, // tab on mobile qwerty keyboard
            0, // dash - on mobile qwerty keyboard,
            69 // e - on the keyboard
        ];

    return notAllowedKeyCodes.indexOf(keycode) === -1;
};

/**
 * Validate the free amount value
 * @param {jQuery Object} e - event object
 */
function keyUpAction(e) {
    var element = e.currentTarget,
        maxAllowedValue = parseInt(element.max, 10),
        amount = parseInt(element.value, 10);

    // do nothing if any of the values are NaN
    if (isNaN(maxAllowedValue) || isNaN(amount)) {
        return;
    }

    // limit amount to the max specified value
    amount = Math.min(amount, maxAllowedValue);

    // update the value on free amount field ( cases: when nubmer starts with 0, ex: 03, 0400 ... )
    e.currentTarget.value = amount;
    // set the value to hidden amount input element
    cache.$finalAmount.val(amount);

    // remove any previous error message
    cache.$amountErrorWrapper.html('');
    // remove any previous error class
    cache.$amountWrapper.removeClass('gl_has-error');
}

/**
 * Action when selecting one of the fixed amount values
 * @param {jQuery Object} e - event object
 */
function fixedAmountAction(e) {
    // mouse click or enter/space key
    if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
        // prevent the default action to stop scrolling when space is pressed
        e.preventDefault();

        var $this = $(e.currentTarget),
            amount = $this.attr('data-value');

        // remove any values from free amount if any
        cache.$freeAmount.val('')
            .change()
            .valid();

        // un-select any selected fixed amount values
        cache.$fixedAmount.removeClass('gl_has-selected').attr('aria-pressed', 'false');

        // select current fixed amount value
        $this.addClass('gl_has-selected').attr('aria-pressed', 'true');

        // set the value to hidden amount input element
        cache.$finalAmount.val(amount);

        // remove any previous error message
        cache.$amountErrorWrapper.html('');
        // remove any previous error class
        cache.$amountWrapper.removeClass('gl_has-error');
    }
}

/**
 * Check if current amount is not empty
 * @returns {Boolean} true if the field is not empty and the value is an integer, false otherwise
 */
function valid() {
    // check directly for the "final amount field"
    var finalAmount = cache.$finalAmount.val(),
        parsedAmount = parseInt(finalAmount, 10);

    // remove any previous error class
    cache.$amountWrapper.removeClass('gl_has-error');

    // security
    if (!finalAmount) {
        cache.$amountWrapper.addClass('gl_has-error');
        cache.$amountErrorWrapper.html('Please select an amount');
        return false;
    }

    if (isNaN(parsedAmount)) {
        cache.$amountWrapper.addClass('gl_has-error');
        cache.$amountErrorWrapper.html('Please select an amount');
        return false;
    }

    return true;
}

/**
 * Clear any selected fixed amount and the input amount
 */
function clear() {
    cache.$fixedAmount.removeClass('gl_has-selected').attr('aria-pressed', 'false');
    cache.$freeAmount.val('');
}

/**
 * Show custom error message
 * @param {Stroing} message - error message to show
 */
function showError(message) {
    cache.$amountWrapper.addClass('gl_has-error');
    cache.$amountErrorWrapper.html(message);
}

/**
 * on page load check if "final amount" field has any value and update the custom amount fields
 */
function updateAmountValue() {
    var amountValue = cache.$finalAmount.val();
    var customAmountField;

    if (amountValue) {
        customAmountField = cache.$document.find('.js-fixed_amount[data-value="' + amountValue + '"]');
    }

    if (customAmountField && customAmountField.length) {
        customAmountField.addClass('gl_has-selected');
    }
    else {
        cache.$freeAmount.val(amountValue);
    }
}

/**
 * Init events
 */
function initEvents() {
    cache.$freeAmount.on('keydown', keyPressAction);
    cache.$freeAmount.on('keyup', keyUpAction);
    cache.$fixedAmount.on('click keydown', fixedAmountAction);

    // validate the amount value when hovering the mouse over the form submit button
    cache.$submitButton.on('mouseover', valid);
}

module.exports = {
    init: function () {
        initCache();
        initEvents();
        updateAmountValue();
    },
    valid: valid,
    clear: clear,
    showError: showError
};
