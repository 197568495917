'use strict';

var ajax = require('./ajax'),
    util = require('./util'),
    _isString = require('./lib/lodash.isstring'),
    _debounce = require('./lib/lodash.debounce');

/*
Two approaches to initialize dialog:
1. jQuery dialog $(…).dialog() on existing dialog content in DOM: scan code base to find samples
2. Current component is DW encapsulation dialog.open() to get dialog content from url OR html params
*/

var dialog = {
    /**
     * @function
     * @description Appends a dialog to a given container (target)
     * @param {Object} params  params.target can be an id selector or an jquery object
     */
    $close: $(),
    $focusOnClose: $(),
    create: function (params) {
        var $target = null,
            id;

        if (params.hasOwnProperty('target')) {
            if (_isString(params.target)) {
                if (params.target.charAt(0) === '#') {
                    $target = $(params.target);
                } else {
                    $target = $('#' + params.target);
                }
            } else if (params.target instanceof jQuery) {
                $target = params.target;
            }
        }

        if ($target === null) {
            $target = $('#dialog-container');
        }

        // if no element found, create one
        if ($target.length === 0) {
            if ($target.selector && $target.selector.charAt(0) === '#') {
                id = $target.selector.substr(1);
                $target = $('<div>').attr('id', id).addClass('dialog-content').appendTo('body');
            }
            else {
                $target = $('<div>').attr('id', 'dialog-container').addClass('dialog-content').appendTo('body');
            }
        }

        // create the dialog
        this.$container = $target;
        this.$container.dialog($.extend(true, {}, this.settings, params.options || {}));
        this.customOptions = params;
    },
    /**
     * @function
     * @description Opens a dialog using the given url (params.url) or html (params.html)
     * @param {Object} params
     * @param {Object} params.url should contain the url
     * @param {String} params.html contains the html of the dialog content
     */
    open: function (params) {
        if (!params.url && !params.html) {
            return;
        }

        // close any open dialog
        this.close();
        this.create(params);
        this.replace(params);

        // reposition the dialog with the provided or default position
        this.repositionDialog();

        return this;
    },
    /**
     * @description populate the dialog with html content, then open it
     **/
    openWithContent: function (params) {
        var content, callback;

        if (!this.$container) { return; }
        content = params.content || params.html;
        if (!content) { return; }
        this.$container.empty().html(content);
        if (!this.$container.dialog('isOpen')) {
            this.adjustDialogContent(params);
            this.$container.dialog('open');
        }

        callback = (typeof params.callback === 'function') ? params.callback : function () {};
        callback();
    },
    /**
     * @function
     * @description Repositions the dialog related to the window
     * when the window is resized or when the dialog is created/opened.
     * @param {boolean} [isResize=true] - Optional parameter to determine if the resize logic should be applied
     */
    repositionDialog: function(isResize = true) {
        if (this.$container) {
            // Use params.options.position or this.settings.position for positioning
            var position = (this.customOptions && this.customOptions.options && this.customOptions.options.position) ? 
            this.customOptions.options.position : this.settings.position;

            this.$container.dialog('option', 'position', position);

            // Bind the resize event to reposition the dialog
            if (isResize) {
                this._debouncedReposition = this._debouncedReposition || _debounce(this.repositionDialog.bind(this), 100);
                $(window).off('resize', this._debouncedReposition).on('resize', this._debouncedReposition);
            }
        }
    },
    /**
     * @description adjust the actual content dialog
     **/
    adjustDialogContent: function (params) {
        var dialogObject = this;

        this.$dialog = this.$container.closest('.ui-dialog');

        // EADA-40 - The modal container is not labeled as such: DW encapsulation case
        if (window.vbqUtils) {
            window.vbqUtils.jqDialogOpenAdjust(this.$dialog);
        }

        // define close button after actualize this.$container content via replace (url OR html): custom OR jquery default close
        this.$close = params.customCloseSelector ? this.$container.find(params.customCloseSelector) : this.$dialog.find('.ui-dialog-titlebar-close');
        this.$container.on('click', params.customCloseSelector ? params.customCloseSelector : '.ui-dialog-titlebar-close', function () {
            dialogObject.close();
        });

        // define custom focused element on dialog close
        this.$focusOnClose = params.customFocusOnCloseElement || $();

        window.vbqUtils.cache.$body.css('overflow', 'hidden');
        window.vbqUtils.cache.$body.addClass(params.bodyClass || '');
        window.vbqUtils.cache.$document.on('click', '.ui-widget-overlay', function () {
            dialogObject.close();
        });
    },
    /**
     * @description Replace the content of current dialog
     * @param {object} params
     * @param {string} params.url - If the url property is provided, an ajax call is performed to get the content to replace
     * @param {string} params.html - If no url property is provided, use html provided to replace
     */
    replace: function (params) {
        if (!this.$container) {
            return;
        }
        if (params.url) {
            params.url = util.appendParamToURL(params.url, 'format', 'ajax');
            ajax.load({
                url: params.url,
                data: params.data,
                callback: function (response) {
                    params.content = response;
                    this.openWithContent(params);
                }.bind(this)
            });
        } else if (params.html) {
            this.openWithContent(params);
        }
    },
    /**
     * @function
     * @description Closes the dialog
     */
    close: function () {
        if (!this.$container) {
            return;
        }

        // activate body scroll
        window.vbqUtils.cache.$body.css('overflow', '');
        window.vbqUtils.cache.$body.removeClass(this.customOptions.bodyClass);

        //this.$close.off('click');
        window.vbqUtils.cache.$document.off('viewportchange').off('click', '.ui-widget-overlay');


        var closeCallback = (typeof this.customOptions.closeCallback === 'function') ? this.customOptions.closeCallback : function () {};
        closeCallback();

        // Default jQuery dialog after-close focus: https://api.jqueryui.com/dialog/#event-focus
        // Upon closing a dialog, focus is automatically returned to the element that had focus when the dialog was opened.

        // set focus on custom focused element on dialog close
        if (this.$focusOnClose.length > 0) {
            this.$focusOnClose.focus();
        }

        // Unbind the resize event when the dialog is closed
        $(window).off('resize', this._debouncedReposition);

        this.$container.dialog('close');
    },
    exists: function () {
        return this.$container && (this.$container.length > 0);
    },
    isActive: function () {
        return this.exists() && (this.$container.children.length > 0);
    },
    settings: {
        autoOpen: false,
        height: 'auto',
        modal: true,
        overlay: {
            opacity: 0.5,
            background: 'black'
        },
        resizable: false,
        // reset previous dialog title & dialogClass value
        title: '',
        dialogClass: '',
        classes: {
            'ui-dialog': ''
        },
        width: '800',
        closeOnEscape: true, // EADA-34 - All modal can be closing on escape key: DW encapsulation case
        closeText: window.Resources.CLOSE,
        // focus() runs if no custom focus in options on dialog.open({})
        focus: function () {
            // Force jQuery dialog focus logic below
            /*
                Upon opening a dialog, focus is automatically moved to the first item that matches the following:
                1. The first element within the dialog with the autofocus attribute
                2. The first :tabbable element within the dialog's content
                3. The first :tabbable element within the dialog's buttonpane
                4. The dialog's close button
                5. The dialog itself
            */

            // Force focus on jQuery close button
            dialog.$close.focus();
        },
        // close() runs if no custom close in options on dialog.open({})
        close: function () {
            // Fix to close() on both cases: button close & Esc
            dialog.close();
        },
        position: {
            my: 'center',
            at: 'center',
            of: window,
            collision: 'flipfit'
        }
    }
};

module.exports = dialog;
