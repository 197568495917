/* jshint esversion: 6 */

'use strict';

/**
 * Video for carousel will load bynder vide in a carousel
 */

var bynderScriptUrl = 'https://d8ejoa1fys2rk.cloudfront.net/modules/bynder-embed/4.0.0/js/bynder-embed.min.js';
var cache = {};
cache.document = document;

/**
 * Create bynder video placehoder
 * @param {Object} dataAttributes - object with element attributes to use for video placeholder
 * @return {DOM Element} videoplaceholder
 */
function createBynderVideoPlacehoder(dataAttributes) {
    var mediaId = dataAttributes.mediaId || '',
        autoplay = dataAttributes.autoplay || false,
        videoplaceholder = cache.document.createElement('span');

    videoplaceholder.classList.add('js-bynderVideo');
    videoplaceholder.setAttribute('data-bynder-widget', 'video-item');
    videoplaceholder.setAttribute('data-media-id', mediaId);
    videoplaceholder.setAttribute('data-autoplay', autoplay);

    return videoplaceholder;
}

/**
 * Create bynder script
 */
function createBynderScript() {
    var bynderScript = cache.document.createElement('script');
    bynderScript.setAttribute('src', bynderScriptUrl);
    bynderScript.setAttribute('id', 'bynder-widgets-js');
    bynderScript.setAttribute('data-account-url', 'media.vilebrequin.com');
    bynderScript.setAttribute('data-language', 'en');
    bynderScript.setAttribute('defer', true);

    return bynderScript;
}

/**
 * Returns the Bynder video id based on the device viewport
 * @param {HTMLElement} element - the element containing the Bynder video ids
 * @returns {string} - the Bynder video id for the device viewport
 */
function getBynderVideoId(element) {
    var bynderid = element.dataset.bynderid,
        // below attributes are optional so as a fallback we use the bynderid attribute
        desktopbynderid = element.dataset.desktopbynderid || bynderid,
        tabletbynderid = element.dataset.tabletbynderid || bynderid,
        mobilebynderid = element.dataset.mobilebynderid || bynderid,
        // set default value for mediaId
        mediaId = bynderid;

    // based on the device viewport use the corresponding bynder video id
    if (window.vbqUtils.breakpoint.is('mobile')) {
        mediaId = mobilebynderid;
    } else if (window.vbqUtils.breakpoint.is('tablet')) {
        mediaId = tabletbynderid;
    } else if (window.vbqUtils.breakpoint.is('desktop')) {
        mediaId = desktopbynderid;
    }

    return mediaId;
}

/**
 * Loads Bynder video into the given element
 * @param {HTMLElement} element - the element to load the video into
 * @param {boolean} playVideo - if true, the video will be played automatically
 */
function loadBynderVideo(element, playVideo) {
    // security, if element is not defined or the bynder video placeholder was already added, do nothing
    if (!element || element.querySelector('.js-bynderVideo')) {
        return;
    }

    var dataAttributes = {
            mediaId: getBynderVideoId(element),
            autoplay: playVideo || false
        },
        // create video placeholder element for bynder
        videoPlaceholder = createBynderVideoPlacehoder(dataAttributes),
        // create the script element to load the bynder script
        bynderScript = createBynderScript();

    // append script to the video placeholder
    videoPlaceholder.appendChild(bynderScript);

    // append to the video wrapper element, so that the video placeholoder loader is not removed here.
    // the loader will be removed after the video is loaded
    element.appendChild(videoPlaceholder);
}

/**
 * Updates the slick slider height when a video element is added
 * @param {Object} slick - the slick slider object
 * @param {Object} videoElementWrapper - the DOM element that wraps the video element
 */
function updateSliderHeight(slick, videoElementWrapper) {
    // eslint-disable-next-line prefer-const
    let videoElementPlaceholder = videoElementWrapper && videoElementWrapper.querySelector('.js-bynderVideo');
    // security, if videoElementPlaceholder is not found or the mutation observer was already added, do nothing
    if (!videoElementPlaceholder || videoElementPlaceholder.classList.contains('bynder-mutation-observer-added')) {
        return;
    }

    let observer = {};
    // Create an observer instance linked to the callback function
    observer = new MutationObserver(function(mutationsList) {
        let mutation;
        for (let i = 0; i < mutationsList.length; i++) {
            mutation = mutationsList[i];

            // Ignore mutations that are not 'childList'
            if (mutation.type !== 'childList') {
                continue;
            }

            // check for video element
            let videoElement;
            Array.from(mutation.addedNodes).forEach(function (node) {
                // bynder video adds the video element inside a div wrapper at the same time
                videoElement = node.nodeName == 'DIV' && node.querySelector('video');

                // if videoElement was found then no need to check the other nodes, as bynder adds only 1 video element
                if (videoElement) {
                    return;
                }
            });

            // if video element was not found, continue to next mutation
            if (!videoElement) {
                continue;
            }

            // video element was found ( has been added )
            // check when video is loaded and update slick slider height
            videoElement.addEventListener('loadeddata', function() {
                // remove the video placeholder loader
                var videoPlaceholderLoader = videoElementWrapper.querySelector('span.js-video-placeholder__loader');

                if (videoPlaceholderLoader) {
                    videoElementWrapper.removeChild(videoPlaceholderLoader);
                }

                // Video has finished loading, update slick slider to take into account the new height
                slick.setPosition();
            
                // Disconnect the observer since we no longer need it
                if (observer && observer instanceof MutationObserver) {
                    observer.disconnect();
                }
            });

            // video was found and the event attached, no need to continue
            break;
        }
    });
    // Start observing the target node for configured mutations
    // https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/observe
    observer.observe(videoElementPlaceholder, {childList: true, subtree: true});

    // add class to the video placeholder element to indicate that the mutation observer was added
    videoElementPlaceholder.classList.add('bynder-mutation-observer-added');
}

/**
 * Loads the first slide video and updates the slick slider height
 * @param {Object} slick - slick slider instance
 */
function loadFirstSlideVideo(slick) {
    var currentSlideElement = slick.$slides[0],
        currentSlideVideo = currentSlideElement.querySelector('.js-videoforcarousel'),
        playVideo = true;

    // load bynder video
    loadBynderVideo(currentSlideVideo, playVideo);
    // update slick slider height
    updateSliderHeight(slick, currentSlideVideo);
}

/**
* Event handler for afterChange event of slick slider
* @param {Object} event - the event object
* @param {Object} slick - the slick slider object
* @param {number} currentSlide - the current slide index
*/
function onAfterChangeAction(_event, slick, currentSlide) {
    // get current slide
    var currentSlideElement = slick.$slides[currentSlide],
        currentSlideVideo = currentSlideElement.querySelector('.js-videoforcarousel'),
        video = currentSlideVideo && currentSlideVideo.querySelector('video');

    // if video is found, play it
    if (video) {
        video.play();
    }

    // video was not found, so load it
    // load bynder video
    loadBynderVideo(currentSlideVideo);
    // update slick slider height
    updateSliderHeight(slick, currentSlideVideo);
}

/**
 * Handles the beforeChange event of the slick slider
 * @param {Object} event - the event object
 * @param {Object} slick - the slick object
 * @param {Number} currentSlide - the current slide index
 * @param {Number} nextSlide - the next slide index
 */
function onBeforeChangeAction(_event, slick, currentSlide, nextSlide) {
    // get current slide
    var currentSlideElement = slick.$slides[currentSlide],
        currentSlideVideo = currentSlideElement.querySelector('.js-videoforcarousel'),
        video = currentSlideVideo && currentSlideVideo.querySelector('video');

    // if video is already loaded, pause it
    if (video) {
        video.pause();
    }

    // set the height of the video placeholder loader from the next slide to be the same as current one
    // This measure is implemented to prevent abrupt changes in the height of the slider upon video loading, ensuring a consistent user experience.
    var nextSlideElement = slick.$slides[nextSlide],
        nextSlideVideo = nextSlideElement.querySelector('.js-videoforcarousel'),
        nextSlideVideoPlaceholder = nextSlideVideo && nextSlideVideo.querySelector('span.js-video-placeholder__loader');

    if (nextSlideVideoPlaceholder) {
        nextSlideVideoPlaceholder.style.height = currentSlideVideo.offsetHeight + 'px';
    }
}

/**
 * Initializes the video for the first slide in the carousel
 * @param {Object} event - the event object
 * @param {Object} slick - the slick object
 */
function onInitAction(_event, slick) {
    loadFirstSlideVideo(slick);
}

/**
 * Clears the contents of the videoforcarousel element
 * @param {Object} event - the slick event object
 * @param {Object} slick - the slick object
 */
function onDestroyAction(_event, slick) {
    slick.$slides.each(function (_index, slide) {
        // remove the bynder video from the slide
        // to allow on re-initialization to get the correct video format for the current viewport
        // add the placeholder loader
        slide.querySelector('.js-videoforcarousel').innerHTML = '<span class="js-video-placeholder__loader spinner"></span>';
    });
}

/**
 * Initializes the carousel video
 * @param {Object} carousel - the target carousel
 */
function initCarouselVideo(carousel) {
    // load video form current slide on first initialization and after each re-initialization
    $(carousel).on('init reInit', onInitAction);

    // on after slide change load the video from the current slide
    $(carousel).on('afterChange', onAfterChangeAction);
    // on before slide change, pause the video from the current slide, before going to next one
    $(carousel).on('beforeChange', onBeforeChangeAction);

    // if slick slider is already initialized, then load the video from first slide
    if (carousel.slick) {
        loadFirstSlideVideo(carousel.slick);
    }

    // on destroy, clear the contents of the videoforcarousel element
    // to allow on re-initialization to get the correct video format for the current viewport
    $(carousel).on('destroy', onDestroyAction);
}

/**
 * Initializes the cache object
 */
function initCache() {
    cache.videoCarousels = cache.document.querySelectorAll('.js-videocarousel');
}

/**
 * Initializes the carousel video events
 */
function initEvents() {
    // if there are no video carousels, do nothing
    if (!cache.videoCarousels || cache.videoCarousels.length == 0) {
        return;
    }

    for (var i = 0; i < cache.videoCarousels.length; i++) {
        initCarouselVideo(cache.videoCarousels[i]);
    }
}

module.exports = {
    init: function () {
        initCache();
        initEvents();
    }
};
