'use strict';

module.exports = function () {
    // if the page is rendered in BM PageDesigner do nothing
    if (window.location.href.indexOf('__SYSTEM__Page-Show') > -1) {
        return;
    }

    var $cookieDialog = $('#js-cookieDialog'),
        $cookieAcceptAnalytic = $('input:radio[name="cookie_analytic"]'),
        $cookieAcceptAdvertising = $('input:radio[name="cookie_advertising"]'),
        $cookieAcceptSocialNetwork = $('input:radio[name="cookie_sn"]'),
        $fakeSaveButton = $('#js-cookiePolicyButton');

    // ECM20-127 - Cookie Banner - CTA click mandatory
    if (!window.SessionAttributes.DO_NOT_TRACK) {
        if ($cookieDialog.length) {
            // ECM23-68 - Remove the cookie banner on the US website
            if (window.vbq.siteId.indexOf('US') != -1) {
                // ECM23-69 - Change the cookie consent default to YES on the US website only
                $cookieDialog.remove();

                // mimic GDPR banner being displayed, so it's only once per session
                $.ajax({
                    url: window.Urls.updateBannerDisplay.replace('outlet-', ''),
                    data: {'bannerShown': 'tracking'}
                }).done(function (data) {
                    // No action necessary
                });

                // mimic "accept all"
                // update cookie analytics
                updateCookieValue({type: 'analytic', value: 'yes'});
                // update cookie analytics
                updateCookieValue({type: 'advertising', value: 'yes'});
                // update cookie socialnetwork
                updateCookieValue({type: 'socialnetwork', value: 'yes'});
            }
            else {
                var $cookieAcceptAll = $('.js-cookieAccept'),
                    $cookieMoreInfo = $('.js-cookieMoreInfo'),
                    $cookieRefuseAll = $('.js-cookieRefuseAll');

                $cookieDialog.dialog({
                    autoOpen: false,
                    draggable: false,
                    modal: true,
                    resizable: false,
                    // no close button in cookie dialog: no closeText + no closeOnEscape
                    closeOnEscape: false,
                    classes: {
                        'ui-dialog': 'cookieDialog' //used to force styles
                    },
                    open: function (e) {
                        // EADA-40 - The modal container is not labeled as such: .dialog() case
                        window.vbqUtils.jqDialogOpenAdjust($(e.target).closest('.ui-dialog'));

                        // No action necessary
                        $cookieDialog.removeClass('hidden');

                        // EADA21-79 - ADA - Cookie Banner - #1: Keyboard and screen reader users do not have their focus moved to this modal dialog when it opens
                        $cookieRefuseAll.focus();
                    },
                    close: function () {
                        window.vbqUtils.cache.$document.trigger('closeCookieDialog');
                        $cookieDialog.remove();
                    }
                });

                window.setTimeout(function () {
                    $cookieDialog.dialog('open');
                }, 50); // the dialog needs to be visible immediately

                $('.js-cookieAccept, .js-cookieMoreInfo, .js-cookieRefuseAll').on('click', function (e) {
                    if (!$(this).hasClass('js-cookieMoreInfo')) {
                        e.preventDefault();
                    }

                    $cookieDialog.dialog('close');

                    $.ajax({
                        url: window.Urls.updateBannerDisplay,
                        data: {'bannerShown': 'tracking'}
                    }).done(function (data) {
                        // No action necessary
                    });

                    if (window.Urls.updateBannerDisplay.indexOf('outlet-') != -1) { // on outlet site, also call the main site so the banner is not shown when clicking on "js-cookieMoreInfo" button
                        $.ajax({
                            url: window.Urls.updateBannerDisplay.replace('outlet-', ''),
                            data: {'bannerShown': 'tracking'}
                        }).done(function (data) {
                            // No action necessary
                        });
                    }
                });

                if ($cookieAcceptAll.length) {
                    // accept all cookies, update the session tracking value and update the radio buttons selection
                    $cookieAcceptAll.on('click', function () {
                        // update session tracking value
                        $.ajax({url: window.Urls.updateTracking, data: {accept: 'yes'}});

                        // update cookie analytics
                        updateCookieValue({type: 'analytic', value: 'yes'});
                        // update radio button selection
                        updateRadioSelection($cookieAcceptAnalytic, 'yes');

                        // update cookie analytics
                        updateCookieValue({type: 'advertising', value: 'yes'});
                        // update radio button advertising
                        updateRadioSelection($cookieAcceptAdvertising, 'yes');

                        // update cookie socialnetwork
                        updateCookieValue({type: 'socialnetwork', value: 'yes'});
                        // update radio button selection
                        updateRadioSelection($cookieAcceptSocialNetwork, 'yes');
                    });
                }

                if ($cookieMoreInfo.length) {
                    // refuse all cookies
                    // no need to update radio buttons selection as the values are already set to 'no'
                    $cookieMoreInfo.on('click', function () {
                        // update cookie analytics
                        updateCookieValue({type: 'analytic', value: 'no'});

                        // update cookie advertising
                        updateCookieValue({type: 'advertising', value: 'no'});

                        // update cookie socialnetwork
                        updateCookieValue({type: 'socialnetwork', value: 'no'});
                    });
                }

                if ($cookieRefuseAll.length) {
                    // refuse all cookies
                    // no need to update radio buttons selection as the values are already set to 'no'
                    $cookieRefuseAll.on('click', function () {
                        // update cookie analytics
                        updateCookieValue({type: 'analytic', value: 'no'});

                        // update cookie advertising
                        updateCookieValue({type: 'advertising', value: 'no'});

                        // update cookie socialnetwork
                        updateCookieValue({type: 'socialnetwork', value: 'no'});
                    });
                }
            }
        }
    }
    else {
        $cookieDialog.remove();
    }

    // GDPR-30 - GDPR - Cookie Policy - Data layer - Display and update logic for the new radios
    if ($cookieAcceptAnalytic.length) {
        if ($cookieAcceptAnalytic.prop('checked') != true) {
            $.ajax({
                url: window.Urls.updateGdprRadio,
                data: {'type': 'analytic'}
            }).done(function (data) {
                var radioVal = (data && data.option) || 'no';
                updateRadioSelection($cookieAcceptAnalytic, radioVal);
                pushDataLayerEvent('ga', radioVal);
            });
        }
        else {
            pushDataLayerEvent('ga', $cookieAcceptAnalytic.filter(':checked').val());
        }

        $cookieAcceptAnalytic.on('change', {type: 'analytic'}, updateCookieValueHandler);
    }

    if ($cookieAcceptAdvertising.length) {
        if ($cookieAcceptAdvertising.prop('checked') != true) {
            $.ajax({
                url: window.Urls.updateGdprRadio,
                data: {'type': 'advertising'}
            }).done(function (data) {
                var radioVal = (data && data.option) || 'no';
                updateRadioSelection($cookieAcceptAdvertising, radioVal);
                pushDataLayerEvent('ad', radioVal);
            });
        }
        else {
            pushDataLayerEvent('ad', $cookieAcceptAdvertising.filter(':checked').val());
        }

        $cookieAcceptAdvertising.on('change', {type: 'advertising'}, updateCookieValueHandler);
    }

    // ECM-899 - Cookie policy optin improvement
    if ($cookieAcceptSocialNetwork.length) {
        if ($cookieAcceptSocialNetwork.prop('checked') != true) {
            $.ajax({
                url: window.Urls.updateGdprRadio,
                data: {'type': 'socialnetwork'}
            }).done(function (data) {
                var radioVal = (data && data.option) || 'no';
                updateRadioSelection($cookieAcceptSocialNetwork, radioVal);
                pushDataLayerEvent('sn', radioVal);
            });
        }
        else {
            pushDataLayerEvent('sn', $cookieAcceptSocialNetwork.filter(':checked').val());
        }

        $cookieAcceptSocialNetwork.on('change', {type: 'socialnetwork'}, updateCookieValueHandler);
    }

    // GDPR-22 - GDPR - Consent alert when privacy policy changes
    if (window.SessionAttributes.POLICY_UPDATED) {
        var $cookieUpdateWrapper = $('.cookie-update-banner');
        $cookieUpdateWrapper.removeClass('hidden').slideDown('slow', function () {
            window.vbqUtils.determineFixedNavHeight();
        });

        $('.js-cookieUpdateAccept').on('click', function () {
            $.ajax({
                url: window.Urls.updateConsentDates
            }).done(function (data) {
                $cookieUpdateWrapper.slideUp(400, function () {
                    window.vbqUtils.determineFixedNavHeight();
                });

                $.ajax({
                    url: window.Urls.updateBannerDisplay,
                    data: {'bannerShown': 'update'}
                }).done(function (data) {
                    // No action necessary
                });
            });
        });

        $('.js-cookieUpdateClose, .js-cookieReviewSettings, .js-cookieUpdateAccept').on('click', function () {
            $cookieUpdateWrapper.slideUp(400, function () {
                window.vbqUtils.determineFixedNavHeight();
            });

            $.ajax({
                url: window.Urls.updateBannerDisplay,
                data: {'bannerShown': 'update'}
            }).done(function (data) {
                // No action necessary
            });
        });
    }

    $fakeSaveButton.on('click', function (e) {
        e.preventDefault();
        if (window.Urls && window.Urls.home) {
            window.vbqUtils.progress.show($fakeSaveButton);
            window.location = window.Urls.home;
        }
    });

    /**
     * Update radio button selection on storefront
     * @param {jQuery/Object} $element - jQuery element
     * @param {String} value - radio button value
     */
    function updateRadioSelection($element, value) {
        if ($element.length) {
            $element.prop('checked', false).checkboxradio('refresh');
            $element.filter('[value="' + value + '"]').prop('checked', true).checkboxradio('refresh');
        }
    }

    /**
     * Handle cookie value update
     * This method is called on a change event
     * @param {Object} event - current event
     */
    function updateCookieValueHandler(event) {
        updateCookieValue({type: event.data.type, value: event.currentTarget.value});
    }

    /**
     * Update cookie value
     * This method is called directly
     * @param {Object} params - type & value
     */
    function updateCookieValue(params) {
        var cookieType = params.type,
            cookieName = '',
            cookieValue = params.value;

        switch (cookieType) {
            case 'analytic':
                cookieName = 'ga';
                break;
            case 'advertising':
                cookieName = 'ad';
                break;
            case 'socialnetwork':
                cookieName = 'sn';
                break;
            default:
        }

        $.ajax({
            url: window.Urls.updateTracking,
            data: {'type': cookieType, 'accept': cookieValue}
        }).done(function () {
            pushDataLayerEvent(cookieName, cookieValue);
        });
    }

    /**
     * Push event to GTM using cookie type and value to send
     * @param {String} cookieType - sn, ga or advertising
     * @param {String} sendValue - cookie value 'yes' or 'no'
     */
    function pushDataLayerEvent(cookieType, sendValue) {
        if (window.dataLayer) {
            var cookieEvent = {},
                cookieValue = sendValue == 'no',
                cookieOptin = 'cookie_' + cookieType + '_optin',
                cookieOptout = 'cookie_' + cookieType + '_optout';

            cookieEvent.event = cookieValue ? cookieOptout : cookieOptin;
            cookieEvent[cookieOptout] = cookieValue;

            window.dataLayer.push(cookieEvent);
        }
    }
};
