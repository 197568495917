'use strict';

/**
 * ERB-51 - SEO Tracking - Google Tag Manager
 * Module for handling dynamic events
 */

window.dataLayer = window.dataLayer || {};
window.gtmPageType = window.gtmPageType || '';

/**
 * Initializes events, pushes data
 */
var init = function () {
    //add promotion impressions
    addPromotionImpressions();

    //add product impressions
    addProductImpressions();

    //add product detail
    addProductDetail();

    //store locator
    addProductList();

    //product clicks
    initProductClick();

    //product sets
    $('.add-all-to-cart').on('click', function () {
        addToCart($('#productset-list').find('.add-to-cart'));
    });

    //remove from cart
    $('.js-gtmRemoveFromCart').on('click', function () {
        removeFromCart($(this));
    });

    //promotion clicks
    $('.js-gtmPromotionClick').on('click', function () {
        addPromotionClick($(this));
    });

    //faq
    $('.faq-info-btn').addClass('data-gtm-tab');

    // ECM24-8 - Datalayer update (GA4 GTM)
    // check sso login
    $('.social-login-btn').on('click', function () {
        pushSignUpEvent('account with sso');
    });
};

/**
 * Initializes product click event binding
 */
var initProductClick = function () {
    $('.js-gtmProductClick').off('click').on('click', function (e) {
        e.preventDefault();

        var $elem = $(this),
            $gtmProductTile = $elem.closest('.gtm-product-tile'),
            position = $gtmProductTile.length > 0 ? parseInt($gtmProductTile.attr('data-gtm-position'), 10) : 1,
            list = ($gtmProductTile.length > 0 && $gtmProductTile.attr('data-gtm-list')) ? (window.gtmPageType + '_' + $gtmProductTile.attr('data-gtm-list')) : (window.gtmPageType || '');

        addProductClick($elem, list, position.toFixed());

        // ECM24-8 - Datalayer update (GA4 GTM)
        setTimeout(function() {
            window.location.href = $elem.attr('href');
        }, 50);
    });
};

/**
 * ECM24-8 - Datalayer update (GA4 GTM)
 * Pushes an addToCart event to GTM dataLayer
 */
var addToCart = function ($elem) {
    if (window.dataLayer && $elem !== null && $elem.length > 0) {
        var products = [];
        $elem.each(function () {
            var $this = $(this);
            products.push(
                {
                    'item_name': $this.attr('data-productName'),
                    'item_id': $this.attr('data-productId'),
                    'item_ean': $this.attr('data-productEan'),
                    'price': $this.attr('data-productPrice'),
                    'currency': $elem.attr('data-productCurrency'),
                    'discount': $elem.attr('data-productDiscount'),
                    'item_brand': $this.attr('data-productBrand'),
                    'item_category': $this.attr('data-productCategory'),
                    'item_category2': $this.attr('data-productCategory2'),
                    'item_category3': $this.attr('data-productCategory3'),
                    'item_variant': $this.attr('data-productVariant'),
                    'coupon': $this.attr('data-productCoupon'),
                    'index': 1,
                    'quantity': parseInt($this.attr('data-productQuantity'), 10),
                    'item_family': $this.attr('data-productFamily'),
                    'item_print': $this.attr('data-productPrint'),
                    'item_color': $this.attr('data-productColor'),
                    'item_size': $this.attr('data-productSize')
                }
            );
        });

        window.dataLayer.push({ecommerce: null}); // Clear the previous ecommerce object.
        window.dataLayer.push({
            'event': 'add_to_cart',
            'event_category': 'browse_catalog',
            'ecommerce': {
                'currency': window.vbq.gtmPageData.sessionCurrency || '',
                'items': products
            }
        });
    }
};

/**
 * ECM24-8 - Datalayer update (GA4 GTM)
 * Pushes a removeFromCart event to GTM dataLayer
 */
var removeFromCart = function ($elem) {
    window.dataLayer.push({ecommerce: null}); // Clear the previous ecommerce object.
    window.dataLayer.push({
        'event': 'remove_from_cart',
        'event_category': 'funnel',
        'ecommerce': {
            'currency': window.vbq.gtmPageData.sessionCurrency || '',
            'items': [
                {
                    'item_name': $elem.attr('data-productName'),
                    'item_id': $elem.attr('data-productId'),
                    'item_ean': $elem.attr('data-productEan'),
                    'price': $elem.attr('data-productPrice'),
                    'currency': $elem.attr('data-productCurrency'),
                    'discount': $elem.attr('data-productDiscount'),
                    'item_brand': $elem.attr('data-productBrand'),
                    'item_category': $elem.attr('data-productCategory'),
                    'item_category2': $elem.attr('data-productCategory2'),
                    'item_category3': $elem.attr('data-productCategory3'),
                    'item_variant': $elem.attr('data-productVariant'),
                    'coupon': $elem.attr('data-productCoupon'),
                    'index': 1,
                    'quantity': parseInt($elem.attr('data-productQuantity'), 10),
                    'item_family': $elem.attr('data-productFamily'),
                    'item_print': $elem.attr('data-productPrint'),
                    'item_color': $elem.attr('data-productColor'),
                    'item_size': $elem.attr('data-productSize')
                }
            ]
        }
    });
};

/**
 * Updates product impressions in GTM dataLayer
 * Also reinitializes event binding
 */
var updateProductImpressions = function () {
    addProductImpressions();
    initProductClick();
};

/**
 * ECM24-8 - Datalayer update (GA4 GTM)
 * Adds product impressions into GTM dataLayer
 */
var addProductImpressions = function () {
    if (window.dataLayer
        && window.vbq
        && window.vbq.gtmPageData
        && window.vbq.gtmPageData.productImpressions
        && window.vbq.gtmPageData.productImpressions.length > 0
    ) {
        for (var i = 0; i < window.vbq.gtmPageData.productImpressions.length; i++) {
            window.vbq.gtmPageData.productImpressions[i].item_list_name = window.gtmPageType + '_' + window.vbq.gtmPageData.productImpressions[i].item_list_name;
        }

        window.dataLayer.push({ecommerce: null}); // Clear the previous ecommerce object.
        var productsChunks = splitArrayIntoChunks(window.vbq.gtmPageData.productImpressions);

        productsChunks.forEach(function (items) {
            window.dataLayer.push({
                'event': 'view_item_list',
                'event_category': 'browse_catalog',
                'ecommerce': {
                    'currency': window.vbq.gtmPageData.sessionCurrency || '',
                    'items': items
                }
            });
        });

        // empty the array, so only new impressions are added to GTM next time addProductImpressions is called
        window.vbq.gtmPageData.productImpressions = null;
    }
};

/**
 * Updates product details in GTM dataLayer
 * for now function calls another function,
 * but it's kept as a separate function for possible cases in future
 */
var updateProductDetail = function () {
    addProductDetail(true);
};

/**
 * Adds product detail into GTM dataLayer
 */
var addProductDetail = function (quickview) {
    if (window.dataLayer
        && window.vbq
        && window.vbq.gtmPageData
        && window.vbq.gtmPageData.productDetail
        && window.vbq.gtmPageData.productDetail.length > 0
    ) {
        var isQuickView = quickview || window.gtmIsQuickView;

        if (isQuickView || window.gtmPageType != 'product') {
            window.dataLayer.push({
                'event': 'productDetail',
                'ecommerce': {
                    'detail': {
                        'products': window.vbq.gtmPageData.productDetail
                    }
                }
            });

            // empty the array so data cannot be sent again
            window.vbq.gtmPageData.productDetail = null;
        }

        if (!isQuickView &&
            (window.vbq.gtmPageData.productDetail && window.vbq.gtmPageData.productDetail.length > 0)
        ) {
            var data = {
                'event': 'view_item',
                'event_category': 'browse_catalog',
                'ecommerce': {
                    'currency': window.vbq.gtmPageData.sessionCurrency || '',
                    'items': window.vbq.gtmPageData.productDetail
                }
            };

            window.dataLayer.push({ecommerce: null}); // Clear the previous ecommerce object.
            window.dataLayer.push(data);

            // empty the array so data cannot be sent again
            window.vbq.gtmPageData.productDetail = null;
        }
    }
};

/**
 * ECM24-8 - Datalayer update (GA4 GTM)
 * Adds product click event into GTM dataLayer
 */
var addProductClick = function ($elem, list, position) {
    if (window.dataLayer && $elem !== null && $elem.length > 0 && list !== null) {
        window.dataLayer.push({ecommerce: null}); // Clear the previous ecommerce object.
        window.dataLayer.push({
            'event': 'select_item',
            'event_category': 'browse_catalog',
            'ecommerce': {
                'item_list_name': list,
                'currency': window.vbq.gtmPageData.sessionCurrency || '',
                'items': [
                    {
                        'item_name': $elem.attr('data-productName'),
                        'item_id': $elem.attr('data-productId'),
                        'item_ean': $elem.attr('data-productEan'),
                        'price': $elem.attr('data-productPrice'),
                        'currency': $elem.attr('data-productCurrency'),
                        'discount': $elem.attr('data-productDiscount'),
                        'item_brand': $elem.attr('data-productBrand'),
                        'item_category': $elem.attr('data-productCategory'),
                        'item_category2': $elem.attr('data-productCategory2'),
                        'item_category3': $elem.attr('data-productCategory3'),
                        'item_variant': $elem.attr('data-productVariant'),
                        'coupon': $elem.attr('data-productCoupon'),
                        'index': $elem.attr('data-productPosition') || position || 1,
                        'quantity': 1,
                        'item_family': $elem.attr('data-productFamily'),
                        'item_print': $elem.attr('data-productPrint'),
                        'item_color': $elem.attr('data-productColor'),
                        'item_size': $elem.attr('data-productSize')
                    }
                ]
            }
        });
    }
};

/**
 * Adds product quickview event into GTM dataLayer
 */
var productQuickView = function ($elem) {
    if (window.gtmPageType != 'product' && window.dataLayer && $elem !== null && $elem.length > 0) {
        var separator = window.location.pathname.slice(-1) == '/' ? '' : '/';
        window.dataLayer.push({
            'page': {
                'pageTitle': window.document.title,
                'pagePath': window.location.pathname + separator + 'product-quickview/?virtual=1',
                'pageType': 'product-quickview'
            },
            'event':'pageview'
        });
    }
};

/**
 * Adds product list into GTM dataLayer (for store-locator-availability)
 */
var addProductList = function () {
    if (window.dataLayer
        && window.vbq
        && window.gtmPageType == 'store-locator-availability'
        && window.vbq.gtmPageData.productList
        && window.vbq.gtmPageData.productList.length > 0
    ) {
        window.dataLayer.push({
            'productsList': {
                'name': 'store-locator-availability',
                'totalPrice': window.vbq.gtmPageData.productListTotal || 0,
                'products': window.vbq.gtmPageData.productList
            },
            'event':'productsList'
        });
    }
};

/**
 * Adds promotion impressions into GTM dataLayer
 */
var addPromotionImpressions = function () {
    if (window.dataLayer
        && window.vbq
        && window.vbq.gtmPageData
        && window.vbq.gtmPageData.promotionImpressions
        && window.vbq.gtmPageData.promotionImpressions.length > 0
    ) {
        window.dataLayer.push({
            'event': 'promotionImpression',
            'ecommerce': {
                'promoView': {
                    'promotions': window.vbq.gtmPageData.promotionImpressions
                }
            }
        });
    }
};

/**
 * Adds promotion click events into GTM dataLayer
 */
var addPromotionClick = function ($elem) {
    if (window.dataLayer && $elem !== null && $elem.length > 0) {
        //get promotion position
        var promotionPosition = 0,
            promotionId = $elem.attr('data-promotionId');

        for (var i = 0; i < window.vbq.gtmPageData.promotionImpressions.length; i++) {
            if (window.vbq.gtmPageData.promotionImpressions[i].id == promotionId) {
                promotionPosition = window.vbq.gtmPageData.promotionImpressions[i].position;
                break;
            }
        }

        window.dataLayer.push({
            'event': 'promotionClick',
            'ecommerce': {
                'promoClick': {
                    'promotions': [
                        {
                            'name': $elem.attr('data-promotionName'),
                            'id': promotionId,
                            'creative': $elem.attr('data-promotionCreative'),
                            'position': promotionPosition
                        }
                    ]
                }
            }
        });
    }
};

/**
 * ECM24-8 - Datalayer update (GA4 GTM)
 * Splits an array into smaller arrays of a specified size
 * @param {Array} array - the array to be split
 * @param {number} chunkSize - the size of each resulting array
 * @returns {Array} an array of smaller arrays
 */
function splitArrayIntoChunks(array, chunkSize) {
    var splitArrays = [],
        // set a default chunk size
        chunkSizeValue = chunkSize || 15;

    while (array.length > 0) {
        splitArrays.push(array.splice(0, chunkSizeValue));
    }

    return splitArrays;
}

/**
 * ECM24-8 - Datalayer update (GA4 GTM)
 * Adds products to the wishlist and sends the data to the dataLayer for Google Tag Manager
 * @param {jQuery} $elem - jQuery element representing the product(s) to be added to the wishlist
 * @returns {void}
 */
function addToWishlist($elem) {
    if (window.dataLayer && $elem !== null && $elem.length > 0) {
        var $elementTile = $elem.closest('.js-grid-tile'),
            position = $elementTile.length ? parseInt($elementTile.attr('data-gtm-position'), 10) : 1;

        window.dataLayer.push({ecommerce: null}); // Clear the previous ecommerce object.
        window.dataLayer.push({
            'event': 'add_to_wishlist',
            'event_category': 'wishlist',
            'ecommerce': {
                'currency': window.vbq.gtmPageData.sessionCurrency || '',
                'items': [
                    {
                        'item_name': $elem.attr('data-productName'),
                        'item_id': $elem.attr('data-productId'),
                        'item_ean': $elem.attr('data-productEan'),
                        'price': $elem.attr('data-productPrice'),
                        'currency': $elem.attr('data-productCurrency'),
                        'discount': $elem.attr('data-productDiscount'),
                        'item_brand': $elem.attr('data-productBrand'),
                        'item_category': $elem.attr('data-productCategory'),
                        'item_category2': $elem.attr('data-productCategory2'),
                        'item_category3': $elem.attr('data-productCategory3'),
                        'item_variant': $elem.attr('data-productVariant'),
                        'coupon': $elem.attr('data-productCoupon'),
                        'index': position.toFixed(),
                        'quantity': $elem.attr('data-productQuantity'),
                        'item_family': $elem.attr('data-productFamily'),
                        'item_print': $elem.attr('data-productPrint'),
                        'item_color': $elem.attr('data-productColor'),
                        'item_size': $elem.attr('data-productSize')
                    }
                ]
            }
        });
    }
}

/**
 * ECM24-8 - Datalayer update (GA4 GTM)
 * Pushes a login event to the dataLayer if available
 */
function pushLoginEvent() {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'login',
            event_category: 'account',
            method: 'form'
        });
    }
}

/**
 * ECM24-8 - Datalayer update (GA4 GTM)
 * Pushes a guest checkout event to the data layer
 */
function pushGuestCheckoutEvent() {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'guest_checkout',
            event_category: 'funnel'
        });
    }
}

/**
 * ECM24-8 - Datalayer update (GA4 GTM)
 * Pushes a sign-up event to the data layer
 * @param {string} method - (optional) the sign-up method, 'account' or 'account with sso', defaults to 'account'
 * @returns {void}
 */
function pushSignUpEvent(method) {
    if (window.dataLayer) {
        var signUpMethod = method || 'account';
        window.dataLayer.push({
            event: 'sign_up',
            event_category: 'account',
            method: signUpMethod
        });
    }
}

/**
 * ECM24-8 - Datalayer update (GA4 GTM)
 * Returns the standardized shipping method name based on the provided shipping method
 * @param {string} shippingMethod - the shipping method to be standardized
 * @returns {string} - the standardized shipping method name (standard, express, SFS, UFD, CC)
 */
function getShippingMethodName(shippingMethod) {
    var shippingMethodName = (shippingMethod || '').toLowerCase();

    if (shippingMethodName.indexOf('shipfromstore') !== -1) {
        return 'SFS';
    }

    if (shippingMethodName.indexOf('ultrafastdelivery') !== -1) {
        return 'UFD';
    }

    if (shippingMethodName.indexOf('clickandcollect') !== -1) {
        return 'CC';
    }

    if (shippingMethodName.indexOf('standard') !== -1
        || shippingMethodName.indexOf('groundshipping') !== -1
    ) {
        return 'standard';
    }

    if (shippingMethodName.indexOf('express') !== -1
        || shippingMethodName.indexOf('international') !== -1
        || shippingMethodName.indexOf('dayair') !== -1
        || shippingMethodName.indexOf('hawaiiandalaska') !== -1
        || shippingMethodName.indexOf('specialfederalstates') !== -1
    ) {
        return 'express';
    }

    return shippingMethodName;
}

/**
 * ECM24-8 - Datalayer update (GA4 GTM)
 * Pushes checkout information event to the data layer for tracking purposes
 * @param {number} step - the step of the checkout process (1 for shipping info, 2 for payment info)
 * @param {Object} data - additional data related to the checkout step
 * @param {string} data.shippingMethod - the selected shipping method
 * @param {string} data.paymentType - the selected payment type
 * @returns {void}
 */
function pushCheckoutInfoEvent(step, data) {
    if (window.dataLayer && step && window.vbq.gtmPageData.basketData) {
        var ecommerceData = {
                currency: window.vbq.gtmPageData.sessionCurrency || '',
                // use cart total amount including shipping fee
                // on shipping step the shipping fee is not yet included, so cartTotalPrice is 0, in this case use total merchandise price
                value: window.vbq.gtmPageData.basketData.cartTotalPrice || window.vbq.gtmPageData.basketData.cartTotalAmountPrice,
                coupon: window.vbq.gtmPageData.basketData.coupon,
                items: window.vbq.gtmPageData.basketData.items
            },
            eventType = step === 1 ? 'add_shipping_info' : 'add_payment_info',
            shippingMethods = data && data.shippingMethods || '',
            paymentType = data && data.paymentType || '';

        if (step === 1) {
            ecommerceData.shipping_tier = shippingMethods.map(getShippingMethodName).join('|');
        }
        else if (step === 2) {
            ecommerceData.payment_type = paymentType;
        }

        window.dataLayer.push({ecommerce: null}); // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: eventType,
            event_category: 'funnel',
            ecommerce: ecommerceData
        });
    }
}

/* exported methods */
exports.init = init;
exports.initProductClick = initProductClick;
exports.addToCart = addToCart;
exports.addToWishlist = addToWishlist;
exports.removeFromCart = removeFromCart;
exports.addProductImpressions = addProductImpressions;
exports.updateProductImpressions = updateProductImpressions;
exports.addProductDetail = addProductDetail;
exports.updateProductDetail = updateProductDetail;
exports.addProductClick = addProductClick;
exports.productQuickView = productQuickView;
exports.addProductList = addProductList;
exports.addPromotionImpressions = addPromotionImpressions;
exports.addPromotionClick = addPromotionClick;
exports.pushLoginEvent = pushLoginEvent;
exports.pushGuestCheckoutEvent = pushGuestCheckoutEvent;
exports.pushSignUpEvent = pushSignUpEvent;
exports.pushCheckoutInfoEvent = pushCheckoutInfoEvent;
