'use strict';

var cache = {};

/**
 * Initializes the cache for various DOM elements
 * @returns {void}
 */
function initCache() {
    cache.$document = $(document);
    cache.$createAccountForm = cache.$document.find('#create-account');
    cache.$createAccountButton = cache.$createAccountForm.find('button[type="submit"]');
    cache.$globalErrorWrapper = cache.$createAccountForm.find('.js-global-error');
}

/**
 * Handles the form submission action for creating a new account
 * @param {Event} event - the form submission event
 * @returns {void}
 */
function onSubmitAction(event) {
    event.preventDefault();

    var $form = $(this);

    // if the form is not valid, do not submit
    if (!$form.valid()) {
        return;
    }

    // hide global error message and remove text
    cache.$globalErrorWrapper.addClass('hidden').text('');

    var url = $form.attr('action'),
        formData = $form.serializeArray();

    // add the submit button name/value as data
    formData.push({
        name: $form.find('button[type="submit"]').attr('name'),
        value: $form.find('button[type="submit"]').attr('value')
    });

    // get serialized form data
    var data = $.param(formData);

    // disable button
    cache.$createAccountButton.prop('disabled', true);
    
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: data,
        success: function (data) {
            if (data.success) {
                window.location.href = data.redirectUrl;
            } else {
                cache.$createAccountButton.prop('disabled', false);
                if (data.errorMessages) {
                    cache.$createAccountForm.validate().showErrors(data.errorMessages);
                }
                else {
                    cache.$createAccountForm.validate();
                    cache.$globalErrorWrapper.removeClass('hidden').text(data.globalError || Resources.CREATE_ACCOUNT_ERROR);
                }
            }
        },
        error: function () {
            cache.$createAccountButton.prop('disabled', false);
            cache.$globalErrorWrapper.removeClass('hidden').text(Resources.CREATE_ACCOUNT_ERROR);
        }
    });
}

/**
 * Initializes the events for the create account form
 */
function initEvents() {
    cache.$createAccountForm.on('submit', onSubmitAction);
}

module.exports = {
    init: function () {
        initCache();
        initEvents();
    }
};
