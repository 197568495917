'use strict';

var customerService = require('./customerservice');

var content = {
    init: function () {
        customerService.init();
    }
};

module.exports = content;