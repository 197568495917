'use strict';

/**
 * Add custom validation for password rules
 */
var addCustomPasswordValidation = function () {
    // pattern definition
    var pattern = {
        minLength: '^.{8,255}$',
        letterUppercase: '[A-Z]',
        letterLowercase: '[a-z]',
        figure: '[0-9]',
        specialchar: '[#\!&\$%\*\+\/\?\=\^`~\}\|\{]'
    };

    // requirements: elements and their patterns
    var requirements = [
        {
            element: $('.js-password_length'),
            pattern: pattern.minLength
        },
        {
            element: $('.js-password_letter_uppercase'),
            pattern: pattern.letterUppercase
        },
        {
            element: $('.js-password_letter_lowercase'),
            pattern: pattern.letterLowercase
        },
        {
            element: $('.js-password_figure'),
            pattern: pattern.figure
        },
        {
            element: $('.js-password_specialchar'),
            pattern: pattern.specialchar
        }
    ];

    /**
     * Mark all rules as invalid
     */
    var invalidateRules = function () {
        requirements.forEach(function (rule) {
            rule.element.removeClass('success').addClass('error');
        });
    };

    $.validator.addMethod('customPasswordValidation', function (value) {
        if (!value) {
            // invalidate rules
            invalidateRules();

            return;
        }

        // default status
        var status = true;

        requirements.forEach(function (rule) {
            var regexp = new RegExp(rule.pattern),
                success = regexp.test(value);

            // update validation status
            status &= success;

            if (success) {
                rule.element.removeClass('error').addClass('success');
            }
            else {
                rule.element.removeClass('success').addClass('error');
            }
        });

        return status;
    }, '');

    $('.customPasswordValidation').on('keyup', function (e) {
        e.preventDefault();

        var $this = $(this);

        // if the field has no value then invalidate shown rules
        if (!$this.val()) {
            invalidateRules();
        }
    });
};

module.exports = {
    initCustomValidation: addCustomPasswordValidation
};
