'use strict';

var bonusProductsView = require('../bonus-products-view'),
    quickview = require('../quickview'),
    storesDialog = require('./checkout/storesDialog'),
    progress = require('../progress'),
    monogram = require('./product/monogram'),
    _debounce = require('../lib/lodash.debounce'),
    cartCache = {
        $stickyCartPanel: $('.js-sticky-cart-panel')
    };

/**
 * set sticky cart panel on mobile
 */
function setStickyCartPanel() {
    if (window.pageContext.ns == 'cart' && cartCache.$stickyCartPanel.length > 0) {
        var isMobile = window.vbqUtils.breakpoint.is('mobileAndTablet');

        if (window.vbqUtils.cache.$window.scrollTop() > 0 && isMobile) {
            window.vbqUtils.cache.$footer.css('padding-bottom', cartCache.$stickyCartPanel.outerHeight() + 'px');
            cartCache.$stickyCartPanel.addClass('mobile-sticky-cart-panel');
        }
        else {
            removeStickyCartPanel();
        }
    }
}

/**
 * remove sticky cart panel
 */
function removeStickyCartPanel() {
    if (window.pageContext.ns == 'cart' && cartCache.$stickyCartPanel.length > 0) {
        window.vbqUtils.cache.$footer.css('padding-bottom', '0');
        cartCache.$stickyCartPanel.removeClass('mobile-sticky-cart-panel');
    }
}

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
    // Cart form submit
    $('.js-cart-items-form').on('submit', function () {
        window.vbqUtils.progressLoader(true, $('.js-cart-loader'));
    });

    // Open the PDP in quickview mode to modify the product's color/size
    $('#cart-table').on('click keydown', '.item-edit-details .cart-edit-product', function (e) {
        // mouse click or enter/space key
        if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
            // prevent the default action to stop scrolling when space is pressed
            e.preventDefault();

            quickview.show({
                url: $(this).data('href'),
                source: 'cart'
            });
        }
    })
    .on('click', '.bonus-item-actions a, .item-edit-details .bonusproducts', function (e) {
        e.preventDefault();
        bonusProductsView.show(this.href);
    });

    var cartScrollListener = _debounce(setStickyCartPanel, 50);
    window.vbqUtils.breakpoint.set([
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('mobileAndTablet'),
            enter: function () {
                setStickyCartPanel();
                window.vbqUtils.cache.$window.scroll(cartScrollListener);
            }
        },
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('fromDesktop'),
            enter: function () {
                removeStickyCartPanel();
                window.vbqUtils.cache.$window.off('scroll', cartScrollListener);
            }
        }
    ]);

    // ECM21-326 - Init accordion on Promo on EU only
    var $couponAccordion = $('.js-couponAccordion');

    if ($couponAccordion.hasClass('expanded')) {
        $couponAccordion.customAccordion().customAccordion('active', 0);
    }
    else {
        $couponAccordion.customAccordion();
    }

    var $couponBtn = $('.js-coupon-button'),
        $couponLoader = $('.js-coupon-loader');

    $couponBtn.on('click', function () {
        $couponBtn.addClass('disabled');
        window.vbqUtils.progressLoader(true, $couponLoader);
    });

    // EADA23-5 ADA Focus on the input field if error message is displayed
    var $couponCodeInput = $('form input[name$="_couponCode"]'),
        $couponError = $('#js-CouponError');

    if ($couponError.length) {
        $couponCodeInput.first().focus();
    }

    // override enter key for coupon code entry
    $couponCodeInput.on('keydown', function (e) {
        if (e.which === 13 && $(this).val().length === 0) { return false; }
    });

    //to prevent multiple submissions of the form when removing a product from the cart
    var removeItemEvent = false;
    $('button[name$="deleteProduct"]').on('click', function (e) {
        if (removeItemEvent) {
            e.preventDefault();
        } else {
            removeItemEvent = true;
        }
    });

    //ERF-47 Cart page - paypal button has submit behavior, but for styling reasons it cannot be a <button>
    var $checkoutFormSubmit = $('.js-cartpage-checkout-button'),
        $checkoutFormPayPal = $('.js-pay-pal-button'),
        $checkoutFormAdyenKlarna = $('.js-adyen-klarna-button');


    $checkoutFormAdyenKlarna.on('click', function (e) {
        e.preventDefault();
        $checkoutFormSubmit.click();
    });

    $checkoutFormPayPal.on('click', function (e) {
        e.preventDefault();
        $checkoutFormSubmit.click();
    });

    $checkoutFormSubmit.on('click', function () {
        $checkoutFormSubmit.addClass('disabled');
        $checkoutFormPayPal.addClass('disabled');
        progress.show($checkoutFormSubmit);
    });

    //ERF-47 Cart page - modify product quickview on cart page close button
    window.vbqUtils.cache.$document.on('click', '.js-cartpage-modify-close', function () {
        $('#QuickViewDialog').dialog('close');
    });

    window.vbqUtils.initializeJQueryUiPlugins();

    $('.js-home-delivery').on('click', '.js-delivery-option', function () {
        var $this = $(this),
            pid = $this.attr('data-pid'),
            uuid = $this.attr('data-uuid');

        window.vbqUtils.progressLoader(true, $this.parents('.js-item-delivery-wrapper').find('.js-delivery-progress-loader'));

        // update the cart line item and reload the page
        $.ajax({
            url: Urls.addProduct,
            type: 'POST',
            data: {pid: pid, uuid: uuid, storeid: null, storepickup: false, format: 'ajax'}
        }).always(function (data) {
            window.location.reload(true);
        });
    });

    $('.js-cartUltraFastDelivery').on('click', function (e) {
        e.preventDefault();

        var $this = $(this),
            pid = $this.attr('data-pid'),
            uuid = $this.attr('data-uuid'),
            storeId = $this.attr('data-storeid');

        progress.show($this.closest('.form-row'));

        // update the cart line item and reload the page
        $.ajax({
            url: Urls.addProduct,
            type: 'POST',
            data: {pid: pid, uuid: uuid, storeid: storeId, storepickup: true, format: 'ajax', cc: false, ufd: true}
        }).always(function (data) {
            window.location.reload(true);
        });
    });

    monogram.handleMonogramming($('#cart-items-form'), false);
}

function initializeDOM () {
    // put initialize DOM here
}

exports.init = function () {
    // Initialize the jQuery UI elements
    window.vbqUtils.initializeJQueryUiPlugins();

    // ERB-71 Gift box and gift message
    window.vbqUtils.giftBoxMessages.init();
    initializeDOM();
    initializeEvents();
    storesDialog.CartSummaryInit();
};
