'use strict';

/**
 * Init phone codes
 */
function initPhoneCodes () {
    var $registrationForm = $('#RegistrationForm'),
        $country,
        countryCode,
        $phone;

    // if the form was not found don't run rest of the code
    if (!$registrationForm.length) {
        return;
    }

    $country = $registrationForm.find('.country_field');
    $phone = $registrationForm.find('.phone_field');

    // if country or phone fields were not found don't initialize the phone codes
    if (!$country.length || !$phone.length) {
        return;
    }

    if ($country.val() === ''
        && window.vbq
        && window.vbq.localizationData
        && window.vbq.localizationData.countryCode
        && window.vbq.localizationData.countryCode != 'not_supported'
    ) {
        countryCode = window.vbq.localizationData.countryCode;
        $country.val(countryCode);
    }

    window.vbqUtils.phoneCodes.init($country, $phone);
}

module.exports = {
    initPhoneCodes: initPhoneCodes
};
