'use strict';

var util = require('./util');

/**
 * ERV-485 - Checkout - Billing and Payment - Guest
 * @private
 * @function
 * @description Applies improved validation logic in forms
 * @param {Element} $form The form to apply improvements
 */
function formValidation($form) {
    if (!$form) {
        return;
    }

    var $continueValidateButton = $form.find('button').filter('[type="submit"]'),
        validator = $form.data('validator') || $form.validate(),
        $requiredInputs = $form.find('.required').find(':input');

    if ($continueValidateButton.length > 0) {
        $continueValidateButton.parent().on('mouseover', function () {
            validator.form();
            if (!$form.valid()) {
                // scroll up to first error
                var $firstErrorElement = $form.find('.gl_has-error');
                if ($firstErrorElement.length > 0 && !isInViewport($firstErrorElement[0])) {
                    window.vbqUtils.cache.$window.scrollTop($firstErrorElement[0].offsetTop);
                }
            }
            // Precaution to update the state of the button
            else if ($continueValidateButton.is(':disabled')) {
                $continueValidateButton.removeAttr('disabled');
            }
        });
    }

    $requiredInputs.each(function (index, item) {
        $(item).on('focus', function () {
            if (index > 0) {
                for (var i = 0; i < index; i++) {
                    var result = validator.element($requiredInputs[i]);

                    // billing address form can be hidden, which break the global form validation on submit
                    if (!result && $($requiredInputs[i]).closest('#js-billingAddressForm').length) {
                        $('#js-billingAddressFormEdit').trigger('click');
                    }
                }
            }
        });
    });
}

/**
 * @private
 * @function
 * @description Checks if element is visible in viewport
 * @param {Element} element DOM Element
 */
function isInViewport(element) {
    if (element) {
        var rect = element.getBoundingClientRect(),
            html = document.documentElement;
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || html.clientHeight) &&
            rect.right <= (window.innerWidth || html.clientWidth)
        );
    } else {
        return false;
    }
}

/* exported methods */
exports.formValidation = formValidation;
