'use strict';

var util = require('../util'),
    _gmap, _markers, _infowindow, $storesWrappers, $allStores, $mapWrapper, $gMapPlacesInput,
    $mapFilter, $storeAvailabilityWrapper, userPosition;

window._gMapInitialized = false;

var _formatStoreData = function (store) {
    var code = [];

    code.push(store.name);
    code.push(store.translatedAddress);
    code.push('<a href="tel:' + store.phone + '">' + store.phone + '</a>');
    if (store.canBeContactedWithWhatsapp && store.WhatsappLink !== '') {
        code.push('<a href="' + store.WhatsappLink + '">WhatsApp</a>');
    }

    if (store.isTemporaryClose) {
        code.push('<br/><b>' + window.Resources.STORE_TEMPORARY_CLOSE + '</b>');
    }
    else if (store.isSeasonalClose) {
        code.push('<br/><b>' + window.Resources.STORE_SEASONAL_CLOSE + '</b>');
    }
    else {
        code.push(store.translatedStoreHoursDatetime);
    }
    // ECM-125 - Store Locator Front end - Added the store detail URL
    if (store.URL) {
        code.push('<a class="store-locator-discover-store" href="' + store.URL + '">' + window.Resources.DISCOVER_STORE + '</a>');
    }

    return code.join('<br/>');
};

var setUserPosition = function () {
    var userLat = parseFloat(window.vbq.userLat),
        userLong = parseFloat(window.vbq.userLong),
        defaultBounds = new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(userLat - 3, userLong - 3),
            new window.google.maps.LatLng(userLat + 3, userLong + 3)
        );

    userPosition = defaultBounds.getCenter(); // needed for the store list sort, center is modified latter
};

var setDefaultPosition = function () {
    var userLat = parseFloat(window.vbq.userLat),
        userLong = parseFloat(window.vbq.userLong),
        defaultBounds = new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(userLat - 3, userLong - 3),
            new window.google.maps.LatLng(userLat + 3, userLong + 3)
        ),
        center = defaultBounds.getCenter(),
        distance;

    userPosition = defaultBounds.getCenter(); // needed for the store list sort, center is modified latter

    //extend bounds for any store in a 500km distance from bounds center
    for (var storeID in _markers) {
        if (_markers.hasOwnProperty(storeID)) {
            distance = window.google.maps.geometry.spherical.computeDistanceBetween(center, _markers[storeID].position);
            if (distance < 500000) { //500km
                defaultBounds.extend(_markers[storeID].position);
            }
        }
    }

    _gmap.setCenter(center);
    _gmap.fitBounds(defaultBounds);
};

var gMapCallback = function () {
    var distance,
        store,
        mapOptions = {zoom: 8},
        icon,
        hash = window.location.hash;

    _gmap = new window.google.maps.Map($mapWrapper[0], mapOptions);

    // Create the search box and link it to the UI element.
    var input = $gMapPlacesInput[0],
        searchBox = new window.google.maps.places.SearchBox(input),
        devicePixelRatio = window.devicePixelRatio === undefined ? 1 : window.devicePixelRatio,
        requireImageSuffix = '';

    window.vbqUtils.searchBox = searchBox;

    if (devicePixelRatio > 1) {
        requireImageSuffix = '_2x';
    }

    // Image for the marker
    // scaledSize are the dimensions of the 1x sprite
    icon = {
        scaledSize: new window.google.maps.Size(30, 30),
        anchor: new window.google.maps.Point(18, 50)
    };

    _markers = {};
    var nbStores = Object.keys(window.vbq.stores).length;
    for (var storeID in window.vbq.stores) {
        if (window.vbq.stores.hasOwnProperty(storeID)) {
            store = window.vbq.stores[storeID];

            var iconName = store.storeType == 'VS' ? 'store' : (store.storeType == 'VO' ? 'outlet' : 'other-store');
            icon.url = 'https://' + (window.vbq.baseUrl || []).join('/') + '/images_active/mapIcons/map-' + iconName + requireImageSuffix + '.png';

            _markers[storeID] = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(store.latitude, store.longitude),
                map: _gmap,
                title: store.name,
                icon: icon
            });

            //add listener in scoped function, needed to avoid variable value mess
            (function (marker, store) {
                window.google.maps.event.addListener(marker, 'click', function () {
                    if (_infowindow) {
                        _infowindow.close();
                    }
                    _infowindow = new window.google.maps.InfoWindow({
                        map: _gmap,
                        position: marker.getPosition(),
                        content: _formatStoreData(store)
                    });

                    var pixelSizeAtZoom0 = 6, //minimum size of the icon
                        maxPixelSize = 30, //maximum size of the icon
                        zoom = _gmap.getZoom(),
                        relativePixelSize = Math.round(pixelSizeAtZoom0 * Math.pow(2, zoom)); // use 2 to the power of current zoom to calculate relative pixel size.  Base of exponent is 2 because relative size should double every time you zoom in

                    if (relativePixelSize > maxPixelSize) { //restrict the maximum size of the icon
                        relativePixelSize = maxPixelSize;
                    }

                    var iconName = store.storeType == 'VS' ? 'store' : (store.storeType == 'VO' ? 'outlet' : 'other-store');
                    marker.setIcon(new window.google.maps.MarkerImage(
                        'https://' + (window.vbq.baseUrl || []).join('/') + '/images_active/mapIcons/map-' + iconName + (window.vbqUtils.isSR ? '' : '-active') + requireImageSuffix + '.png',
                        null, //size
                        null, //origin
                        null, //anchor
                        new window.google.maps.Size(relativePixelSize, relativePixelSize) //changes the scale
                    ));

                    $allStores.removeClass('selected').removeAttr('aria-current')
                        .filter('[data-store="' + store.id +'"]').addClass('selected').attr('aria-current', 'true');

                    // ECM-125 - Store Locator Front end
                    scrollStoreIntoView();
                });
            })(_markers[storeID], store);

            //when the map zoom changes, resize the marker icon based on the zoom level so the marker covers the same geographic area
            (function (marker, store) {
                window.google.maps.event.addListener(_gmap, 'zoom_changed', function () {
                  var pixelSizeAtZoom0 = 6, //minimum size of the icon
                      maxPixelSize = 30, //maximum size of the icon
                      zoom = _gmap.getZoom(),
                      relativePixelSize = Math.round(pixelSizeAtZoom0 * Math.pow(2, zoom)); // use 2 to the power of current zoom to calculate relative pixel size.  Base of exponent is 2 because relative size should double every time you zoom in

                  if (relativePixelSize > maxPixelSize) { //restrict the maximum size of the icon
                      relativePixelSize = maxPixelSize;
                  }

                  //change the size of the icon
                  marker.setIcon(new window.google.maps.MarkerImage(
                      marker.getIcon().url,
                      null, //size
                      null, //origin
                      null, //anchor
                      new window.google.maps.Size(relativePixelSize, relativePixelSize) //changes the scale
                  ));
              });
            })(_markers[storeID], store);
        }
    }

    // PDP and checkout pick up in-store map
    var $wrapper = $('#wrapper');
    if ($wrapper.hasClass('pt_cart') || $wrapper.hasClass('pt_product-details')) {
        var bounds = new window.google.maps.LatLngBounds();

        //extend bounds for any store eligible for pick up in-store
        for (storeID in _markers) {
            if (_markers.hasOwnProperty(storeID)) {
                bounds.extend(_markers[storeID].position);
            }
        }

        _gmap.fitBounds(bounds);
    }
    //from footer form
    else if (hash !== null && hash !== '') {
        //use geolocation API to get searched position as latlong
        hash = hash.substr(1);
        $gMapPlacesInput.val(hash);

        var geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({address: hash}, function (results, status) {
            if (status == window.google.maps.GeocoderStatus.OK) {
                var bounds, center, distance;

                if (results[0].geometry.bounds) {
                    bounds = results[0].geometry.bounds;
                }
                else {
                    bounds = new window.google.maps.LatLngBounds();
                    bounds.extend(results[0].geometry.location);
                }

                center = bounds.getCenter();

                //extend bounds for any store in a 25km distance from bounds center
                for (var storeID in _markers) {
                    if (_markers.hasOwnProperty(storeID)) {
                        distance = window.google.maps.geometry.spherical.computeDistanceBetween(center, _markers[storeID].position);
                        if (distance < 25000) { //25km
                            bounds.extend(_markers[storeID].position);
                        }
                    }
                }

                _gmap.fitBounds(bounds);
            }
            else {
                setDefaultPosition();
            }
        });
    }
    //default use: storelocator page, map in checkout modal: shipping & multishipping flow
    else {
        setDefaultPosition();
    }

    searchBox.addListener('places_changed', function () {
        var places = searchBox.getPlaces();

        if (places === undefined || places.length === 0) {
            return;
        }

        var bounds = new window.google.maps.LatLngBounds(),
            center;
        for (var i = 0; i < places.length; i++) {
            bounds.extend(places[i].geometry.location);

            // make sure the search results are fully visible in the updated map
            if (places[i].geometry.viewport) {
                bounds.extend(places[i].geometry.viewport.getNorthEast());
                bounds.extend(places[i].geometry.viewport.getSouthWest());
            }
        }
        center = bounds.getCenter();

        //ERB-51 - SEO Tracking - Google Tag Manager - start counter
        var markersCounter = 0,
            searchTerm = input.value;

        //extend bounds for any store in a 25km distance from bounds center
        for (var storeID in _markers) {
            if (_markers.hasOwnProperty(storeID)) {
                distance = window.google.maps.geometry.spherical.computeDistanceBetween(center, _markers[storeID].position);
                if (distance < 25000) { //25km
                    bounds.extend(_markers[storeID].position);
                    //ERB-51 - SEO Tracking - Google Tag Manager - counter increment
                    markersCounter++;
                }
            }
        }

        if (markersCounter === 0) {
            var closestStore = null;
            for (storeID in _markers) {
                if (_markers.hasOwnProperty(storeID)) {
                    distance = window.google.maps.geometry.spherical.computeDistanceBetween(center, _markers[storeID].position);
                    if (closestStore === null) {
                        closestStore = {distance: distance, position: _markers[storeID].position};
                    }
                    else if (distance < closestStore.distance) {
                        closestStore = {distance: distance, position: _markers[storeID].position};
                    }
                }
            }

            if (closestStore !== null && closestStore.position) {
                bounds.extend(closestStore.position);
                markersCounter++;
            }
        }

        _gmap.setCenter(center);
        _gmap.fitBounds(bounds);
        _gmap.setZoom(_gmap.getZoom() + 1);
    });

    $('.js-google-map-search-wrapper .gl_input-submit').on('click', function () {
        var noop = function () {};

        window.google.maps.event.trigger(input, 'focus', {});
        // need to force the selection of the first places list result
        window.google.maps.event.trigger(input, 'keydown', {
            keyCode: 40, // arrow down
            stopPropagation: noop, // because these get called
            preventDefault: noop,
        });
        window.google.maps.event.trigger(input, 'keydown', {
            keyCode: 13
        });
        window.google.maps.event.trigger(input, 'places_changed');
    });

    var ignoreBoundsChange = false,
        mapLoad = true;
    _gmap.addListener('bounds_changed', function () {
        if (ignoreBoundsChange) {
            ignoreBoundsChange = false;
            return;
        }

        var currentBounds = _gmap.getBounds();

        if (currentBounds) { // getBounds() can return null if the map is not fully loaded, another "bounds_changed" will happen when it does
            var center = currentBounds.getCenter(),
                atLeastOne = false;

            // Bias the SearchBox results towards current map's viewport.
            searchBox.setBounds(currentBounds);

            //update stores list
            $allStores.addClass('hidden');
            for (var storeID in _markers) {
                if (currentBounds.contains(_markers[storeID].getPosition())) {
                    atLeastOne = true;

                    $allStores.filter('[data-store="' + storeID + '"]').removeClass('hidden');
                }
            }

            if (userPosition === undefined) {
                setUserPosition();
            }

            //sort stores by distance & insert in mobile / desktop lists
            $storesWrappers.each(function () {
                var $storesWrapper = $(this),
                    $stores = $storesWrapper.find('.js-store');

                $stores.sort(function (a, b) {
                    var aStoreID = $(a).attr('data-store'),
                        bStoreID = $(b).attr('data-store'),
                        aDistance = parseFloat(window.google.maps.geometry.spherical.computeDistanceBetween(userPosition, _markers[aStoreID].position)),
                        bDistance = parseFloat(window.google.maps.geometry.spherical.computeDistanceBetween(userPosition, _markers[bStoreID].position));

                    return aDistance - bDistance;
                }).appendTo($storesWrapper);
            });

            if (!atLeastOne) {
                $allStores.removeClass('hidden');
            }

            // ECM-125 - Store Locator Front end - The scroll needs to be updated when the list changes
            scrollStoreIntoView();
        }

        // avoid a map too much zoomed when only 1 store is displayed (omnichannel uses)
        if (mapLoad) {
            mapLoad = false;

            if (nbStores === 1) {
                ignoreBoundsChange = true;
                _gmap.setZoom(12); // will trigger 'bounds_changed' so it needs to be done only once
            }
        }
    });

    // force the value back on the autocomplete attribute, GMap force it to "off"
    window.setTimeout(function () {
        $gMapPlacesInput.attr('autocomplete', 'postal-code');
    }, 500);

    window._gMapInitialized = true;
    window.vbqUtils._gMapLoaded = true;
};

var storeAvailabilityInit = function () {
    var $productVariationList = $('.product-variation-list'),
        $productAttributesList = $('.product-attributes-list');

    window.vbqUtils.breakpoint.set([
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('mobileAndTablet'),
            enter: function () {
                $storeAvailabilityWrapper.off('click');
                $productVariationList.show();
                $('.variation-select-title').removeClass('active');

                $productAttributesList.customAccordion();
            }
        },
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('fromDesktop'),
            enter: function () {
                $productVariationList.hide();
                $storeAvailabilityWrapper.on('click', '.variation-select-title', function () {
                    var $this = $(this);

                    $this.parent().find('.product-variation-list').toggle();
                    $this.toggleClass('active');
                });

                if ($productAttributesList.hasClass('accordion-initialized')) {
                    $productAttributesList.customAccordion('destroy');
                }
            }
        }
    ]);
};

// ECM-125 - Store Locator Front end
var scrollStoreIntoView = function () {
    var $selectedStore = $storesWrappers.filter(':visible').find('.selected');
    if ($selectedStore.length) {
        var $storelocatorPageList = $('.store-locator-stores-content').filter(':visible');
        if ($storelocatorPageList.length) {
            $storelocatorPageList.scrollTop($selectedStore.position().top);
        }
        else {
            $storesWrappers.filter(':visible').scrollTop($selectedStore.position().top);
        }
    }
};

var loadGoogleMapScript = function () {
    if (!window.vbqUtils._gMapLoaded) {
        var lang = $('html').attr('lang'),
            script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAvbk_I8xH7kWnmw8JGtfDz0GVFcDqGRiw&libraries=places,geometry&language=' + lang + '&callback=gMapCallback';
        document.body.appendChild(script);
    }
    else {
        gMapCallback();
    }
};

exports.init = function () {
    if (window.vbq && window.vbq.stores && !window._gMapInitialized) {
        //needed for the callback after script load
        $storesWrappers = $('.js-storesWrapper');
        // all stores are in two lists in mobile & desktop
        $allStores = $storesWrappers.find('.js-store');
        $mapWrapper = $('#js-gMapWrapper');
        $gMapPlacesInput = $('#js-gMapPlacesInput');
        $mapFilter = $('#js-mapFilter');

        if ($storesWrappers.length > 0 && $mapWrapper.length > 0) {
            window.gMapCallback = gMapCallback;
            loadGoogleMapScript();
        }

        // EADA-20 Added floating label
        window.vbqUtils.initFloatingLabels($mapFilter);
    }

    $storeAvailabilityWrapper = $('.store-availability-wrapper');
    if ($storeAvailabilityWrapper.length) {
        storeAvailabilityInit();
    }
};
