'use strict';

var productTile = require('../product-tile'),
    accessibility = require('../accessibility'),
    util = require('../util'),
    wishlist = require('./wishlist'),
    _debounce = require('../lib/lodash.debounce'),
    searchCache = {
        $stickyAreaWrapper: $('.js-sticky-area-wrapper'),
        $stickyResultsItems: $('.js-sticky-result-items'),
        $refinementsWrapper: $('.js-refinements-wrapper'),
        $mobileFilterWrapper: $('.js-mobile-filter-wrapper'),
        mobileFilterWrapperTop: -1,
        $leftStickyStopper: $('.js-left-sticky-stopper'),
        $stickySectionVertical: $('.js-sticky-section-vertical'),
        $refinementsValues: $('.js-refinement-values'),
        $mobileFilterOpen: $('.js-filter-button'),
        $mobileTopFilterClose: $('.js-top-filter-close'),
        $mobileFilterClose: $('.js-filter-close'),
        $mobileFilterBack: $('.js-refinement-back'),
        $mobileFilterSlide: $('.js-refinement-mobile-slide'),
        $desktopFilterToggle: $('.js-refinement-toggle'),
        $desktopFilterToggleChevron: $('.js-refinement-chevron'),
        $desktopSortSelect: $('.js-sortBySelect'),
        $refinementsAttrOptions: $('.js-refinement-value'),
        $mobileSortOptions: $('.js-sort-value'),
        $topLinks: $('.js-top-links'),
        $selectedExerptList: $('.js-selectedExerptList'),
        $header: $('.js-header-sticky-panel'),
        $filterPanel: $('#js-filteringPanel')
    },
    updateLeftFilterState = _debounce(function () {
        if (searchCache.$stickyAreaWrapper.length) {
            var windowTop = parseInt(window.vbqUtils.cache.$window.scrollTop(), 10),
                stickOffset = parseInt(window.vbqUtils.cache.$stickyHeader.height(), 10), // sticky filter offset: sticky header
                stickyTop = parseInt(searchCache.$stickyAreaWrapper.offset().top, 10),
                stickyTopPadding = parseInt(searchCache.$stickyAreaWrapper.css('padding-top'), 10),
                startPoint = stickyTop + stickyTopPadding,
                endPoint = startPoint + parseInt(searchCache.$stickyAreaWrapper.height(), 10),
                filterHeight = parseInt(searchCache.$stickySectionVertical.height(), 10),
                filterBottomPos = windowTop + stickOffset + filterHeight,
                resultItemsHeight = parseInt(searchCache.$stickyResultsItems.height(), 10),
                filterBannerHeight = parseInt(searchCache.$filterPanel.outerHeight(), 10),
                filterBannerPos = windowTop + stickOffset + filterBannerHeight;

            // SR-24 - SFCC category list pages
            if (window.vbqUtils.isSR) {
                if (filterHeight < resultItemsHeight) {
                    if (windowTop + stickOffset + filterBannerHeight > startPoint) {
                        // absolute refinement panel mode if place to show filter <= filter height
                        if (filterBottomPos >= endPoint) {
                            setFilterAbsolute();
                        }
                        // sticky refinement panel mode
                        else {
                            setFilterSticky(stickOffset);
                        }
                    }
                    else {
                        resetFilterState();
                    }

                    // Filters banner
                    // SR-36 SFCC - sticky logic research for new filters display
                    if (windowTop + stickOffset > (startPoint - filterBannerHeight - stickyTopPadding)) {
                        if (filterBannerPos >= endPoint) {
                            setFilterBannerAbsolute(filterBannerHeight);
                        }
                        else {
                            setFilterBannerSticky(stickOffset, filterBannerHeight);
                        }
                    }
                    else {
                        resetFilterState();
                    }
                }
                else {
                    resetFilterState();
                }
            }
            // VBQ logic
            else {
                if (filterHeight < resultItemsHeight) {
                    if (windowTop + stickOffset > startPoint) {
                        // absolute refinement panel mode if place to show filter <= filter height
                        if (filterBottomPos >= endPoint) {
                            setFilterAbsolute();
                        }
                        // sticky refinement panel mode
                        else {
                            setFilterSticky(stickOffset);
                        }
                    }
                    else {
                        resetFilterState();
                    }
                }
                else {
                    resetFilterState();
                }
            }
        }
    }, 50),
    updateMobileFilterState = _debounce(function () {
        if (searchCache.$mobileFilterWrapper.length > 0) {
            var windowTop = window.vbqUtils.cache.$window.scrollTop(),
                headerHeight = parseInt(window.vbqUtils.cache.$stickyHeader.height(), 10),
                filterBannerHeight = parseInt(searchCache.$mobileFilterWrapper.outerHeight(), 10);

            // sticky header height without search panel
            if (window.vbqUtils.cache.$header.hasClass('search-open')) {
                headerHeight -= parseInt(window.vbqUtils.cache.$headerSearchMobile.outerHeight(), 10);
            }

            searchCache.mobileFilterWrapperTop = searchCache.mobileFilterWrapperTop == -1 ? parseInt(searchCache.$mobileFilterWrapper.offset().top, 10) : searchCache.mobileFilterWrapperTop;

            if (windowTop + headerHeight > searchCache.mobileFilterWrapperTop) {
                searchCache.$mobileFilterWrapper.addClass('sticky').css('top', headerHeight - 1);
                searchCache.$stickyAreaWrapper.css('margin-top', filterBannerHeight);
            }
            else {
                searchCache.$mobileFilterWrapper.removeClass('sticky').css('top', '');
                searchCache.$stickyAreaWrapper.css('margin-top', 0);
            }
        }
    }, 50),
    topLinkInit = _debounce(function () {
        if (searchCache.$topLinks.hasClass('js-custom-scrollbar')) {
            util.customScrollbarInit(searchCache.$topLinks);
        }
    }, 50);

/**
 * Set the banner sticky
 * @param {Int} offsetNumber - filter bar offset
 * @param {Int} mainMargin - top margin for sticky area
 */
function setFilterBannerSticky(offsetNumber, mainMargin) {
    if (!searchCache.$filterPanel.hasClass('sticky')) {
        searchCache.$filterPanel.addClass('sticky').removeClass('absolute').css('top', offsetNumber);
        searchCache.$stickyAreaWrapper.css('margin-top', mainMargin);

        if (!window.vbqUtils.isSR) {
            searchCache.$refinementsWrapper.css('padding-top', 40);
        }
    }
}

/**
 * Set the banner absolute
 * @param {Int} mainMargin - top margin for sticky area
 */
function setFilterBannerAbsolute(mainMargin) {
    if (!searchCache.$filterPanel.hasClass('absolute')) {
        searchCache.$filterPanel.addClass('absolute').removeClass('sticky');
        searchCache.$stickyAreaWrapper.css('margin-top', mainMargin);
        searchCache.$refinementsWrapper.css('padding-top', '');
    }
}

/**
 * Set filter sticky
 * @param {Number} offsetNumber - offset number
 */
function setFilterSticky(offsetNumber) {
    if (!searchCache.$refinementsWrapper.hasClass('sticky')) {
        searchCache.$refinementsWrapper
            .removeClass('absolute')
            .addClass('sticky')
            .css('top', offsetNumber + 10 + 'px')
            .css('bottom', '');
    }

    // adjust width for simple CLP & search pages
    if (!searchCache.$refinementsWrapper.hasClass('.pattern-refinements-wrapper') && parseInt(searchCache.$stickyResultsItems.css('margin-left'), 10) > 0) {
        searchCache.$refinementsWrapper.css('width', searchCache.$stickyResultsItems.css('margin-left'));
    }
}

/**
 * Set filter position as absolute
 * Filter will stop at the bottom of the screen and will move alongside the rest of the elements up, when the page is scrolled down
 */
function setFilterAbsolute() {
    if (!searchCache.$refinementsWrapper.hasClass('absolute')) {
        searchCache.$refinementsWrapper
            .removeClass('sticky')
            .css('top', '')
            .css('width', '')
            .addClass('absolute')
            .css('padding-top', '');

        // align absolute refinement panel with the last product tile line
        var $lastProductTile = searchCache.$stickyAreaWrapper.find('.js-grid-tile').last(),
            $lastProductTileTopParent = $lastProductTile.parents('.js-search-result-subset'),
            $lastProductTileParent = $lastProductTile.parent('.js-category-subset-row'),
            bottomAbsoluteRefinement = $($lastProductTileTopParent.length && $lastProductTileParent.length) ? $lastProductTileTopParent.outerHeight() - $lastProductTileParent.height() : null;

        if (bottomAbsoluteRefinement) {
            searchCache.$refinementsWrapper.css('bottom', + bottomAbsoluteRefinement + 'px');
        }
    }
}

/**
 * Remove any set classes, reset filter state
 */
function resetFilterState() {
    if (searchCache.$refinementsWrapper.hasClass('sticky') || searchCache.$refinementsWrapper.hasClass('absolute')) {
        searchCache.$refinementsWrapper
            .removeClass('sticky')
            .removeClass('absolute')
            .css('width', '')
            .css('top', '')
            .css('bottom', '')
            .css('padding-top', '');
    }

    if (searchCache.$filterPanel.hasClass('sticky') || searchCache.$filterPanel.hasClass('absolute')) {
        searchCache.$filterPanel
            .removeClass('sticky')
            .removeClass('absolute')
            .removeAttr('style');

        searchCache.$stickyAreaWrapper.css('margin-top', 0);
    }
}

//compareWidget = require('../compare-widget'),
/*
function infiniteScroll() {
    // getting the hidden div, which is the placeholder for the next page
    var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]'),
        // get url hidden in DOM
        gridUrl = loadingPlaceHolder.attr('data-grid-url');

    if (loadingPlaceHolder.length === 1 && util.elementInViewport(loadingPlaceHolder.get(0), 250)) {
        // switch state to 'loading'
        // - switches state, so the above selector is only matching once
        // - shows loading indicator
        loadingPlaceHolder.attr('data-loading-state', 'loading');
        loadingPlaceHolder.addClass('infinite-scroll-loading');


        // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
        var fillEndlessScrollChunk = function (html) {
            loadingPlaceHolder.removeClass('infinite-scroll-loading');
            loadingPlaceHolder.attr('data-loading-state', 'loaded');
            $('div.search-result-content').append(html);
        };

        // old condition for caching was `'sessionStorage' in window && sessionStorage["scroll-cache_" + gridUrl]`
        // it was removed to temporarily address RAP-2649

        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: gridUrl,
            success: function (response) {
                // put response into cache
                try {
                    sessionStorage['scroll-cache_' + gridUrl] = response;
                } catch (e) {
                    // nothing to catch in case of out of memory of session storage
                    // it will fall back to load via ajax
                }
                // update UI
                fillEndlessScrollChunk(response);
                productTile.init();

                //ERB-52 International Shipping
                window.vbqUtils.priceDisplay.refresh();

                //ERB-51 - SEO Tracking - Google Tag Manager - update product impressions
                window.vbqUtils.gtmUtils.updateProductImpressions();
            }
        });
    }
}
*/

/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing(url) {
    if (!url || url === window.location.href) {
        return;
    }

    var origUrl = url,
        $wrapper = $('#main');

    url = util.appendParamToURL(url, 'format', 'ajax');

    window.vbqUtils.progressLoader();

    $wrapper.load(url, function () {
        //compareWidget.init();
        productTile.init();

        history.pushState(undefined, '', origUrl);

        // Initialize the jQuery UI elements
        window.vbqUtils.initializeJQueryUiPlugins();
        updateTabs();

        // EADA-78 Catalog filters -  tab filter panel  accessibility
        accessibility.ajaxTabAccessibilityInit();

        window.vbqUtils.progressLoader(false);

        //ERB-52 International Shipping
        window.vbqUtils.priceDisplay.refresh();

        //ERB-51 - SEO Tracking - Google Tag Manager - update product impressions
        window.vbqUtils.gtmUtils.updateProductImpressions();
    });
}

/*ERF-75 Search Results integration
* positionRefinementDropdown positions search refinement dropdown
* to the left side of search refinements wrapper, it's needed since parent of
* dropdown has position relative to properly display other elements
*/
var $currentRefinement = false,
    $currentDropdown = false;

function positionRefinementDropdown() {
    if (!$currentDropdown || !window.vbqUtils.cache.$globalWrapper || !$currentRefinement) {
        return;
    }

    var thumbOffset = $currentRefinement ? $currentRefinement.offset() : null,
        $el = window.vbqUtils.cache.$main.find('.search-result-refinements'),
        elOffset = $el ? $el.offset() : null;

    if (thumbOffset && elOffset) {
        var shift = thumbOffset.left - elOffset.left,
            elWidth = $el.width() - 1;

        $currentDropdown.css({
            left: -shift,
            width: elWidth
        });
    }
}

/**
 * @private
 * @function resetRefinementUI
 * @description reset refinement UI state
 */
function resetRefinementUI() {
    $('.search-refinement').removeClass('expanded').find('.search-refinement-toggle').attr('aria-label', window.Resources.OPEN);
    $('.search-refinement-dropdown').removeClass('visible').attr('aria-expanded', 'false').attr('aria-hidden', 'true');
}

/**
* ERF-75 Search Results integration: legacy mobile filter on special CLP (e.g.: Pattern product CLP)
 * @private
 * @function updateTabs
 * @description Initializes tabs on sort by and filter
 */
function updateTabs() {
    var $searchRefinementsMobile = $('.search-result-refinements, .sort-by-mobile-interface'),
        $mobileLegacyTabs = $('.js-legacy-mobile-tabs'),
        $mobileLegacyFilter = $('.js-legacy-mobile-filters');

    window.vbqUtils.breakpoint.set([
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('mobileAndTablet'),
            enter: function () {
                if ($mobileLegacyTabs.length > 0) {
                    $mobileLegacyTabs.tabs({
                        collapsible: true,
                        active: false
                    });
                }

                $searchRefinementsMobile.addClass('visible');
                resetRefinementUI();
            }
        },
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('fromDesktop'),
            enter: function () {
                if ($mobileLegacyTabs.length > 0 && $mobileLegacyTabs.hasClass('ui-tabs')) {
                    $mobileLegacyTabs.tabs('destroy');
                }

                $mobileLegacyFilter.hide();
                resetRefinementUI();
            }
        }
    ]);
}

/**
 * Get all selected filter options including sorting option
 *
 * @returns {Object} filterOptions - object with all selected filter options including sorting option
 */
function getFilterOptions() {
    var filterOptions = {},
        dwFilterOptions = {},
        refinementIDs = [];

    // get all the filters
    searchCache.$refinementsAttrOptions.each(function () {
        var $this = $(this),
            refinementID = $this.attr('data-refinement-id');
        if (refinementIDs.indexOf(refinementID) == -1) {
            refinementIDs.push(refinementID);
        }
    });

    // get all selected filters
    searchCache.$refinementsAttrOptions.filter(':checked').each(function () {
        var $this = $(this),
            refinementID = $this.attr('data-refinement-id'),
            refinementValue = $this.attr('data-refinement-value');

        if (filterOptions[refinementID]) {
            filterOptions[refinementID] = filterOptions[refinementID] + '|' + refinementValue;
        }
        else {
            filterOptions[refinementID] = refinementValue;
        }
    });

    // check query string for any filter not present in the page filters
    // like prefn1=orliweb_stat20_mat_rgt&prefv1=061 on https://www.vilebrequin.com/eu/en/men-father-and-son-prints/?prefn1=orliweb_stat20_mat_rgt&prefv1=061
    var params = util.getQueryStringParams(window.location.search && window.location.search.substr(1)),
        matchKeys = [],
        prefCouples = {};

    Object.keys(params).forEach(function (key, index) {
        if (key.indexOf('prefn') != -1 && refinementIDs.indexOf(params[key]) == -1 && matchKeys.indexOf(key) == -1) {
            matchKeys.push(key.replace('n', 'v')); // prefv1
            prefCouples[key] = params[key]; // orliweb_stat20_mat_rgt
        }
        else if (key.indexOf('prefv') != -1 && matchKeys.indexOf(key) != -1) {
            var k = prefCouples[key.replace('v', 'n')]; // orliweb_stat20_mat_rgt
            filterOptions[k] = params[key]; // 061
        }
    });

    // update filter object with correct attribute name and value
    Object.keys(filterOptions).forEach(function (key, index) {
        dwFilterOptions['prefn' + (index + 1)] = key;
        dwFilterOptions['prefv' + (index + 1)] = filterOptions[key];
    });

    // get sorting option value
    var selectedSortOption = $('.js-sort-by').find('.js-sortBySelect').find('option').filter(':selected'),
        selectedSortOptionValue = selectedSortOption && selectedSortOption.length && selectedSortOption.val().trim();

    if (selectedSortOptionValue) {
        dwFilterOptions.srule = selectedSortOptionValue;
    }

    return dwFilterOptions;
}

/**
 * Updates product grid based on selected filters
 */
function updateProductGrid() {
    window.vbqUtils.progressLoader();

    var dataObj = getFilterOptions(),
        $searchResultItems = $('#search-result-items'),
        $filterProductsNumber = $('.js-filter-products-number'),
        cgid = $searchResultItems.attr('data-cgid'),
        // get only the 'q' parameter for search result pages, rest of the parameters are managed by the filter
        searchQuery = window.location.search && window.location.search.substr(1).split('&').filter(function (item) {
            return item.indexOf('q=') == 0;
        }).join('&'),
        url = searchQuery ? window.Urls.searchShow + '?' + searchQuery : window.Urls.searchShow;

    if (cgid) {
        var urlWithoutQueryString = window.location.href.split('?')[0];
        if (urlWithoutQueryString != window.Urls.searchShow) {
            // keep the rewritten URL
            url = urlWithoutQueryString;
        }
        else {
            // add the category ID to the generic search URL
            dataObj.cgid = $searchResultItems.attr('data-cgid');
        }
    }
    dataObj.format = 'ajax';
    dataObj.update = true;

    return $.ajax({
        url: url,
        timeout: 60000,
        data: dataObj
    })
    .done(function (html) {
        if (html) {
            // ECM21-103 - Filters disappeared after using "back button" from PDP to Page List
            // ECM23-159 - ajax fix on Clear all filters
            window.history.pushState({urlPath: window.location.pathname}, '', this.url.replace('?format=ajax&update=true', '').replace('&format=ajax&update=true', ''));

            $searchResultItems.html(html);
            productTile.init();
            window.vbqUtils.priceDisplay.refresh();
            wishlist.updateWishlistIcons();

            // update filter products number
            $filterProductsNumber.html($('.js-products-number').attr('data-products-number'));

            updateSelectedValues();
            updateMobileFilterLayout();
            updateLeftFilterState();

            // ECM22-126 SR/VBQ when selecting a filter the scroll is not reset we get back on the bottom of the page
            window.vbqUtils.cache.$html.animate({scrollTop: 0}, 0);
        }

        window.vbqUtils.progressLoader(false);
    })
    .fail(function () {
        window.vbqUtils.progressLoader(false);
    });
}

/**
 * Check if current view mode is desktop
 */
function isDesktopView() {
    var $desktopView = $('#js-desktop-view');

    return $desktopView.length && $desktopView.is(':visible');
}

/**
 * Set top mobile filter menu accessibility
 */
function setTopFilterMenuAccessibility(isFilterMenuState, isTrapFocusInit) {
    // EADA-78 - catalog filters: initial state - turn off accessibility of all buttons and inputs in filter slides
    searchCache.$refinementsValues.find('button, input').attr('tabindex', '-1');

    // turn on circle focus in the defined wrapper
    if (isTrapFocusInit) {
        accessibility.trapFocusInit(searchCache.$refinementsWrapper, false, true);
    }

    // EADA23-94 - Focus on close button on initial open state
    if (isFilterMenuState == 'initialOpened') {
        // EADA-78 - catalog filters: added focus on mobile filter close of top filter view on filter menu open
        searchCache.$mobileTopFilterClose.focus();
    }
    else if (isFilterMenuState == 'closed') {
        // EADA-78 - catalog filters: added focus on mobile filter button on filter menu close
        searchCache.$mobileFilterOpen.focus();
    }
}

/**
 * Update filter selected values
 */
function updateSelectedValues() {
    // update sort selection value
    var selectedSortOption = $('.js-sort-by').find('.js-sortBySelect').find('option').filter(':selected'),
        selectedSortOptionValue = selectedSortOption && selectedSortOption.length && !selectedSortOption.is('[disabled]') && selectedSortOption.text().trim() || '';

    // update mobile sort option selected value
    $('.js-selected-sort-value').html(selectedSortOptionValue);

    // update filter selected values
    var filterSelectedValues = {},
        keysArray = [];

    searchCache.$refinementsAttrOptions.filter(':checked').each(function () {
        var $this = $(this),
            refinementID = $this.attr('data-refinement-id'),
            selectedValue = $this.attr('data-refinement-name');

        if (filterSelectedValues[refinementID]) {
            filterSelectedValues[refinementID] = filterSelectedValues[refinementID] + ', ' + selectedValue;
        }
        else {
            filterSelectedValues[refinementID] = selectedValue;
        }
    });

    keysArray = Object.keys(filterSelectedValues);

    // clear showed selected values
    $('.js-selected-values').html('');

    if (keysArray.length) {
        keysArray.forEach(function (key) {
            $('.js-selected-values[data-refinement-id="' + key + '"]').html(filterSelectedValues[key]);
        });
    }
}

/**
 * Update mobile filter buttons visibility based on the nr of products and if any of the filters were selected
 */
function updateMobileFilterLayout() {
    // - get selected filters and nr of found products
    var numberOfSelectedFilters = parseInt($('.js-selected-refinements-number').attr('data-refinements-number') || 0, 10),
        numberOfProducts = parseInt($('.js-products-number').attr('data-products-number') || 0, 10),
        $oneProduct = $('.js-mobile-products-wrapper-single'),
        $multipleProducts = $('.js-mobile-products-wrapper-multiple'),
        $filterValidate = $('.js-filter-validate-show'),
        $filterClear = $('.js-filter-clear-show');

    if (numberOfProducts) {
        if (numberOfProducts <= 1) {
            // show one product message
            $oneProduct.removeClass('hidden');
            // hide multiple products message
            $multipleProducts.addClass('hidden');
        }
        else {
            // show multiple products message
            $multipleProducts.removeClass('hidden');
            // hide one product message
            $oneProduct.addClass('hidden');
        }

        if (numberOfSelectedFilters) {
            // show validate and clear buttons
            $filterValidate.removeClass('hidden');
            $filterClear.removeClass('hidden');
        }
        else {
            // hide validate and clear buttons
            $filterValidate.addClass('hidden');
            $filterClear.addClass('hidden');
        }
    }
    else {
        // no products found
        // show no product message and filter clear button
        $oneProduct.removeClass('hidden');
        $filterClear.removeClass('hidden');

        // hide one and multiple products message and validate button
        $multipleProducts.addClass('hidden');
        $filterValidate.addClass('hidden');
    }
}

function updateSelectedFiltersExerpt() {
    // reset list
    searchCache.$selectedExerptList.find('.js-selectedExerptEntry').remove();
    var code = '',
        $skeleton = searchCache.$selectedExerptList.find('.js-selectedExerptEntrySkeleton').clone();

    if ($skeleton.length) {
        $skeleton.removeClass('hidden').attr('id', '').addClass('js-selectedExerptEntry');

        searchCache.$refinementsAttrOptions.each(function () {
            var $this = $(this);
            if ($this.prop('checked')) {
                $skeleton
                    .find('.js-refinement-selected-value')
                        .attr('data-refinement-id', $this.attr('data-refinement-id'))
                        .attr('data-refinement-value', $this.attr('data-refinement-value'))
                        .attr('aria-label', window.Resources.REFINEMENTS_CLEAR + ' ' + $this.attr('data-refinement-name'))
                    .find('.js-selectedExerptText').html($this.attr('data-refinement-name'));
                code += $skeleton[0].outerHTML;
            }
        });
    }

    if (code.length > 0) {
        searchCache.$selectedExerptList.removeClass('hidden').prepend(code).find('.js-selectedExerptReset').removeClass('hidden');
    }
    else {
        searchCache.$selectedExerptList.addClass('hidden').find('.js-selectedExerptReset').addClass('hidden');
    }
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
    var $wrapper = $('#wrapper').filter('.pt_product-search-result'); // specific pages

    $wrapper.on('click', '.search-refinement', function () {
        var $currentToggleRefinement = $(this).find('.search-refinement-toggle');
        $currentRefinement = $(this);

        $('.search-refinement').not(this).removeClass('expanded').find('.search-refinement-toggle').attr('aria-label', window.Resources.OPEN);
        $('.search-refinement').not(this).find('.search-refinement-dropdown').attr('aria-expanded', 'false').attr('aria-hidden', 'true');
        $currentRefinement.toggleClass('expanded');
        $currentToggleRefinement.attr('aria-label', ($currentToggleRefinement.attr('aria-label') === window.Resources.CLOSE ? window.Resources.OPEN : window.Resources.CLOSE));
        $currentDropdown = $currentRefinement.find('ul');
        $('.search-refinement-dropdown').not($currentDropdown[0]).removeClass('visible');
        positionRefinementDropdown();
        $currentDropdown.toggleClass('visible');
        $currentDropdown.attr('aria-expanded', ($currentDropdown.attr('aria-expanded') === 'true' ? 'false' : 'true'));
        $currentDropdown.attr('aria-hidden', ($currentDropdown.attr('aria-hidden') === 'false' ? 'true' : 'false'));
    });

    window.vbqUtils.cache.$window.resize(_debounce(positionRefinementDropdown, 200));

    window.vbqUtils.cache.$body.on('click', function (ev) {
        if (!$(ev.target).closest('.search-refinement').length) {
            resetRefinementUI();
        }
    });

    // ERF-75 Search results integration, searchResult tabs init
    updateTabs();

    window.vbqUtils.cache.$body.on('click keydown', '.sort-by-interface-item', function (e) {
        // mouse click or enter/space key
        if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
            // prevent the default action to stop scrolling when space is pressed
            e.preventDefault();

            var $target = $(e.target),
                $sortByForm = $target.parents('form'),
                $targetSelect = $sortByForm.find('.search-refinement-sort-select'),
                $targetOption = $sortByForm.find('#select-option-' + $target.attr('data-sort-by-index'));

            $targetSelect.val($targetOption.val()).change();
        }
    });

    // handle events for updating grid
    $wrapper.on('click', '.refinements a, .family-gender-refinements a, .pagination a, .breadcrumb-refinement-value a', function (e) {
        // don't intercept for category and folder refinements, as well as unselectable
        if ($(this).parents('.category-refinement').length > 0 || $(this).parents('.folder-refinement').length > 0 || $(this).parent().hasClass('unselectable')) {
            return;
        }
        e.preventDefault();

        updateProductListing(this.href);
    });

    // handle sorting change
    $wrapper.on('change', '.sort-by select', function (e) {
        e.preventDefault();
        updateProductListing($(this).find('option:selected').val());
    })
    .on('change', '.items-per-page select', function () {
        var refineUrl = $(this).find('option:selected').val();
        if (refineUrl === 'INFINITE_SCROLL') {
            $('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
        } else {
            $('html').addClass('disable-infinite-scroll').removeClass('infinite-scroll');
            updateProductListing(refineUrl);
        }
    });

    /* ERB-66 - Catalog pages (product grid layout)
     * Function to create the full "view more" URL, load the AJAX results and remove the "view more" button, for the L1-L3 category pages
     */
    $wrapper.on('click', '.js-gridViewMore', function (e) {
        e.preventDefault();

        var $that = $(e.target),
            $alert = $that.next('.js-progress-alert'),
            isViewAll = $that.data('viewall') == true,
            hasViewAllClass = $that.hasClass('js-grid-view-all'),
            $ul, currentAssetCount, start, totalAssetCount, $viewAllLoader;

        window.vbqUtils.progressLoader();
        $viewAllLoader = $that.parent().find('.gl_ajax-loader');
        $ul = !isViewAll || hasViewAllClass ? $that.siblings('ul') : $that.parent().siblings('ul');
        currentAssetCount = $ul.find('.custom-asset-tile').length;
        start = $ul.find('.grid-tile').length - currentAssetCount;
        totalAssetCount = $('#search-result-items').find('.custom-asset-tile').length;

        var url = $that.data('href') + '&start=' + start + '&nextAssetNo=' + (totalAssetCount + 1) + '&currentAssetCount=' + currentAssetCount;

        $viewAllLoader.show();

        if (!isViewAll || hasViewAllClass) {
            $that.addClass('loading');
            $alert.html('<span>' + window.Resources.ALERT_MESSAGE + '</span>');
        }

        // EADA21-86 - ADA - #165 & #194 - view more button does not move the user's focus to the targeted location on the page.
        $ul.find('.js-grid-tile-focus').removeClass('js-grid-tile-focus').removeAttr('tabindex');

        $.ajax({
            url: url,
            timeout: 60000 // 60 seconds
        }).done(function (data) {
            if (!isViewAll) {
                $that.removeClass('loading');
                $alert.html('');
            }
            $viewAllLoader.hide();
            $ul.append(data);
            $ul.find('.js-grid-tile-focus').focus();

            if ($ul.find('.no-showmore').length) {
                $that.closest('.subset').find('.js-gridViewMore').remove();
                $('.no-showmore').remove();
            }

            window.vbqUtils.progressLoader(false);

            productTile.init();

            window.vbqUtils.cache.$window.trigger('viewMore');

            //ERB-52 International Shipping
            window.vbqUtils.priceDisplay.refresh();

            //ERB-51 - SEO Tracking - Google Tag Manager - update product impressions
            window.vbqUtils.gtmUtils.updateProductImpressions();

            wishlist.updateWishlistIcons();

        }).fail(function() {
            window.vbqUtils.progressLoader(false);
        });
    });

    // Function to load the pattern results pages AJAX results: patternssearchresults.isml
    $wrapper.on('click', '.js-custom-load-more', function (e) {
        e.preventDefault();

        var $that = $(this),
            $alert = $that.next('.js-progress-alert'),
            $ul = $that.siblings('.category-subset-row'),
            start = $ul.find('.subset').length,
            url = $that.data('href') + '&customStart=' + start;

        $that.addClass('loading');
        $alert.html('<span>' + window.Resources.ALERT_MESSAGE + '</span>');

        // EADA21-86 - ADA - #165 & #194 - view more button does not move the user's focus to the targeted location on the page.
        $ul.find('.js-grid-tile-focus').removeClass('js-grid-tile-focus').removeAttr('tabindex');

        $.ajax({
            url: url
        }).done(function (data) {
            $that.removeClass('loading');
            $alert.html('');
            $ul.append(data);
            $ul.find('.js-grid-tile-focus').focus();
            if ($ul.find('.no-showmore').length) {
                $that.remove();
                $('.no-showmore').remove();
            }

            productTile.init();

            //ERB-52 International Shipping
            window.vbqUtils.priceDisplay.refresh();

            //ERB-51 - SEO Tracking - Google Tag Manager - update product impressions
            window.vbqUtils.gtmUtils.updateProductImpressions();

            wishlist.updateWishlistIcons();
        });
    });

    // Function to load the pattern results / family products page AJAX results
    // familyproductsearchresult.isml
    $wrapper.on('click', '.js-pattern-load-more', function (e) {
        e.preventDefault();

        var $that = $(this).addClass('loading'),
            $alert = $that.next('.js-progress-alert'),
            $ul = $that.siblings('ul'),
            start = $ul.find('.grid-tile').length,
            url = $that.data('href') + '&customStart=' + start;

        $alert.html('<span>' + window.Resources.ALERT_MESSAGE + '</span>');

        // EADA21-86 - ADA - #165 & #194 - view more button does not move the user's focus to the targeted location on the page.
        $ul.find('.js-grid-tile-focus').removeClass('js-grid-tile-focus').removeAttr('tabindex');

        $.ajax({
            url: url
        }).done(function (data) {
            $that.removeClass('loading');
            $alert.html('');
            $ul.append(data);
            $ul.find('.js-grid-tile-focus').focus();
            if ($ul.find('.no-showmore').length) {
                $that.remove();
                $('.no-showmore').remove();
            }
            productTile.init();

            //ERB-52 International Shipping
            window.vbqUtils.priceDisplay.refresh();

            //ERB-51 - SEO Tracking - Google Tag Manager - update product impressions
            window.vbqUtils.gtmUtils.updateProductImpressions();

            wishlist.updateWishlistIcons();
        });
    });

    $wrapper.on('click', '.clear-refinement-mobile-link', function (ev) {
        ev.stopPropagation();
    });

    // new ajax requests for filter options
    $wrapper.on('change', '.js-sort-by .js-sortBySelect', function (e, viewport) {
        var $this = $(this);

        // update product grid
        updateProductGrid().done(function (html) {
            if (viewport == 'mobile' && html) {
                searchCache.$refinementsValues.removeClass('visible');

                // EADA-78 - catalog filters: state of filter menu on back to top filter menu
                setTopFilterMenuAccessibility('opened', true);
            }

            // sync with mobile sort radio inputs
            searchCache.$mobileSortOptions.prop('checked', false).filter('[data-value="' + $this.val() + '"]').trigger('click');
        });
    });

    // desktop slide toggle
    searchCache.$desktopFilterToggle.on('click keydown', function (e) {
        // mouse click or enter/space key
        if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
            // prevent the default action to stop scrolling when space is pressed
            e.preventDefault();

            var $currentDesktopFilterToggle = $(this),
                $currentRefinementValues = $currentDesktopFilterToggle.parents('.js-refinement-filter-item').find('.js-refinement-values'),
                currentRefinementValuesStatus = $currentRefinementValues.hasClass('visible');

            searchCache.$refinementsValues.filter('.visible').slideUp({
                duration: 400,
                progress: function () {
                    // update filter sticky position
                    updateLeftFilterState();
                },
                complete: function () {
                    var $this = $(this);

                    $this.parents('.js-refinement-filter-item').find('.js-refinement-toggle').attr('aria-expanded', 'false')
                        .find('.js-refinement-chevron').removeClass('open');
                    $this.removeClass('visible').attr('aria-hidden', 'true').css('display', '')
                        // turn off accessibility of all buttons and inputs in closed filter menu
                        .find('button, input').attr('tabindex', '-1');
                }
            });

            if (!currentRefinementValuesStatus) {
                $currentDesktopFilterToggle.attr('aria-expanded', 'true').find('.js-refinement-chevron').addClass('open');

                $currentRefinementValues.slideDown({
                    duration: 400,
                    progress: function () {
                        // update filter sticky position
                        updateLeftFilterState();
                    },
                    complete: function () {
                        $currentRefinementValues.addClass('visible').attr('aria-hidden', 'false').css('display', '')
                            // turn on accessibility of all buttons and inputs in opened filter menu
                            .find('button, input').attr('tabindex', '0');
                    }
                });
            }
        }
    });

    // EADA23-94 - Attach trap logic on submenu animation end: transition: left 0.3s;
    searchCache.$refinementsValues.on('transitionend', function() {
        var $this = $(this);

        // EADA23-94 - focus on back btn on open submenu
        if ($this.hasClass('visible')) {
            accessibility.trapFocusInit($this, true);
        } else {
            // EADA23-94 - focus on toggle element that controls values
            $this.siblings('.js-refinement-mobile-slide').focus();
        }
    });

    // mobile slide 'slide'
    searchCache.$mobileFilterSlide.on('click keydown', function (e) {
        // mouse click or enter/space key
        if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
            // prevent the default action to stop scrolling when space is pressed
            e.preventDefault();

            var $this = $(this);

            $this.siblings('.js-refinement-values').attr('aria-hidden', 'false')
                // turn on accessibility of all buttons and inputs in open filter slide
                .find('button, input').attr('tabindex', '0')
                // Run animation to trigger transitionend listener
                .end().addClass('visible');
        }
    });

    // mobile go back slide
    searchCache.$mobileFilterBack.on('click keydown', function (e) {
        // mouse click or enter/space key
        if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
            // prevent the default action to stop scrolling when space is pressed
            e.preventDefault();

            searchCache.$mobileFilterBack
                .closest('.js-refinement-values').removeClass('visible').attr('aria-hidden', 'true');

            // EADA-78 - catalog filters: state of filter menu on back to top filter menu
            setTopFilterMenuAccessibility('opened');
        }
    });

    // check box functionality for filter options
    searchCache.$refinementsAttrOptions.on('click', function (e, status) {
        // on desktop each option click triggers a grid update
        // if status variable is present and has value 'reset', every filter selection is reset but no grid update is made, which is done by other function
        if (!(status && status == 'reset') && isDesktopView()) {
            updateProductGrid();
        }

        updateSelectedFiltersExerpt();
    });

    // mobile apply sorting option
    $wrapper.on('click', '.js-sort-apply', function () {
        var $sortOptionSelect = $('.js-sort-by .js-sortBySelect'),
            $selectedSortOption = searchCache.$mobileSortOptions.filter(':checked'),
            selectedValue = $selectedSortOption.length && $selectedSortOption.attr('data-value');

        if (selectedValue) {
            $sortOptionSelect.val(selectedValue);
        }

        $sortOptionSelect.trigger('change', ['mobile']);
    });

    // mobile apply selected filters
    $wrapper.on('click', '.js-filters-apply', function () {
        updateProductGrid().done(function (html) {
            if (html) {
                searchCache.$refinementsValues.removeClass('visible');
            }

            // EADA-78 - catalog filters: state of filter menu on back to top filter menu
            setTopFilterMenuAccessibility('opened', true);
        });
    });

    // open mobile filter modal
    searchCache.$mobileFilterOpen.on('click', function () {
        searchCache.$refinementsWrapper.addClass('active');
        window.vbqUtils.cache.$body.addClass('filter-active');

        // EADA-78 - catalog filters: initial state of filter menu on open
        setTopFilterMenuAccessibility('initialOpened', true);
    });

    // close mobile filter modal
    searchCache.$mobileFilterClose.on('click', function () {
        searchCache.$refinementsWrapper.removeClass('active');
        searchCache.$refinementsValues.removeClass('visible').attr('aria-hidden', 'true');
        window.vbqUtils.cache.$body.removeClass('filter-active');

        // EADA-78 - catalog filters: state of filter menu on close
        setTopFilterMenuAccessibility('closed');
    });

    // reset filter selection on mobile
    $wrapper.on('click', '.js-filter-reset', function () {
        // remove 'checked' property from sort selection, also refresh checkboxradio() state
        searchCache.$mobileSortOptions.filter(':checked').prop('checked', false).checkboxradio('refresh');
        // udpate the real select element
        $('.js-sortBySelect').val('');

        // trigger a click event to correctly update the classes for input label and span's
        // pass the reset value to not update the grid on each option reset
        searchCache.$refinementsAttrOptions.filter(':checked').trigger('click', ['reset']);
        updateProductGrid().done(function () {
            // EADA-78 - catalog filters: state of filter menu on back to top filter menu
            setTopFilterMenuAccessibility('initialOpened', true);
        });
    });

    // faq show answer functionality
    $('.js-faq-show-answer').on('click keydown', function (e) {
        // mouse click or enter/space key
        if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
            // prevent the default action to stop scrolling when space is pressed
            e.preventDefault();

            var $this = $(this);

            $this.next('.js-faq-answer').slideDown();
            $this.addClass('hidden');
        }
    });

    // read more functionality
    $('.js-read-more-link').on('click keydown', function (e) {
        // mouse click or enter/space key
        if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
            // prevent the default action to stop scrolling when space is pressed
            e.preventDefault();

            var $this = $(this),
                $thisParent = $this.closest('.js-read-more-wrapper');

            $this.removeClass('visible');
            $thisParent.find('.js-read-less-link').addClass('visible');
            $thisParent.find('.read-more-dots').removeClass('visible');
            $thisParent.find('.read-more-text').addClass('visible');
        }
    });

    // Manage filter on viewport changes
    window.vbqUtils.breakpoint.set([
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('mobileAndTablet'),
            enter: function () {
                // deactivate desktop filter
                searchCache.$refinementsValues.removeClass('visible');
                searchCache.$desktopFilterToggle.attr('aria-expanded', 'false');
                searchCache.$desktopFilterToggleChevron.removeClass('open');
                searchCache.$desktopSortSelect.attr('tabindex', '-1');
                searchCache.$refinementsValues.attr('aria-hidden', 'true');

                // deactivate desktop left sticky filter
                resetFilterState();
                // activate mobile top sticky filter
                updateMobileFilterState();

                window.vbqUtils.cache.$window.off('scroll', updateLeftFilterState).scroll(updateMobileFilterState);
                window.vbqUtils.cache.$window.off('resize', updateLeftFilterState).resize(updateMobileFilterState);

                // re-init custom scrollbar functionality
                topLinkInit();
                window.vbqUtils.cache.$window.resize(topLinkInit);

                // activate modal mode
                searchCache.$refinementsWrapper.attr('role', 'dialog').attr('aria-modal', 'true');
            }
        },
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('fromDesktop'),
            enter: function () {
                // deactivate modal mode
                searchCache.$refinementsWrapper.attr('role', 'region').removeAttr('aria-modal');

                // deactivate mobile filter
                searchCache.$mobileFilterClose.trigger('click');

                // activate desktop left sticky filter
                searchCache.$desktopSortSelect.attr('tabindex', '0');
                updateLeftFilterState();
                window.vbqUtils.cache.$window.off('scroll', updateMobileFilterState).scroll(updateLeftFilterState);
                window.vbqUtils.cache.$window.off('resize', updateMobileFilterState);
                window.vbqUtils.cache.$window.off('resize', topLinkInit);
                window.vbqUtils.cache.$window.resize(updateLeftFilterState);
            }
        }
    ]);

    $('.js-refinement-values').on('click', '.js-refinementViewMore', function () {
        var $this = $(this),
            $wrapper = $this.parent('.js-refinementViewMoreWrapper'),
            $parent = $this.parents('.js-refinementWrapper');

        $this.off('click');
        $wrapper.remove();
        $parent.find('.js-refinementOption').removeClass('gl_mobile-only');
    });

    $wrapper.on('click', '.js-refinement-selected-value', function (e) {
        e.preventDefault();
        var $this = $(this);

        searchCache.$refinementsAttrOptions
            .filter('[data-refinement-id="' + $this.attr('data-refinement-id') + '"]')
            .filter('[data-refinement-value="' + $this.attr('data-refinement-value') + '"]')
            .trigger('click');

        if (!isDesktopView()) {
            $('.js-filters-apply').trigger('click');
        }
    });

    updateSelectedFiltersExerpt();

    wishlist.updateWishlistIcons();
}

exports.init = function () {
    //compareWidget.init();
    /*
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        window.vbqUtils.cache.$window.on('scroll', function () {
            infiniteScroll();
        });
    }
    */

    // ERB-153 - Quickview support on any page with products list
    // Commented the init since we have it in app.js now. It is still reinitialized for infinite scroll and view more.
    //productTile.init();

    initializeEvents();

    // necessary for page reload after using window.history.pushState
    // else only the URL changes but not the content
    $(window).on('popstate', function(e) {
        // EADA23-28: don't reload page on anchor navigation
        if (e.state) { // user is navigating back or forward: access the state object to determine the previous or next page
            location.reload(true);
        }
    });

    // SR-192 CLP tiles showing under the sticky header
    window.vbqUtils.cache.$body.on('header:sticky', function () {
        if (window.vbqUtils.breakpoint.is('fromDesktop')) {
            var stickOffset = parseInt(window.vbqUtils.cache.$stickyHeader.height(), 10);

            searchCache.$filterPanel.css('top', stickOffset);

            if (searchCache.$refinementsWrapper.hasClass('sticky')) {
                searchCache.$refinementsWrapper.css('top', stickOffset + 10 + 'px');
            }
        }
    });
};
