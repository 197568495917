'use strict';

var dialog = require('../dialog'),
    account = require('./account'),
    cache = {};

function initCache() {
    cache.$document = $(document);
    cache.$productRepairRequset = cache.$document.find('.js-product_repairrequest');
}

function initEvents() {
    cache.$productRepairRequset.on('click', function (e) {
        e.preventDefault();

        var $this = $(this),
            productKey = $this.attr('data-product-key'),
            productRegistrationDate = $this.attr('data-registration-date'),
            url = $this.attr('data-url'),
            data = {
                productKey: productKey,
                registrationDate: productRegistrationDate
            };

        dialog.open({
            url: url,
            data: data,
            bodyClass: 'repair-service-active',
            customCloseSelector: '.js-repair-service-close',
            options: {
                classes: {
                    'ui-dialog': 'repairRequestDialog' // used to find close button
                }
            }
        });
    });
}

exports.init = function () {
    initCache();
    initEvents();

    if ($('#secondary').length) {
        // load account js for secondary navigation
        account.init();
    }
};
