'use strict';

var address = require('./address'),
    formPrepare = require('./formPrepare'),
    dialog = require('../../dialog'),
    util = require('../../util'),
    validator = require('../../validator'),
    // ERB-213 - Address forms - USA/Canada states logic (+ Australia and Mexico)
    ajax = require('../../ajax'),
    _debounce = require('../../lib/lodash.debounce'),
    storelocator = require('../storelocator'),
    storesDialog = require('./storesDialog'),
    googleplaces = require('../../googleplaces'),
    usaPostalMap = require('../../helpers/usaStatePostalMapping');

/**
 * @function
 * @description Initializes gift message box for multiship shipping, the message box starts off as hidden and this will display it if the radio button is checked to yes, also added event handler to listen for when a radio button is pressed to display the message box
 */
function initMultiGiftMessageBox() {
    $.each($('.item-list'), function () {
        var $this = $(this);
        var $giftMessage = $this.find('.gift-message-text');

        //handle initial load
        $giftMessage.toggleClass('hidden', $('input[name$="_isGift"]:checked', this).val() !== 'true');

        //set event listeners
        $this.on('change', function () {
            $giftMessage.toggleClass('hidden', $('input[name$="_isGift"]:checked', this).val() !== 'true');
        });
    });
}

/**
 * @function
 * @description capture add edit address form events
 */
function addEditAddress(target) {
    var $addressForm = $('#edit-address-form'),
        $addressDropdown = $addressForm.find('select[name$=_addressList]'),
        $addressList = $addressForm.find('.address-list'),
        add = true,
        originalUUID,
        resetOptionValue = false,
        selectedAddressUUID = $(target).closest('.multishipping-address').find('.select-address').val();

    $addressDropdown.off('change').on('change', function (e) {
        e.preventDefault();

        var selectedAddress = $addressList.find('select').val();
        if (selectedAddress !== 'newAddress') {
            selectedAddress = $.grep($addressList.data('addresses'), function (add) {
                return add.UUID === selectedAddress;
            })[0];
            add = false;
            resetOptionValue = false;
            // proceed to fill the form with the selected address
            util.fillAddressFields(selectedAddress, $addressForm);
        }
        else if (selectedAddress === 'newAddress') {
            add = true;
            resetOptionValue = true;
            $addressForm.find('.input-text, .input-select').val('');
        }
        else {
            //reset the form if the value of the option is not a UUID
            $addressForm.find('.input-text, .input-select').val('');
        }
    });

    $addressForm.off('click').on('click', '.js-cancel-button', function (e) {
        e.preventDefault();
        dialog.close();
    });

    $addressForm.off('submit').on('submit', function (e) {
        e.preventDefault();
        if (!$addressForm.valid()) {
            return false;
        }

        // needed so form serialize() works for the phone number
        var $phone = $addressForm.find('.phone_field');
        $phone.siblings('input').val(window.intlTelInputGlobals.getInstance($phone[0]).getNumber());

        add = $addressForm.find('.input-select-multiship').val() == 'newAddress';

        $.ajax({
            url: Urls.addEditAddress,
            data: $addressForm.serialize()
        }).done(function (data) {
            var $multiAddressError = $('#multiaddresserror');
            if (!data.success) {
                if (data.message) {
                    $multiAddressError.html(data.message + '<br/><br/>' + Resources.COULD_NOT_SAVE_ADDRESS);
                }
                else {
                    $multiAddressError.html(Resources.COULD_NOT_SAVE_ADDRESS);
                }
                return;
            }
            $multiAddressError.toggleClass('hidden', data.success);

            // ERB-213 - Address forms - USA/Canada states logic (+ Australia and Mexico)
            // Added the country to the new option by sending its name through JSON
            var address = data.address,
                $selects = $('.checkout-multi-shipping').find('.select-address'),
                $shippingAddress = $(target).closest('.shippingaddress'),
                $select = $shippingAddress.find('.select-address'),
                $selected = $select.find('option').filter(':selected'),
                newOption = '<option value="' + address.UUID + '">' +
                    (address.ID ? '(' + address.ID + ')' : (address.firstName + ' ' + address.lastName)) + ', ' +
                    address.address1 + ', ' + address.city + ', ' + ((address.stateCode !== null && address.stateCode !== '') ? (address.stateCode + ', ') : '') + address.postalCode + ', ' + data.countryName +
                    '</option>';

            dialog.close();

            if (address.UUID !== originalUUID) {
                resetOptionValue = true;
            }

            if (add) {
                // add new option on all selects
                $selects.removeClass('no-option').append(newOption);
                $('.no-address').hide();
            }
            else {
                // update option on all selects
                $selects.find('option').filter('[value="' + address.UUID + '"]').html(newOption);
            }

            // if there's no previously selected option, select it
            if ($selected.length === 0 || $selected.val() === '' || resetOptionValue) {
                $select.find('option').filter('[value="' + address.UUID + '"]').prop('selected', true).trigger('change');
            }
        }).fail(function () {
            window.alert(Resources.CHECKOUT_AJAX_ERROR);
        });
    });

    //preserve the uuid of the option for the hop up form
    if (selectedAddressUUID) {
        //update the form with selected address
        $addressList.find('option').each(function () {
            //check the values of the options
            if ($(this).attr('value') === selectedAddressUUID) {
                $(this).prop('selected', 'selected');
                $addressDropdown.trigger('change');
            }
        });
        originalUUID = selectedAddressUUID;
    }

    validator.init();

    //ERV-485 - Checkout - Billing and Payment - Guest - apply form validation logic improvements
    window.vbqUtils.validationLogic.formValidation($addressForm);

    window.vbqUtils.phoneCodes.init($addressForm.find('.country_field'), $addressForm.find('.phone_field'));
}

/**
 * ERB-213 - Address forms - USA/Canada states logic (+ Australia and Mexico)
 * @function
 * @description capture add edit address form events
 */
function updateAddressFields() {
    var $select = $('[name$="_addressFields_country"]'),
        $multishippingForm = $('form').filter('[name$="multishipping_editAddress"]'),
        countryCode = $select.val();

    // needed so form serialize() works for the phone number
    var $phone = $multishippingForm.find('.phone_field');
    $phone.siblings('input').val(window.intlTelInputGlobals.getInstance($phone[0]).getNumber());

    $.ajax({
        url: Urls.updateMultipleShippingAddressForm,
        data: {'countryCode': countryCode, 'form': $multishippingForm.serialize()}
    }).done(function (data) {
        $multishippingForm.find('.address-dynamic-form').html(data);

        // Re-initialize the form
        // ERV-335 - Shipping methods to France - Updated the check to match the changed HTML structure
        if ($('.cart-row').find('.shippingaddress').find('.select-address').length > 0
            || $('.multishipping-cart-row').find('.shippingaddress').find('.select-address').length > 0
        ) {
            formPrepare.init({
                continueSelector: '[name$="addressSelection_save"]',
                formSelector: '[id$="multishipping_addressSelection"]'
            });
        }

        window.vbqUtils.initFloatingLabels($multishippingForm);
        window.vbqUtils.phoneCodes.init($multishippingForm.find('.country_field'), $multishippingForm.find('.phone_field'));

        // update googleplaces country, search input field and cache the new form elements
        googleplaces.updateCache();

        // ECM23-119 - USA ZIP Codes & Postal Codes List By States
        // update cached form elements and events
        usaPostalMap.update();
    }).fail(function () {
        window.alert(Resources.CHECKOUT_AJAX_ERROR);
    });
}

function multiShippingAddressSave() {
    var $useThisAddressCheckboxes = $('.choose-billing-address-checkbox'),
        $useAsBillingId = $('.use-as-billing-address-id'),
        $selectAddressDropdowns = $('.select-address'),
        $multishippingForm = $('.checkout-multi-shipping'),
        $multishippingFormSubmit = $('.shipping-continue-button');

    if ($useThisAddressCheckboxes.length) {
        $useThisAddressCheckboxes.on('change', function (ev) {
            var $this = $(this);
            if (!ev.isCustom) {
                //Update other "use this address" checkboxes to have a radio button behavior
                $useThisAddressCheckboxes.not($this[0]).prop('checked', false).checkboxradio('refresh').trigger({
                    type: 'change',
                    isCustom: true
                });

                if ($this.prop('checked') === true) {
                    var selectedAddressId = $this.closest('.multishipping-address').find('select').find('option').filter(':selected').val();

                    $useAsBillingId.val(selectedAddressId);
                }
                else {
                    $useAsBillingId = $useAsBillingId.val('');
                }
            }
        });

        $selectAddressDropdowns.on('change', function () {
            var $this = $(this),
                useThisAddressForBilling = $this.closest('.multishipping-address').find('.choose-billing-address-checkbox').prop('checked') === true;

            if (useThisAddressForBilling) {
                $useAsBillingId.val($this.find('option').filter(':selected').val());
            }
        });
    }
}


/**
 * @function
 * OCC-3 - Omnichannel Click and Collect - pick up in-store support for multiple shipping
 * manage the pick up in store feature
 */
function pickUpInStoreInit() {
    var $pickUpInStoreDialogWrapper = $('#js-pickUpInStoreDialogWrapper'),
        $pickUpInStoreOpenDialog = $('.js-pickUpInStoreOpenDialog'),
        $currentOpener;

    $pickUpInStoreOpenDialog.on('click', function (e) {
        e.preventDefault();  // prevent form submit

        var $currentPickUpInStoreOpenDialog = $(e.target),
            $pickUpInStoreCloseDialog = $pickUpInStoreDialogWrapper.find('.js-store-locator-close');

        $currentOpener = $(this);
        $pickUpInStoreDialogWrapper.removeClass('hidden').dialog({
            draggable: false,
            modal: true,
            resizable: false,
            closeText: window.Resources.CLOSE,
            closeOnEscape: true, // EADA-34 - All modal can be closing on escape key: .dialog() case
            title: window.Resources.SELECT_STORE,
            classes: {
                'ui-dialog': 'pickUpInStoreDialog multishipPickUpInStoreDialog' //used to force styles
            },
            position: {
                my: 'center top',
                at: 'center top'
            },
            open: function (e) {
                // EADA-40 - The modal container is not labeled as such: .dialog() case
                window.vbqUtils.jqDialogOpenAdjust($(e.target).closest('.ui-dialog'));

                // deactivate body scroll on mobile/tablet when dialog full width/height
                window.vbqUtils.cache.$body.addClass('storelocator-dialog-active');

                storelocator.init();
                // ECM-297 - Checkout - Pick up in store - Select a store
                // Trigger the callback after the dialog opens, so that the map displays
                window.vbqUtils.cache.$document.trigger('gMapCallback');

                window.vbqUtils.cache.$document.on('click', '.ui-widget-overlay', function () {
                    $pickUpInStoreCloseDialog.trigger('click');
                });

                $pickUpInStoreCloseDialog.on('click', function () {
                    $pickUpInStoreDialogWrapper.dialog('close');
                });
            },
            focus: function () {
                // EADA-74 - Storelocator - focus on modal opening
                $pickUpInStoreCloseDialog.focus();
            },
            close: function () {
                // activate body scroll
                window.vbqUtils.cache.$body.removeClass('storelocator-dialog-active');

                $pickUpInStoreCloseDialog.off('click');

                // EADA-74 - Storelocator - focus on open dialog button on modal close
                $currentPickUpInStoreOpenDialog.focus();
            }
        });
    });

    window.vbqUtils.cache.$window.resize(_debounce(function () {
        if ($pickUpInStoreDialogWrapper.is(':visible')) {
            $pickUpInStoreDialogWrapper.dialog('option', 'position', {
                my: 'center top+100',
                at: 'center top',
                of: window,
                collision: 'none'
            });
        }
    }, 400));

    // init can be done here as the dialog is already in the page source code
    storesDialog.init();

    $('.js-storesWrapper').on('click', '.js-chooseStore', function () {
        var storeID = $(this).attr('data-store'),
            store = window.vbq.stores[storeID],
            $shippingAddress = $currentOpener.closest('.shippingaddress'),
            $select = $shippingAddress.find('.select-address');

        fillFormWithStoreData(store);

        // Use store address again if found
        var $storeAddressOption = $select.find('option').filter('[data-storeid="' + storeID + '"]');
        if ($storeAddressOption.length) {
            $storeAddressOption.prop('selected', true);
            $select.trigger('change');
            managePickUpInStoreAddress($shippingAddress);
            $pickUpInStoreDialogWrapper.dialog('close');
            if (window.vbqUtils.breakpoint.is('mobileAndTablet')) {
                window.vbqUtils.cache.$body.scrollTop(0);
            }
            return;
        }

        $.ajax({
            url: Urls.addEditAddress,
            data: $('#pickupinstore-address-form').find(':input').serialize()
        }).done(function (data) {
            if (!data.hasOwnProperty('success') || !data.success) {
                window.alert(Resources.COULD_NOT_SAVE_ADDRESS);
            }
            else {
                // ERB-213 - Address forms - USA/Canada states logic (+ Australia and Mexico)
                // Added the country to the new option by sending its name through JSON
                var address = data.address,
                    $selected = $select.find('option').filter(':selected'),
                    newOption = '<option value="' + address.UUID + '"' +
                        (address.storeID ? ' data-storeid="' + address.storeID + '"' : '') +
                        '>' +
                        (address.firstName + ' ' + address.lastName) + ', ' +
                        address.address1 + ', ' + address.city + ', ' + ((address.stateCode !== null && address.stateCode !== '') ? (address.stateCode + ', ') : '') + address.postalCode + ', ' + data.countryName +
                        '</option>';

                var $option = $select.find('option').filter('[value="' + address.UUID + '"]');
                if ($option.length === 0) {
                    // add new option on all selects
                    $('.select-address').removeClass('no-option').append(newOption);
                }
                $('.no-address').hide();

                $select.find('option').filter('[value="' + address.UUID + '"]').prop('selected', true);
                $select.trigger('change');
                managePickUpInStoreAddress($shippingAddress);

                $pickUpInStoreDialogWrapper.dialog('close');
                if (window.vbqUtils.breakpoint.is('mobileAndTablet')) {
                    window.vbqUtils.cache.$body.scrollTop(0);
                }
            }
        }).fail(function () {
            window.alert(Resources.CHECKOUT_AJAX_ERROR);
        });
    });

    $('.shippingaddress').each(function () {
        var $this = $(this);
        managePickUpInStoreAddress($this);

        $this.find('.select-address').on('change', function () {
            managePickUpInStoreAddress($(this).closest('.shippingaddress'));
        });
    });
}

/* OCC-3 - Omnichannel Click and Collect
 * handle the limitation of UI when an address is selected, depending on if it's a store one
 */
function managePickUpInStoreAddress($shippingAddress) {
    var $select = $shippingAddress.find('.select-address'),
        $selectedOption = $select.find('option').filter('[value="' + $select.val() + '"]'),
        $button = $shippingAddress.find('.js-pickUpInStoreOpenDialog'),
        $useForBilling = $shippingAddress.find('.js-choose-billing-address').find('input'),
        $editButton = $shippingAddress.find('.js-multishipping-edit-button');

    if ($selectedOption.attr('data-storeid')) { // chosen pick up in store
        $useForBilling.prop('checked', false);
        $useForBilling.closest('.js-choose-billing-address').addClass('offscreen');
        $editButton.addClass('hidden');

        $button.html($button.attr('data-anotherLabel'));
    }
    else {
        $useForBilling.prop('checked', false);
        $useForBilling.closest('.js-choose-billing-address').removeClass('offscreen');
        $editButton.removeClass('hidden');

        $button.html($button.attr('data-label'));
    }
    $useForBilling.checkboxradio('refresh');
}

/**
 * @function
 * OCC-3 - Omnichannel Click and Collect
 * fills pick up in store address form fields with store data
 */
function fillFormWithStoreData(store) {
    var $form = $('#pickupinstore-address-form');
    $form.find(':input').val('');
    store = store || {};

    var tmp = (store && store.name) ? store.name.split(' ') : [],
        firstname = '',
        lastname = '';
    for (var i = 0; i < tmp.length; i++) {
        if (i === 0) {
            firstname = tmp[i];
        } else {
            lastname = lastname + ' ' + tmp[i];
        }
    }

    //security
    if (lastname === '') {
        lastname = 'Vilebrequin';
    }

    //ERV-437 - [US Site] Order Total false - Order Summary - removed triggering
    var $formInputs = $form.find(':input');
    $formInputs.filter('#js-storeId').val(store.id);
    $formInputs.filter('[name$="_firstName"]').val(firstname);
    $formInputs.filter('[name$="_lastName"]').val(lastname);
    $formInputs.filter('[name$="_address1"]').val(store.address1);
    $formInputs.filter('[name$="_address2"]').val(store.address2);
    $formInputs.filter('[name$="_country"]').val(store.countryCode);
    $formInputs.filter('[name$="_state"]').val(store.stateCode);
    $formInputs.filter('[name$="_postal"]').val(store.postalCode);
    $formInputs.filter('[name$="_city"]').val(store.city);
    $formInputs.filter('[name$="_phone"]').val(store.phone);
}

/**
 * @function
 * @description shows gift message box in multiship, and if the page is the multi shipping address page it will call initmultishipshipaddress() to initialize the form
 */
exports.init = function () {
    initMultiGiftMessageBox();

    // Initialize the jQuery UI elements
    window.vbqUtils.initializeJQueryUiPlugins();

    // ERV-335 - Shipping methods to France - Updated the check to match the changed HTML structure
    // However, the code currently doesn't seem to do anything
    if ($('.cart-row').find('.shippingaddress').find('.select-address').length > 0
        || $('.multishipping-cart-row').find('.shippingaddress').find('.select-address').length > 0
    ) {
        formPrepare.init({
            continueSelector: '[name$="addressSelection_save"]',
            formSelector: '[id$="multishipping_addressSelection"]'
        });
    }

    var $shippingEditAddress = $('.js-multishipping-edit-button');
    $shippingEditAddress.on('click', function (e) {
        e.preventDefault();  // prevent form submit

        dialog.open({
            url: util.appendParamsToUrl(this.attributes.href.value, {multishipping: true}),
            bodyClass: 'address-popup-dialog-active',
            customCloseSelector: '.js-address-popup-close',
            options: {
                title: window.Resources.ADD_EDIT_ADDRESS,
                classes: {
                    'ui-dialog': 'shippingEditAddressDialog' // used to force styles
                },
                open: function () {
                    address.init();
                    addEditAddress(e.target);
                    var $form = $('form').filter('[name$="multishipping_editAddress"]');
                    // ERB-213 - Address forms - USA/Canada states logic (+ Australia and Mexico)
                    // Initialize the jQuery UI elements
                    window.vbqUtils.initializeJQueryUiPlugins();
                    window.vbqUtils.initFloatingLabels($form);
                    window.vbqUtils.phoneCodes.init($form.find('.country_field'), $form.find('.phone_field'));

                    // Update the shipping form
                    $form.on('change', 'select[name$=_addressList], select[name$="_addressFields_country"]', function () {
                        updateAddressFields();
                    });

                    // EAWGC-1: initialize googleplaces api
                    googleplaces.init({
                        country: window.vbq.localizationData.countryCode
                    });

                    // ECM23-119 - USA ZIP Codes & Postal Codes List By States
                    usaPostalMap.init($form);
                }
            }
        });
    });

    multiShippingAddressSave();

    // OCC-3 - Omnichannel Click and Collect - pick up in-store support for multiple shipping
    pickUpInStoreInit();

    // ECM24-8 - Datalayer update (GA4 GTM)
    $('.js-multishipping_continue_button')
        .off('click')
        .on('click', function () {
            var shippingMethods = [];

            $('.js-multishipping_method_select').each(function () {
                shippingMethods.push($(this).val());
            });

            window.vbqUtils.gtmUtils.pushCheckoutInfoEvent(1, {shippingMethods: shippingMethods});
        });
};
