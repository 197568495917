'use strict';

var address = require('./address'),
    billing = require('./billing'),
    multiship = require('./multiship'),
    shipping = require('./shipping'),
    b2b2c = require('./b2b2c'),
    account = require('../account'),
    _debounce = require('../../lib/lodash.debounce');

function initializeDOM () {
    window.vbqUtils.breakpoint.set([
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('fromDesktop'),
            enter: function () {
                $('.js-bag-details').trigger('click', true);
            }
        },
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('mobileAndTablet'),
            enter: function () {
                $('.js-bag-details').trigger('click');
            }
        }
    ]);
}

/**
 * Update basket items closed/open state
 */
function setBasketItemsState() {
    var $checkoutBagItems = $('.js-bag-items');

    // shopping bag items view/close
    $('.js-bag-details').off('click').on('click', function (e, forceActiveState) {
        e.preventDefault();

        var $link = $(this),
            isSetActiveState = (forceActiveState != undefined) ? forceActiveState : $link.attr('aria-expanded') == 'false';

        $link.toggleClass('active', isSetActiveState).attr('aria-expanded', (isSetActiveState ? 'true' : 'false'));
        $checkoutBagItems.toggleClass('hidden', !isSetActiveState).attr('aria-hidden', (isSetActiveState ? 'false' : 'true'));
    });
}

/**
 * @function Initializes the page events depending on the checkout stage (shipping/billing)
 */
exports.init = function () {
    address.init();
    setBasketItemsState();
    initializeDOM();

    // EW-63 - Checkout right column sticky when slot is present
    var makeStickable = function () {
        var $leftCol = $('#primary').find('.checkout-primary-content'),
            $rightCol = $('#secondary').find('.checkout-summary-wrapper'),
            toggle = (window.vbqUtils.breakpoint.is('fromDesktop')) && ($leftCol.outerHeight(false) > $rightCol.outerHeight(false) + 100);

        $rightCol.toggleClass('stickable', toggle);
    };

    window.vbqUtils.cache.$window.resize(_debounce(function () {
        makeStickable();
    }, 400));

    window.vbqUtils.cache.$window.on('stickyCheckoutRightCol', function() {
        makeStickable();

        // stickyCheckoutRightCol custom event runs ajax updates of $('#secondary.summary') html
        // => need to attach action on $('.js-bag-details') click again
        setBasketItemsState();

        if (window.vbqUtils.breakpoint.is('fromDesktop')) {
            $('.js-bag-details').trigger('click', true);
        }
        else {
            $('.js-bag-details').trigger('click');
        }
    });

    if ($('.js-checkout-shipping').length > 0) {
        shipping.init();
        //makeStickable(); summary is refresh through ajax on load
    }
    else if ($('.checkout-multi-shipping').length > 0) {
        multiship.init();
        //makeStickable(); summary is refresh through ajax on load
    }
    else {
        billing.init();
        makeStickable();
    }

    // load custom js for b2b2c site checkout page
    if ($('.js-b2b2c-checkout').length) {
        b2b2c.init();
    }

    //if on the order review page and there are products that are not available diable the submit order button
    if ($('.order-summary-footer').length > 0) {
        if ($('.notavailable').length > 0) {
            $('.order-summary-footer .submit-order .button-fancy-large').attr('disabled', 'disabled');
        }
    }

    // init login logic on checkout login page
    if ($('#js-checkoutLogin').length) {
        account.initCheckoutLogin();
    }
};
