'use strict';

var $loader;
var loaderArray = [];

/**
 * @function
 * @description Shows an AJAX-loader on top of a given container
 * @param {Element} container The Element on top of which the AJAX-Loader will be shown
 */
var show = function (container) {
    var $target = (!container || $(container).length === 0) ? window.vbqUtils.cache.$body : $(container);
    $loader = $loader || $('.loader');

    if ($loader.length === 0) {
        $loader = $('<div/>').addClass('gl_ajax-wrapper').html(window.vbqUtils.loaderTemplate);
    }

    return loaderArray.push($loader.clone().removeClass('hidden').appendTo($target.addClass('gl_loading')));
};
/**
 * @function
 * @description Hides an AJAX-loader
 */
var hide = function () {
    $.each(loaderArray, function(i, loader) {
        loader.closest('.gl_loading').removeClass('gl_loading');
        loader.remove();
    });

    loaderArray = [];
};

exports.show = show;
exports.hide = hide;
