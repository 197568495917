'use strict';
/**
 * ERB-189 - Gifting pages
 */

var util = require('../util'),
    productTile = require('../product-tile');

function giftTabsLoader (state) {
    var $loader = $('#js-refinements-loader');

    if (state === undefined || state) {
        $loader.show();
    } else {
        $loader.hide();
    }
}

function initializeEvents() {
    var $searchResultWrapper = $('#search-result-items'),
        $searchRefinementToggle = $('.search-refinement-toggle'),
        $searchResultsMobileThumbs = $('.js-search-results-mobile-thumbs'),
        //ERV-597 - Gift page "View more" display issue - move 'gender' and 'defaultGender' into initializeEvents-function scope
        defaultGender = $('.giftpage-tabs-menu').data('defaultgender'),
        gender = defaultGender;

    window.vbqUtils.breakpoint.set([
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('mobileAndTablet'),
            enter: function () {
                $searchResultsMobileThumbs.customAccordion();
            }
        },
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('fromDesktop'),
            enter: function () {
                if ($searchResultsMobileThumbs.hasClass('accordion-initialized')) {
                    $searchResultsMobileThumbs.customAccordion('destroy');
                }
                $searchRefinementToggle.off();
            }
        }
    ]);

    // Load more function
    $searchResultWrapper.on('click', '.js-giftpage-more', function (ev) {
        ev.preventDefault();
        var $this = $(this),
            $subset = $this.parents('.subset'),
            $ul = $subset.find('.category-subset-row'),
            //ERV-597 - Gift page "View more" display issue - append gender to request url
            url = $this.data('href') + '&start=' + $ul.find('.grid-tile').length + '&gender=' + gender,
            // activate all category loaders
            $viewAllLoader = $(this).parents('.js-giftpage-result-items').find('.progress-loader');

        window.vbqUtils.progressLoader(true, $viewAllLoader);

        // EADA21-86 - ADA - #165 & #194 - view more button does not move the user's focus to the targeted location on the page.
        $ul.find('.js-grid-tile-focus').removeClass('js-grid-tile-focus').removeAttr('tabindex');

        $.ajax({
            url: url
        }).done(function (data) {
            $ul.append(data);
            window.vbqUtils.progressLoader(false, $viewAllLoader);
            $ul.find('.js-grid-tile-focus').focus();

            if ($ul.find('.no-showmore').length) {
                $subset.find('.js-giftpage-more, .no-showmore').remove();
            }
            // Reinit the product tile
            productTile.init();

            //ERB-52 International Shipping
            window.vbqUtils.priceDisplay.refresh();

            //ERB-51 - SEO Tracking - Google Tag Manager - update product impressions
            window.vbqUtils.gtmUtils.updateProductImpressions();
        });
    });

    // Hashchange function
    window.vbqUtils.cache.$window.on('hashchange', function () {
        var href = location.href.split('#'),
            $ul = $('#search-result-items'),
            $tabsLink = $('.js-tabsLink'),
            $filterLink = $('.js-filterLink');

        //ECM-1014 - Gifting pages - add loader on gender change
        giftTabsLoader();
        window.vbqUtils.progressLoader();

        if (href.length > 1 && href[1].length) {
            gender = href[1];
            // ERB-244 - Gifting page - empty gender should not be in navigation
            var $existingList = $('.giftpage-tabs-link'),
                genderExists = false;
            if ($existingList.length) {
                $existingList.each(function () {
                    if (gender == $(this).data('gender')) {
                        genderExists = true;
                        return false;
                    }
                });
            }
            if (!genderExists) {
                gender = defaultGender;
            }
        }
        else {
            gender = defaultGender;
        }

        $.ajax({
            url: util.appendParamToURL(href[0], 'gender', gender)
        }).done(function (data) {
            $tabsLink.parent().removeClass('active');
            $filterLink.parent().removeClass('active');

            // EADA23-7 - Removed radio behavior from mobile
            $filterLink.removeClass('active').filter('[title="' + gender + '"]').addClass('active').parent().addClass('active');

            // EADA-82 Added tablist behavior on desktop
            $tabsLink.filter('.giftpage-tabs-link')
                .removeClass('active').attr('aria-selected', 'false').attr('tabindex', '-1')
                .filter('[title="' + gender + '"]').addClass('active').attr('aria-selected', 'true').attr('tabindex', '0')
                .parent().addClass('active');

            $ul.html(data);
            // Update the hash
            location.hash = gender;
            // Re-init the product tile
            productTile.init();

            //ERB-52 International Shipping
            window.vbqUtils.priceDisplay.refresh();

            //ERB-51 - SEO Tracking - Google Tag Manager - update product impressions
            window.vbqUtils.gtmUtils.updateProductImpressions();

            //ECM-1014 - Gifting pages - add loader on gender change
            giftTabsLoader(false);
            window.vbqUtils.progressLoader(false);
        });

        return false;
    });

    // Trigger the hashchange on load if a hash exists in the link
    if (location.hash) {
        window.vbqUtils.cache.$window.trigger('hashchange');
    }
}

exports.init = function init () {
    initializeEvents();
};
