'use strict';

var _debounce = require('./lib/lodash.debounce');

var CarouselHelper = function ($carouselWrapper) {
    var $viewMoreLinkWrapper = $carouselWrapper.find('.js-view-more-wrapper'),
        $viewMoreLink = $carouselWrapper.find('.js-view-more'),
        $carousel = $carouselWrapper.find('.js-carousel'),
        $tileWrapper = $carouselWrapper.find('.js-carousel-tile'),
        gridColumnClasses = $carouselWrapper.attr('data-carousel-tile-grid-classes'),
        /**
         * Initialize view more button and functionality
         *
         * @param {Object} settings - carousel settings
         */
        initViewMore = function (settings) {
            $tileWrapper.addClass(gridColumnClasses);

            disableCarousel(); // security; remove carousel mode

            // set carousel in align left mode when carousel mode is not active so tiles are aligned properly when "view-more" is used
            if (settings.enableViewMore && settings.initialVisibleTiles < $tileWrapper.length) {
                $carousel.addClass('flex-left');
            }
            else {
                $carousel.removeClass('flex-left');
            }

            if (settings.enableViewMore && settings.initialVisibleTiles < $tileWrapper.length && $carousel.attr('data-view-more') != 'open') {
                // show view more link and hide all tiles
                $viewMoreLinkWrapper.show();
                $tileWrapper.hide();

                // show initial tiles
                $tileWrapper.each(function (index, element) {
                    if (index == settings.initialVisibleTiles) {
                        return false;
                    }

                    $(element).show();
                });

                $viewMoreLink.on('click', function () {
                    $viewMoreLinkWrapper.hide();
                    $tileWrapper.show();
                    $carousel.attr('data-view-more', 'open');
                });
            }
            else if (settings.enableViewMore) {
                $viewMoreLinkWrapper.hide();
                $tileWrapper.show();
            }
            else if (settings.truncateSlideNumber) {
                $viewMoreLinkWrapper.hide();
                $tileWrapper.hide().slice(0, settings.initialVisibleTiles).show();
                $carousel.removeAttr('data-view-more');
            }
            else {
                $viewMoreLinkWrapper.hide();
                $tileWrapper.show();
                $carousel.removeAttr('data-view-more');
            }
        },

        /**
         * Get carousel settings
         *
         * @param {String} type - carousel type (large-desktop/desktop/tablet/mobile)
         * @return {Object} carousel settings
         */
        getCarouselSettings = function(type) {
            var settings = {},
                enable = true,
                forceCarouselDisplay = false,
                showDots = false,
                showArrows = false,
                swipe = true,
                enableViewMore = false,
                halfSlideMode = false,
                truncateSlideNumber = true,
                infinite = true,
                autoplay = false,
                variableWidth = false,
                adaptiveHeight = false,
                focusOnSelect = false,
                carouselRole = true,
                carouselAriaLabel = true,
                customAriaLabel = '',
                isNavigationGuide = false,
                asNavFor = '',
                slidesToShow,
                slidesToScroll,
                numberOfTilesDisplayedWhenCarouselNotUsed,
                isFallBackMode,
                rows,
                appendArrows,
                appendDots,
                slidesPerRow,
                speed,
                initialVisibleTiles = 4;

            switch (type) {
                case 'desktop-extra-large':
                    settings = JSON.parse($carousel.attr('data-desktop-extra-large-settings') || '{}');
                    // set default values
                    slidesToShow = 6;
                    initialVisibleTiles = 6;
                    break;

                case 'desktop-large':
                    settings = JSON.parse($carousel.attr('data-desktop-large-settings') || '{}');
                    // set default values
                    slidesToShow = 5;
                    initialVisibleTiles = 5;
                    break;

                case 'desktop':
                    settings = JSON.parse($carousel.attr('data-desktop-settings') || '{}');
                    // set default values
                    slidesToShow = 4;
                    initialVisibleTiles = 4;
                    break;

                case 'tablet':
                    settings = JSON.parse($carousel.attr('data-tablet-settings') || '{}');
                    // update default values
                    enable = false;
                    slidesToShow = 3;
                    initialVisibleTiles = 3;
                    break;

                case 'mobile':
                    settings = JSON.parse($carousel.attr('data-mobile-settings') || '{}');
                    // update default values
                    enable = false;
                    slidesToShow = 1;
                    showDots = true;
                    initialVisibleTiles = 4;
                    break;

                default:
                    // do nothing
            }

            // set values based on the provided settings
            isFallBackMode = !(settings && settings.hasOwnProperty('enable'));
            enable = settings && settings.hasOwnProperty('enable') ? settings.enable : enable;
            variableWidth = settings && settings.hasOwnProperty('variableWidth') ? settings.variableWidth : variableWidth;
            adaptiveHeight = settings && settings.hasOwnProperty('adaptiveHeight') ? settings.adaptiveHeight : adaptiveHeight;
            focusOnSelect = settings && settings.hasOwnProperty('focusOnSelect') ? settings.focusOnSelect : focusOnSelect;
            carouselRole = settings && settings.hasOwnProperty('carouselRole') ? settings.carouselRole : carouselRole;
            carouselAriaLabel = settings && settings.hasOwnProperty('carouselAriaLabel') ? settings.carouselAriaLabel : carouselAriaLabel;
            customAriaLabel = settings && settings.hasOwnProperty('customAriaLabel') ? settings.customAriaLabel : customAriaLabel;
            isNavigationGuide = settings && settings.hasOwnProperty('isNavigationGuide') ? settings.isNavigationGuide : isNavigationGuide;
            slidesToShow = settings && settings.hasOwnProperty('slidesToShow') ? settings.slidesToShow : slidesToShow;
            slidesToScroll = settings && settings.hasOwnProperty('slidesToScroll') ? settings.slidesToScroll : 1;
            showDots = settings && settings.hasOwnProperty('showDots') ? settings.showDots : showDots;
            showArrows = settings && settings.hasOwnProperty('showArrows') ? settings.showArrows : showArrows;
            swipe = settings && settings.hasOwnProperty('swipe') ? settings.swipe : swipe;
            enableViewMore = settings && settings.hasOwnProperty('enableViewMore') ? settings.enableViewMore : enableViewMore;
            halfSlideMode = settings && settings.hasOwnProperty('halfSlideMode') ? settings.halfSlideMode : halfSlideMode;
            forceCarouselDisplay = settings && settings.hasOwnProperty('forceCarouselDisplay') ? settings.forceCarouselDisplay : forceCarouselDisplay;
            asNavFor = settings && settings.hasOwnProperty('asNavFor') ? settings.asNavFor : asNavFor;
            speed = settings && settings.hasOwnProperty('speed') ? settings.speed : null;
            truncateSlideNumber = settings && settings.hasOwnProperty('truncateSlideNumber') ? settings.truncateSlideNumber : truncateSlideNumber;
            numberOfTilesDisplayedWhenCarouselNotUsed = settings && settings.hasOwnProperty('initialVisibleTiles') ? settings.initialVisibleTiles : initialVisibleTiles;
            infinite = settings && settings.hasOwnProperty('infinite') ? settings.infinite : infinite;
            autoplay = settings && settings.hasOwnProperty('autoplay') ? settings.autoplay : autoplay;
            rows = settings && settings.hasOwnProperty('rows') ? settings.rows : '';
            slidesPerRow = settings && settings.hasOwnProperty('slidesPerRow') ? settings.slidesPerRow : '';
            appendArrows = settings && settings.hasOwnProperty('appendArrows') ? settings.appendArrows : '';
            appendDots = settings && settings.hasOwnProperty('appendDots') ? settings.appendDots : '';

            return {
                isFallBackMode: isFallBackMode,
                enable: enable,
                slidesToShow: slidesToShow,
                slidesToScroll: slidesToScroll,
                showDots: showDots,
                showArrows: showArrows,
                swipe: swipe,
                enableViewMore: enableViewMore,
                halfSlideMode: halfSlideMode,
                forceCarouselDisplay: forceCarouselDisplay,
                asNavFor: asNavFor,
                speed: speed,
                truncateSlideNumber: truncateSlideNumber,
                initialVisibleTiles: numberOfTilesDisplayedWhenCarouselNotUsed,
                infinite: infinite,
                autoplay: autoplay,
                variableWidth: variableWidth,
                adaptiveHeight: adaptiveHeight,
                focusOnSelect: focusOnSelect,
                carouselRole: carouselRole,
                carouselAriaLabel: carouselAriaLabel,
                isNavigationGuide: isNavigationGuide,
                customAriaLabel: customAriaLabel,
                rows: rows,
                slidesPerRow: slidesPerRow,
                appendArrows: appendArrows,
                appendDots: appendDots
            };
        },

        /**
         * Initialize carousel
         *
         * @param {Object} settings - carousel settings
         */
        initCarousel = function (settings) {
            // hide view more link and show all tiles for carousel
            $viewMoreLinkWrapper.hide();
            $tileWrapper.removeClass(gridColumnClasses);
            $carousel.removeAttr('data-view-more');
            $tileWrapper.show();

            $viewMoreLink.off('click'); // security; remove view-more-link mode
            disableCarousel();

            if (settings.enable && !$carousel.hasClass('slick-initialized') && ($carousel.children().length > settings.slidesToShow || settings.forceCarouselDisplay)) {
                var carouselSettings = {
                    slidesToShow: settings.slidesToShow > 0 ? settings.slidesToShow : 1, // minimum 1 slide
                    slidesToScroll: settings.slidesToScroll,
                    dots: settings.showDots,
                    arrows: settings.showArrows,
                    swipe: settings.swipe,
                    infinite: settings.infinite,
                    autoplay: settings.autoplay,
                    variableWidth: settings.variableWidth,
                    adaptiveHeight: settings.adaptiveHeight,
                    focusOnSelect: settings.focusOnSelect,
                    carouselRole: settings.carouselRole,
                    carouselAriaLabel: settings.carouselAriaLabel,
                    customAriaLabel: settings.customAriaLabel,
                    isNavigationGuide: settings.isNavigationGuide
                };

                if (settings.rows) {
                    carouselSettings.rows = settings.rows;
                }

                if (settings.slidesPerRow) {
                    carouselSettings.slidesPerRow = settings.slidesPerRow;
                }

                if (settings.appendArrows) {
                    carouselSettings.appendArrows = settings.appendArrows;
                }

                if (settings.appendDots) {
                    carouselSettings.appendDots = settings.appendDots;
                }

                if (settings.asNavFor) {
                    carouselSettings.asNavFor = settings.asNavFor;
                }

                if (settings.speed != null) {
                    carouselSettings.speed = settings.speed;
                }

                if (settings.halfSlideMode) {
                    // halfSlideModeSlidesToShow is initial slidesToShow 
                    var halfSlideModeSlidesToShow = 2;

                    // force infinite to render halfSlideModee carousel correctly
                    carouselSettings.infinite = true;

                    // minimum slidesToShow should be 2 to render halfSlideMode correctly
                    if (settings.slidesToShow > 1) {
                        halfSlideModeSlidesToShow = settings.slidesToShow;
                    }

                    // half-visible slides on both side leads to -1 slides in reachable slides area
                    // carouselSettings.slidesToShow is adjusted slidesToShow
                    carouselSettings.slidesToShow = halfSlideModeSlidesToShow - 1;

                    // halfSlideMode carousel:
                    // case 1: adjusted slidesToShow is odd
                    //         infinite = true;
                    //         centerPadding = space of 1 half-slide on both side
                    //         centerMode = true;
                    // case 2: adjusted slidesToShow is even
                    //         infinite = true;
                    //         centerPadding = space of 1 half-slide on both side
                    //         no centerMode

                    // centerMode enables centered view with odd numbered slidesToShow counts (case 1)
                    if (carouselSettings.slidesToShow % 2 === 1) {
                        carouselSettings.centerMode = true;
                    } 

                    // equal space of 2 half-slides on both side
                    carouselSettings.centerPadding = (100 / halfSlideModeSlidesToShow / 2) + '%';
                }

                $carousel.slick(carouselSettings);

                if (settings.showArrows && !settings.halfSlideMode) {
                    $carousel.addClass('carousel-with-arrows');
                }

                if (settings.halfSlideMode) {
                    $carousel.addClass('half-carousel-mode');
                }
            }
        },

        /**
         * Disable carousel
         */
        disableCarousel = function () {
            if ($carousel.hasClass('slick-initialized')) {
                $carousel.slick('unslick').removeClass('carousel-with-arrows half-carousel-mode');
            }
        };

    this.$carousel = $carousel;
    this.$viewMoreLink = $viewMoreLink;
    this.initViewMore = initViewMore;
    this.getCarouselSettings = getCarouselSettings;
    this.initCarousel = initCarousel;
    this.disableCarousel = disableCarousel;
};

var carousel = {
    /**
     * Create carousel based on found settings
     *
     * @param {jQuery} $carouselWrapper
     */
    productCarousel: function ($carouselWrapper) {
        var carouselHelper = new CarouselHelper($carouselWrapper),
            desktopExtraLargeSettings = carouselHelper.getCarouselSettings('desktop-extra-large'),
            desktopLargeSettings = carouselHelper.getCarouselSettings('desktop-large'),
            desktopSettings = carouselHelper.getCarouselSettings('desktop'),
            tabletSettings = carouselHelper.getCarouselSettings('tablet'),
            mobileSettings = carouselHelper.getCarouselSettings('mobile');

        if (carouselHelper.$carousel.hasClass('slick-initialized')) {
            // don't execute any of the remaining calls as the carousel is already initialized
            // this can be the case when .carousel.init() is called multiple times
            return;
        }

        // if extra desktop-extra-large settings is not set then use desktop-large settings values
        if (desktopExtraLargeSettings.isFallBackMode) {
            // make a copy of the settings
            desktopExtraLargeSettings = JSON.parse(JSON.stringify(desktopLargeSettings));
        }

        window.vbqUtils.breakpoint.set([
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('mobile'),
                enter: function () {
                    // if carousel is enabled then initialize it, else initialize view more functionality
                    if (mobileSettings.enable) {
                        carouselHelper.initCarousel(mobileSettings);
                    }
                    else {
                        carouselHelper.initViewMore(mobileSettings);
                    }
                }
            },
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('tablet'),
                enter: function () {
                    // if carousel is enabled then initialize it, else initialize view more functionality
                    if (tabletSettings.enable) {
                        carouselHelper.initCarousel(tabletSettings);
                    }
                    else {
                        carouselHelper.initViewMore(tabletSettings);
                    }
                }
            },
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('desktop'),
                enter: function () {
                    // if carousel is enabled then initialize it, else initialize view more functionality
                    if (desktopSettings.enable) {
                        carouselHelper.initCarousel(desktopSettings);
                    }
                    else {
                        carouselHelper.initViewMore(desktopSettings);
                    }
                }
            },
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('desktopLarge'),
                enter: function () {
                    // if carousel is enabled then initialize it, else initialize view more functionality
                    if (desktopLargeSettings.enable) {
                        carouselHelper.initCarousel(desktopLargeSettings);
                    }
                    else {
                        carouselHelper.initViewMore(desktopLargeSettings);
                    }
                }
            },
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('desktopExtraLarge'),
                enter: function () {
                    // if carousel is enabled then initialize it, else initialize view more functionality
                    if (desktopExtraLargeSettings.enable) {
                        carouselHelper.initCarousel(desktopExtraLargeSettings);
                    }
                    else {
                        carouselHelper.initViewMore(desktopExtraLargeSettings);
                    }
                }
            }
        ]);

        carouselHelper.$carousel.find('.lazyload').each(function (index, el) {
            // SRM22-18 - Improve loading time on mobile on the HP
            if (window.vbqUtils.isSR) {
                // force load of visible lazy image only
                if ($(el).is(':visible')) {
                    window.lazySizes.loader.unveil(el);
                }
            }
            else {
                window.lazySizes.loader.unveil(el);
            }
        });

        window.vbqUtils.cache.$window.resize(_debounce(function () {
            if (carouselHelper.$carousel.hasClass('slick-initialized')) {
                carouselHelper.$carousel.slick('setPosition');
            }
        }, 250));
    },

    /**
     * Initialize found carousels
     */
    init: function () {
        var that = this;
        $('.js-carousel-wrapper').each(function () {
            // initialize carousel for each individual carousel wrappers
            that.productCarousel($(this));
        });
    }
};

module.exports = carousel;
