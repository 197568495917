/* datepicker.js is working on virtual shopping pages only for now
   datepicker.js is required the next plugins from vslib.min.js (pt_virtualshopping.isml) to work:
    - flatpickr.min.js + locales: de.js, es.js, fr.js, it.js, zh.js
    - moment.min.js',
    - moment-timezone-with-data.min.js'
*/

'use strict';

/* global flatpickr moment (on pages with pt_virtualshopping decorator) */

var accessibility = require('./accessibility');
var dCache = {},
    selectedDateTime = {
        date: '',
        timezone: {
            value: '',
            abbr: '',
            gmt: ''
        },
        time: ''
    },
    disabledDates = [];

/**
 * Get selected date, default to current date
 */
function getSelectedDate() {
    var dateString = dCache.timezones.$date.val(),
        date = dateString ? new Date(dateString) : new Date();

    return date;
}

/**
 * Update selected timezone
 * @param {String} timezone - selected timezone
 */
function updateSelectedTimezone(timezone) {
    if (!timezone) {
        return;
    }

    var abbr = window.moment.tz.zone(timezone).abbr(getSelectedDate());

    dCache.timezones.$selected.html(timezone + ' (' + abbr + ')');

    // update dateData object
    selectedDateTime.timezone.value = timezone;
    selectedDateTime.timezone.abbr = abbr;
    selectedDateTime.timezone.gmt = 'GMT ' + window.moment.tz(timezone).format('Z');
}

/**
 * Show local timezone
 */
function showLocalTimezone() {
    var localTimezone = window.moment.tz.guess(), // Europe/Brussels
        timeZoneAbbr = window.moment.tz.zone(localTimezone).abbr(new Date()), // CEST
        localDateTime = window.moment().format('dddd MMMM Do YYYY') + ' (' + timeZoneAbbr + ')'; // Tuesday May 19th 2020 (CEST)

    dCache.timezones.$current.html(localDateTime);

    // show local timezone as selected one
    updateSelectedTimezone(localTimezone);
}

/**
 * Hide timezone list drop down selection
 */
function hideTimezonesList() {
    dCache.timezones.$selectedWrapper.removeClass('hidden');
    dCache.timezones.$changeSection.addClass('hidden');
    dCache.timezones.$buttonChange.focus();
}

/**
 * Show timezone list selection
 */
function showTimezonesList() {
    dCache.timezones.$selectedWrapper.addClass('hidden');
    dCache.timezones.$changeSection.removeClass('hidden');
    dCache.timezones.$wrapper.find('.js-timezones-list').focus();
}

/**
 * Select time value
 * @param {jQuery} $time - element to select
 */
function selectTime($time) {
    if (!$time || $time.length === 0) {
        return;
    }

    // update dateData object
    selectedDateTime.time = ($time.html() || '').trim();
}

/**
 * Update selected date
 * @param {String} dateString
 */
function selectDate(dateString) {
    var date = dateString ? dateString : dCache.timezones.$date.val();
    selectedDateTime.date = date;
}

/**
 * Add a select dropdown with a list with all timezones
 */
function addTimezonesList() {
    var timezonesList = window.moment.tz.names(),
        $selectTemplate = dCache.timezones.$template,
        optionTemplate = '<option value="{VALUE}" {SELECTED}>{LABEL}</option>',
        localTimezone = window.moment.tz.guess(),
        options = [];

    for (var i = 0; i < timezonesList.length; i++) {
        var timezone = timezonesList[i];

        // don't include in the list the values that directly use the GMT timezone
        // POSIX compatibility requires that the offsets are inverted. Therefore, Etc/GMT-X will have an offset of +X and Etc/GMT+X will have an offset of -X.
        // The Europe/Madrid indentifer should be used instead of the Etc/GMT+1 identifier.
        if (timezone.toLowerCase().includes('gmt')) {
            continue;
        }
        var timezoneGMT = timezone + ' GMT ' + window.moment.tz(timezone).format('Z'),
            currentOption = timezone == localTimezone ? optionTemplate.replace('{SELECTED}', 'selected') : optionTemplate.replace('{SELECTED}', '');

        options.push(currentOption.replace('{VALUE}', timezone).replace('{LABEL}', timezoneGMT));
    }

    if (dCache.timezones.$wrapper.length && $selectTemplate.length) {
        dCache.timezones.$wrapper.html($selectTemplate.replace('{{OPTIONS}}', options.join('')));
    }
}

/**
 * Hide timezone list drop down selection event
 * @param {Object} e - event object
 */
function hideTimezonesListEvent(e) {
    e.preventDefault();

    hideTimezonesList();
}

/**
 * Show timezone list event
 * @param {Object} e - event object
 */
function showTimezonesListEvent(e) {
    e.preventDefault();

    showTimezonesList();
}

/**
 * Update selected timezone event
 * @param {Object} e - event object
 */
function updateSelectedTimezoneEvent(e) {
    e.preventDefault();

    var selectedTimezone = $(e.target).val();
    updateSelectedTimezone(selectedTimezone);
    hideTimezonesList();
}

/**
 * Select time event
 * @param {Object} e - event object
 */
function selectTimeEvent(e) {
    e.preventDefault();

    selectTime(dCache.timezones.$time.filter('.selected'));
}

/**
 * Select date event
 * @param {Object} e - event object
 */
function selectDateEvent(e) {
    e.preventDefault();

    // update dateData object
    selectDate($(e.target).val());
}

/**
 * Initialize datepicker dom elements
 */
function initializeDom() {
    dCache.$document = window.vbqUtils.cache.$document;
    dCache.$datepicker = dCache.$document.find('#datepicker');
    dCache.timezones = {
        $wrapper: dCache.$document.find('.js-timezones-list-wrapper'),
        $template: dCache.$document.find('#js-timezonelist-template').html(),
        $changeSection: dCache.$document.find('.js-timezones-change-wrapper'),
        $selectedWrapper: dCache.$document.find('.js-selected-timezone-wrapper'),
        $selected: dCache.$document.find('.js-selected-timezone'),
        $current: dCache.$document.find('.js-current-timezone'),
        $buttonChange: dCache.$document.find('.js-timezone-change'),
        $buttonCancel: dCache.$document.find('.js-timezone-cancel'),
        $timeContainer: dCache.$document.find('.js-time-wrapper'),
        $time: dCache.$document.find('.js-time-select'),
        $date: dCache.$document.find('#datepicker')
    };

    var datepickerDisabledDates = dCache.$datepicker.attr('data-disabled-weekdays') || '[]';
    disabledDates = JSON.parse(datepickerDisabledDates);
}

/**
 * Initialize datepicker events
 */
function initializeEvents() {
    // Init fake radio groups according to the ADA Compliance: time radio group
    accessibility.fakeRadioGroupInit(dCache.timezones.$timeContainer);

    dCache.timezones.$buttonChange.on('click', showTimezonesListEvent);
    dCache.timezones.$buttonCancel.on('click', hideTimezonesListEvent);
    dCache.timezones.$wrapper.on('change', '.js-timezones-list', updateSelectedTimezoneEvent);
    dCache.timezones.$time.on('click', selectTimeEvent);
    dCache.timezones.$date.on('change', selectDateEvent);
}

module.exports = {
    /**
     * Init datepicker
     */
    init: function () {
        initializeDom();
        addTimezonesList();
        showLocalTimezone();
        initializeEvents();
        window.flatpickr('#datepicker', {
            inline: true,
            minDate: new Date(),
            defaultDate: new Date(),
            locale: dCache.$datepicker.attr('data-calendar-locale') != 'en' ? dCache.$datepicker.attr('data-calendar-locale') : 'default',
            disable: [
                function (date) {
                    return disabledDates.indexOf(date.getDay()) > -1;
                }
            ],
            customMode: dCache.$datepicker.attr('data-custom-mode'), // custom config
            onReady: function(selectedDates, dateStr, instance) {
                // fix calendar keyboard accessibility if none-input mode
                if (instance.config.customMode == 'none-input') {
                    instance.prevMonthNav.setAttribute('tabindex', '0');
                    instance.prevMonthNav.setAttribute('role', 'button');
                    instance.nextMonthNav.setAttribute('tabindex', '0');
                    instance.nextMonthNav.setAttribute('role', 'button');
                    instance.monthsDropdownContainer.setAttribute('tabindex', '0');
                    instance.currentYearElement.setAttribute('tabindex', '0');
                }
            },
            onChange: function(selectedDates, dateStr, instance) {
                // keep focus on selected date after selection
                if (instance.config.customMode == 'none-input') {
                    instance.daysContainer.querySelector('.flatpickr-day.selected').focus();
                }
            },
            onMonthChange: function(selectedDates, dateStr, instance) {
                if (instance.config.customMode == 'none-input') {
                    instance.monthsDropdownContainer.setAttribute('tabindex', '0');
                }
            },
            onKeyDown: function(selectedDates, dateStr, instance, e) {
                if (instance.config.customMode == 'none-input') {
                    // 9  - tab
                    // 13 - enter
                    // 32 - space
                    // 38 - arrow up
                    // 40 - arrow down

                    // keyboard accessibility on prev month nav
                    if ((e.keyCode == 13 || e.keyCode == 32) && e.target == instance.prevMonthNav) {
                        e.preventDefault();
                        instance.changeMonth(-1);
                    }

                    // keyboard accessibility on next month nav
                    if ((e.keyCode == 13 || e.keyCode == 32) && e.target == instance.nextMonthNav) {
                        e.preventDefault();
                        instance.changeMonth(1);
                    }

                    // keyboard accessibility on calendar days
                    if (e.keyCode == 40 && (e.target == instance.prevMonthNav || e.target == instance.nextMonthNav)) {
                        instance.daysContainer.querySelector('.flatpickr-day:not(.prevMonthDay):not(.flatpickr-disabled)').focus();
                    }

                    // keyboard accessibility on calendar panel
                    if (e.keyCode == 9 && e.shiftKey && e.target.classList.value.indexOf('flatpickr-day') != -1) {
                        instance.nextMonthNav.focus();
                    }
                }
            }
        });
        selectTime(dCache.timezones.$time.filter('.selected'));
        selectDate();
    },

    /**
     * Get selected date and time
     * @return {Object} dateData
     */
    getSelectedDateTime: function () {
        return selectedDateTime;
    }
};
