'use strict';

var cache = {};

/**
 * Initializes the cache for beach slider elements
 */
function initCache() {
    cache.$document = $(document);
    cache.$beachSlider = cache.$document.find('.js-beach_carousel');
    cache.$secondNavigationButton = cache.$beachSlider.find('.js-city-navigation_button');
}

/**
 * Handles the click action on the beach slider tiles
 * @param {Event} event - the click event object
 * @returns {void}
 */
function onClickAction(event) {
    event.preventDefault();

    var $curent = $(event.currentTarget),
        // index value should be the same as the tile inded in the carousel
        index = $curent.data('index');

    cache.$beachSlider.slick('slickGoTo', index);
}

/**
 * Initializes the events for the beach slider page design
 */
function initEvents() {
    cache.$secondNavigationButton.on('click', onClickAction);
}

module.exports = {
    init: function () {
        initCache();

        // if the beach slider is not present on the page, do not initialize the events
        if (cache.$beachSlider.length === 0) {
            return;
        }

        initEvents();
    }
};
