// ERB-52 International Shipping

'use strict';

var progress = require('../progress');

window.vbq = window.vbq || {};

var start = function () {
    var $contextChooserForm = $('#js-contextChooserForm');

    if ($contextChooserForm.length) {
        var redirect = false,
            url = null,
            validating = false,
            $contextCountry = $('#js-contextCountry'),
            $contextCurrency = $('#js-contextCurrency'),
            $globalError = $('.js-globalError'),
            $unsupportedCurrency = $('.js-unsupportedCurrency'),
            $successMessage = $('#js-successMessage'),
            $internationalShippingDetailsSpecialCountries = $('#js-internationalShippingDetails-specialCountries'),
            $internationalShippingDetailsOtherCountries = $('#js-internationalShippingDetails-otherCountries'),
            $contextChooserSubmitButton = $('#js-contextChooserSubmitButton'),
            $currenciesClone = $contextCurrency.clone();

        //toggle international shipping description paragraph depending on the chosen country
        var _displayDetails = function (countryCode) {
            if (countryCode === '') {
                $internationalShippingDetailsSpecialCountries.addClass('hidden');
                $internationalShippingDetailsOtherCountries.addClass('hidden');
            }
            else if (window.vbq.resources.domesticCountryCodes.indexOf(countryCode) == -1) {
                // for these countries the price book used contains taxes and the Zonos taxe computation will not be used (ish)
                // SR-28 - Remove countries check for SR site
                if (!window.vbqUtils.isSR) {
                    if (countryCode == 'CH' || countryCode == 'LI' || countryCode == 'GB' || countryCode == 'JE' || countryCode == 'GG' || countryCode == 'MX' || countryCode == 'JP') {
                        $internationalShippingDetailsSpecialCountries.removeClass('hidden');
                        $internationalShippingDetailsOtherCountries.addClass('hidden');
                    }
                    else {
                        $internationalShippingDetailsSpecialCountries.addClass('hidden');
                        $internationalShippingDetailsOtherCountries.removeClass('hidden');
                    }
                }
                // SR-28 - Add correct behaviour for SR site
                else if (window.vbqUtils.isSR) {
                    $internationalShippingDetailsSpecialCountries.addClass('hidden');
                    $internationalShippingDetailsOtherCountries.removeClass('hidden');
                }
            }
            else {
                $internationalShippingDetailsSpecialCountries.addClass('hidden');
                $internationalShippingDetailsOtherCountries.addClass('hidden');
            }
        };

        //update display, done here to avoid cache issue if done in ISML
        _displayDetails($contextCountry.val());

        //country change listener
        $contextCountry.on('change', function () {
            $globalError.addClass('hidden');
            $unsupportedCurrency.addClass('hidden');

            var countryCode = $contextCountry.val();
            _displayDetails(countryCode);

            if (countryCode === '') {
                $contextCurrency.val('').prop('disabled', false).closest('.gl_form-group').removeClass('.gl_has-success');

                return;
            }
            else {
                $contextCountry.closest('.form-group').removeClass('has-error')
                    .find('.help-block').addClass('hidden');
            }

            $contextCurrency.prop('disabled', false).closest('.gl_form-group').removeClass('.gl_has-success');

            if (window.vbq.countryCurrencyMapping
                && window.vbq.countryCurrencyMapping.hasOwnProperty(countryCode)
            ) {
                var currencyCodes = window.vbq.countryCurrencyMapping[countryCode],
                    currencies = '';
                $currenciesClone.find('option').each(function () {
                    var $this = $(this);
                    if ($.inArray($this.val(), currencyCodes) != -1) {
                        $this.show();
                        currencies += $this[0].outerHTML;
                    }
                });
                $contextCurrency.html(currencies);

                $contextCurrency.val(currencyCodes[0]).trigger('change');

                // only one possible currency -> disable the select
                if (currencyCodes.length === 1) {
                    $contextCurrency.prop('disabled', true);
                }
            }
            else {
                $contextCurrency.val('');
            }
        });

        $contextCurrency.on('change', function () {
            $globalError.addClass('hidden');
            $unsupportedCurrency.addClass('hidden');

            var currencyCode = $contextCurrency.val();
            if (currencyCode !== '') {
                $contextCurrency.closest('.form-group').removeClass('has-error')
                    .find('.help-block').addClass('hidden');
            }
        });

        $contextChooserForm.on('submit', function (e) {
            e.preventDefault();
            redirect = false;
            url = null;

            if (validating) {
                return false;
            }

            validating = true;
            $globalError.addClass('hidden');
            $unsupportedCurrency.addClass('hidden');
            $successMessage.addClass('hidden');

            var countryCode = $contextCountry.val(),
                currencyCode = $contextCurrency.val(),
                isValid = true;

            if (countryCode === '') {
                $contextCountry.closest('.form-group').addClass('has-error')
                    .find('.help-block').addClass('hidden').filter('.js-missing').removeClass('hidden');
                isValid = false;
            }

            if (currencyCode === null || currencyCode === '') {
                $contextCurrency.closest('.form-group').addClass('has-error')
                    .find('.help-block').addClass('hidden').filter('.js-missing').removeClass('hidden');
                isValid = false;
            }

            if (!isValid) {
                return false;
            }

            // ECM20-174 - Ecom Turkey - context Chooser change
            // SR-30 - Remove Turkey redirection for SR site
            if (!window.vbqUtils.isSR && countryCode == 'TR') {
                var $link = $('<a href="https://www.vilebrequin.com.tr" class="hidden" target="_blank"></a>');
                window.vbqUtils.cache.$body.append($link);
                $link[0].click();
                $link.remove();
                return;
            }

            progress.show($contextChooserSubmitButton.attr('disabled', 'disabled'));

            // ECM-987 - Russia entry in context chooser
            // SR-30 - Remove Russia redirection for SR site
            if (!window.vbqUtils.isSR && countryCode == 'RU') {
                window.location = 'https://vilebrequin.ru';
                return;
            }

            //use the UpdateSession URL to force update if user already had a previous session on target site
            //US site, chosen country not managed by it -> redirect on EU site
            // SR-30 - Remove USA redirection for SR site
            if (!window.vbqUtils.isSR) {
                if (window.vbq.resources.currentSite.indexOf('US') != -1
                    && $.inArray(countryCode, window.vbq.resources.US_CountryCodes) == -1
                ) {
                    redirect = true;
                    url = window.Urls.EU_updateSessionURL;
                }
                // EU site, chosen country is managed by US site -> redirect on US site
                else if (window.vbq.resources.currentSite.indexOf('EU') != -1
                    && $.inArray(countryCode, window.vbq.resources.US_CountryCodes) != -1
                ) {
                    redirect = true;
                    url = window.Urls.US_updateSessionURL;
                }
            }

            if (redirect === true) {
                window.location = url + '?country=' + countryCode + '&currency=' + currencyCode;

            }
            else {
                $.ajax({
                    url: $contextChooserForm.attr('action'),
                    //form.serialize() will not work with disabled fields, also not needed in this case
                    data: 'country=' + countryCode + '&currency=' + currencyCode + '&format=ajax',
                    type: 'POST',
                    timeout: 3000,
                    success: function (data) {
                        if (data && data.success === true) {
                            $successMessage.removeClass('hidden');
                            var redirectLocation = window.vbq.homeURL;

                            // ECM-999 - Chekout - Country field
                            // if there is a countryChooserRedirectUrl cookie then redirect to that url
                            var redirectUrl = window.vbqUtils.getCookie('ccr');

                            if (redirectUrl) {
                                // delete cookie
                                document.cookie = 'ccr=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';

                                // redirect to new location
                                redirectLocation = redirectUrl;
                            }

                            window.setTimeout(function () {
                                window.location = redirectLocation;
                            }, 1000);
                        }
                        else if (data.error) {
                            $contextCurrency.val(data.error);
                            $unsupportedCurrency.removeClass('hidden');
                            $contextCurrency.attr('aria-describedby', $unsupportedCurrency.attr('id'));
                            window.setTimeout(function () {
                                window.location.reload();
                            }, 1000);
                        }
                        else {
                            progress.hide($contextChooserSubmitButton.removeAttr('disabled', 'disabled'));
                            $globalError.removeClass('hidden');
                            $contextCountry.attr('aria-describedby', $globalError.attr('id'));
                            validating = false;
                        }
                    },
                    error: function () {
                        progress.hide($contextChooserSubmitButton.removeAttr('disabled', 'disabled'));
                        $globalError.removeClass('hidden');
                        $contextCountry.attr('aria-describedby', $globalError.attr('id'));
                        validating = false;
                    }
                });
            }
        });

        //set current session values
        var countryCode = $contextCountry.val(),
            currencyCode = $contextCurrency.val();
        if (countryCode === ''
            && currencyCode === ''
            && window.vbq
            && window.vbq.localizationData
            && window.vbq.localizationData.countryCode
            && window.vbq.localizationData.countryCode != 'not_supported'
            && window.vbq.localizationData.currencyCode
        ) {
            $contextCountry.val(window.vbq.localizationData.countryCode).trigger('change');
            $contextCurrency.val(window.vbq.localizationData.currencyCode);
        }
        else {
            $contextCountry.val('').trigger('change');
            $contextCurrency.val('');
        }
    }
};

var modal = function () {
    var $contextChooserModal = $('#js-contextChooserModal');

    if ($contextChooserModal.length > 0) {
        $contextChooserModal.dialog({
            autoOpen: false,
            draggable: false,
            modal: true,
            resizable: false,
            width: '800',
            closeOnEscape: true, // EADA-34 - All modal can be closing on escape key: .dialog() case
            closeText: window.Resources.CLOSE,
            classes: {
                'ui-dialog': 'contextChooserDialog' //used to force styles
            },
            open: function (e) {
                // EADA-40 - The modal container is not labeled as such: .dialog() case
                window.vbqUtils.jqDialogOpenAdjust($(e.target).closest('.ui-dialog'));
            }
        });

        $contextChooserModal.show();

        $contextChooserModal.find('#js-close-contextchooser').on('click', function () {
            $contextChooserModal.dialog('close');
        });

        start();

        $contextChooserModal.dialog('open');
    }
};

var contextChooser = {
    init: function () {
        start();
    },
    showModal: function () {
        modal();
    }
};

module.exports = contextChooser;
