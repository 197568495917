'use strict';

var cache = {};

/**
 * Parse json string
 * @param {String} jsonString - json string to parse
 * @return {Object}
 */
function parseJson(jsonString) {
    try {
        return JSON.parse(jsonString);
    }
    catch (e) {
        return {};
    }
}

/**
 * Init cache
 */
function initCache() {
    cache.$form = $('form.js-checkout-shipping');
    cache.defaultValuesString = cache.$form.attr('data-default-values');
    cache.defaultValues = parseJson(cache.defaultValuesString);
    cache.$countryElement = cache.$form.find('[name=dwfrm_singleshipping_shippingAddress_addressFields_country');
    cache.$phoneElement = cache.$form.find('#dwfrm_singleshipping_shippingAddress_addressFields_phone');
}

/**
 * Init events
 */
function initEvents() {
    $('.js-reset-address').on('click', function () {
        // udpate form fields, inclusive phone number value
        Object.keys(cache.defaultValues).forEach(function (key) {
            cache.$form.find('[name*=_' + key + ']').val(cache.defaultValues[key] || "").trigger('change'); // trigger needed for the floating label logic
        });

        // save phone number as it will be changed by the setCountry() method
        var phoneNumber = cache.$phoneElement.val();
        // update phone country dropdown
        var intlPhone = window.intlTelInputGlobals.getInstance(cache.$phoneElement.get(0));
        intlPhone.setCountry(cache.$countryElement.val());
        // update/format number using intltel style
        intlPhone.setNumber(phoneNumber);
    });
}

exports.init = function () {
    initCache();
    initEvents();
};
