'use strict';

var cookieHelper = {
    /**
     * Set cookie
     *
     * @param {string} key - The cookie name
     * @param {string} value - The cookie value
     * @param {integer} minutes - The number of minutes to expire the cookie
    */
    setCookie: function (key, value, minutes) {
        var date = new Date();
        date.setTime(date + (minutes * 60 * 1000));

        document.cookie = key + '=' + value + ';expires=' + date.toGMTString() + '; path=/;secure;SameSite=Strict';
    },

    /**
     * Get cookie
     *
     * @param {String} key - The cookie name
     * @returns {String} cookie value
     */
    getCookie: function (key) {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var tokens = cookies[i].split('=');
            var cookieKey = tokens[0].trim();
            if (cookieKey === key) {
                return tokens[1];
            }
        }
        return null;
    }
};

module.exports = cookieHelper;