'use strict';

/**
 * ERB-52 - International Shipping - Manage price display for international shipping
 */
var _canConvert = false,
    _country = null,
    _currencyCode = null,
    _conversionRate = null,
    _lcpMultiplier = null,
    _locale = null;

window.vbq = window.vbq || {};

var refresh = function () {
    if (_canConvert) {
        $('.js-priceDisplay').each(function () {
            var $this = $(this),
                priceValue = parseFloat($this.attr('data-priceValue')),
                convertedPrice = null,
                formattedPrice = null;

            if (_lcpMultiplier !== null && !$this.hasClass('isShippingCost') && !$this.hasClass('isGiftCertificateAmount')) { //"premium" multiplier, but not for shipping cost and GC amount
                priceValue = (priceValue !== null && !isNaN(priceValue) && priceValue >= 0) ? (priceValue * _lcpMultiplier) : -1;
            }

            // for GC amount use only the priceValue
            if ($this.hasClass('isGiftCertificateAmount')) {
                convertedPrice = priceValue;
            }
            else {
                convertedPrice = (priceValue !== null && !isNaN(priceValue) && priceValue >= 0) ? (priceValue * _conversionRate) : -1;
            }

            if (!isNaN(convertedPrice) && convertedPrice >= 0) {
                if (formattedPrice === null || formattedPrice === '') {
                    formattedPrice = new Intl.NumberFormat(_locale.replace('_', '-'), {style: 'currency', currency: _currencyCode}).format(convertedPrice);
                }

                if (formattedPrice !== null && formattedPrice !== '') {
                    $this.html(formattedPrice);
                }
            }

            $this.removeClass('price-invisible js-priceDisplay');
        });

        $('.js-convertedPriceDisplay').each(function () {
            var $this = $(this),
                priceValue = parseFloat($this.attr('data-priceValue'));
            if (!isNaN(priceValue)) {
                priceValue = new Intl.NumberFormat(_locale.replace('_', '-'), {style: 'currency', currency: _currencyCode}).format(priceValue);
                $this.html(priceValue);
            }

            $this.removeClass('price-invisible js-convertedPriceDisplay');
        });

        // this check is done for prices rendered with "disaplaymixedprice.isml" template
        // this template is needed to show the "total" basket amount on cart page and / or checkout sidebar
        // when order has regular and GC products and has international shipping this template show the corrected total amount based on products amount, shipment and GC products, each with the corrected converted value.
        $('.js-mixedPriceDisplay').each(function () {
            var $this = $(this),
                priceValue = parseFloat($this.attr('data-priceValue')),
                gcPriceValue = parseFloat($this.attr('data-gcPriceValue')),
                shipPriceValue = parseFloat($this.attr('data-shipPriceValue')),
                shipConvertedPrice = null,
                convertedPrice = null,
                formattedPrice = null;

            if (_lcpMultiplier) { //"premium" multiplier
                priceValue = (priceValue !== null && !isNaN(priceValue) && priceValue >= 0) ? (priceValue * _lcpMultiplier) : -1;
            }

            convertedPrice = (priceValue !== null && !isNaN(priceValue) && priceValue >= 0) ? (priceValue * _conversionRate) : -1;

            shipConvertedPrice = (shipPriceValue && !isNaN(shipPriceValue) && shipPriceValue >= 0) ? (shipPriceValue * _conversionRate) : 0;

            // add shipping price if any
            convertedPrice += shipConvertedPrice;

            // add gc price
            convertedPrice += gcPriceValue;

            if (!isNaN(convertedPrice) && convertedPrice >= 0) {
                if (formattedPrice === null || formattedPrice === '') {
                    formattedPrice = new Intl.NumberFormat(_locale.replace('_', '-'), {style: 'currency', currency: _currencyCode}).format(convertedPrice);
                }

                if (formattedPrice !== null && formattedPrice !== '') {
                    $this.html(formattedPrice);
                }
            }

            $this.removeClass('price-invisible js-mixedPriceDisplay');
        });
    }
    else if ($('.js-forcedPriceDisplay').length) {
        $('.js-forcedPriceDisplay').each(function () {
            var $this = $(this),
                priceValue = parseFloat($this.attr('data-priceValue'));
            if (!isNaN(priceValue)) {
                priceValue = new Intl.NumberFormat(_locale.replace('_', '-'), {style: 'currency', currency: _currencyCode}).format(priceValue);
                $this.html(priceValue);
            }

            $this.removeClass('price-invisible js-priceDisplay js-forcedPriceDisplay');
        });
    }
    else {
        $('.js-priceDisplay').removeClass('price-invisible');
        $('.js-mixedPriceDisplay').removeClass('price-invisible');
        $('.js-convertedPriceDisplay').removeClass('price-invisible');
    }
};

var _checkLocalizationData = function () {
    //check country two letters ISO code
    if (window.vbq.localizationData.countryCode === null
        || typeof window.vbq.localizationData.countryCode != 'string'
        || window.vbq.localizationData.countryCode.length != 2
    ) {
        return false;
    }

    //check language ISO code
    if (window.vbq.localizationData.languageCode === null
        || typeof window.vbq.localizationData.languageCode != 'string'
        // With Borderfree the languageCode was 2 characters (fr), now it is 5 (fr_FR), keep the 2 length case as a security, both will work with Intl.NumberFormat
        || (window.vbq.localizationData.languageCode.length != 5 && window.vbq.localizationData.languageCode.length != 2)
    ) {
        return false;
    }

    //check currency tree letters ISO code
    if (window.vbq.localizationData.currencyCode === null
        || typeof window.vbq.localizationData.currencyCode != 'string'
        || window.vbq.localizationData.currencyCode.length != 3
    ) {
        return false;
    }

    //check rate value
    if (window.vbq.localizationData.conversionRate === null
        || typeof window.vbq.localizationData.conversionRate != 'number'
        || isNaN(window.vbq.localizationData.conversionRate)
        || window.vbq.localizationData.conversionRate <= 0
    ) {
        return false;
    }

    return true;
};

var init = function () {
    if ($('.js-priceDisplay').length) {
        /* usePriceConverter will be false for:
            - ship to US (USD)
            - ship to EU country with EUR currency selected (forced or not)
            - ship to UK (GBP), CH (CHF), SG (SGD), HK (HKD), MC (MOP), AU (AUD), CA (CAD), MX (MXN)
            - ship to any country with currency EUR
        */

        _locale = window.vbq.localizationData.languageCode;
        _currencyCode = window.vbq.localizationData.currencyCode;

        if (window.vbq.usePriceConverter === true && window.vbq.localizationData !== null) {
            _canConvert = _checkLocalizationData();

            if (_canConvert) {
                _country = window.vbq.localizationData.countryCode;
                _conversionRate = window.vbq.localizationData.conversionRate;

                if (window.vbq.localizationData.lcpMultiplier) {
                    _lcpMultiplier = window.vbq.localizationData.lcpMultiplier;
                }
            }
        }

        refresh();
    }
    else if ($('.js-convertedPriceDisplay').length) {
        _canConvert = _checkLocalizationData();
        if (_canConvert) {
            _locale = window.vbq.localizationData.languageCode;
            _currencyCode = window.vbq.localizationData.currencyCode;
        }

        refresh();
    }
};

exports.init = init;
exports.refresh = refresh;
