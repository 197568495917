'use strict';

/**
 * Update dob field
 * @param {jQuery Object} $dobDay 
 * @param {jQuery Object} $dobMonth 
 * @param {jQuery Object} $dobYear 
 * @param {jQuery Object} $dob 
 */
function manageDoB($dobDay, $dobMonth, $dobYear, $dob) {
    var day = $dobDay.val(),
        month = $dobMonth.val(),
        year = $dobYear.val();

    if (day !== null && day !== '' && month !== null && month !== '' && year !== null && year !== '') {
        $dob.val(month + '/' + day + '/' + year);
    } else {
        $dob.val('');
    }
}

/**
 * Update dob fields based on the selected country
 * @param {jQuery Object} $country - country field
 * @param {Object} data - object with jQuery elements
 * $country: jQuery country field
 * $dobDay: jQuery dob day field
 * $dobMonth: jQuery dob month field
 * $dobYear: jQuery dob year field
 * $dobLabel: jQuery dob label field
 */
function updateDobFields(data) {
    var $country = data.$country,
        $dobDay = data.$dobDay,
        $dobMonth = data.$dobMonth,
        $dobYear = data.$dobYear,
        $dobLabel = data.$dobLabel,
        country = $country.val(),
        optional = window.Resources.OPTIONAL;

    if (country == 'US') {
        $dobDay.prop('required', true);
        $dobMonth.prop('required', true);
        $dobYear.prop('required', true);
        $dobLabel.text(function (i, t) {return t.replace('(' + optional + ')', '*');});
    }
    else {
        $dobDay.prop('required', false);
        $dobMonth.prop('required', false);
        $dobYear.prop('required', false);
        $dobLabel.text(function (i, t) {return t.replace('*',' (' + optional + ')');});
    }
}

/**
 * Init dob validation
 */
function init() {
    var $registrationForm = $('#RegistrationForm'),
        $dob,
        $dobDay,
        $dobMonth,
        $dobYear,
        $dobLabel,
        $country,
        date;

    // if the form was not found don't run rest of the code
    if (!$registrationForm.length) {
        return;
    }

    $dob = $registrationForm.find('#dwfrm_profile_customer_birthday');
    $dobDay = $registrationForm.find('#dob-day');
    $dobMonth = $registrationForm.find('#dob-month');
    $dobYear = $registrationForm.find('#dob-year');
    $dobLabel = $registrationForm.find('#dob-label');
    $country = $registrationForm.find('#dwfrm_profile_customer_country');

    date = $dob.val();
    if (date !== null && date !== undefined && date !== '') {
        date = date.split('/');
        if (date.length == 3) {
            $dobDay.val(date[1]);
            $dobMonth.val(date[0]);
            $dobYear.val(date[2]);
        }
    }

    /**
     * ECM23-16 - Limit the registration of minors on our websites
     * Updated dob as mandatory if the country is US
     */
    $country.on('change', function (e) {
        updateDobFields({
            $country: $(e.currentTarget),
            $dobDay:  $dobDay,
            $dobMonth:  $dobMonth,
            $dobYear:  $dobYear,
            $dobLabel:  $dobLabel
        });
    });

    // check also on page load
    updateDobFields({
        $country: $country,
        $dobDay:  $dobDay,
        $dobMonth:  $dobMonth,
        $dobYear:  $dobYear,
        $dobLabel:  $dobLabel
    });

    $dobDay.on('change', function () {
        manageDoB($dobDay, $dobMonth, $dobYear, $dob);
    });
    $dobMonth.on('change', function () {
        manageDoB($dobDay, $dobMonth, $dobYear, $dob);
    });
    $dobYear.on('change', function () {
        manageDoB($dobDay, $dobMonth, $dobYear, $dob);
    });

    $dobDay.rules('add', {birthday: [$dobDay, $dobMonth, $dobYear]});
    $dobMonth.rules('add', {birthday: [$dobDay, $dobMonth, $dobYear]});
    $dobYear.rules('add', {birthday: [$dobDay, $dobMonth, $dobYear]});

    // move date of birth field inside date-month-year fields group so validation styles are shared
    $('#dobHidden')
        .find('.gl_form-group').removeClass('gl_form-group').end()
        .appendTo('#dobSplit');
}

module.exports = {
    init: init
};
