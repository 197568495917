'use strict';

var _debounce = require('../../lib/lodash.debounce');

var $form, $continue, $requiredInputs, validator;

var hasEmptyRequired = function () {
    var radioGroups = {};

    // filter out only the visible fields
    var requiredValues = [];
    $requiredInputs.filter(':visible').each(function () {
        var $this = $(this);
        if ($this.hasClass('input-checkbox')) {
            requiredValues.push($this.prop('checked') ? $this.val() : '');
        }
        else if ($this.hasClass('input-radio')) {
            var name = $this.attr('name'),
                val = $this.prop('checked') ? $this.val() : '';
            if (radioGroups.hasOwnProperty(name)) { // radio with same name already encountered
                if (val !== '') { // only add value if not empty
                    radioGroups[name] = val;
                }
            }
            else {
                radioGroups[name] = val;
            }
        }
        else {
            requiredValues.push($this.val());
        }
    });

    for (var k in radioGroups) {
        if (radioGroups.hasOwnProperty(k)) {
            requiredValues.push(radioGroups[k]);
        }
    }

    return requiredValues.indexOf('') != -1;
};

var validateForm = function () {
    // only validate form when all required fields are filled to avoid
    // throwing errors on empty form
    if (!validator) {
        return;
    }
    if (!hasEmptyRequired()) {
        if (validator.form()) {
            $continue.removeAttr('disabled');
        }
    }
    else {
        $continue.attr('disabled', 'disabled');
    }
};

var validateEl = function () {
    var $this = $(this);

    if ($this.val() === '') {
        $continue.attr('disabled', 'disabled');
    }
    else {
        // enable continue button on last required field that is valid
        // only validate single field
        if (validator.element(this) && !hasEmptyRequired()) {
            $continue.removeAttr('disabled');
        }
        else {
            $continue.attr('disabled', 'disabled');
        }
    }
};

var refreshRequiredInputs = function () {
    // remove the events from previously required fields
    $requiredInputs.off('change', validateEl);
    $requiredInputs.filter('input').off('keyup input', _debounce(validateEl, 200));

    $requiredInputs = $('.required', $form).find(':input');
    $requiredInputs.off('change', validateEl);
    $requiredInputs.on('change', validateEl);
    $requiredInputs.filter('input').off('keyup input', _debounce(validateEl, 200));
    $requiredInputs.filter('input').on('keyup input', _debounce(validateEl, 200));
};

var init = function (opts) {
    if (!opts.formSelector || !opts.continueSelector) {
        throw new Error('Missing form and continue action selectors.');
    }
    $form = $(opts.formSelector);
    $continue = $(opts.continueSelector);
    validator = $form.validate();
    $requiredInputs = $form.find('.required').find(':input');
    validateForm();

    // on form init, disable the continue button if the form is invalid
    if (validator && validator.errorList.length > 0) {
        $continue.attr('disabled', 'disabled');
    }

    // start listening
    $requiredInputs.on('change', validateEl);
    $requiredInputs.filter('input').on('keyup input', _debounce(validateEl, 200));

    //ERV-485 - Checkout - Billing and Payment - Guest - Logic improvements for validation
    window.vbqUtils.validationLogic.formValidation($form);

    // necessary for Belgium with Bancontact billing case
    window.vbqUtils.formPrepareRefreshRequiredInputs = refreshRequiredInputs;
};

exports.init = init;
exports.refreshRequiredInputs = refreshRequiredInputs;
exports.validateForm = validateForm;
exports.validateEl = validateEl;
