'use strict';

var dialog = require('../../dialog'),
    storesDialog = require('../checkout/storesDialog'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    addToCart = require('./addToCart'),
    image = require('./image'),
    //productNav = require('./productNav'), // ECM-633 - Remove Next item/Previous item block from PDPs
    productSet = require('./productSet'),
    //recommendations = require('./recommendations'),
    variant = require('./variant'),
    minicart = require('../../minicart'),
    backInStock = require('./backInStock'),
    monogram = require('./monogram'),
    _debounce = require('../../lib/lodash.debounce');

/**
 * @description Initialize description tab sliders
 */
function initDescriptionSliders () {
    var $pdpSliders = $('.pdp-block-slider');
    // PDP Detail tab content sliders & duplicated controllers
    $pdpSliders.each(function (index, el) {
        var $el = $(el),
            //ERV-346 Product page - gifting block appears unformatted on mobile devices on portrait mode - used a helper class for slides because the unslick method is removing the slick default classes
            $slides = $el.find('.pdp-slick-slide');

        if ($slides.length > 1) {
            $el.slick({
                slidesToShow: 1,
                dots: false,
                arrows: false,
                swipe: true
            });

            //PDP slick sliders controls duplicated in different position to obtain markup flow
            $el.find('.pdp-block-dots-item').on('click', function () {
                var $this = $(this),
                    buttonIndex = $this.index();
                $el.slick('slickGoTo', buttonIndex);
            });
        }
        else {
            $el.slick({
                slidesToShow: 1,
                dots: false,
                arrows: false,
                swipe: false
            });
            $el.find('.slick-dots').remove();
        }

        $el.on('setPosition', function (event, slick) {
            $el.find('.pdp-block-dots-item').removeClass('slick-active').filter(':nth-child(' + (slick.currentSlide + 1) + ')').addClass('slick-active');
        });
    });
}

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom() {
    //productNav(); // ECM-633 - Remove Next item/Previous item block from PDPs
    //recommendations();
    tooltip.init();
    // SR-16 SFCC - PDP changes - deactivate zoom for SR sites
    if (!$('.js-pdp-main').hasClass('js-cross-sell-pdp')) {
        window.vbqUtils.setupProductPrimaryImage();

        if (!window.vbqUtils.isSR) {
            image.imagesShowMore();
        }
    }
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents(isQuickView) {
    var $pdpMain = $('.js-pdp-main'),
        $pdpTabs = $('#pdpTabs'),
        $pdpProductImage = $('.product-primary-image-wrapper'),
        $socialMediaCarousel = $('.socialmedia-carousel'),
        $thumbnailSlider = $('.pdp-thumbnail-slider'),
        isCrossSell = $('.js-pdp-main').hasClass('js-cross-sell-pdp'),
        isProductSet = $('#wrapper').hasClass('pt_product-set');

    addToCart();
    variant();
    image(); // needed for the listener

    if (isProductSet) {
        productSet();
    }

    monogram.handleMonogramming($pdpMain, true);

    // Add to Wishlist and Add to Gift Registry links behaviors
    $pdpMain.on('click', '[data-action="wishlist"], [data-action="gift-registry"]', function () {
        var data = util.getQueryStringParams($('.pdpForm').serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.appendParamsToUrl(this.href, data);
        this.setAttribute('href', url);
    });

    // EPU-1 - add logic to handle detail bullet list deployment
    $pdpMain.on('click', '.js-detailsmore', function (e) {
        e.preventDefault();
        var $more = $(this),
            $less = $more.siblings('.js-detailsless'),
            $list = $more.siblings('.product-description-content-list');
        $more.addClass('hidden');
        $less.removeClass('hidden');
        $list.addClass('deployed');
    });
    $pdpMain.on('click', '.js-detailsless', function (e) {
        e.preventDefault();
        var $less = $(this),
            $more = $less.siblings('.js-detailsmore'),
            $list = $less.siblings('.product-description-content-list');
        $less.addClass('hidden');
        $more.removeClass('hidden');
        $list.removeClass('deployed');
    });

    // product options
    if (window.vbqUtils.isSR) {
        $pdpMain.on('change', '.product-options select', function () {
            var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales'),
                selectedItem = $(this).children().filter(':selected').first();
            salesPrice.text(selectedItem.data('combined'));
        });

        // prevent default behavior of thumbnail link and add this Button
        $pdpMain.on('click', '.thumbnail-link, .unselectable a', function (e) {
            e.preventDefault();
        });
    }

    if (isQuickView) {
        if ($pdpMain.find('.js-back-in-stock-subscription').length) {
            backInStock.init($pdpMain);
        }

        //ERF-58 Product detail page - Size chart section highlight listener
        window.vbqUtils.cache.$document.on('pdpTopUpdate', function () {
            if ($pdpMain.find('.js-back-in-stock-subscription').length) {
                backInStock.init($pdpMain);
            }
        });
    }

    if (!isQuickView) {
        // Pdp tabs on mobile is an accordion
        $pdpTabs.customAccordion();

        //ERF-58 Product detail page - Mobile/Desktop inits
        window.vbqUtils.breakpoint.set([
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('mobileAndTablet'),
                enter: function () {
                    // Second socialmedia carousel active only on mobile 2/row
                    $socialMediaCarousel.slick('unslick').slick({
                        dots: true,
                        arrows: false,
                        slidesToShow: 2,
                        rows: 2,
                        slidesPerRows: 2
                    });
                }
            },
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('fromDesktop'),
                enter: function () {
                    // Pdp social media carousel
                    $socialMediaCarousel.slick('unslick').slick({
                        slidesToShow: 4,
                        dots: false,
                        arrows: true
                    });

                    if (window.vbqUtils.isSR) {
                        $pdpTabs.find('.tabs-accordion-header').first().trigger('click');
                    }
                }
            }
        ]);

        // PDP carousel: updates on media query change only!
        window.vbqUtils.breakpoint.set([
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('fromDesktop'),
                enter: function () {
                    if (window.vbqUtils.isSR) {
                        // find actual thumbnailSlider in DOM after ajax variation change
                        $thumbnailSlider = $('.pdp-thumbnail-slider');

                        if ($thumbnailSlider.hasClass('slick-initialized')) {
                            $thumbnailSlider.slick('refresh');
                        }
                    }

                    // find actual pdpProductImage in DOM after ajax variation change
                    $pdpProductImage = $('.product-primary-image-wrapper');

                    if ($pdpProductImage.length && !isCrossSell) {
                        if (window.vbqUtils.isSR) {
                            $pdpProductImage.slick('unslick')
                                //ERV-440: PDP main image slider gets broken after viewport switch from desktop to mobile - removed flex styling for a correct calculation of slider position
                                .find('.product-primary-image').removeAttr('style').end()
                                .slick({
                                    slidesToShow: 1,
                                    dots: false,
                                    arrows: true,
                                    infinite: $pdpProductImage.find('.image-type-video').length === 0,
                                    asNavFor: '.pdp-thumbnail-slider',
                                    fade: true,
                                    cssEase: 'linear',
                                    carouselRole: false,
                                    carouselAriaLabel: false
                                });
                        }
                        else {
                            if ($pdpProductImage.find('.product-images-mosaic').hasClass('slick-initialized')) {
                                $pdpProductImage.find('.product-images-mosaic').slick('unslick');
                            }
                        }
                    }
                }
            },

            {
                media: window.vbqUtils.breakpoint.getMediaQuery('mobileAndTablet'),
                enter: function () {
                    // find actual pdpProductImage in DOM after ajax variation change
                    $pdpProductImage = $('.product-primary-image-wrapper');

                    if (window.vbqUtils.isSR) {
                        $pdpProductImage.find('.product-primary-image').eq($thumbnailSlider.slick('slickCurrentSlide')).css('display', 'flex').addClass('active');

                        // SR-16 SFCC - PDP changes
                        $pdpProductImage.on('init afterChange', function(event, slick, currentSlide, nextSlide){
                            var i = (currentSlide ? currentSlide : 0) + 1;
                            window.vbqUtils.cache.$body.find('#js-carouselSlidesNumber').html(i + '/' + slick.slideCount);
                        });

                        if (!isCrossSell) {
                            $pdpProductImage.slick('slickGoTo', $thumbnailSlider.slick('slickCurrentSlide'));
                        }

                        if ($pdpProductImage.length && !isCrossSell) {
                            $pdpProductImage.slick('unslick').slick({
                                slidesToShow: 1,
                                dots: window.vbqUtils.isSR,
                                arrows: !window.vbqUtils.isSR,
                                infinite: $pdpProductImage.find('.image-type-video').length === 0,
                                isNavigationGuide: true,
                                customAriaLabel: window.Resources.PRODUCT_CAROUSEL_ARIA_LABEL
                            });
                        }
                    }
                    else {
                        if ($pdpProductImage.length && !isCrossSell && !isQuickView) {
                            $pdpProductImage.find('.product-images-mosaic').slick('unslick').slick({
                                slidesToShow: 1,
                                dots: false,
                                arrows: true,
                                infinite: $pdpProductImage.find('.image-type-video').length === 0,
                                isNavigationGuide: true,
                                customAriaLabel: window.Resources.PRODUCT_CAROUSEL_ARIA_LABEL
                            });
                        }
                    }
                }
            }
        ], true); // on media query change only

        // EPU-1
        var $matchSlide;
        if (!window.vbqUtils.isSR) {
            var $styleGuideFits = $('.styleguide-fits');
            $matchSlide = $styleGuideFits.find('.styleguide-pdp-match');
            window.vbqUtils.cache.$window.on('accordion.tabActive', function (event, target) {
                var $activeTab = $(target.activeTarget).parent('.js-tabs-accordion-header-wrapper').next();

                if ($activeTab.hasClass('styleguide-tab') && !$styleGuideFits.hasClass('slick-initialized')) {
                    $styleGuideFits.slick('unslick').slick({
                        slidesToShow: 1,
                        dots: false,
                        arrows: true,
                        infinite: false,
                        initialSlide: $matchSlide.length === 1 ? $matchSlide.data('num') : 0
                    });
                }
            });

            window.vbqUtils.cache.$window.resize(_debounce(function () {
                var $activeTab = $('.js-accordion-tab.active');
                if ($activeTab.hasClass('styleguide-tab') && $styleGuideFits.hasClass('slick-initialized')) {
                    $styleGuideFits.slick('setPosition');
                }
            }, 250));
        }
        else {
            // ECM-967 - New style guide women
            var $womenSliders = $('.styleguide-women').find('.js-styleguide-women-slider');
            $matchSlide = $womenSliders.find('.styleguide-pdp-match');

            // adjust women slider on tab activation
            window.vbqUtils.cache.$window.on('accordion.tabActive', function (event, target) {
                var $activeTab = $(target.activeTarget).next();

                if ($activeTab.hasClass('styleguide-tab') && $womenSliders.length > 0 && $womenSliders.hasClass('slick-initialized')) {
                    $womenSliders.slick('setPosition');
                }
            });

            window.vbqUtils.breakpoint.set([
                {
                    media: window.vbqUtils.breakpoint.getMediaQuery('mobileAndTablet'),
                    enter: function () {
                        if ($womenSliders.length > 0) {
                            $womenSliders.slick('unslick').slick({
                                slidesToShow: 1,
                                dots: true,
                                arrows: false,
                                centerMode: true,
                                infinite: true,
                                centerPadding: '0',
                                variableWidth: true,
                                adaptiveHeight: true,
                                initialSlide: $matchSlide.length === 1 ? $matchSlide.data('num') : 0
                            }).slick('setPosition');
                        }
                    }
                },
                {
                    media: window.vbqUtils.breakpoint.getMediaQuery('fromDesktop'),
                    enter: function () {
                        if ($womenSliders.length > 0) {
                            $womenSliders.slick('unslick').slick({
                                slidesToShow: 4,
                                dots: false,
                                arrows: true,
                                infinite: true,
                                initialSlide: $matchSlide.length === 1 ? $matchSlide.data('num') : 0
                            });
                        }
                    }
                }
            ]);

            // adjust women slider on resize
            window.vbqUtils.cache.$window.resize(_debounce(function () {
                if ($womenSliders.hasClass('slick-initialized')) {
                    $womenSliders.slick('setPosition');
                }
            }, 250));
        }

        $('.js-styleguideWomenTab').on('accordion.tabOpen', function() {
            if ($womenSliders.hasClass('slick-initialized')) {
                $womenSliders.slick('setPosition');
            }
        });

        // Size chart functionality - highlight selected product size with corresponding size in chart
        // Same logic to hide elements that require a size selected
        var $sizeChart = $('#size-chart'),
            indexSizeChart = $('#pdp-tabs-container').children('.tab').index($sizeChart),
            $sizeChartTab = $('#size-chart-tab'),
            offsetCompensation = window.vbqUtils.cache.$window.outerHeight() / 4,
            $sizeCharts = $('.js-sizeChart'),
            // EW-123 - Size chart reboot
            sizeChartCountries = ['FR', 'UK', 'IT', 'US'],
            currentCountry = window.vbq.localizationData ? window.vbq.localizationData.countryCode : '',
            sizeChartCountry = sizeChartCountries.indexOf(currentCountry) != -1 ? (currentCountry == 'GB' ? 'UK' : currentCountry) : 'EU',
            sizeChartUnit = sizeChartCountry == 'US' ? 'INCH' : 'CM',
            $sizeChartCountrySelect = $sizeChart.find('.js-sizeChartCountrySelect'),
            $sizeChatUnits = $sizeChart.find('.js-sizeChatUnits').find('.js-sizeChatUnit'),
            manageSizeRelated = function ($target) {
                var $wrapper = $target ? $target.closest('.js-pdp-main') : $('.js-pdp-main'),
                    // EPU-1 - New PDP design on VBQ - size list logic per brand
                    $selectedSize = !window.vbqUtils.isSR ? $wrapper.find('.js-size-list').find('.selected') : $wrapper.find('.js-size-select').filter(':visible').first(),
                    $addToCart = $wrapper.find('.add-to-cart'),
                    currentSizeChartCountry = $sizeChartCountrySelect.val(),
                    currentSizeChartUnit = $sizeChatUnits.filter('.active').attr('value'),
                    sizeChartCountrySelectId = 'sizeChartCountrySelect';

                // EADA-89 - Added aria-pressed on size buttons
                $sizeChatUnits.attr('aria-pressed', 'false').filter('.active').attr('aria-pressed', 'true');

                // EADA-45 - Added label on select
                $sizeChartCountrySelect.attr('id', sizeChartCountrySelectId);
                if ($sizeChartCountrySelect.siblings('label').length == 0) {
                    $('<label for="' + sizeChartCountrySelectId + '" class="offscreen">' + Resources.COUNTRY_LABEL + '</label>').insertBefore($sizeChartCountrySelect);
                }

                // respect user choice
                sizeChartCountry = sizeChartCountry != currentSizeChartCountry ? currentSizeChartCountry : sizeChartCountry;
                sizeChartUnit = sizeChartUnit != currentSizeChartUnit ? currentSizeChartUnit : sizeChartUnit;

                if ($selectedSize.length) {
                    // EPU-1 - New PDP design on VBQ - size list logic per brand
                    var selectedSize = !window.vbqUtils.isSR ? $selectedSize.attr('data-value') : $selectedSize.val();
                    $sizeCharts.addClass('hidden')
                        .filter('.js-sizeChart-' + sizeChartCountry + '-' + sizeChartUnit).removeClass('hidden')
                        .find('.js-sizeChartLine').removeClass('sizeChart-lineHightlight').removeAttr('aria-current')
                        .filter('[data-size="' + selectedSize + '"]').addClass('sizeChart-lineHightlight').attr('aria-current', 'true');
                }
                else {
                    $sizeCharts.find('.js-sizeChartLine').removeClass('sizeChart-lineHightlight').removeAttr('aria-current');
                    $sizeCharts.addClass('hidden').filter('.js-sizeChart-' + sizeChartCountry + '-' + sizeChartUnit).removeClass('hidden');
                    $addToCart.addClass('disabled');
                }
            };

        $sizeChatUnits.on('click', function (e) {
            e.preventDefault();

            $sizeChatUnits.removeClass('active');
            $(this).addClass('active');
            manageSizeRelated();
        });

        $sizeChartCountrySelect.on('change', function () {
            manageSizeRelated();
        });

        $pdpMain.on('click', '.js-size-variation-find', function (e) {
            if ($pdpTabs.length && indexSizeChart > 0) {
                e.preventDefault();

                $pdpTabs.customAccordion('active', indexSizeChart);

                window.vbqUtils.cache.$html.animate({
                    scrollTop: $sizeChart.offset().top - offsetCompensation
                }, 500, function() {
                    $sizeChartTab.focus();
                });
            }
        });

        // initial case
        manageSizeRelated();

        // on load the size <select> is forced to no value, ensure size related elements are hidden/shown (does not override the monogram button logic)
        // EPU-1 - New PDP design on VBQ - size list logic per brand
        if ((window.vbqUtils.isSR && $pdpMain.find('.js-size-select').val() === null)
            || (!window.vbqUtils.isSR && $pdpMain.find('.js-size-list').find('.js-size-radio').filter('.selected').length === 0)
        ) {
            $pdpMain.find('#js-noSizeSelectedDefaultButton').removeClass('hidden');
            $pdpMain.find('.js-monogram-set').addClass('hidden');
            $pdpMain.find('.js-monogram-noset').addClass('hidden');
        }
        else {
            $pdpMain.find('#js-sizeSelectedUnavailable').removeClass('hidden');
            $pdpMain.find('.js-monogram-set').removeClass('hidden');
            $pdpMain.find('.js-monogram-notset').removeClass('hidden');
        }

        //ERF-58 Product detail page - Size chart section highlight listener
        window.vbqUtils.cache.$document.on('variationchanged', function (e, type, $this) {
            var off = $this.parent('.selected-value').length;

            $sizeCharts.removeClass('active');
            if (type == 'size' && !off) {
                manageSizeRelated($this);
            }
        }).on('pdpTopUpdate', function () {
            if ($pdpMain.find('.js-back-in-stock-subscription').length) {
                backInStock.init(false, $pdpMain.find('.js-back-in-stock-subscription-sticky'));
                backInStock.init(false, $pdpMain.find('.js-back-in-stock-subscription-desktop'));
            }
        });

        if ($pdpMain.find('.js-back-in-stock-subscription').length) {
            backInStock.init(false, $pdpMain.find('.js-back-in-stock-subscription-sticky'));
            backInStock.init(false, $pdpMain.find('.js-back-in-stock-subscription-desktop'));
        }

        // ERB-210 - Product detail page - implement iframe logic
        // If not inside an iframe, listen for the iframe add-to-cart
        // Added compatibility support for IE+ browsers
        var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent',
            eventer = window[eventMethod],
            messageEvent = (eventMethod == 'attachEvent') ? 'onmessage' : 'message';

        if (!$('#main').hasClass('js-inIframe')) {
            eventer(messageEvent, function (e) {
                var data = e && e.data && e.origin.indexOf('vilebrequin.com') != -1 && typeof e.data == 'string' && $.trim(e.data).indexOf('{') == 0 ? JSON.parse(e.data) : {};
                if (data.addToCart) {
                    if (data.hasUuid) {
                        window.location.reload();
                    }
                    else {
                        minicart.show(data.response);
                        dialog.close();
                        window.vbqUtils.cache.$body.css('overflow', ''); // EW-130 - CRO - PdP Cross Sell
                        window.vbqUtils.cache.$body.scrollTop(0);

                        minicart.addedToCartDialog({
                            classes: {
                                'ui-dialog': 'mixmatch-minicart-mobile-alert-dialog' //used to force styles
                            }
                        });
                    }
                }

                if (data.closeQVDialog) {
                    dialog.close();
                    window.vbqUtils.cache.$body.css('overflow', ''); // EW-130 - CRO - PdP Cross Sell
                    $('#QuickViewDialog').dialog('close');
                }

                if (data.openStoresDialog) {
                    storesDialog.openDialog(data.pid, true);
                }
            }, false);
        }

        //ERV-350 Product page - recently view - quick view appear unformated - used postmessage for passing the close dialog event from the iframe to the main window
        window.vbqUtils.cache.$body.on('click', '#js-close-quickview', function () {
            if (!window.location.origin) { // Some browsers (mainly IE) do not have this property, so we need to build it manually...
                window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? (':' + window.location.port) : '');
            }
            window.parent.postMessage(JSON.stringify({closeQVDialog: true}), window.location.origin);
        });

        // Will trigger iframe height calculations through postMessage based on lazyload finishing loading images
        // 'load' event was not enough on IE
        var $iframeLoadReference = $('.iframe-load-reference');
        if ($iframeLoadReference.length) {
            if (!window.location.origin) { // Some browsers (mainly IE) do not have this property, so we need to build it manually...
                window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? (':' + window.location.port) : '');
            }

            $iframeLoadReference.on('load', function () {
                window.parent.postMessage(JSON.stringify({primaryImagesLoaded: true}), window.location.origin);
            });

            // force first height update, before image are loaded, to avoid display bug if image loading fails
            window.parent.postMessage(JSON.stringify({iFrameLoaded: true}), window.location.origin);
        }

        //ERV-346 Product page - gifting block appears unformatted on mobile devices on portrait mode -initialize the sliders on mobile only after the images are preloaded and the tabs are active
        window.vbqUtils.breakpoint.set([
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('mobileAndTablet'),
                enter: function () {
                    // Scroll in the color list to selected color item
                    window.vbqUtils.colorListSet($pdpMain);
                    window.vbqUtils.cache.$window.resize(_debounce(function() {
                        window.vbqUtils.colorListSet($pdpMain, true);
                    }, 50));
                }
            }
        ]);

        initDescriptionSliders();

        // ENRP-3 - Replacement of Power-Reviews by Avis-Vérifiés
        window.vbqUtils.cache.$body.on('click', '.NETREVIEWS_PRODUCT_STARS', function (e) {
            e.preventDefault();

            var $reviewsTab = $('.js-reviews-tab');

            if ($reviewsTab.length) {
                if (!$reviewsTab.hasClass('active')) {
                    $reviewsTab.click();
                }
                $reviewsTab.focus();
            }
        });

        /* obsolete, the write review button not shown on PDP anymore
        // ECM-929 - Updates following our phone call with Avis-Vérifiés
        window.vbqUtils.cache.$body.on('click', '.write-review-button', function (e) {
            e.preventDefault();

            dialog.open({
                target: $('<div/>').attr('id', 'writeReviewDialog').appendTo(document.body),
                html: $('<iframe/>').attr('class', 'write-review-iframe').attr('src', url).attr('title', window.Resources.WRITE_REVIEW),
                options: {
                    width: 'auto',
                    dialogClass : 'write-review-dialog',
                    title: window.Resources.WRITE_REVIEW
                }
            });
        });
        */

        storesDialog.PDPInit($('.js-pdp-main'));

        // OUFD20-2 - Omnichannel Ultra Fast Delivery
        $pdpMain.on('click', '.js-ultraFastDelivery', function (e) {
            e.preventDefault();
            var $this = $(this),
                storeId = $this.attr('data-ufdStoreId'),
                isUltraFastDelivery = true;
            $this.parent().find('.add-to-cart-primary').trigger('click', [storeId, isUltraFastDelivery]);
        });
    }
    else {
        if (!window.vbqUtils.isSR && !isCrossSell && window.vbqUtils.breakpoint.is('mobileAndTablet')) {
            $pdpProductImage.find('.product-images-mosaic').slick('unslick').slick({
                slidesToShow: 1,
                dots: false,
                arrows: true,
                infinite: $pdpProductImage.find('.image-type-video').length === 0,
                isNavigationGuide: true,
                customAriaLabel: window.Resources.PRODUCT_CAROUSEL_ARIA_LABEL
            });
        }
    }

    if (window.vbqUtils.isSR) {
        window.vbqUtils.cache.$body.on('click', '.js-ecofriendlyTooltipIcon', function (e) {
            var $this = $(this);
            $this.attr('aria-expanded', $this.attr('aria-expanded') == 'true' ? 'false' : 'true').siblings('.js-ecofriendlyTooltip').finish().fadeToggle();
        }).on('mouseenter', '.js-ecofriendlyTooltipIcon', function () {
            $(this).attr('aria-expanded', 'true').siblings('.js-ecofriendlyTooltip').finish().fadeIn();
        }).on('mouseleave blur', '.js-ecofriendlyTooltipIcon', function () {
            $(this).attr('aria-expanded', 'false').siblings('.js-ecofriendlyTooltip').finish().fadeOut();
        });
    }
}

function setupProductPrimaryImage($wrapper) {
    /*ERF-58 PDP integration - pdp primary image refactor
    * Setup thumbnail carousel & custom events, show corresponding primary image,
    * Setup zoom behavior
    * Needs to be called after variation change
    */
    $wrapper = $wrapper || $('.js-pdp-main');

    var $pdpImageSection = $wrapper.find('.js-productimage-wrapper'),
        $thumbnailSlider = $wrapper.find('.pdp-thumbnail-slider'),
        $primaryImages = $wrapper.find('.product-primary-image'),
        $pdpProductImage = $wrapper.find('.product-primary-image-wrapper'),
        isCrossSell = $wrapper.hasClass('js-cross-sell-pdp'),
        isQuickView = $wrapper.find('.pdp-in-quickview').length;
        // EPU-1 - osbolete for VBQ and not used for SR
        //$ecofriendlyTooltipIcon = $('.js-ecofriendlyTooltipIcon'),
        //$familyBadge = $('.family-badge');

    if (window.vbqUtils.isSR) {
        // Setup thumbnail carousel
        if ($thumbnailSlider.length && !$thumbnailSlider.hasClass('slick-initialized')) {
            $thumbnailSlider.slick({
                vertical: true,
                slidesToShow: isQuickView ? 3 : window.vbqUtils.isSR ? 4 : 6,
                dots: false,
                focusOnSelect: true,
                draggable: true,
                swipe: true,
                asNavFor: '.product-primary-image-wrapper',
                carouselRole: false,
                carouselAriaLabel: false
            });
        }

        // EADA22-5 ADA #95 : Monogram gallery carousel interaction
        if (!isCrossSell) {
            window.vbqUtils.breakpoint.set([
                {
                    media: window.vbqUtils.breakpoint.getMediaQuery('fromDesktop'),
                    enter: function () {
                        if ($pdpImageSection.length && $thumbnailSlider.length) {
                            $pdpImageSection.attr('role', 'region').attr('aria-label', window.Resources.CAROUSEL_COMMON_OFFSCREEN);
                        }
                    }
                },
                {
                    media: window.vbqUtils.breakpoint.getMediaQuery('mobileAndTablet'),
                    enter: function () {
                        if ($pdpImageSection.length && $thumbnailSlider.length) {
                            $pdpImageSection.removeAttr('role aria-label');
                        }
                    }
                }
            ]);
        }
    }

    // Reinit product image carousel on variation change
    var slickActive;
    if (window.vbqUtils.isSR) {
        if ($pdpProductImage.length && !isCrossSell) {
            if (window.vbqUtils.breakpoint.is('fromDesktop')) {
                $pdpProductImage.slick('unslick').slick({
                    slidesToShow: 1,
                    dots: false,
                    arrows: true,
                    infinite: $pdpProductImage.find('.image-type-video').length === 0,
                    asNavFor: '.pdp-thumbnail-slider',
                    fade: true,
                    cssEase: 'linear',
                    carouselRole: false,
                    carouselAriaLabel: false
                });
            }
            else {
                $pdpProductImage.slick('unslick').slick({
                    slidesToShow: 1,
                    dots: window.vbqUtils.isSR,
                    arrows: !window.vbqUtils.isSR,
                    infinite: $pdpProductImage.find('.image-type-video').length === 0,
                    isNavigationGuide: true,
                    customAriaLabel: window.Resources.PRODUCT_CAROUSEL_ARIA_LABEL
                });
            }

            // SR-16 SFCC - PDP changes
            if (window.vbqUtils.isSR) {
                $pdpProductImage.on('init afterChange', function(event, slick, currentSlide, nextSlide){
                    var i = (currentSlide ? currentSlide : 0) + 1;
                    window.vbqUtils.cache.$body.find('#js-carouselSlidesNumber').html(i + '/' + slick.slideCount);
                });
            }

            slickActive = true;
        }

        if (isCrossSell && !$pdpProductImage.hasClass('slick-initialized')) {
            $pdpProductImage.slick({
                slidesToShow: 1,
                dots: false,
                arrows: true,
                infinite: $pdpProductImage.find('.image-type-video').length === 0,
                fade: true,
                cssEase: 'linear'
            });

            slickActive = true;
        }
    }
    else {
        if (!isCrossSell) {
            var toggleStickyAddToCart = function () {
                var rect = window.vbqUtils.cache.$pdpMobileAddToCartStickyTrigger[0].getBoundingClientRect();

                var isOnScreen = (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= window.vbqUtils.cache.$window.height() &&
                    rect.right <= window.vbqUtils.cache.$window.width()
                );

                $('#js-mobileAddToCartSticky').toggleClass('sticky', !isOnScreen);
            };

            window.vbqUtils.cache.$window.scroll(_debounce(function () {
                if (window.vbqUtils.breakpoint.is('mobileAndTablet') && window.vbqUtils.cache.$pdpMobileAddToCartStickyTrigger.length) {
                    toggleStickyAddToCart();
                }
            }, 50));

            window.vbqUtils.cache.$document.on('pdpTopUpdate', function () {
                if (window.vbqUtils.breakpoint.is('mobileAndTablet') && window.vbqUtils.cache.$pdpMobileAddToCartStickyTrigger.length) {
                    toggleStickyAddToCart();
                }
            });

            if (window.vbqUtils.breakpoint.is('mobileAndTablet')) {
                $pdpProductImage.find('.product-images-mosaic').slick('unslick').slick({
                    slidesToShow: 1,
                    dots: false,
                    arrows: true,
                    infinite: $pdpProductImage.find('.image-type-video').length === 0,
                    isNavigationGuide: true,
                    customAriaLabel: window.Resources.PRODUCT_CAROUSEL_ARIA_LABEL
                });

                slickActive = true;
            }
        }
        else {
            $pdpProductImage.find('.product-images-mosaic').slick({
                slidesToShow: 1,
                dots: false,
                arrows: true,
                infinite: $pdpProductImage.find('.image-type-video').length === 0,
                isNavigationGuide: true,
                customAriaLabel: window.Resources.PRODUCT_CAROUSEL_ARIA_LABEL
            });

            slickActive = true;
        }
    }

    if (window.vbqUtils.isSR) {
        // show corresponding product image from thumbnail carousel
        // mobile thumbnail carousel is disabled
        var $videoSlide;
        if ($thumbnailSlider.length && $thumbnailSlider.hasClass('slick-initialized')) {
            if (window.vbqUtils.breakpoint.is('mobileAndTablet')) {
                $primaryImages.eq($thumbnailSlider.slick('slickCurrentSlide')).show().css('display', 'flex').addClass('active');
            }

            // force video height, for DAM videos
            if ($primaryImages.eq($thumbnailSlider.slick('slickCurrentSlide')).hasClass('dam-video')) {
                $videoSlide = $primaryImages.eq($thumbnailSlider.slick('slickCurrentSlide'));
                $videoSlide.css('height', $videoSlide.css('width'));
            }
        }
        else {
            if (window.vbqUtils.breakpoint.is('mobileAndTablet')) {
                $primaryImages.first().show().css('display', 'flex').addClass('active');
            }

            // force video height, for DAM videos
            if ($primaryImages.first().hasClass('dam-video')) {
                $videoSlide = $primaryImages.first();
                $videoSlide.css('height', $videoSlide.css('width'));
            }
        }

        // events
        // PDP thumbnail slick slider will not fire events if nr of slides do not generate navigation
        // Sync thumbnail & primary image sliders manually or using plugin events
        if ($thumbnailSlider.hasClass('slick-initialized') && $thumbnailSlider.find('.slick-slide').length <= $thumbnailSlider.slick('slickGetOption', 'slidesToShow')) {
            var $currentThumbnailSlide = $thumbnailSlider.find('.slick-active'),
                $currentProductImageSlide = $primaryImages.filter('[data-for="' + $currentThumbnailSlide.data('for') +'"]');

            $thumbnailSlider.on('click', '.slick-slide', function () {
                var $nextThumbnailSlide = $(this),
                    $nextProductImageSlide = $primaryImages.filter('[data-for="' + $(this).data('for') +'"]');

                productVideoStandby($currentProductImageSlide, $nextProductImageSlide);

                $currentThumbnailSlide = $nextThumbnailSlide;
                $currentProductImageSlide = $nextProductImageSlide;
            });
        }
        else {
            $thumbnailSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                var $currentProductImageSlide = $primaryImages.eq(currentSlide),
                    $nextProductImageSlide = $primaryImages.eq(nextSlide);

                productVideoStandby($currentProductImageSlide, $nextProductImageSlide);
            });

            $thumbnailSlider.on('afterChange', function (e, slick, currentSlide) {
                var $currentSlide = $primaryImages.eq(currentSlide);
            });
        }
    }

    //ERF-58 PDP integration - zoom implementation
    var firstClickEvent,// Need to check where first click on zoom is coming from
        $tapButton = $wrapper.find('.tap-zoom-wrapper');

    if (window.vbqUtils.getCookie('zhs') === null && (!window.vbqUtils.isSR || window.vbqUtils.isDevice)) {
        window.vbqUtils.setCookie('zhs', 'true', 3600 * 24);
        $tapButton.show();
    }

    if (!isCrossSell) {
        var $productPrimaryImage = $('.product-primary-image:not(.image-type-video)'),
            $zoomImages = $('.zoom-images');
        // force the image loading, so the carousel shows properly
        $zoomImages.find('.zoom-image')
            .each(function (index, el) {
                window.lazySizes.loader.unveil(el);
            });

        // EPU-1 - zoom modal with zoom images carousel, cursor move, scroll, and touch support
        var avoidDoubleTrigger = false;
        $productPrimaryImage.on('click tap', function (e) {
            $tapButton.hide();

            if (e.type == 'click') {
                avoidDoubleTrigger = true;
            }
            else if (e.type == 'tap' && avoidDoubleTrigger) {
                avoidDoubleTrigger = false;
                return false;
            }

            var index = $('.product-primary-image:not(.image-type-video):not(.slick-cloned)').index($(e.currentTarget));

            var panzooms = [];
            $zoomImages.dialog({
                autoOpen: true,
                draggable: false,
                modal: true,
                resizable: false,
                width: '100vw',
                height: '100vh',
                position: {my: 'left top', at: 'left top', of: window},
                closeOnEscape: true,
                closeText: window.Resources.CLOSE,
                classes: {
                    'ui-dialog': 'zoomImagesDialog' //used to force styles
                },
                open: function () {
                    // EADA-40 - The modal container is not labeled as such: .dialog() case
                    window.vbqUtils.jqDialogOpenAdjust();

                    $zoomImages.find('.zoom-images-list').slick('unslick').slick({
                        arrows: true,
                        swipe: false,
                        infinite: true
                    }).slick('slickGoTo', index, true);

                    window.vbqUtils.cache.$body.css('overflow', 'hidden');

                    // 1960 for the image width and height on VBQ
                    var imageW = window.vbqUtils.isSR ? 922 : 1960,
                        imageH = window.vbqUtils.isSR ? 1383 : 1960,
                        scaleW = window.vbqUtils.cache.$window.width() / imageW,
                        scaleH = window.vbqUtils.cache.$window.height() / imageH,
                        scale = Math.max(scaleW, scaleH);

                    $zoomImages.find('.zoom-image').each(function(index, elem) {
                        var panzoom = window.Panzoom(elem, {canvas: true, contains: 'invert', startScale: scale, minScale: Math.min(scaleW, scaleH), maxScale: scale * 5, maxZoom: 1, minZoom: 0.5, cursor: 'all-scroll', bounds: true, boundsPadding: 10}),
                            parent = elem.parentElement;
                        parent.addEventListener('wheel', panzoom.zoomWithWheel);

                        panzooms.push({elem: elem, parent: parent, instance: panzoom});
                    });

                    // force better starting position, needs to be delayed
                    window.setTimeout(function () {
                        var windowW = window.vbqUtils.cache.$window.width(),
                            windowH = window.vbqUtils.cache.$window.height();
                        for (var i = 0; i < panzooms.length; i++) {
                            panzooms[i].instance.pan((windowW - imageW) / 2 / scale, (windowH - imageH) / 2 / scale);
                        }
                    }, 100);
                },
                close: function () {
                    for (var i = 0; i < panzooms.length; i++) {
                        panzooms[i].instance.destroy();
                        panzooms[i].parent.removeEventListener('wheel', panzooms[i].instance.zoomWithWheel);
                    }
                    $zoomImages.dialog('destroy');
                    window.vbqUtils.cache.$body.css('overflow', '');
                }
            });
        });
    }
}

function productVideoStandby ($currentSlide, $nextSlide) {
    var $youtubeVideo, $damVideo;
    if ($currentSlide.hasClass('image-type-video')) {
        $youtubeVideo = $currentSlide.find('iframe'); // youtube video
        $damVideo = $currentSlide.find('video');
        if ($youtubeVideo.length) {
            $youtubeVideo[0].contentWindow.postMessage('{"event": "command", "func": "pauseVideo", "args": ""}', '*');
        }
        if ($damVideo.length) { // DAM video
            $damVideo[0].pause();
        }
    }
    else if ($nextSlide.hasClass('image-type-video')) {
        $youtubeVideo = $nextSlide.find('iframe'); // youtube video
        $damVideo = $nextSlide.find('video');
        if ($youtubeVideo.length) {
            $youtubeVideo[0].contentWindow.postMessage('{"event": "command", "func": "playVideo", "args": ""}', '*');
        }
        if ($damVideo.length) { // DAM video
            $damVideo[0].play();
        }
    }
}

var product = {
    initializeEvents: initializeEvents,
    init: function () {
        window.vbqUtils.setupProductPrimaryImage = setupProductPrimaryImage;
        initializeDom();
        initializeEvents(false);

        if (!window.vbqUtils.isSR) {
            if ($('.js-cross-sell-pdp').length === 0 && $('#QuickViewDialog').length === 0) {
                var reviewsIntervalCount = 0;
                var reviewsInterval = window.setInterval(function () {
                    reviewsIntervalCount++;
                    if ($('.netreviews_review_rate_and_stars').length) {
                        $('#js-reviewsSummaryAjax').html($('#js-reviewsSummary').clone().removeAttr('id')).removeClass('hidden');
                        $('#js-reviewsStars').html($('#js-reviewsSummary').find('.netreviews_review_rate_and_stars').clone());

                        window.clearInterval(reviewsInterval);
                    }
                    else if (reviewsIntervalCount > 10) {
                        window.clearInterval(reviewsInterval);
                    }
                }, 500);
            }
        }
    },
    initQuickview: function () { // only init available elements and not everything
        window.vbqUtils.setupProductPrimaryImage = setupProductPrimaryImage;
        window.vbqUtils.setupProductPrimaryImage();
        initializeEvents(true);

        //ERB-52 International Shipping
        window.vbqUtils.priceDisplay.refresh();
    }
};

module.exports = product;

exports.setupProductPrimaryImage = setupProductPrimaryImage;
