'use strict';

var cache = {},
    progress = require('../progress');

/**
 * Cache needed elements
 */
function initCache() {
    cache.selectedProducts = 0;
    cache.$document = $(document);
    cache.$productTile = cache.$document.find('.js-product-tile');
    cache.$formButton = cache.$document.find('.js-select-products');
}

/**
 * Update total number of selected products
 * @param {Boolean} checked - true if to increment the number, false otherwise
 */
function updateSelectedProductsNumber(checked) {
    // update total number of selected products
    if (checked) {
        cache.selectedProducts += 1;
    }
    else {
        cache.selectedProducts -= 1;

        // keep the nubmer to zero
        cache.selectedProducts = Math.max(cache.selectedProducts, 0);
    }
}

/**
 * Verify if there is any checked tiles and enable submit button in this case
 */
function updateSubmitButton() {
    // enable or disable form subimt button based on the nubmer of selected products
    if (cache.selectedProducts) {
        cache.$formButton.prop('disabled', false);
    }
    else {
        cache.$formButton.prop('disabled', true);
    }
}

/**
 * Update selected products number, also enable or disable the submit button based on them
 */
function updateSelectedProducts() {
    cache.$productTile.each(function () {
        var $this = $(this),
            $input = $this.find('input[type=checkbox]'),
            checked = $input.prop('checked');

        // update number of selected products
        if (checked) {
            cache.selectedProducts += 1;
        }
    });
    // enable or disable form subimt button based on the nubmer of selected products
    updateSubmitButton();
}

/**
 * Init events
 */
function initEvents() {
    cache.$productTile.on('click keydown', function (e) {
        // mouse click or enter/space key
        if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
            // prevent the default action to stop scrolling when space is pressed
            e.preventDefault();

            var $this = $(this),
                $image = $this.find('.js-product-img'),
                $input = $this.find('.js-product-checkbox'),
                checked = !$input.prop('checked');

            $image.toggleClass('selected', checked);
            $input.prop('checked', checked).checkboxradio('refresh');

            // update number of selected products
            updateSelectedProductsNumber(checked);

            // enable or disable form subimt button based on the nubmer of selected products
            updateSubmitButton();
        }
    });
    cache.$formButton.on('click', function (e) {
        if (!cache.selectedProducts) {
            e.preventDefault();
            cache.$formButton.prop('disabled', true);
        }
        else {
            cache.$formButton.addClass('disabled');
            progress.show(cache.$formButton);
        }
    });

    updateSelectedProducts();
}

exports.init = function () {
    initCache();
    initEvents();
};
