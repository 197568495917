'use strict';

var $cartForm = $('#cart-items-form'),
    giftBoxesStates = [];

/**
 * ERB-71 - Gift box and gift message
 */

function initializeEvents() {
    // listener for gift checkbox
    $cartForm.on('change', '.is-gift', function () {
        // get current plid
        var $isGift = $(this),
            $isGiftControl = $isGift.closest('[id^="isGift_"]'),
            plid = $isGiftControl.attr('id').split('_')[1];

        // trigger for check status
        if ($isGift.prop('checked')) {
            // update bd with new status
            updateIsGiftAJAX(plid, true);
            $isGift.attr('aria-expanded', 'true')
                .siblings('.js-giftBoxUncheckedLabel').addClass('hidden').end()
                .siblings('.js-giftBoxCheckedLabel').removeClass('hidden');
            $('#giftBoxBlock_' + plid).removeClass('hidden').attr('aria-hidden', 'false');
        }
        else {
            updateIsGiftAJAX(plid, false);
            $isGift.attr('aria-expanded', 'false')
                .siblings('.js-giftBoxUncheckedLabel').removeClass('hidden').end()
                .siblings('.js-giftBoxCheckedLabel').addClass('hidden');
            $('#giftBoxBlock_' + plid).addClass('hidden').attr('aria-hidden', 'true');
            // clean html from unused elements
            resetGiftBoxMessages(plid);
        }
    });

    $cartForm.on('change', '.is-gift-box-message', function () {
       var $isGiftBoxMessage = $(this),
           plid = $isGiftBoxMessage.attr('id').split('_')[1],
           boxID = $isGiftBoxMessage.attr('id').split('_')[2],
           check = $isGiftBoxMessage.prop('checked'),
           $boxWrapper = $('#boxWrapperID_' + plid + '_' + boxID),
           $box = $('#boxID_' + plid + '_' + boxID),
           $applyAll = $('#isApplyToAll_' + plid + '_' + boxID);

        if (check) {
            if ($box.val()) {
                addGiftBoxAmountHTML(plid);
            }
            $boxWrapper.show().attr('aria-hidden', 'false');
            $applyAll.show();
            $isGiftBoxMessage.closest('.giftbox-message-section').attr('aria-expanded', 'true');
        }
        else {
            $boxWrapper.hide().attr('aria-hidden', 'true');
            $applyAll.hide();
            // clean message in textarea on frontend side
            $box.val('');
            // run request to clean message on backend side
            updateGiftBoxMessageAJAX(plid, boxID, '');
            $isGiftBoxMessage.closest('.giftbox-message-section').attr('aria-expanded', 'false');
        }
    });

    // listener for gift box amount selects
    $cartForm.on('change', '.js-gift-box-amount', function (event, currentElement) {
        var $giftBoxAmount = $(this),
            plid = $giftBoxAmount.attr('data-plid'),
            // currentElementID will be the clicked giftbox input if it is triggered by its our action or it will be the giftBoxId if it is triggered by the main gift block action, never undefined
            currentElementID = currentElement ? currentElement.giftBoxId : plid;

        if (currentElementID === plid) {
            updateGiftBoxAmountAJAX(plid, $giftBoxAmount.val());
        }
    }).trigger('change');

    // EPM-2 - Monogramming
    $cartForm.find('.js-gift-box-amount.monogrammed').each(function () {
        var $giftBoxAmount = $(this),
            plid = $giftBoxAmount.closest('[id^="giftBoxPanel_"]').attr('id').split('_')[1];

        updateIsGiftAJAX(plid, true);
        updateGiftBoxAmountAJAX(plid, $giftBoxAmount.val());
    });

    // listener for gift messages textarea
    $cartForm.on('blur', '.gift-box-message', function () {
        var $giftBoxMessage = $(this),
            plid = $giftBoxMessage.closest('[id^="giftMessages_"]').attr('id').split('_')[1],
            boxID = $giftBoxMessage.attr('id').split('_')[2],
            message = $giftBoxMessage.val();

        updateGiftBoxMessageAJAX(plid, boxID, message);
    });

    // basic length and line validation
    $cartForm.on('keyup', '.gift-box-message', function () {
        var $textarea = $(this),
            $textareaCounter = $textarea.parent().find('.gift-box-counter'),
            maxlength = $textarea.attr('maxlength') || 129,
            lines = $textarea.val().split('\n'),
            spaces = $textarea.attr('cols'),
            limit = 4;

        $textareaCounter.html(parseInt(maxlength - $textarea.val().length, 10));

        for (var i = 0; i < lines.length; i++) {
            if (lines[i].length <= spaces) {
                continue;
            }

            var j = 0,
                space = spaces;

            while (j++ <= spaces) {
                if (lines[i].charAt(j) === ' ') space = j;
            }

            lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || '');
            lines[i] = lines[i].substring(0, space);
        }

        if (lines.length > limit) {
            $textarea.css('color', 'red');
            setTimeout(function () {
                $textarea.css('color', '');
            }, 1000);
        }

        $textarea.val(lines.slice(0, limit).join('\n'));
    });

    $('.gift-box-message').trigger('keyup');

    // listener for apply to all link
    $cartForm.on('click', '.gift-message-apply-to-all', function (e) {
        e.preventDefault();

        var $giftMessageApplyToAll = $(this),
            plid =$giftMessageApplyToAll.closest('[id^="giftMessages_"]').attr('id').split('_')[1],
            boxID = $giftMessageApplyToAll.attr('id').split('_')[2],
            message = $('#boxID_' + plid + '_' + boxID).val();

        applyToAllGiftBoxMessages(plid, boxID, message);
    });

    // ERV-439 - maximum quantity in cart
    quantityValidation();
}

function quantityValidation() {
    $('.cartpage-input-quantity').each(function () {
        var $this = $(this);

        $this.on('keyup change', function () {
            var $input = $(this),
                $inputError = $input.siblings('.quantity-range-error');

            if (Number($input.val()) > Number($input.attr('max'))) {
                $inputError.html(Resources.VALIDATE_QTY.replace('{0}', $input.attr('max'))).fadeIn().delay(2000).fadeOut();
                $input.val($this.attr('max'));
            }
        });
    });
}

function applyToAllGiftBoxMessages(plid, boxID, message) {
    var $giftboxLoader = $('.js-giftbox-loader-' + plid);

    window.vbqUtils.progressLoader(true, $giftboxLoader);
    saveGiftBoxesStates();

    $.ajax({
        method: 'POST',
        url: Urls.setAllGiftMessages,
        data: {
            plid: plid,
            boxID: boxID,
            message: message
        },
        cache: false
    }).done(function (data) {
        var giftBoxMessagesErrorDomElement = $('#giftBoxMessagesError_' + plid);
        if (data.error) {
            giftBoxMessagesErrorDomElement.html(data.message);

            if (data.type !== 'xss') {
                window.location.reload(true);
            }
        }
        else {
            getGiftMessagesHTML(plid);
            giftBoxMessagesErrorDomElement.html('');
        }

        window.vbqUtils.progressLoader(false, $giftboxLoader);
    });
}
/**
 * Update gift message for current gift box of pli
 *
 * @param plid - productLineItem ID
 * @param boxID - box ID of productLineItem
 * @param message - gift box message content
 */
function updateGiftBoxMessageAJAX(plid, boxID, message) {
    $.ajax({
        method: 'POST',
        url: Urls.setGiftMessage,
        data: {
            plid: plid,
            boxID: boxID,
            message: message
        },
        cache: false,
        async: true
    }).done(function (data) {
        var giftBoxMessagesErrorDomElement = $('#giftBoxMessagesError_' + plid);
        if (data.error) {
            giftBoxMessagesErrorDomElement.html(data.message);

            // if it's xss error then don't reload the page and let the error message be visiable.
            if (!data.type || data.type !== 'xss') {
                window.location.reload(true);
            }
        }
        else {
            giftBoxMessagesErrorDomElement.html('');
        }
    });
}

/**
 * Update plid with new gift value
 *
 * @param plid - productLineItem ID
 * @param status - true if productLineItem was marked for a gift, false if unmarked
 */
function updateIsGiftAJAX(plid, status) {
    $.ajax({
        method: 'POST',
        url: Urls.updateIsGift,
        data: {
            plid: plid,
            isGift: status
        },
        cache: false
    }).done(function (data) {
        var giftBoxMessagesErrorDomElement = $('#giftBoxMessagesError_' + plid);
        if (!data.error && status) {
            addGiftBoxAmountHTML(plid);
            giftBoxMessagesErrorDomElement.html('');
        }
        else if (data.error) {
            giftBoxMessagesErrorDomElement.html(data.message);
            window.location.reload(true);
        }
        else {
            giftBoxMessagesErrorDomElement.html('');
        }
    });
}

/**
 * Update pli gift system attribute
 *
 * @param plid - productLineItem ID
 * @param giftBoxAmount - gift box quantity for productLineItem
 */
function updateGiftBoxAmountAJAX(plid, giftBoxAmount) {
    var $giftAmountInput = $('#giftBoxAmount_' + plid),
        $productBasketQuantity = $('.mini-cart-product[data-uuid=' + plid + ']').find('[data-quantity]');

    saveGiftBoxesStates();

    // ERV-483 - Error on gift box validation despite of front-end checks - no ajax request if the quantity is not valid
    if (Number($giftAmountInput.val()) <= Number($productBasketQuantity.data('quantity'))) {
        $.ajax({
            method: 'POST',
            url: Urls.setGiftBoxAmount,
            data: {
                plid: plid,
                count: giftBoxAmount
            },
            cache: false
        }).done(function (data) {
            if (!data.error) {
                getGiftMessagesHTML(plid);
            }
        });
    }
    else {
        $('#giftBoxMessagesError_' + plid).html(Resources.GIFT_ERROR).fadeIn().delay(2000).fadeOut();

        // Reset gift box messages amount to the current number of products added to the cart
        $giftAmountInput.val($productBasketQuantity.data('quantity')).trigger('change');

        // Show error message if the gift messages number is greater than product item quantity - update the cart with the new quantity
        $('#giftBoxMessagesError_' + plid).parents('.cart-row').find('.quantity-range-error').html(Resources.UPDATE_QTY_ERROR).fadeIn().delay(2000).fadeOut();
    }
}

/**
 * Return gift box html
 *
 * @param plid productLineItem ID
 */
function addGiftBoxAmountHTML(plid) {
    $.ajax({
        method: 'GET',
        url: Urls.getGiftBoxHTML,
        data: {
            plid: plid
        },
        cache: false
    }).done(function (data) {
        $('#giftBoxPanel_' + plid).html(data);
        $cartForm.find('.js-gift-box-amount').trigger('change', [{giftBoxId: plid}]);
        // Initialize the jQuery UI elements
        window.vbqUtils.initializeJQueryUiPlugins();
    });
}

/**
 * Clean html from unused elements
 *
 * @param plid productLineItem ID
 */
function resetGiftBoxMessages(plid) {
    $.ajax({
        method: 'POST',
        url: Urls.resetGiftBoxMessages,
        data: {
            plid: plid
        },
        cache: false
    }).done(function (data) {
        if (!data.error) {
            $('#giftBoxPanel_' + plid).empty();
            $('#giftMessages_' + plid).empty();
            $('#giftBoxMessagesError_' + plid).empty();
        }
        else {
            $('#giftBoxMessagesError_' + plid).html(data.message);
        }
    });
}

/**
 * Get gift messages html for current pli
 *
 * @param plid productLineItem ID
 */
function getGiftMessagesHTML(plid) {
    $.ajax({
        method: 'GET',
        url: Urls.getGiftMessagesHTML,
        data: {
            plid: plid,
            format: 'ajax'
        },
        cache: false
    }).done(function (data) {
        $('#giftMessages_' + plid).html(data).find('.gift-box-message').trigger('keyup');
        // Initialize the jQuery UI elements
        window.vbqUtils.initializeJQueryUiPlugins();

        applyGiftBoxesStates();
    });
}

/**
 * Save original gift box open/closed states
 */
function saveGiftBoxesStates() {
    // for security empty the array first
    giftBoxesStates = [];
    $('.is-gift-box-message').each(function () {
        var $this = $(this),
            checked = $this.prop('checked');

        giftBoxesStates.push(checked);
    });
}

/**
 * Apply originall gift box open/closed states
 */
function applyGiftBoxesStates() {
    // update the original state of the gift box
    $('.is-gift-box-message').each(function (index, element) {
        var $this = $(element),
            checked = giftBoxesStates[index];

        if (checked && !$this.prop('checked')) {
            // mark checkbox as checked based on the history from giftBoxesStates array
            $this.trigger('click');
        }
    });

    // for security empty array after using it
    giftBoxesStates = [];
}

exports.init = function init() {
    initializeEvents();
};
