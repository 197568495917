// ERF-16 Home page
// Sitegenesis base code unusable. Replaced whole file.

'use strict';

function initializeDOM() {
    var $socialSlider = $('.socialmedia-carousel');

    window.vbqUtils.breakpoint.set([
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('mobileAndTablet'),
            enter: function () {
                $socialSlider.slick('unslick').slick({
                    dots: true,
                    arrows: false,
                    slidesToShow: 2,
                    rows:2,
                    slidesPerRows: 2
                });
            }
        },{
            media: window.vbqUtils.breakpoint.getMediaQuery('fromDesktop'),
            enter: function () {
                $socialSlider.slick('unslick').slick({
                    dots: false,
                    arrows: true,
                    slidesToShow: 4
                });
            }
        }
    ]);
}

var storefront = {
    init: function () {
        initializeDOM();
    }
};

module.exports = storefront;
