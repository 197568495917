'use strict';

function initializeDOM() {
}

function initializeEvents() {
    // Accordion menu on FAQ page
    var $html = $('html, body'),
        $faqParent = $('.js-faq'),
        $faqAccToggle = $('.faq-info-btn'),
        $faqAccPanel = $('.faq-info-desc'),
        faqScroll = !($faqParent.attr('data-skip-scroll') || $faqParent.hasClass('js-faq-skip-scroll')),
        viewOffset = parseInt($(window).height() / 3, 10);

    // EADA21-56 ADA on custom accordion
    $faqAccToggle.each(function () {
        var $this = $(this),
            $next = $this.parent('.faq-info-title').next('.faq-info-desc');

        // set id if it is missed from content asset html
        if ($this.attr('id') == undefined) {
            $this.uniqueId();
        }
        $next.uniqueId();
        $this.attr('role', 'button').attr('aria-expanded', 'false').attr('tabindex', '0').attr('aria-controls', $next.attr('id'));
        $next.attr('aria-hidden', 'true').attr('aria-labelledby', $this.attr('id'));
    });

    $faqAccToggle.on('click keydown', function (e) {
        // mouse click or enter/space key
        if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
            // prevent the default action to stop scrolling when space is pressed
            e.preventDefault();

            var $this = $(this),
                $next = $this.parent('.faq-info-title').next('.faq-info-desc'),
                isMenuActive = $this.attr('aria-expanded') == 'true' ? true : false;

            // close all panels
            $faqAccToggle.not($this).attr('aria-expanded', 'false').removeClass('active');
            $faqAccPanel.not($next).attr('aria-hidden', 'true').removeClass('active');

            // open current panel
            $this.attr('aria-expanded', isMenuActive ? 'false' : 'true').toggleClass('active');
            $next.attr('aria-hidden', isMenuActive ? 'true' : 'false').toggleClass('active');

            if (faqScroll) {
                $html.animate({
                    scrollTop: $(this).parent('.faq-info-title').next('.faq-info-desc').offset().top - viewOffset
                }, 1000);
            }
        }
    });

    // Open corresponding section in case of anchored URL
    if (window.location.hash.length > 1) {
        var $targetElement = $('.faq-wrapper').find(window.location.hash);

        if ($targetElement.length > 0) {
            var $faqInfoBtn = $targetElement.find('.faq-info-btn');

            if ($faqInfoBtn.length > 0) {
                $faqInfoBtn.trigger('click');
            }
        }

        // clean up URL from hash to fix scroll conflicts on page re-load
        window.history.replaceState({}, '', window.location.href.split('#')[0]);
    }
}

var customerservice = {
    init: function () {
        initializeDOM();
        initializeEvents();
    }
};

module.exports = customerservice;
