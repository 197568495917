'use strict';

var ajax = require('../../ajax'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    _debounce = require('../../lib/lodash.debounce'),
    accessibility = require('../../accessibility'),
    productSetCache = {
        $addToCart: $('.add-to-cart'),
        $addAllToCart: $('#add-all-to-cart'),
        $productSetList: $('#productset-list')
    };

function updateAddToCartButtons() {
    if (productSetCache.$productSetList.find('.add-to-cart[disabled]').length > 0) {
        productSetCache.$addAllToCart.attr('disabled', 'disabled');
        // product set does not have an add-to-cart button, but product bundle does
        productSetCache.$addToCart.attr('disabled', 'disabled');
    }
    else {
        productSetCache.$addAllToCart.removeAttr('disabled');
        productSetCache.$addToCart.removeAttr('disabled');
    }
}

function updateProductSetItem($target, isSize, noFocus) {
    var $option = null;
    if (isSize && window.vbqUtils.isSR) {
        $option = $target.find('option:checked');
        if ($option.val() == '') { // no size selected, default value
            $option = $target.find('option').last();
        }
    }

    var urlString = !window.vbqUtils.isSR ? $target.attr('data-href') : (isSize ? $option.attr('data-url') : $target.attr('data-href')),
        url = window.Urls.getSetItem + '?' + urlString.split('?')[1],
        $container = $target.closest('.js-product-set-item'),
        selectedSize = isSize ? (!window.vbqUtils.isSR ? $container.find('.js-size-radio.selected').attr('data-value') : $target.val()) : null,
        qty = $container.find('form input[name="Quantity"]').first().val();

    productSetCache.$addToCart.attr('disabled', 'disabled');
    window.vbqUtils.progressLoader(true, $container.find('.js-set-progress-loader'));

    ajax.load({
        url: util.appendParamToURL(url, 'Quantity', isNaN(qty) ? '1' : qty),
        target: $container,
        callback: function () {
            // update size dropdown and add-to-cart button
            if (selectedSize) {
                var $addToCart = $container.find('.add-to-cart');

                if (window.vbqUtils.isSR) {
                    $container.find('.js-product-set-size-select').val(selectedSize);
                }
                $container.find('.js-scheduledShippingDate').removeClass('hidden');

                if ($addToCart.attr('data-productUnavailable') == 'true') {
                    // out of stock case
                    $addToCart.attr('disabled', 'disabled');
                }
                else {
                    $addToCart.removeAttr('disabled');
                }
            }

            updateAddToCartButtons();
            tooltip.init();
            //ERB-52 International Shipping
            window.vbqUtils.priceDisplay.refresh();

            // update images
            if (!isSize) {
                var $newImages = $container.find('.js-updateImages'),
                    $imageContainer = $container.closest('.productset-item-content').find('.product-image-container');
                if ($newImages.length && $imageContainer.length) {
                    $imageContainer.html($newImages.html());
                    $newImages.remove();

                    initSliders($imageContainer.find('.pdp-ps-image-slider'));
                }
            }

            // EADA-89 - color swatch with radio button behavior
            // Init fake radio groups according to the ADA Compliance: color switch
            accessibility.fakeRadioGroupInit($container);

            // Scroll in the color list to selected color item
            window.vbqUtils.colorListSet($container, true);

            // set focus back
            if (!noFocus) {
                if (!isSize) {
                    $container.find('.js-color-radio.selected').parent().focus();
                }
                else {
                    if (!window.vbqUtils.isSR) {
                        $container.find('.js-size-radio.selected').focus();
                    }
                    else {
                        $container.find('.js-product-set-size-select').focus();
                    }
                }
            }
        }
    });
}

module.exports = function () {
    if (productSetCache.$productSetList.length > 0) {
        updateAddToCartButtons();
    }

    // EADA-89 - color swatch with radio button behavior: attach logic after fake radio state updates
    window.vbqUtils.cache.$body.on('afterFakeRadioStateUpdated', '.js-product-set-item .js-color-radio', function (e, configs) {
        // call ajax refresh on keyboard select action
        if (configs.typeEvent == 'keydown' && configs.actionEvent == 'select') {
            $(this).find('.js-swatchanchor').trigger('customClick');
        }
    });

    // click on color swatch for product set
    productSetCache.$productSetList.on('click customClick', '.js-product-set-item .js-swatchanchor', function (e) {
        e.preventDefault();
        e.stopPropagation(); // prevent conflict with fake radio button wrapper .js-color-radio
        var $this = $(this),
            $li = $this.parents('li');
        if ($li.hasClass('unselectable')) { return; }

        updateProductSetItem($this, $li.hasClass('js-size-radio'));
    });

    // change size selet on for product set
    productSetCache.$productSetList.on('change', '.js-product-set-item .js-product-set-size-select', function () {
        updateProductSetItem($(this), true);
    });

    window.setTimeout(function () {
        $('.js-product-set-size-select').each(function () {
            updateProductSetItem($(this), true, true);
        });
    }, 50);

    // add another layer of window size handling
    window.vbqUtils.cache.$window.resize(_debounce(function () {
        initSliders();
        //lazySizes.autoSizer.checkElems(); // force update of the image as the slider container width changed
    }, 250));

    $('#productset-list').find('.lazyload.forced').each(function (index, el) {
        window.lazySizes.loader.unveil(el);
    });

    initSliders();
};

var initSliders = function ($el) {
    var configs = {
        mobileAndTablet: {
            slidesToShow: 1,
            dots: true,
            arrows: false
        },
        desktop: {
            slidesToShow: 1,
            dots: false,
            arrows: true
        }
    };

    var config = configs.desktop;
    if (window.vbqUtils.breakpoint.is('mobileAndTablet')) {
        config = configs.mobileAndTablet;
    }

    var init = function ($slider) {
        if ($slider.hasClass('slick-initialized')) {
            $slider.slick('unslick');
        }

        $slider.slick(config);
    };

    if ($el !== null && $el !== undefined && $el.length) {
        init($el);
    }
    else {
        $('.pdp-ps-image-slider').each(function () {
            init($(this));
        });
    }
};
