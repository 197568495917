'use strict';

var progress = require('../progress');

/**
 * Init additional checks and events for registration form
 */
function init() {
    var $registrationForm = $('#RegistrationForm'),
        $registrationSubmit = $registrationForm.find('.js-registration-submit'),
        $title,
        titleValue;

    // if the form was not found don't run rest of the code
    if (!$registrationForm.length) {
        return;
    }

    //ERB-51 - SEO Tracking - Google Tag Manager - push GTM data on submit
    $registrationForm.on('submit', function () {
        if ($(this).valid()) {
            // blocked submit button
            $registrationSubmit.addClass('disabled');
            progress.show($registrationSubmit);

            // ECM24-8 - Datalayer update (GA4 GTM)
            window.vbqUtils.gtmUtils.pushSignUpEvent();
        }
    });

    $title = $registrationForm.find('#dwfrm_profile_customer_title');
    titleValue = $registrationForm.find('#title-value').val();

    // security to force the value, DW bug
    if (titleValue !== null && titleValue !== undefined && titleValue !== '' && $title.val() != titleValue) {
        $title.val(titleValue);
    }
}

module.exports = {
    init: init
};
