'use strict';

var _debounce = require('../../lib/lodash.debounce');

/**
 * ERB-111 Customer restock alerts
 * ERB-261 Product in store availability logic
 */
function initializeEvents($wrapper, $emailForm) {
    // standard form validation (formPrepare.js, validationLogic.js, ...) can not be used here
    // so the form status and error message are managed directly through jQuery validator
    var $form = $wrapper ? $wrapper.find('.js-back-in-stock-subscription') : $emailForm,
        $email = $form.find('.js-email'),
        validator = $form.validate(),
        options = {};

    if ($form.length) {
        $email.rules('add', {
            required: true,
            email: true
        });

        $email.off().on('keyup input', _debounce(function () {
            if ($(this).val() !== '') {
                validator.element(this);
            }
        }, 200));

        // init floating label
        window.vbqUtils.initFloatingLabels($form);

        //run submit if ok button was clicked
        $form.off().on('click', '.gl_input-submit.gl_input-ok', function (e) {
            e.preventDefault();

            $form.submit();
        }).on('submit', function (e) {
            e.preventDefault();

            if (!$form.valid()) {
                return false;
            }

            var $formfieldset = $form.find('fieldset');

            //send email to server
            $.ajax({
                method: 'POST',
                url: window.Urls.subscribeToBackInStock,
                data: $form.serialize(),
                cache: false,
                beforeSend: function () {
                    options = {};
                    $formfieldset.removeClass('gl_has-success gl_has-error').addClass('gl_loading');
                }
            }).done(function (data) {
                $formfieldset.removeClass('gl_loading');
                if (!data.alert && data.type == 'success') {
                    $formfieldset.addClass('gl_has-success');
                }
                else {
                    switch (data.type) {
                        case 'format':
                            options[$email.attr('name')] = window.Resources.VALIDATE_EMAIL;
                            break;
                        default:
                            options[$email.attr('name')] = window.Resources.VALIDATE_EMAIL_GLOBAL;
                            break;
                    }
                    validator.showErrors(options);
                }
            }).fail(function () {
                $formfieldset.removeClass('gl_loading');
                options[$email.attr('name')] = window.Resources.SERVER_ERROR;
                validator.showErrors(options);
            });
        });
    }
}

exports.init = function init ($wrapper, $emailForm) {
    initializeEvents($wrapper, $emailForm);
};
