'use strict';

var updateWishlistIcons = function () {
    var $wishlistToggles = $('.js-wishlistToggle');
    // product tiles are in cache, so the icon must be updated after page load
    // for the pdp main product wishlist icon, it's loaded with the add-to-cart form ajax refresh, so it's up-to-date
    $wishlistToggles.not('.product-detail-wishlist').each(function () {
        var $button = $(this),
            isAlreadyInWishlist = window.vbq.productWishlist && window.vbq.productWishlist.indexOf($button.attr('data-masterkey')) != -1;
        if (isAlreadyInWishlist) {
            $button.attr('title', $button.attr('data-removetitle'));
            $button.attr('aria-label', $button.attr('data-removetitle'));
            $button.find('.js-addwish').addClass('hidden');
            $button.find('.js-removewish').removeClass('hidden');
            $button.find('.js-wishlisticon').addClass('filled');
        }
        else {
            $button.attr('title', $button.attr('data-addtitle'));
            $button.attr('aria-label', $button.attr('data-addtitle'));
            $button.find('.js-addwish').removeClass('hidden');
            $button.find('.js-removewish').addClass('hidden');
            $button.find('.js-wishlisticon').removeClass('filled');
        }
    });

    // ECM22-67 - Header wishlist icon should be filled when wishlist is not empty
    $('.js-headerwishlist').find('.js-wishlisticon').toggleClass('filled', window.vbq.productWishlist && window.vbq.productWishlist !== '' && window.vbq.productWishlist.length > 0);

    $wishlistToggles.off('click');

    $wishlistToggles.click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        var $button = $(this);

        $.ajax({
            url: $button.attr('data-href') + '&format=ajax'
        }).done(function (data) {
            if (data.list) {
                // update the front-end side list
                window.vbq.productWishlist = data.list;

                if (window.vbq.productWishlist.indexOf($button.attr('data-masterkey')) != -1) {
                    $button.attr('title', $button.attr('data-removetitle'));
                    $button.attr('aria-label', $button.attr('data-removetitle'));
                    $button.find('.js-addwish').addClass('hidden');
                    $button.find('.js-removewish').removeClass('hidden');
                    $button.find('.js-wishlisticon').addClass('filled');

                    // ECM24-8 - Datalayer update (GA4 GTM)
                    window.vbqUtils.gtmUtils.addToWishlist($button);
                }
                else {
                    $button.attr('title', $button.attr('data-addtitle'));
                    $button.attr('aria-label', $button.attr('data-addtitle'));
                    $button.find('.js-addwish').removeClass('hidden');
                    $button.find('.js-removewish').addClass('hidden');
                    $button.find('.js-wishlisticon').removeClass('filled');
                }
            }

            // ECM22-67 - Header wishlist icon should be filled when wishlist is not empty
            $('.js-headerwishlist').find('.js-wishlisticon').toggleClass('filled', window.vbq.productWishlist && window.vbq.productWishlist !== '' && window.vbq.productWishlist.length > 0);
        });
    });
};

// for product list and detail pages
exports.updateWishlistIcons = updateWishlistIcons;

// for wishlist page
exports.init = function () {
    $('.js-wishlistRemove').click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        var $button = $(this);

        $.ajax({
            url: $button.attr('data-href') + '&format=ajax'
        }).done(function (data) {
            if (data.list) {
                // update the front-end side list
                window.vbq.productWishlist = data.list;

                $button.closest('.js-wishlistProductTile').remove();
            }

            // ECM22-67 - Header wishlist icon should be filled when wishlist is not empty
            $('.js-headerwishlist').find('.js-wishlisticon').toggleClass('filled', window.vbq.productWishlist && window.vbq.productWishlist !== '' && window.vbq.productWishlist.length > 0);
        });
    });

    $('.js-shareListButton').click(function () {
        $('.js-shareListLink').removeClass('hidden');
    });

    $('#js-wishlistShareCopyButton').click(function (e) {
        e.preventDefault();
        // not supported by all navigators (+ permission prompt on some)
        // navigator.clipboard.writeText($('#js-wishlistShareCopyURL').text());

        // use deprecated document.execCommand instead
        var copyText = document.getElementById("js-wishlistShareCopyURL");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    });
};
