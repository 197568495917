'use strict';

var outlet = {
    init: function () {
        var $form = $('#js-outletLoginForm');

        $form.validate(); // revalidate the form is necessary before adding new rules
        $form.find(':input').filter('[type="email"]').rules('add', {
            email: true
        });
    }
};

module.exports = outlet;
