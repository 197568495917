'use strict';

var swimwearstylesCache = {
    $complexSwimwearstylesCarousels: $('.js-complex-swimwearstyles-carousel'),
    $partTitleCarouselDotsAll: $('.js-part-title-dots')
};

// re-set custom part controls
function setInitialPartState() {
    swimwearstylesCache.$complexSwimwearstylesCarousels.each(function () {
        var $complexSwimwearstylesCarousel = $(this),
            $partTitleCarouselDots = $complexSwimwearstylesCarousel.find('.js-part-title-dots'),
            $partTitleCarouselDotsBtns = $partTitleCarouselDots.find('.js-part-title-dot-button');

        $partTitleCarouselDotsBtns.removeAttr('aria-current')
            .parent('.js-part-title-dot-li').removeClass('slick-active')
            .end().first().attr('aria-current', 'true')
            .parent('.js-part-title-dot-li').addClass('slick-active');
    });
}

var swimwearstyles = {
    /**
     * @private
     * @function
     * @description init events for the swimwearstyles PD component
     */
    init: function () {
        // control custom part dots display
        window.vbqUtils.breakpoint.set([
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('mobile'),
                enter: function () {
                    swimwearstylesCache.$partTitleCarouselDotsAll.each(function () {
                        var $dotsList = $(this);
                        $dotsList.toggle($dotsList.attr('data-enable-mobile') == 'true');
                    });

                    setInitialPartState();
                }
            },
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('tablet'),
                enter: function () {
                    swimwearstylesCache.$partTitleCarouselDotsAll.each(function () {
                        var $dotsList = $(this);
                        $dotsList.toggle($dotsList.attr('data-enable-tablet') == 'true');
                    });

                    setInitialPartState();
                }
            },
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('desktop'),
                enter: function () {
                    swimwearstylesCache.$partTitleCarouselDotsAll.each(function () {
                        var $dotsList = $(this);
                        $dotsList.toggle($dotsList.attr('data-enable-desktop') == 'true');
                    });

                    setInitialPartState();
                }
            },
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('fromDesktopLarge'),
                enter: function () {
                    swimwearstylesCache.$partTitleCarouselDotsAll.each(function () {
                        var $dotsList = $(this);
                        $dotsList.toggle($dotsList.attr('data-enable-desktop-large') == 'true');
                    });

                    setInitialPartState();
                }
            }
        ]);

        // init custom part controls
        swimwearstylesCache.$complexSwimwearstylesCarousels.each(function () {
            var $complexSwimwearstylesCarousel = $(this),
                $partTitlesCarousel = $complexSwimwearstylesCarousel.find('.js-part-titles-carousel'),
                $partTitleCarouselDots = $complexSwimwearstylesCarousel.find('.js-part-title-dots'),
                $partTitleCarouselDotsBtns = $partTitleCarouselDots.find('.js-part-title-dot-button');

            $partTitleCarouselDotsBtns.on('click', function () {
                var $dotBtn = $(this);

                if ($partTitlesCarousel.hasClass('slick-initialized')) {
                    var slickGoToStr = $dotBtn.attr('data-start-tile-slide') || '0',
                        slickGoToNum = parseInt(slickGoToStr, 10);

                    $partTitlesCarousel.slick('slickGoTo', slickGoToNum);

                    $partTitleCarouselDotsBtns.removeAttr('aria-current').parent('.js-part-title-dot-li').removeClass('slick-active');
                    $dotBtn.attr('aria-current', 'true').parent('.js-part-title-dot-li').addClass('slick-active');
                }
            });
        });
    }
};

module.exports = swimwearstyles;
