'use strict';

/**
 * Load a 3rd party script
 * @param {String} url - url string, to load the script
 * @param {Function} callback - function to be run after the script is loaded
 * @returns {documentElement} - the new created script element
 */
module.exports = function (url, callback) {
    // Adding the script tag to the head
    var head = document.head,
        script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;

    // Then bind the event to the callback function.
    // There are several events for cross browser compatibility.
    if (callback) {
        script.onreadystatechange = callback;
        script.onload = callback;
    }

    // Fire the loading
    head.appendChild(script);

    // return the new script element
    return script;
};
