'use strict';

var cache = {},
    util = require('../util');

function initCache() {
    cache.$document = $(document);
    cache.$productDataWrapper = cache.$document.find('#js-product-data_wrapper');
    cache.classes = {
        removeProductButton: '.js-remove-product',
        formButton: 'js-save-products'
    };
    cache.ids = {
        csrfInput: 'input#js-hidden-input'
    };
}

function initEvents() {
    cache.$productDataWrapper.on('click', cache.classes.removeProductButton, function () {
        var $this = $(this),
            productKey = $this.attr('data-product-key'),
            url = $this.attr('data-url'),
            $csrfInput = cache.$productDataWrapper.find(cache.ids.csrfInput),
            $deleteTileLoader = $this.parent('.js-product-tile').find('.js-productsoverview-tile-loader'),
            data = {
                format: 'ajax',
                productKey: productKey
            };

        // security
        if (!$csrfInput || $csrfInput.length == 0) {
            return;
        }

        // add csrf token
        data[$csrfInput.attr('name')] = $csrfInput.val();
        util.progressLoader(true, $deleteTileLoader);

        $.ajax({
            url: url,
            method: 'post',
            data: data
        })
        .done(function (data, _status, xhr) {
            var contentType = xhr.getResponseHeader('content-type') || '';

            if (contentType == 'application/json;charset=UTF-8') {
                if (data.redirectUrl) {
                    window.location.href = data.redirectUrl;
                }
                return;
            }

            // it should be a html
            cache.$productDataWrapper.html(data);
        })
        .fail(function () {
            // reload the page
            window.location.reload();
        });
    });
}

exports.init = function () {
    initCache();
    initEvents();
};
