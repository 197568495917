'use strict';

/* globals grecaptcha */

var cache = {};

/**
 * Initializes the global grecaptcha object and overwrites its default `ready`
 * method to ensure that it can be safely called at any time.
 */
function initGrecaptcha() {
    // How this code snippet works:
    // This logic overwrites the default behavior of `grecaptcha.ready()` to
    // ensure that it can be safely called at any time. When `grecaptcha.ready()`
    // is called before reCAPTCHA is loaded, the callback function that is passed
    // by `grecaptcha.ready()` is enqueued for execution after reCAPTCHA is
    // loaded.
    if(typeof grecaptcha === 'undefined') {
        window.grecaptcha = {};
    }
    grecaptcha.ready = function(cb) {
        if(typeof grecaptcha === 'undefined') {
            // window.__grecaptcha_cfg is a global variable that stores reCAPTCHA's
            // configuration. By default, any functions listed in its 'fns' property
            // are automatically executed when reCAPTCHA loads.
            var c = '___grecaptcha_cfg';
            window[c] = window[c] || {};
            (window[c].fns = window[c].fns||[]).push(cb);
        } else {
            cb();
        }
    };
}

/**
 * Initializes the recaptchaHelper cache
 * @returns {void}
 */
function initCache() {
    // set recaptcha site key
    cache.reCaptchaSiteKey = window.vbq.siteId.indexOf('SR') == -1 ? '6LeftxQnAAAAACwmOQvZ6eAyrRByr0TpzePsIlIl' : '6LcWuBQnAAAAACn4XYLHrQaAEdWRc-xWt0IbMNJ9';
    cache.$reCaptchaButtons = $('.js-recaptcha-button');

    // set action
    cache.defaultAction = 'submit';
}

/**
 * Initializes the reCaptcha on a given element
 * @param {number} _index - index of the element
 * @param {Object} element - the element to initialize reCaptcha on
 */
function initReCaptcha(_index, element) {
    var $button = $(element),
        action = $button.data('action') || cache.defaultAction,
        $form = $button.closest('form');

    $button.on('click', function (e) {
        // if form is not valid do not call recaptcha
        if (!$form.valid()) {
            return;
        }

        e.preventDefault();

        grecaptcha.ready(function() {
            e.preventDefault();
            grecaptcha.execute(cache.reCaptchaSiteKey, {action: action}).then(function(token) {
                // Add token to form and submit
                $form.append('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
                $form.submit();
            });
        });
    });
}

/**
 * Initializes the events for reCaptcha
 */
function initEvents() {
    cache.$reCaptchaButtons.each(initReCaptcha);
}

/**
 * Initializes the recaptcha helper
 */
function init() {
    initGrecaptcha();
    initCache();
    initEvents();
}

module.exports = {
    init: init
};
