'use strict';

/* ajaxTabAccessibilityInit Helpers */
function handleAjaxTabKeydown(e) {
    var $activeTab = $(e.target),
        activeTabNum = parseInt($activeTab.attr('data-tab-num'), 10),
        tabKeyCodes = {
            'left'  : 37,
            'right' : 39
        };

    if (e.keyCode == tabKeyCodes.right) {
        if ($activeTab.attr('data-tab-last') == 'true') {
            e.data.firstTab.attr('tabindex', '0').focus();
        } else {
            e.data.allTabs.filter('[data-tab-num=' + (activeTabNum + 1) +']').attr('tabindex', '0').focus();
        }

        $activeTab.attr('tabindex', '-1');
    }

    if (e.keyCode == tabKeyCodes.left) {
        if ($activeTab.attr('data-tab-first') == 'true') {
            e.data.lastTab.attr('tabindex', '0').focus();
        } else {
            e.data.allTabs.filter('[data-tab-num=' + (activeTabNum - 1) +']').attr('tabindex', '0').focus();
        }

        $activeTab.attr('tabindex', '-1');
    }
}

/* trapFocusInit Helpers */
function handleLastKeydown(e) {
    if (e.keyCode === 9 && !e.shiftKey) {
        e.preventDefault();

        e.data.firstFocusableElement.focus();
    }
}

function handleFirstKeydown(e) {
    if (e.keyCode === 9 && e.shiftKey) {
        e.preventDefault();

        e.data.lastFocusableElement.focus();
    }
}

var accessibility = {
    /**
     * @function
     * @description initialization of fake radio group accessibility
     * @param {DOM element} $radioGroupsContainer the DOM element is the container of .js-ada-radio-group
     */
    fakeRadioGroupInit: function ($radioGroupsContainer) {
        var $radioGroups = $radioGroupsContainer ? $radioGroupsContainer.find('.js-ada-radio-group') : $('.js-ada-radio-group'),
            radioKeyCodes = {
                'enter' : 13,
                'space' : 32,
                'left'  : 37,
                'up'    : 38,
                'right' : 39,
                'down'  : 40
            },
            resetRadioGroupState = function ($fakeRadioButtons) {
                if ($fakeRadioButtons.length > 1) {
                    $fakeRadioButtons.removeClass('selected').attr('aria-checked', 'false').attr('tabindex', '-1');
                }
            },
            setSelectedRadioState = function ($fakeRadioButton, isFocus) {
                $fakeRadioButton.addClass('selected').attr('aria-checked', 'true').attr('tabindex', '0');

                if (isFocus != false) {
                    $fakeRadioButton.focus();
                }
            };

        function handleFakeRadioClick(e) {
            var $target = $(e.target),
                $radio = $target.attr('role') == 'radio' ? $target : $target.parents('[role=radio]');

            resetRadioGroupState(e.data.radioButtons);
            setSelectedRadioState($radio);
            $radio.trigger('afterFakeRadioStateUpdated', [{typeEvent: 'click'}]);
        }

        function handleSetActiveRadioState(e, configs) {
            var $target = $(e.target),
                $radio = $target.attr('role') == 'radio' ? $target : $target.parents('[role=radio]');

            resetRadioGroupState(e.data.radioButtons);
            setSelectedRadioState($radio, configs.isFocus);

            if (configs.isCallback != false) {
                $radio.trigger('afterFakeRadioStateUpdated', [{typeEvent: 'set'}]);
            }
        }

        function handleFakeRadioKeydown(e) {
            var $target = $(e.target),
                $currentRadioBtn = $target.attr('role') == 'radio' ? $target : $target.parents('[role=radio]'),
                $allRadioButtons = e.data.radioButtons,
                currentAction = '',
                currentRadioBtnID = parseInt($currentRadioBtn.attr('data-radio-id'), 10),
                $prevRadioButton = $allRadioButtons.filter('[data-radio-id=' + (currentRadioBtnID - 1) +']'),
                $nextRadioButton = $allRadioButtons.filter('[data-radio-id=' + (currentRadioBtnID + 1) +']');

            // reset radio buttons state
            if (e.keyCode == radioKeyCodes.enter || e.keyCode == radioKeyCodes.space || e.keyCode == radioKeyCodes.left || e.keyCode == radioKeyCodes.up || e.keyCode == radioKeyCodes.right || e.keyCode == radioKeyCodes.down) {
                // prevent the default action to stop scrolling when space is pressed
                e.preventDefault();

                // reset
                resetRadioGroupState($allRadioButtons);

                switch (e.keyCode) {
                    // activate current radio button
                    case radioKeyCodes.enter:
                    case radioKeyCodes.space:
                        currentAction = 'select';

                        setSelectedRadioState($currentRadioBtn);
                        break;

                    // go to prev radio button & activate it
                    case radioKeyCodes.left:
                    case radioKeyCodes.up:
                        currentAction = 'prev';

                        if ($allRadioButtons.length > 1 && $prevRadioButton.length) {
                            setSelectedRadioState($prevRadioButton);
                        }
                        else {
                            setSelectedRadioState($currentRadioBtn);
                        }

                        // force horizontal scroll update on focus (product set case)
                        if ($('#wrapper').find('.product-set-pdp').length
                            && $target.closest('.js-custom-horizontal-scrollbar').length
                        ) {
                            window.vbqUtils.colorListSet($target.closest('.js-custom-horizontal-scrollbar'), false, true);
                        }

                        break;

                    // go to next radio button & activate it
                    case radioKeyCodes.right:
                    case radioKeyCodes.down:
                        currentAction = 'next';

                        if ($allRadioButtons.length > 1 && $nextRadioButton.length) {
                            setSelectedRadioState($nextRadioButton);
                        }
                        else {
                            setSelectedRadioState($currentRadioBtn);
                        }

                        // force horizontal scroll update on focus (product set case)
                        if ($('#wrapper').find('.product-set-pdp').length
                            && $target.closest('.js-custom-horizontal-scrollbar').length
                        ) {
                            window.vbqUtils.colorListSet($target.closest('.js-custom-horizontal-scrollbar'), false, true);
                        }

                        break;

                    default:
                        break;
                }

                $currentRadioBtn.trigger('afterFakeRadioStateUpdated', [{typeEvent: 'keydown', actionEvent: currentAction}]);
            }
        }

        if ($radioGroups.length) {
            $radioGroups.each(function () {
                var $radioGroup = $(this),
                    $radioButtons = $radioGroup.find('[role="radio"]');

                if ($radioButtons.length > 0) {
                    $radioGroup.attr('role', 'radiogroup');

                    $radioButtons.each(function (i) {
                        $(this).attr('data-radio-id', i);
                    });

                    $radioButtons
                        .off('click', null, handleFakeRadioClick)
                        .on('click', null, {radioButtons: $radioButtons, isCallback: true}, handleFakeRadioClick)
                        .off('setActiveRadioState', null, handleSetActiveRadioState)
                        .on('setActiveRadioState', null, {radioButtons: $radioButtons}, handleSetActiveRadioState)
                        .off('keydown', null, handleFakeRadioKeydown)
                        .on('keydown', null, {radioButtons: $radioButtons}, handleFakeRadioKeydown);
                }
            });
        }
    },
    /**
     * @function
     * @description initialization of fake tabs accessibility with ajax panel refresh
     * @param {DOM element} $tabsContainer the DOM element is the container of .js-ada-tabs
     */
    ajaxTabAccessibilityInit: function ($tabsContainer) {
        // Tabs accessibility with ajax panel refreshing is requied arrows nav and circle nav only
        var $tabsGroup = $tabsContainer ? $tabsContainer.find('.js-ada-tabs') : $('.js-ada-tabs');

        if ($tabsGroup.length) {
            $tabsGroup.each(function () {
                var $tabsWrapper = $(this);

                if ($tabsWrapper.attr('data-ajax-tab-init') != 'on') {
                    var $tabs = $tabsWrapper.find('.js-ada-tab');

                    if ($tabs.length > 0) {
                        var num = 0,
                            $firstTab = $tabs.first(),
                            $lastTab = $tabs.last();

                        $tabs.each(function () {
                            $(this).attr('data-tab-num', num);
                            num++;
                        });

                        $firstTab.attr('data-tab-first', 'true');
                        $lastTab.attr('data-tab-last', 'true');
                        $tabs.off('keydown', null, handleAjaxTabKeydown).on('keydown', null, {allTabs: $tabs, firstTab: $firstTab, lastTab: $lastTab}, handleAjaxTabKeydown);
                    }

                    // mark ajaxTabAccessibilityInit attached
                    $tabsWrapper.attr('data-ajax-tab-init', 'on');
                }
            });
        }
    },
    /**
     * @function
     * @description turn on circle focus in the defined wrapper
     * @param {DOM element} $focusableWrapper the DOM elements is the wrapper of children focusable elements : modal, open menu, etc.
     */
    trapFocusInit: function ($focusableWrapper, isFirstFocused, isReinit) {
        var $focusableElements = $focusableWrapper.find('a, button, [role="button"]').not('[tabindex="-1"]').filter(':visible'), // list for mobile CLP filter && mobile menu; can be extended
            $firstFocusableElement = $focusableElements.first();

        if ($focusableWrapper.attr('data-trap-focus') != 'on' || isReinit) {
            var $lastFocusableElement = $focusableElements.last();

            $focusableElements.filter('[data-first-focus=true]').off('keydown', null, handleFirstKeydown).removeAttr('data-first-focus');
            $focusableElements.filter('[data-last-focus=true]').off('keydown', null, handleLastKeydown).removeAttr('data-last-focus');

            // Set focus on first input
            if (isFirstFocused) {
                $firstFocusableElement.focus();
            }

            // Redirect last tab to first input
            $lastFocusableElement.attr('data-last-focus', 'true')
                .on('keydown', null, {firstFocusableElement: $firstFocusableElement}, handleLastKeydown);

            // Redirect last tab to first input
            $firstFocusableElement.attr('data-first-focus', 'true')
                .on('keydown', null, {lastFocusableElement: $lastFocusableElement}, handleFirstKeydown);

            // mark trapFocusInit attached
            $focusableWrapper.attr('data-trap-focus', 'on');
        }
        // EADA23-94 - Set focus on first input on init trap wrapper
        else if ($focusableWrapper.attr('data-trap-focus') == 'on' && isFirstFocused) {
            // Set focus on first input
            if (isFirstFocused) {
                $firstFocusableElement.focus();
            }
        }
    }
};

module.exports = accessibility;
