'use strict';
var cache = {};
var postalMap = {
    // Alabama
    'AL': '35004-36925',
    // Alaska
    'AK': '99501-99950',
    // Arizona
    'AZ': '85001-86556',
    // Arkansas
    'AR': '71601-72959',
    // California
    'CA': '90001-96162',
    // Colorado
    'CO': '80001-81658',
    // Connecticut
    'CT': '06001-06928',
    // Delaware
    'DE': '19701-19980',
    // District of Columbia
    'DC': '20001-20039',
    // Florida
    'FL': '32003-34997',
    // Georgia
    'GA': '30002-39901',
    // Hawaii
    'HI': '96701-96898',
    // Idaho
    'ID': '83201-83877',
    // Illinois
    'IL': '60001-62999',
    // Indiana
    'IN': '46001-47997',
    // Iowa
    'IA': '50001-52809',
    // Kansas
    'KS': '66002-67954',
    // Kentucky
    'KY': '40003-42788',
    // Louisiana
    'LA': '70001-71497',
    // Maine
    'ME': '03901-04992',
    // Maryland
    'MD': '20588-21930',
    // Massachusetts
    'MA': '01001-05544',
    // Michigan
    'MI': '48001-49971',
    // Minnesota
    'MN': '55001-56763',
    // Mississippi
    'MS': '38601-39776',
    // Missouri
    'MO': '63001-65899',
    // Montana
    'MT': '59001-59937',
    // Nebraska
    'NE': '68001-69367',
    // Nevada
    'NV': '88901-89883',
    // New Hampshire
    'NH': '03031-03897',
    // New Jersey
    'NJ': '07001-08989',
    // New Mexico
    'NM': '87001-88439',
    // New York
    'NY': '00501-14925',
    // North Carolina
    'NC': '27006-28909',
    // North Dakota
    'ND': '58001-58856',
    // Ohio
    'OH': '43001-45999',
    // Oklahoma
    'OK': '73001-74966',
    // Oregon
    'OR': '97001-97920',
    // Pennsylvania
    'PA': '15001-19640',
    // Rhode Island
    'RI': '02801-02940',
    // South Carolina
    'SC': '29001-29945',
    // South Dakota
    'SD': '57001-57799',
    // Tennessee
    'TN': '37010-38589',
    // Texas
    'TX': '73301-88595',
    // Utah
    'UT': '84001-84791',
    // Vermont
    'VT': '05001-05907',
    // Virginia
    'VA': '20101-24658',
    // Washington
    'WA': '98001-99403',
    // West Virginia
    'WV': '24701-26886',
    // Wisconsin
    'WI': '53001-54990',
    // Wyoming
    'WY': '82001-83414'
};

/**
* Finds the state associated with the given postal code
* @param {string} postalCode - the postal code
* @returns {string} - the associated state or empty string if not found
*/
function getPostalState(postalCode) {
    if (!postalCode) {
        return '';
    }

    // get the first 5 digits of the postal code
    var postalValue = postalCode.split('-')[0].trim();

    // if the postal code is not 5 digits, do nothing
    if (postalValue.length !== 5) {
        return '';
    }

    // convert the postal code to an integer
    postalValue = parseInt(postalValue, 10);

    var foundState = Object.keys(postalMap).find(function (state) {
        var range = postalMap[state].split('-');
        var min = parseInt(range[0], 10);
        var max = parseInt(range[1], 10);

        return postalValue >= min && postalValue <= max;
    });

    return foundState || '';
}

/**
* Handles the change event of the postal code field
* @param {jQuery Object} e - the change event object
*/
function onPostalChange(e) {
    var $postal = $(e.target),
        postal = $postal.val(),
        state = getPostalState(postal);

    if (!state) {
        return;
    }

    cache.$state.val(state);
}

/**
* Initializes the cache object with the form elements
* @param {jQuery Object} $form - jQuery object of the form
*/
function initCache($form) {
    cache.$form = $form;
    cache.$country = $form.find('select[name$="_country"]');
    cache.$state = $form.find('select[name$="_states_state"]');
    cache.$postal = $form.find('input[name$="_postal"]');
}

/**
* Initializes events for the USAStatePostalMapping module
*/
function initEvents() {
    cache.$postal.on('keyup input', onPostalChange);
}

/**
* Initializes the US state/postal mapping
* @param {jQuery Object} $form - jQuery object of the form
*/
function init($form) {
    // if no form is passed, do nothing
    if (!$form) {
        return;
    }

    initCache($form);

    // if country is not US, do nothing
    if (cache.$country.val() !== 'US') {
        return;
    }

    initEvents();
}

/**
* Updates the form
* @param {Object} $form - the form to update
*/
function update($form) {
    // use the new provided form or the cached one
    var $updatedForm = $form || cache.$form;
    initCache($updatedForm);

    // if country is not US, do nothing
    if (cache.$country.val() !== 'US') {
        return;
    }

    initEvents();
}

module.exports = {
    postalMap: postalMap,
    init: init,
    update: update
};
