'use strict';

var addProductToCart = require('./product/addToCart'),
    tooltip = require('../tooltip'),
    util = require('../util'),
    dialog = require('../dialog'),
    login = require('../login'),
    validator = require('../validator'),
    // ERB-213 - Address forms - USA/Canada states logic (+ Australia and Mexico)
    ajax = require('../ajax'),
    progress = require('../progress'),
    _debounce = require('../lib/lodash.debounce'),
    googleplaces = require('../googleplaces'),
    usaPostalMap = require('../helpers/usaStatePostalMapping');
    // ECM-880 - Adyen 3DSv2 - new logic but not used (not storing credit card)
    //adyenCse = require('./checkout/adyen-checkout');

/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initializeAddressForm() {
    var $form = $('#edit-address-form');

    $form.find('input').filter('[name="format"]').remove();
    $form.validate(); // required for the address add/edit dialogs
    tooltip.init();

    // Initialize the jQuery UI elements
    window.vbqUtils.initializeJQueryUiPlugins();
    window.vbqUtils.initFloatingLabels($form);
    window.vbqUtils.phoneCodes.init($form.find('.country_field'), $form.find('.phone_field'));

    // ECM23-119 - USA ZIP Codes & Postal Codes List By States
    usaPostalMap.init($form);

    // ERB-213 - Address forms - USA/Canada states logic (+ Australia and Mexico)
    // Update the address form
    $form.on('change', '[name$="_address_country"]', function () {
        var $this = $(this),
            countryCode = $this.val();
        ajax.load({
            url: util.appendParamsToUrl(Urls.updateAddressForm, {'countryCode': countryCode, 'form': $form.serialize()}),
            callback: function (response) {
                $form.find('.address-dynamic-form').html(response);

                validator.init();
                // Initialize the jQuery UI elements
                window.vbqUtils.initializeJQueryUiPlugins();
                window.vbqUtils.initFloatingLabels($form);
                window.vbqUtils.phoneCodes.init($form.find('.country_field'), $form.find('.phone_field'));

                // update googleplaces country and cache the new form elements
                googleplaces.update({
                    country: countryCode
                });

                // ECM23-119 - USA ZIP Codes & Postal Codes List By States
                // update cached form elements and events
                usaPostalMap.update();
            }
        });
    });

    $form.on('submit', function (e) {
        e.preventDefault();
        if (!$form.valid()) {
            return false;
        }

        var $phone = $form.find('.phone_field');
        $phone.siblings('input').val(window.intlTelInputGlobals.getInstance($phone[0]).getNumber());

        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax'),
            applyName = $form.find('.apply-button').attr('name'),
            options = {
                url: url,
                data: $form.serialize() + '&' + applyName + '=x',
                type: 'POST'
            };
        $.ajax(options).done(function (data) {
            if (typeof(data) !== 'string') {
                if (data.success) {
                    window.vbqUtils.progressLoader(true, $('.js-address-loader'));
                    dialog.close();
                    window.location.reload(true);
                }
                else if (data.error) {
                    window.location.href = Urls.csrffailed;
                }
                else if (data.message) {
                    window.alert(data.message);
                    return false;
                }
                else {
                    window.alert(window.Resources.SERVER_ERROR);
                    return false;
                }
            }
            else {
                $('#dialog-container').html(data);
                account.init();
                tooltip.init();
            }
        });
    })
    .on('click', '.cancel-button, .close-button', function (e) {
        e.preventDefault();
        dialog.close();
    })
    .on('click', '.delete-button', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
            var url = util.appendParamsToUrl(Urls.deleteAddress, {
                AddressID: $form.find('#addressid').val(),
                format: 'ajax'
            });
            $.ajax({
                url: url,
                method: 'POST',
                dataType: 'json'
            }).done(function (data) {
                if (data.status.toLowerCase() === 'ok') {
                    window.vbqUtils.progressLoader(true, $('.js-address-loader'));
                    dialog.close();
                    window.location.reload(true);
                }
                else if (data.message.length > 0) {
                    window.alert(data.message);
                    return false;
                }
                else {
                    window.vbqUtils.progressLoader(true, $('.js-address-loader'));
                    dialog.close();
                    window.location.reload(true);
                }
            });
        }
    });

    validator.init();

    //ERV-485 - Checkout - Billing and Payment - Guest - apply form validation logic improvements
    window.vbqUtils.validationLogic.formValidation($form);
}

/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder () {
    $('.order-items')
        .find('li.hidden:first')
        .prev('li')
        .append('<a class="toggle">View All</a>')
        .children('.toggle')
        .click(function () {
            $(this).parent().siblings('li.hidden').show();
            $(this).remove();
        });
}

/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    var $addresses = $('#js-addresses'),
        addressDialog;

    if ($addresses.length === 0) { return; }

    $addresses.on('click', '.address-edit, .address-create', function (e) {
        e.preventDefault();
        // ECM-394 - Account address popup when session expires - Check that the customer is still authenticated before trying to open the dialog
        var $addressDialogOpen = $(this),
            url = $addressDialogOpen.data('href'),
            title = $addressDialogOpen.hasClass('address-edit') ? window.Resources.EDIT_ADDRESS : window.Resources.ADD_ADDRESS,
            $loader = $('.js-address-loader');

        window.vbqUtils.progressLoader(true, $loader);

        $.ajax({
            url: util.appendParamToURL(window.Urls.isCustomerAuthenticated, 'format', 'ajax')
        }).done(function (data) {
            if (!data.success) {
                window.location.reload(true);
            }
            else {
                addressDialog = dialog.open({
                    url: url,
                    options: {
                        title: title,
                        open: initializeAddressForm,
                        dialogClass: 'account-address-dialog'
                    },
                    position: {
                        my: 'center top',
                        at: 'center top',
                        of: window
                    },
                    callback: function() {
                        window.vbqUtils.progressLoader(false, $loader);

                        var CountryElement = document.querySelector('[name$=_address_country'),
                            country = CountryElement && CountryElement.value || '';

                        // EAWGC-1: initialize googleplaces api
                        googleplaces.init({
                            country: country
                        });

                        // Listen for custom events to reposition the dialog using jQuery
                        addressDialog.$dialog.on('googleplaces:updated', function() {
                            addressDialog.repositionDialog();
                        });
                    },
                    closeCallback: function () {
                        addressDialog.$dialog.off('googleplaces:updated');
                    }
                });
            }
        }).fail(function () {
            window.location.reload(true);
        });
    }).on('click', '.delete', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
            $.ajax({
                url: util.appendParamToURL($(this).attr('href'), 'format', 'ajax'),
                dataType: 'json'
            }).done(function (data) {
                if (data.status.toLowerCase() === 'ok') {
                    window.vbqUtils.progressLoader(true, $('.js-address-loader'));
                    window.location.href = Urls.addressesList;
                }
                else if (data.message.length > 0) {
                    window.alert(data.message);
                }
                else {
                    window.vbqUtils.progressLoader(true, $('.js-address-loader'));
                    window.location.reload(true);
                }
            });
        }
    });
}

/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
/* not used
function initPaymentEvents() {
    $('.add-card').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                open: initializePaymentForm
            }
        });
    });

    var paymentList = $('.payment-list');
    if (paymentList.length === 0) {
        return;
    }

    util.setDeleteConfirmation(paymentList, String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD));

    $('form[name="payment-remove"]').on('submit', function (e) {
        e.preventDefault();
        // override form submission in order to prevent refresh issues
        var button = $(this).find('.delete');
        $('<input/>').attr({
            type: 'hidden',
            name: button.attr('name'),
            value: button.attr('value') || 'delete card'
        }).appendTo($(this));
        var data = $(this).serialize();
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: data
        })
        .done(function () {
            window.location.href = Urls.paymentsList;
        });
    });
}

function initializePaymentForm() {
    // Initialize the jQuery UI elements
    window.vbqUtils.initializeJQueryUiPlugins();

    $('#CreditCardForm').on('click', '.cancel-button', function (e) {
        e.preventDefault();
        dialog.close();
    });

    // ECM-880 - Adyen 3DSv2 - new logic but not used (not storing credit cards)
    if (SitePreferences.ADYEN_CSE_ENABLED) {
        adyenCse.initAccount();
    }
}
*/

// ERF-56 Account pages
function initializeDOM() {
    var $accountNavigationLinks = $('#secondary').find('.account-navigation-list-link'),
        $accountNavigationMobile = $('.account-navigation-list-mobile'),
        $loginpageSectionsWrapper = $('.myaccount-login-wrapper');

    // Set account navigation select to current option
    // vbqutils.page set in isml
    $accountNavigationMobile.find('option[data-page="' + window.vbqUtils.page + '"]').prop('selected', true);

    // Set active on navigation element corresponding to current page
    if (window.vbqUtils.page && window.vbqUtils.breakpoint.is('fromDesktop')) {
        $accountNavigationLinks.removeClass('.active').filter('[data-page="' + window.vbqUtils.page + '"]').addClass('active');
    }

    window.vbqUtils.breakpoint.set([
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('mobileAndTablet'),
            enter: function () {
                // add extra classes to selectmenu plugin
                $accountNavigationMobile.addClass('js-selecmenu').selectmenu({
                    classes: {
                        'ui-selectmenu-button': 'acc-selectmenu-button',
                        'ui-selectmenu-menu': 'acc-selectmenu-menu'
                    }
                }).data('selectmenu', true);

                if ($loginpageSectionsWrapper.length && !$loginpageSectionsWrapper.hasClass('accordion-initialized')) {
                    $loginpageSectionsWrapper.customAccordion();
                }
            }
        },
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('fromDesktop'),
            enter: function () {
                if ($accountNavigationMobile.hasClass('js-selecmenu')) {
                    $accountNavigationMobile.removeClass('js-selecmenu').selectmenu('destroy').data('selectmenu', false);
                }

                if ($loginpageSectionsWrapper.length && $loginpageSectionsWrapper.hasClass('accordion-initialized')) {
                    $loginpageSectionsWrapper.customAccordion('destroy');
                }
            }
        }
    ]);

    // ERV-443 layout broken on viewport switch in account
    // Reload selectmenu or it's position will break window width on small viewports
    window.vbqUtils.cache.$window.resize(_debounce(function () {
        if ($accountNavigationMobile.data('selectmenu')) {
            $accountNavigationMobile.selectmenu('destroy').selectmenu({
                classes: {
                    'ui-selectmenu-button': 'acc-selectmenu-button',
                    'ui-selectmenu-menu': 'acc-selectmenu-menu'
                }
            });
        }
    }, 200));

    $accountNavigationMobile.on('selectmenuchange', function (e) {
        window.location = $(this).val();
    });
}

/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
    toggleFullOrder();
    initAddressEvents();
    //initPaymentEvents();
    login.init();

    // reCaptcha init
    window.vbqUtils.recaptchaHelper.init();

    $('#ContactForm').on('submit', function () {
        var $form = $(this);

        // do not show the loader if the form is not valid
        if (!$form.valid()) {
            return;
        }

        progress.show($form.find('button'));
    });
}

var account = {
    init: function () {
        initializeDOM();
        initializeEvents();
        //giftcert.init();
        addProductToCart();

        $('.disabled').on('click', function (e) {
            e.preventDefault();
            return false;
        });

        // ERV-43 My Account - change password issue
        // add validation rules for password fields
        // ERV-125 registration page - no red text or borders on fields with errors
        // add validation for email field
        $('form').each(function () {
            var $form = $(this);
            $form.validate(); //required before adding rule

            // EW-46 - Revised DESKTOP forms - add "same value" check for email and password "confirmation" fields
            $form.find('#dwfrm_profile_customer_emailconfirm').rules('add', {
                equalTo: '#dwfrm_profile_customer_email'
            });

            $form.find('input').filter('[name^="dwfrm_profile_login_passwordconfirm_"]').rules('add', {
                equalTo: '[name^="dwfrm_profile_login_password_"]'
            });

            $form.find('input').filter('[name^="dwfrm_profile_login_newpasswordconfirm_"]').rules('add', {
                equalTo: '[name^="dwfrm_profile_login_newpassword_"]'
            });

            $form.find('input').filter('[name^="dwfrm_resetpassword_passwordconfirm_"]').rules('add', {
                equalTo: '[name^="dwfrm_resetpassword_password_"]'
            });

            //ERV-485 - Checkout - Billing and Payment - Guest - apply form validation logic improvements
            window.vbqUtils.validationLogic.formValidation($form);
        });

        var $indonesiaIneForm = $('#IndonesiaIneForm');
        if ($indonesiaIneForm.length) {
            var $inenumber = $indonesiaIneForm.find('#dwfrm_indonesiaine_inenumber'),
                $niknumber = $indonesiaIneForm.find('#dwfrm_indonesiaine_niknumber'),
                $idlnumber = $indonesiaIneForm.find('#dwfrm_indonesiaine_idlnumber'),
                $pnumber = $indonesiaIneForm.find('#dwfrm_indonesiaine_pnumber');

            $inenumber.rules('add', {require_from_group: [1, '.js-grouprequired']});
            $niknumber.rules('add', {require_from_group: [1, '.js-grouprequired']});
            $idlnumber.rules('add', {require_from_group: [1, '.js-grouprequired']});
            $pnumber.rules('add', {require_from_group: [1, '.js-grouprequired']});

            if ($('.contactus-submit-error').length) {
                var validator = $indonesiaIneForm.validate();
                validator.element($inenumber[0]);
            }
        }

        var $contactUsCounterMessage = $('#js-ContactUsCounterMessage'),
            rawCounterMessage = $contactUsCounterMessage.attr('data-raw');
        $('#ContactForm').find('.input-textarea').on('keyup change', function () {
            var length = $(this).val().length;
            $contactUsCounterMessage.html(rawCounterMessage.replace(/\{0\}/, (1000 - length).toString()));
        });

        var $registrationForm = $('#RegistrationForm');
        if ($registrationForm.length) {
            var $country = $registrationForm.find('.country_field'),
                $phone = $registrationForm.find('.phone_field'),
                $title = $registrationForm.find('#dwfrm_profile_customer_title'),
                titleValue = $registrationForm.find('#title-value').val(),
                countryCode;

            if ($country.val() === ''
                && window.vbq
                && window.vbq.localizationData
                && window.vbq.localizationData.countryCode
                && window.vbq.localizationData.countryCode != 'not_supported'
            ) {
                countryCode = window.vbq.localizationData.countryCode;
                $country.val(countryCode);
            }

            window.vbqUtils.phoneCodes.init($country, $phone);

            // security to force the value, DW bug
            if (titleValue !== null && titleValue !== undefined && titleValue !== '' && $title.val() != titleValue) {
                $title.val(titleValue);
            }
        }

        var consentFormSubmitting = false;
        $('#js-confirmConsentSubmit').on('click', function (e) {
            if (consentFormSubmitting) {
                e.preventDefault();
                return false;
            }

            consentFormSubmitting = true;
            progress.show($(this).addClass('disabled'));
        });
    },
    initCheckoutLogin: function () {
        login.init();
    }
};

module.exports = account;
