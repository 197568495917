'use strict';

var _debounce = require('../lib/lodash.debounce');
var cache = {};

/**
 * Initializes the cache for document, sticky header and anchor banner
 */
function initCache() {
    cache.$document = $(document);
    cache.$stickyHeader = cache.$document.find('.js-header-sticky-panel');
    cache.$anchorBanner = cache.$document.find('.js-anchorbanner-sticky');

    // security
    if (cache.$stickyHeader.length === 0) {
        return;
    }

    // set parent element of anchorBanner to the same height, so that the anchor banner does not jump up
    // when it becomes sticky
    cache.$anchorBanner.parent().css('height', cache.$anchorBanner.outerHeight() + 'px');
    cache.anchorBannerMarginTop = parseFloat(cache.$anchorBanner.css('margin-top') || 0);
}

/**
 * Handles the scroll action on the page to make the anchor banner sticky
 * @returns {void}
 */
function onPageScrollAction(isResize) {
    var scrollPosition = window.vbqUtils.cache.$window.scrollTop();

    if ((scrollPosition + cache.$stickyHeader.outerHeight()) >= (cache.$anchorBanner.parent().offset().top + cache.anchorBannerMarginTop)) {
        if (!cache.$anchorBanner.hasClass('sticky') || isResize) {
            cache.$anchorBanner.addClass('sticky').css('top', (cache.$stickyHeader.outerHeight() - cache.anchorBannerMarginTop) + 'px');
        }
    } else {
        if (cache.$anchorBanner.hasClass('sticky')) {
            cache.$anchorBanner.removeClass('sticky').css('top', '');
        }
    }
}

/**
 * Initializes the events for the anchor banner
 * @returns {void}
 */
function initEvents() {
    window.vbqUtils.cache.$window.on('scroll', _debounce(function () {
        onPageScrollAction();
    }, 50));

    window.vbqUtils.cache.$window.on('resize', _debounce(function () {
        onPageScrollAction(true);
    }, 50));
}

module.exports = {
    init: function () {
        initCache();

        // if anchor banner is not present, do not initialize the events.
        if (!cache.$anchorBanner || cache.$anchorBanner.length === 0) {
            return;
        }

        initEvents();
    }
};
